import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { Accordion } from 'react-bootstrap';

export const ProfileSwanFAQ: React.FC = () => {
  return (
    <div className="profile ">
      <div className={`content-header Wind`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Frequently Asked
        Questions
      </div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Is Black Swan better than Sampo?</Accordion.Header>
          <Accordion.Body>
            <p>Yes! She is better in all scenarios.</p>
            <ul>
              <li>
                In AoE she is leagues ahead of Sampo - it's not even close.
              </li>
              <li>
                In single target, assuming Sampo is E6, he is only moderately
                behind Black Swan in performance.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Is Black Swan viable without Kafka?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Yes! Black Swan is viable without Kafka although you will lose
              some level of consistency and power, and you will be forced to use
              other units anyway.
            </p>
            <ul>
              <li>
                Sampo E4+ can be used as a substitute for Kafka but will be less
                flexible and smooth to run than Kafka,
              </li>
              <li>
                In pure single target against specific enemies Sampo is
                competitive with Kafka but in AoE he falls behind Kafka,
              </li>
              <li>
                Guinaifen is less powerful and does not have access to the
                ability to detonate Swan's Arcana consistently even with
                Eidolons. She is not equal in power compared to Sampo E4+ or
                Kafka when acting as the sole synergy for Swan.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Do you need Kafka's Signature on your second DoT character?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Kafka's signature - Patience Is All You Need - is not required in
              order for Swan and her team's to perform well but is a substantial
              boost to her power specifically when you're running Kafka + Swan.
            </p>
            <p>
              In this specific team composition, the additional Shock allows
              Kafka to always detonate 3 DoTs applying 3 extra Arcana as opposed
              to 2 (Arcana + Kafka Shock) that she would without it. The
              additional DoT will also apply yet another Arcana stack before
              Arcana deals its damage netting you a consistent 2 extra Arcana
              almost at all times. The extra DoT also helps enable to 4P
              Prisoner set bonus. Without the signature Light Cone you will need
              to find another source of DoT - either from Sampo/Guinaifen or
              from breaking the enemy (not reliable).
            </p>
            <p>
              Conclusion - Kafka's Signature Light Cone isn't needed but the
              guaranteed 3rd DoT opens up team flexibility, ensures more Arcana
              stacks and activates your 4P bonus at all times.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            Do you need Black Swan's Signature Light Cone?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Black Swan's Signature Light Cone is not required but is a large
              damage boost to her personal damage primarily thanks to the 28.8%
              DEF Shred it provides - which is incredibly challenging to replace
              without relying on specific units like Pela or Silver Wolf.
            </p>
            <p>
              Almost all Black Swan teams are double DPS teams and her Signature
              grants her around 20-25% more damage compared to S5 Eye's Of The
              Prey depending on the situation and fight. But in a Kafka + Swan
              team if we assume Kafka deals half the damage and Swan deals the
              other half that would only improve the team's overall damage by
              10-12.5% as the team's damage is split.
            </p>
            <p>
              Conclusion - Black Swan's Signature Light Cone is exceptionally
              powerful but as almost all her currently known teams are double
              damage the huge bonus is diluted.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Why is Black Swan's Theoretical AoE damage so high without feeling
            that powerful in game?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Black Swan's Arcana deals 180% DoT damage to adjacent enemies when
              it detonates while having 3 or more stacks for a total possible
              multiplier of 360% per enemy explosion. In addition to this each
              explosion also further stacks Arcana on adjacent enemies
              perpetuating the 3 stack explosions and causing them to stack even
              higher.
            </p>
            <p>
              Black Swan also always has her 240% multiplier Arcana on all
              enemies at all times immediately. These factors combine to create
              an absurd amount of damage for Swan but in realty she loses a
              large portion of that damage for the following reasons:
            </p>
            <ul>
              <li>
                When an enemy dies before Arcana triggers due to Break, combat
                or other DoTs slaying them first, Arcana will not activate and
                will not deal it's splash damage to adjacent targets leading to
                a massive loss in potential damage. This happens frequently as
                Kafka and Sampo's DoTs are not small and will always trigger
                before Swan's Arcana.
              </li>
              <li>
                The other reason for massive loss of damage is overkill damage
                and the fact that most enemies can only survive 2 and in most
                cases 1 turn of Black Swan and her team's DoTs to begin with
                causing a lot of the value of her DoT spreading and AoE damage
                to be lost before it can ramp to full effect.
              </li>
            </ul>
            <p>
              Conclusion - Black Swan's theoretical AoE damage is insane but
              there aren't to many fights in the game for her to make use of it
              at this time. A fight with 3+ elite enemies would be ideal for her
              to unleash her full potential. Her AoE is still exceptionally
              strong she just rarely faces enemies who allow her to fully
              unleash that potential, which means that she will get stronger and
              stronger as the game difficulty will keep increasing.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
