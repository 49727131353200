import React, { useState } from 'react';
import { HSRCharacter } from '../common/components/hsr-character';
import { Row, Col, Accordion, Alert } from 'react-bootstrap';
import { HSRLightCone } from '../common/components/hsr-light-cone';
import { HSRRelicSet } from '../common/components/hsr-relic-set';
import { HSRStat } from '../common/components/hsr-stat';
import { HSRDPSAcheronDPS } from '../dps-rankings/acheron-array';

export const ProfileAcheronDPS: React.FC = () => {
  const [currentTab, setCurrentTab] = useState('V3');
  return (
    <>
      <Alert variant="primary alert-red ">
        <p>
          <strong>
            Don't use the damage output calculations as an indication on who is
            worth pulling or investing as those are just simulations and in
            day-to-day gameplay there's a lot variables
          </strong>
          . Use the numbers only to supplement your knowledge and treat them as
          the 'floor' of a character's damage output which you can further scale
          with proper team and investment.
        </p>
      </Alert>
      <p>
        The simulation showcases characters{' '}
        <strong>
          damage potential in the 8 cycle turn limit of Memory of Chaos against
          1 target and 3 targets
        </strong>
        . The section below has all the rules listed.
      </p>
      <Accordion className="sim-rules">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Simulation rules</Accordion.Header>
          <Accordion.Body>
            <p>
              <strong>General:</strong>
            </p>
            <ul>
              <li>
                Combat is simulated at 8 cycles of in-game time or 850 Action
                Points,
              </li>
              <li>
                Units are considered to gain 5 additional energy per rotation
                through combat (taking a hit, killing an enemy or through party
                members and their Light Cone),
              </li>
              <li>
                Characters and Light Cones are calculated at maximum output
                starting battle with ramp time removed from stacking buffs
                (intended to give a constant evaluation of power not dependant
                on length of fight that can be used to assess maximum power).
                Additional information about loss of damage due to ramp time can
                be round attached to related Light Cones, relics and characters,
              </li>
              <li>
                Character rotations are smoothed removing breakpoints relating
                to Speed and Energy (finishing a fight with 90% energy full or 2
                AV off next ability leading to skewed results). This removes any
                specific cut off point 850 AV, 750 AV etc, from impacting the
                results.
              </li>
            </ul>
            <p>
              <strong>Enemies:</strong>
            </p>
            <ul>
              <li>
                Enemies are considered to always have a Break Bar resulting in
                units all doing 90% of real damage,
              </li>
              <li>Enemies speed is set to 134,</li>
              <li>
                Enemies are assumed to be at 100% of HP at start of battle and
                to die at exactly at the end of combat. Which means halfway
                through the fight they are at 50% HP,
              </li>
              <li>Enemies are assumed to have 0 Resistance,</li>
              <li>
                Enemies are always considered vulnerable to the attackers
                element,
              </li>
              <li>Enemies are considered level 95 and with DEF included.</li>
            </ul>
            <p>
              <strong>Units</strong>:
            </p>
            <ul>
              <li>All units are level 80,</li>
              <li>
                Traces are all unlocked, skills at Level 10 and Basic at Level
                6,
              </li>
              <li>
                All units have fully upgraded 5 star relics with 24 desired sub
                stats out of a possible 54,
              </li>
              <li>All units use their Best In Slot 2 and 4 piece sets,</li>
              <li>
                All units use Light Cones that are realistically obtainable by
                all players (including those in Herta and FH Stores and Battle
                Pass),
              </li>
              <ul>
                <li>
                  Because the Herta Store and farmable Light Cones are easily
                  accessible to playerbase, we are simulating them at
                  Superimposition 5.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <h5>Acheron disclaimers:</h5>
      <ul>
        <li>
          The numbers below compare the damage for E0-E6 between{' '}
          <strong>Good Night Sleep Well S5</strong> and{' '}
          <strong>Along the Passing Shore S1</strong> in 3 distinct scenarios,
        </li>
        <ul>
          <li>
            4 debuffs assumes Acheron gains 4 stacks of Slashed Dream from her
            teammates every action she takes,
          </li>
          <li>
            6 debuffs assumes Acheron gains 6 stacks of Slashed Dream from her
            teammates every action she takes,
          </li>
          <li>
            x2 Nihility means Acheron has 2 Nihility characters in her team
            activating her bonus ability granting 1.6 times damage,
          </li>
          <li>
            x1 Nihility means Acheron has 1 Nihility character only in her team
            only granting 1.15 times damage,
          </li>
          <li>
            The stacks of Slashed Dream Acheron gains from her teammates
            applying debuffs to enemies are included in these calcs but{' '}
            <strong>
              the damage and effects of the debuffs outside of Acheron's own
              personal kit are not.
            </strong>
          </li>
        </ul>
        <li>
          The criteria were made to properly showcase Acheron's kit as she
          doesn't use Energy and relies on Nihility partners to actually perform
          well. If you try running Acheron with x2 Harmony, her damage will
          suffer greatly,
        </li>
        <li>
          In game, you will most likely be able to obtain more stacks as we
          don't include Break in the calculation and breaking an enemy gives
          Acheron a stack (as Break is a debuff),
        </li>
        <li>
          Because of that you should not directly compare the numbers with other
          damage dealers (which you should not do in the first place anyway).
        </li>
      </ul>
      <div className="tabs acheron">
        <div
          className={`single-tab ${currentTab === 'V3' ? 'active' : ''}`}
          onClick={() => setCurrentTab('V3')}
        >
          <p>1x Nihility (4 debuffs)</p>
        </div>
        <div
          className={`single-tab ${currentTab === 'V1' ? 'active' : ''}`}
          onClick={() => setCurrentTab('V1')}
        >
          <p>2x Nihility (4 debuffs)</p>
        </div>
        <div
          className={`single-tab ${currentTab === 'V2' ? 'active' : ''}`}
          onClick={() => setCurrentTab('V2')}
        >
          <p>2x Nihility (6 debuffs)</p>
        </div>
      </div>
      <Row xs={1} xl={2} className="dps-comparison">
        <Col>
          <div className="box">
            <h5>Acheron [1 target]</h5>
            <div className="simulation-container">
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E6</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].sig_e6.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].sig_e6 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].e6 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].e6.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].e6 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E5</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].sig_e5 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].sig_e5.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].sig_e5 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].e5 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].e5.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].e5 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E4</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].sig_e4 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].sig_e4.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].sig_e4 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].e4 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].e4.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].e4 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E3</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].sig_e3 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].sig_e3.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].sig_e3 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].e3 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].e3.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].e3 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E2</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].sig_e2 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].sig_e2.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].sig_e2 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].e2 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].e2.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].e2 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E1</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].sig_e1 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].sig_e1.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].sig_e1 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].e1 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].e1.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].e1 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E0</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].sig_e0 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].sig_e0.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].sig_e0 /
                            HSRDPSAcheronDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].e0 /
                            HSRDPSAcheronDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].e0.toLocaleString()} dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="box">
            <h5>Acheron [3 targets]</h5>
            <div className="simulation-container">
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E6</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[
                          currentTab
                        ].alt_sig_e6.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e6 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_e6 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].alt_e6.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_e6 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E5</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e5 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[
                          currentTab
                        ].alt_sig_e5.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e5 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_e5 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].alt_e5.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_e5 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E4</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e4 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[
                          currentTab
                        ].alt_sig_e4.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e4 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_e4 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].alt_e4.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_e4 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E3</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e3 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[
                          currentTab
                        ].alt_sig_e3.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e3 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_e3 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].alt_e3.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_e3 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E2</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e2 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[
                          currentTab
                        ].alt_sig_e2.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e2 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_e2 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].alt_e2.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_e2 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E1</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e1 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[
                          currentTab
                        ].alt_sig_e1.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e1 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_e1 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].alt_e1.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_e1 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="acheron" mode="icon" />
                  <span className="eidolon">E0</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e0 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Along the Passing Shore"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[
                          currentTab
                        ].alt_sig_e0.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSAcheronDPS[currentTab].alt_sig_e0 /
                            HSRDPSAcheronDPS[currentTab].alt_e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSAcheronDPS[currentTab].alt_e0 /
                            HSRDPSAcheronDPS[currentTab].alt_sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Good Night and Sleep Well"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSAcheronDPS[currentTab].alt_e0.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="section-build perf">
        <div className="build-relics perf">
          <Row xs={1} xl={2} xxl={2} className="relics">
            <Col>
              <h6 className="header">Relic Set</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet name="Pioneer Diver of Dead Waters" compactMode />
              </div>
            </Col>
            <Col>
              <h6 className="header">Planetary Sets</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet
                  name="Izumo Gensei and Takama Divine Realm"
                  compactMode
                />
              </div>
            </Col>
          </Row>
        </div>
        <h6 className="header">Relic Stats</h6>
        <div className="build-stats">
          <Row xs={1} xl={2} xxl={4} className="main-stats">
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Body</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="CRIT Rate" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Feet</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="Speed" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Planar Sphere</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="ATK" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Link Rope</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="ATK" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="box sub-stats">
                <span>Substats:</span>
                <p>Crit Rate (23.3%), CRIT DMG (70%), Speed (9.2)</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
