export const HSRDPSAcheronDPS = {
  V1: {
    e0: 724780,
    e1: 817795,
    e2: 925733,
    e3: 967588,
    e4: 1022747,
    e5: 1062968,
    e6: 1277625,
    alt_e0: 1525812,
    alt_e1: 1721628,
    alt_e2: 1968617,
    alt_e3: 2078329,
    alt_e4: 2205661,
    alt_e5: 2262120,
    alt_e6: 2700906,
    sig_e0: 923190,
    sig_e1: 1051732,
    sig_e2: 1177888,
    sig_e3: 1234959,
    sig_e4: 1310171,
    sig_e5: 1356574,
    sig_e6: 1621790,
    alt_sig_e0: 1973202,
    alt_sig_e1: 2247946,
    alt_sig_e2: 2536620,
    alt_sig_e3: 2686218,
    alt_sig_e4: 2859844,
    alt_sig_e5: 2928390,
    alt_sig_e6: 3480480
  },
  V2: {
    e0: 920915,
    e1: 1039101,
    e2: 1147039,
    e3: 1203196,
    e4: 1277203,
    e5: 1321665,
    e6: 1578732,
    alt_e0: 1974616,
    alt_e1: 2228029,
    alt_e2: 2475019,
    alt_e3: 2622221,
    alt_e4: 2793064,
    alt_e5: 2859314,
    alt_e6: 3396000,
    sig_e0: 1150232,
    sig_e1: 1310388,
    sig_e2: 1436543,
    sig_e3: 1510330,
    sig_e4: 1607573,
    sig_e5: 1658932,
    sig_e6: 1937715,
    alt_sig_e0: 2492731,
    alt_sig_e1: 2839813,
    alt_sig_e2: 3128487,
    alt_sig_e3: 3321903,
    alt_sig_e4: 3546383,
    alt_sig_e5: 3626371,
    alt_sig_e6: 4292884
  },
  V3: {
    e0: 548014,
    e1: 618343,
    e2: 925733,
    e3: 967588,
    e4: 1022747,
    e5: 1062968,
    e6: 1277625,
    alt_e0: 1123755,
    alt_e1: 1267973,
    alt_e2: 1968617,
    alt_e3: 2078329,
    alt_e4: 2205661,
    alt_e5: 2262120,
    alt_e6: 2700906,
    sig_e0: 701157,
    sig_e1: 798784,
    sig_e2: 1177888,
    sig_e3: 1234959,
    sig_e4: 1310171,
    sig_e5: 1356574,
    sig_e6: 1621790,
    alt_sig_e0: 1455843,
    alt_sig_e1: 1658563,
    alt_sig_e2: 2536620,
    alt_sig_e3: 2686218,
    alt_sig_e4: 2859844,
    alt_sig_e5: 2928390,
    alt_sig_e6: 3480480
  }
};
