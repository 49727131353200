import React from 'react';
import Chart from 'react-google-charts';

export const dataCalcs = [
  ['Skill', 'Dmg'],
  ['Firefly Super Break', 98571],
  ['Firefly Break', 79023],
  ['Firefly DMG', 27336]
];

export const options = {
  is3D: true,
  pieSliceText: 'percentage',
  backgroundColor: '#2C2D33',
  legend: {
    position: 'bottom',
    alignment: 'center',
    textStyle: { color: '#ffffff' },
    scrollArrows: { inactiveColor: '#ffffff', activeColor: '#ffffff' },
    pagingTextStyle: { color: '#ffffff' }
  },
  chartArea: { top: 20 },
  slices: {
    0: { color: '#009EEC' },
    1: { color: '#C267EC' },
    2: { color: '#f75058' },
    3: { color: '#56b972' },
    4: { color: '#76F8F8' },
    5: { color: '#F9A2A2' },
    6: { color: '#e29c55' }
  }
};

export const ProfileFireflySoloDistribution: React.FC = () => {
  return (
    <>
      <div className="damage-distribution">
        <Chart
          chartType="PieChart"
          data={dataCalcs}
          options={options}
          width={'100%'}
          height={'400px'}
        />
      </div>
    </>
  );
};
