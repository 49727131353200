import React from 'react';

export const ProfileSynergyRatio: React.FC = () => {
  return (
    <>
      <ul>
        <li>
          Most of Ratio's damage (around 70% at E0) comes from his follow-up
          attacks.
        </li>
        <li>
          Ratio is a single target specialist and much of his kit revolves
          around that.
        </li>
        <li>
          Has some limited potential to deal with multiple targets thanks to his
          follow-up attacks 'retargetting' if his target dies before launching
          it.
        </li>
        <li>
          Ultimate is entirely single target based and relies on that target not
          dying before it's effect is complete.
        </li>
        <li>
          Has 40% chance to launch a follow-up with his skill baseline and an
          additional 20% chance per debuff active on the enemy at the time of
          target selection. Ratio only achieves his maximum potential when
          attacking enemies with 3 debuffs and loses substantial damage without.
        </li>
        <li>
          Deals up to 50% increased damage against enemies with 3 or more
          debuffs (10% per debuff 30% minimum - 50% maximum). 5 debuffs is ideal
          for maximum talent activations and damage.
        </li>
        <li>
          Ratio can apply 1 debuff himself with his skill. As most of his damage
          comes from his follow-up attack and the skill always occurs before
          that you only need 4 additional debuffs from the team to achieve
          maximum efficiency.
        </li>
        <li>
          Has a incredibly powerful self stacking buff that stacks when striking
          debuffed enemies, the more debuffs that enemy has the faster it
          stacks. This buff will be easily fully stacked in most teams after the
          first cycle so it can be included in his base stats for gearing
          purposes.
        </li>
      </ul>
    </>
  );
};
