import React from 'react';

export const ProfileHarmonyTricks: React.FC = () => {
  return (
    <>
      <h6>How to unlock the Harmony variant of the Trailblazer?</h6>
      <p>
        Play through the 2.2 main story and you will unlock it roughly 3/4 way
        through it.
      </p>
      <h6>How to get Eidolons for the Trailblazer?</h6>
      <p>
        In the 2.2 patch, you can only obtain enough Eidolons to raise the
        character to E5 - and you can obtain them from the Clockie Statue. For
        the last - E6 - Eidolon, we will have to wait for the next patch. Sadly,
        the E6 is actually the most powerful and it affects both the character
        performance and how you build them, so their full power will be unveiled
        in 2.3 patch.
      </p>
      <ul>
        <li>
          Trailblazer's E1 recovers a skill point on first skill use in combat.
          It requires that you first have at least 1 skill point to use the
          skill to then recover it afterwards.
        </li>
        <li>
          Trailblazer's E4 that grants other allies 15% of their Break Effect
          updates as their Break Effect changes. Increases and decreases to
          their current Break Effect in combat will increase and decrease the
          buff.
        </li>
        <li>
          Trailblazer's E6 that grants 2 additional hits with their skill
          increases the total energy generation of the skill from 30 to 42 as it
          is a bounce skill (5 hits becomes 7).
        </li>
      </ul>
      <h6>What is Super Break?</h6>
      <p>Let's compare the Break Effect and Super Break formulas first:</p>
      <blockquote>
        <h6>Break Formula</h6>
        <p>
          Element Multiplier * Lvl Multiplier * Max Toughness Multiplier * (1 +
          Break Effect) * DEF Multi * Res Multi * Vuln Multi * Broken Multi
        </p>
      </blockquote>
      <blockquote>
        <h6>Super Break Formula</h6>
        <p>
          Lvl Multiplier * (Toughness DMG / 30) * (1 + Break Effect) * (1 +
          Trailblazer's A2 bonus) * DEF Multi * Res Multi * Vuln Multi * Broken
          Multi
        </p>
      </blockquote>
      <ul>
        <li>
          Unlike normal Breaks, the enemies toughness is not a factor is how
          much Super Break damage you'll deal. For this reason you can deal the
          same sized Super Break to a weak monster as you could to an end game
          boss.
        </li>
        <li>
          The level of your character directly determines the base damage of
          your Super Break which everything else scales off of. To maximize
          damage make sure all of your characters are level 80.
        </li>
        <li>Break and Super break can be inflicted with the same action.</li>
        <li>
          Super Break's damage will be based on the element of the unit
          inflicting the damage. For example, Fu Xuan's Ultimate would inflict
          Quantum Super Break.
        </li>
        <li>
          All elements of Super Break use the same damage formula and do not
          apply any of the usual elemental effects.
        </li>
        <li>
          Super Break, like normal Break, is modified by all enemy defensive
          mechanics such as DEF, RES and damage taken % in both positive and
          negative ways.
        </li>
        <li>
          The best way to deal more Super Break damage is increasing the
          Toughness damage you deal to an enemy. A basic attack deals 1
          Toughness damage where a Skill normally deals 2. Dealing 2 Toughness
          damage instead of 1 is the equivalent to double damage. So an E6
          Trailblazer's 5 toughness damage Skill, it can deal 5 times the Ssuper
          Break of a normal basic attack.
        </li>
        <li>
          Break Efficiency, such as Ruan Mei's buff, increases the total
          Toughness damage you deal with your attacks and will also increase the
          Super Break damage by an identical amount (50% in Ruan Mei's case).
          Break Effect as with traditional Break damage is also a direct
          multiplier on Super Break damage.
        </li>
        <li>
          Super Break damage is always calculated off of the stats of the
          attacking character's stats. Meaning if you basic attack with your
          Aventurine it will use his Break Effect stat, not the Trailblazers.
        </li>
        <li>
          Enemies do not need to be vulnerable to the unit attacking them to
          take Super Break damage from them. They only need to be broken first.
        </li>
        <li>
          Trailblazer's Dance With the One bonus ability that grants increased
          Super Break DMG is a unique damage multiplier separate to Break
          Effect%. For example, if you would deal 10,000 Super Break damage with
          50% increase from this bonus ability you'd deal 15,000.
        </li>
        <li>
          Dance With the One's strongest effect occurs when only 1 enemy is
          present, granting 60% increased damage to Super Break while when there
          is 5 enemies you'll only get 20% increase.
        </li>
      </ul>
      <h6>Kit interactions</h6>
      <ul>
        <li>
          Trailblazer's skill at E0 deals a total of 4 break units each use
          (Initial 2 + 0.5 per hit after), equivalent to most ultimates in the
          game. At E6 this is increased to 5 break units making their break
          quite potent if all attacks line up on a singular enemy.
        </li>
        <li>
          Trailblazer's Talent that regenerates 10 energy when an enemy target's
          weakness is broken does not trigger off of Super Break.
        </li>
        <li>
          Trailblazer's Bonus ability, Shuffle Along, increases the first hit
          toughness-reducing damage of Trailblazer's skill. Each use taking the
          skills total toughness damage from 3 break units to 4 or from 4 to 5
          with E6.
        </li>
        <li>
          To ensure maximum Ultimate uptime aim to use Trailblazer's Ultimate
          right after or during their turn as the ultimates duration ticks down
          at the start of their turn.
        </li>
        <li>
          When playing Harmony Trailblazer using the skill every action is most
          optimal to ensure the Ultimate is always available and you deal
          maximum toughness and Super Break damage to enemies. With sufficient
          Energy Regeneration gear, Cones, eidolons or talent triggers you can
          effortlessly keep your Ultimate up permanently and with multiple of
          these combined you also have the option to use basic attacks to
          generate skill points if needed while maintaining that 100% uptime all
          be it at the expense of some Break and Toughness damage.
        </li>
      </ul>
    </>
  );
};
