export const HSRDPSJade = {
  V1: {
    e0: 243075,
    e1: 362575,
    e2: 400990,
    e3: 442367,
    e4: 475772,
    e5: 495966,
    e6: 617631
  },
  V2: {
    e0: 994090,
    e1: 1080262,
    e2: 1223119,
    e3: 1335900,
    e4: 1436780,
    e5: 1484963,
    e6: 2045115
  },
  V3: {
    e0: 2143371,
    e1: 2359900,
    e2: 2463802,
    e3: 2718967,
    e4: 2924290,
    e5: 2996409,
    e6: 3999974
  }
};
