import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { HSRCharacter } from '../common/components/hsr-character';

export const ProfileFuEHP: React.FC = () => {
  return (
    <div className="profile fu-ehp">
      <div className={`content-header Quantum`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Energy Breakpoints
      </div>
      <p>
        With Fu Xuan's Ultimate being quite expensive (135) by default you will
        be able to use her Ultimate every 5 actions, but thankfully there are
        multiple ways available to you to decrease the number. The table below
        showcases the amount of Energy you can generate, but keep in mind the
        numbers don't include any combat energy (as in Fu Xuan being hit) so in
        battle and especially when you use a Taunting Light Cone, the number of
        actions needed will go down even further.
      </p>
      <h6>Without Signature Light Cone and E0 Fu Xuan</h6>
      <p>
        <strong className="green">Green color</strong> means you hit the
        required Energy in the rotation and <strong className="red">Red</strong>{' '}
        you didn't.
      </p>
      <Table bordered responsive className="colored-table">
        <thead>
          <tr>
            <th>Rotation</th>
            <th>Base</th>
            <th>Sprightly Vonwacq (5%)</th>
            <th>ER Rope (19.44%)</th>
            <th>Sprightly Vonwacq + ER Rope (24.44%)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>1 Skill + 2 Basic</strong>
            </td>
            <td className="red">95</td>
            <td className="red">99.8</td>
            <td className="red">113.5</td>
            <td className="red">118.2</td>
          </tr>
          <tr>
            <td>
              <strong>1 Skill + 3 Basic</strong>
            </td>
            <td className="red">115</td>
            <td className="red">120.8</td>
            <td className="green">137.4</td>
            <td className="green">143.1</td>
          </tr>
          <tr>
            <td>
              <strong>1 Skill + 4 Basic</strong>
            </td>
            <td className="green">135</td>
            <td className="green">141.8</td>
            <td className="green">161.2</td>
            <td className="green">168</td>
          </tr>
        </tbody>
      </Table>
      <h6>With Signature Light Cone (12% ER) and E0 Fu Xuan</h6>
      <p>
        <strong className="green">Green color</strong> means you hit the
        required Energy in the rotation and <strong className="red">Red</strong>{' '}
        you didn't.
      </p>
      <Table bordered responsive className="colored-table">
        <thead>
          <tr>
            <th>Rotation</th>
            <th>Base</th>
            <th>Sprightly Vonwacq (5%)</th>
            <th>ER Rope (19.44%)</th>
            <th>Sprightly Vonwacq + ER Rope (24.44%)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>1 Skill + 2 Basic</strong>
            </td>
            <td className="red">106.4</td>
            <td className="red">111.2</td>
            <td className="red">124.9</td>
            <td className="red">129.6</td>
          </tr>
          <tr>
            <td>
              <strong>1 Skill + 3 Basic</strong>
            </td>
            <td className="red">128.8</td>
            <td className="red">134.6</td>
            <td className="green">151.2</td>
            <td className="green">156.9</td>
          </tr>
          <tr>
            <td>
              <strong>1 Skill + 4 Basic</strong>
            </td>
            <td className="green">151.2</td>
            <td className="green">158.0</td>
            <td className="green">177.4</td>
            <td className="green">184.2</td>
          </tr>
        </tbody>
      </Table>
      <p>
        As you can see, the ER gained from the Signature Cone doesn't impact the
        rotation at all when talking about the base energy income from the
        rotation. Still, if we would include the combat energy gained, you
        potentially could go down to 3 turn rotation if Fu Xuan's would be hit a
        few times every turn.
      </p>
      <div className={`content-header Quantum`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> EHP Calculations
      </div>
      <p>
        <strong>Effective Hit Points (EHP)</strong> is a way to calculate how
        much damage a character can take before dying and we think that Fu
        Xuan's addition to the game is a great occasion to introduce the stat to
        our website. Below there's a massive graph that shows the EHP based on
        various Relic, Light Cone and Main stat combinations.
      </p>
      <p>Here's the formula used for the combinations descriptions:</p>
      <ul>
        <li>Light Cone SX | Relic Sets (Main Stat on Chest)</li>
        <li>Light Cones:</li>
        <ul>
          <li>Eyes = She Already Shut Her Eyes</li>
          <li>LC = Landau's Choice</li>
          <li>MoV = Moment of Victory</li>
          <li>Day = Day One of My New Life</li>
          <li>ToM = Texture of Memories</li>
        </ul>
        <li>Relic sets:</li>
        <ul>
          <li>Wuth = Guard of Wuthering Snow</li>
          <li>Long = Longevous Disciple</li>
          <li>Hack = Messenger Traversing Hackerspace</li>
        </ul>
      </ul>
      <p>
        As for the common elements between all the combinations, Fu Xuan is
        wearing SPD Boots, HP% Sphere and ERR Rope. Also, conditional effects on
        the Light Cones aren't included.
      </p>
      <Row xs={1} xl={1} className="dps-comparison">
        <Col>
          <div className="box">
            <h5>Fu Xuan's EHP comparison</h5>
            <div className="simulation-container">
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>Eyes S1 | Wuth+Long (DEF%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">31,202 EHP</span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>LC S5 | Wuth+Long (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '96.23%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">30,027 EHP</span>
                      <span className="percent">96.23%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>Eyes S1 | Wuth+Long (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '95.5%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">29,797 EHP</span>
                      <span className="percent">95.5%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>Eyes S1 | Wuth+Hacker (DEF%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '95.41%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">29,771 EHP</span>
                      <span className="percent">95.41%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>MoV S1 | Wuth+Long (DEF%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '95.07%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">29,664 EHP</span>
                      <span className="percent">95.07%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>Day S5 | Wuth+Long (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '94.55%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">29,500 EHP</span>
                      <span className="percent">94.55%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>MoV S1 | Wuth+Long (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '93.15%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">29,065 EHP</span>
                      <span className="percent">93.15%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>LC S5 | Wuth+Hacker (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '92.17%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">28,760 EHP</span>
                      <span className="percent">92.17%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>Eyes S1 | Long+Hacker (DEF%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '92%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">28,706 EHP</span>
                      <span className="percent">92%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>Eyes S1 | Wuth+Hacker (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '91.74%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">28,625 EHP</span>
                      <span className="percent">91.74%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>Day S5 | Wuth+Hacker (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '90.56%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">28,255 EHP</span>
                      <span className="percent">90.56%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>MoV S1 | Wuth+Hacker (DEF%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '90.31%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">28,180 EHP</span>
                      <span className="percent">90.31%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>MoV S1 | Wuth+Hacker (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '89.2%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">27,833 EHP</span>
                      <span className="percent">89.2%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>LC S5 | Long+Hacker (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '88.54%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">27,625 EHP</span>
                      <span className="percent">88.54%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>Eyes S1 | Long+Hacker (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '87.86%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">27,414 EHP</span>
                      <span className="percent">87.86%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>MoV S1 | Long+Hacker (DEF%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '87.47%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">27,291 EHP</span>
                      <span className="percent">87.47%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>Day S5 | Long+Hacker (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{ width: '86.98%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">27,140 EHP</span>
                      <span className="percent">86.98%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>MoV S1 | Long+Hacker (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '85.7%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">26,740 EHP</span>
                      <span className="percent">85.7%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>ToM S5 | Wuth+Long (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '81.81%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">25,526 EHP</span>
                      <span className="percent">81.81%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>ToM S5 | Wuth+Hacker (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '78.34%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">24,444 EHP</span>
                      <span className="percent">78.34%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>ToM S5 | Long+Hacker (HP%)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '75.26%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">23,484 EHP</span>
                      <span className="percent">75.26%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row no-image">
                <div className="chart">
                  <div className="info-2">
                    <p>Eyes S1 | 4-pc Long (DPS Relics)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '51.5%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">16,069 EHP</span>
                      <span className="percent">51.5%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className={`content-header Quantum`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Damage output
      </div>
      <p>
        Fu Xuan actually can deal substantial amounts of damage herself, even if
        you play her as full tank. Below we added two graphs that showcase her
        damage output in both Tank and full DPS builds. Also, we added Herta
        there to put Fu Xuan's damage into perspective.
      </p>
      <Row xs={1} xl={2} className="dps-comparison">
        <Col>
          <div className="box">
            <h5>
              Fu Xuan + She Already Shut Her Eyes (S1) in 1 Target scenario
            </h5>
            <div className="simulation-container">
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="fu-xuan" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Fu Xuan (E1) | DPS Build</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">96,099 dmg (+3 SP)</span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="herta" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Herta (E3)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar ice`}
                      style={{ width: '95%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">91 291 dmg (+1 SP)</span>
                      <span className="percent">95%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="fu-xuan" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Fu Xuan (E0) | DPS Build</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '90.56%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">87,030 dmg (+3 SP)</span>
                      <span className="percent">90.56%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="fu-xuan" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Fu Xuan (E1) | Tank Build</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '44.91%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">43,162 dmg (+3 SP)</span>
                      <span className="percent">44.91%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="fu-xuan" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Fu Xuan (E0) | Tank Build</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '41.17%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">39,566 dmg (+3 SP)</span>
                      <span className="percent">41.17%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="box">
            <h5>
              Fu Xuan + She Already Shut Her Eyes (S1) in 3 Target scenario
            </h5>
            <div className="simulation-container">
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="herta" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Herta (E3)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar ice`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">259,245 dmg (+1 SP)</span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="fu-xuan" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Fu Xuan (E1) | DPS Build</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '78.38%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">203,209 dmg (+3 SP)</span>
                      <span className="percent">78.38%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="fu-xuan" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Fu Xuan (E0) | DPS Build</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '70.99%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">184,033 dmg (+3 SP)</span>
                      <span className="percent">70.99%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="fu-xuan" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Fu Xuan (E1) | Tank Build</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '34.41%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">89,202 dmg (+3 SP)</span>
                      <span className="percent">34.41%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="fu-xuan" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Fu Xuan (E0) | Tank Build</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '31.54%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">81,770 dmg (+3 SP)</span>
                      <span className="percent">31.54%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
