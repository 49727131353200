import React from 'react';

export const ProfileSynergySwan: React.FC = () => {
  return (
    <>
      <ul>
        <li>
          Technique halves after each application of arcana leading to the
          following: 150%, 75%, 37.5%, 18.75%, 9.375%. In most fights 2-3 stacks
          is expected depending on EHR and the resistance of the enemy,
        </li>
        <li>
          Once Arcana has been applied atleast 1 stack will persist on the enemy
          throughout the duration of the fight (
          <strong>it is unremovable and has no duration</strong>),
        </li>
        <li>
          <strong>All DoTs will trigger before Arcana does.</strong> If you have
          2 seperate Shocks on the target and a stack of 2 Arcanas, the enemy
          will first take both of the Shocks applying 2 more stacks of Arcana
          bringing you to 4 stacks. Arcana will then deal its damage resetting
          back down to 1 stack,
        </li>
        <li>
          When considering the bonus ability, Viscera's Disquiet, and its
          ability to grant an additional stack of Arcana when using Black Swans
          skill when enemies are inflicted by Burn, Wind Shear, Bleed and Shock{' '}
          <strong>
            she can only apply 1 additional Arcana stack per type.
          </strong>{' '}
          Having 3 seperate Wind Shears on the enemy will not allow for 3 extra
          stacks but having a Wind Shear, Shock and Burn will. The total
          additional Arcana stacks you can accumulate from this bonus ability is
          4 additional stacks 1 for each elemental DoT type,
        </li>
        <li>
          When using Black Swan's skill,{' '}
          <strong>
            Viscera's Disquiet does not apply to adjacent targets as a part of
            the skills Blast targetting
          </strong>{' '}
          - meaning only the primary target is eligible to receive additional
          Arcana stacks,
        </li>
        <li>
          <strong>
            Goblet's Dredges bonus ability only activates when an ally attack
            triggers a DoT effect
          </strong>{' '}
          on an enemy e.g. Kafka's Skill or Ultimate, Sampo's E4, Guinaifen's
          Ultimate or Luka's enhanced Basic Attack. Each individual DoT trigger
          within the attack will add a stack of Arcana up to a cap of 3 per
          attack per enemy. E.g. Sampo uses his skill with E4 activated on an
          enemy with Wind Shear hitting them 5 times and the enemy will receive
          3 additional Arcana stacks,
        </li>
        <ul>
          <li>
            Note that each individual enemy hit can receive up to 3 stacks
            meaning if each enemy has 3 individual DoTs on them and use activate
            Kafka's ultimate each enemy will receive 3 additional Arcana stacks,
          </li>
        </ul>
        <li>
          After unlocking Goblet's Dredges, Black Swan will have a chance to
          apply a stack of Arcana at the start of battle to all enemies (and
          also to each enemy that spawns during the battle),
        </li>
        <li>
          While affected by Ephiphany, enemies are considered to be Burned,
          Bled, Wind Sheared and Shocked. Because of that,{' '}
          <strong>
            Black Swans skill will apply the maximum 5 stacks of Arcana per use
            to its primary target thanks to Ciscera's Disquiet
          </strong>
          ,
        </li>
        <li>
          Despite Black Swan's skill only benefitting from Viscera's Disquiet,
          her Basic Attack has an identical effect baked into it. Meaning
          against single targets assuming you are able to keep the DEF shred
          debuff active at all times using her Basic Attack in place of the
          skill will only cost you some lost energy and a fraction of a damage
          loss <strong>in return for being skill point positive,</strong>
        </li>
        <li>
          Black Swan's Ultimate effect that applies Ephiphany causing her Arcana
          to be considered all DoT types allows it to be triggered by all other
          trigger effects such as Guinaifen's Ultimate{' '}
          <strong>
            but the damage it deals when triggered is still Wind damage,
          </strong>
        </li>
        <li>
          In order to cap out Black Swan's additional damage from her bonus
          ability Candleglame's Portent she requires{' '}
          <strong>120% Effect Hit Rating,</strong>
        </li>
        <li>Eidolons:</li>
        <ul>
          <li>
            Black Swan's E1 that applies resistance penetration per DoT type on
            the enemy counts as a debuff per elemental type applied. E.g. enemy
            has Burn on them they will also have a seperate debuff applying -25%
            Fire Resistance allowing for 4 additional debuffs if all 4 DoT types
            are present,
          </li>
          <li>
            Black Swan's E4 grants 8 energy per enemy afflicted with the
            ultimate. If 5 enemies are hit you will gain a total of 40 energy
            when they die or start their turn,
          </li>
          <li>
            Black Swan's E6 grants an additional stack of Arcana for every
            allied attack against an enemy including ultimates and follow-up
            attacks which count as their own attacks. E.g. Kafka uses her skill
            activating E6 to apply an additional Arcana and then uses her
            follow-up later that too can apply another additional Arcana. Her E6
            fixed chance to apply an additional Arcana activates on the first
            part of the E6 meaning allies have a 50% chance to apply 2 Arcana
            instead of 1. The 50% fixed chance applies to all Arcana effects
            individually meaning if you were to apply 5 Arcana's with your skill
            due to the fact it had all 4 of the elemental DoT's you would apply
            a maximum of 10 Arcana's at once if you were lucky enough with E6
            active. Black Swan cannot proc the first part of her E6 with her own
            attacks.
          </li>
        </ul>
      </ul>
    </>
  );
};
