import React from 'react';

export const ProfileSynergyAcheron: React.FC = () => {
  return (
    <>
      <ul>
        <li>
          Acheron does not use Energy and instead requires 9 stacks of Slashed
          Dream in order to activate her ultimate. This can is tracked where a
          usual energy gauge traditionally is.
        </li>
        <li>
          Acheron starts endgame encounters such as Memory of Chaos and Pure
          Fiction with 5/9 Slashed Dream Stacks.
        </li>
        <li>
          Acheron can gain Slashed Dream stacks via her skill, technique or
          talent.
        </li>
        <li>
          Acheron's Talent is the main source of her Slashed Dream generation
          (energy generation). Whenever any unit inflicts a debuff on an enemy
          while using their ability Acheron gains 1 stack of Slashed Dream (this
          effect can only trigger once per action).
        </li>
        <li>
          The Slashed Dream generation of Acheron's skill effect is separate
          from her talent allowing her to generate 2 stacks in 1 action assuming
          she can apply a debuff with her skill.
        </li>
      </ul>
      <p>Some prime example of Acheron's Talent interactions are:</p>
      <ul>
        <li>
          Acheron attacks an enemy with her skill generating 1 stack of Slashed
          Dream from its effect but in the process breaks that enemy applying a
          Shock - she will generate a total of 2 Slashed Dream stacks with this
          action (instead of breaking an enemy if Acheron also has a debuff
          inflicting Light Cone equipped every one of her skills will generate 2
          stacks).
        </li>
        <li>
          An enemy dies and explodes inflicted a debuff on all other enemies on
          the field - Acheron will gain 1 stack.
        </li>
        <li>
          Pela equipped with Resolution Shines With Pearls of Sweat basic
          attacks an enemy and then immediately after activates her ultimate.
          Pela will grant Acheron 1 stack from the basic attack thanks to Pearls
          and a 2nd stack from the ultimate thanks to the debuff it applies.
        </li>
        <li>
          The enemy targets Fuxuan who is equipped with Trend of the Universal
          Market With an attack as a result the enemy is Burned granting Acheron
          a stack.
        </li>
        <li>
          <strong>
            Debuffs that refresh or activate as a result of game mechanics
            normally do not provide Acheron stacks as they are not a result of a
            unit inflicting a debuff
          </strong>{' '}
          e.g. Black Swan's Arcana increasing in stack size after a DoT triggers
          or Guinaifen's Firekiss activating.
        </li>
        <li>
          Acheron's Ultimate unlike most other actions cannot grant its own
          stack of Slashed Dream even despite the fact it applies a debuff even
          if Acheron has a debuff applying Light Cone equipped.
        </li>
        <li>
          Most abilities grant Acheron a stack of Slashed dream when being
          refreshed but some do not the most important of which is the
          Resolution Shines With Pearls Of Sweat Debuff. E.g. if you use Pela's
          Ultimate applying Pearls of Sweat to all targets and then basic attack
          an enemy already inflicted with the Pearls debuff attempting to
          reapply it as the debuff has already been applied and is at max
          duration it will not be reapplied meaning Acheron will gain no stack.
          Some other examples of this are Kafka's Signature Light Cone,
          Silverwolf's Signature Light Cone and Topaz's Proof of Debt debuff
          (when applied to a target already inflicted with it).
        </li>
        <li>
          The combination of Acheron's Bonus ability "Red Oni" and Technique
          allows her to gain up to 3 stacks of a buff called Quadrivalent
          Ascendance. This buff allows her to immediately gain and apply Slashed
          Dream and Crimson Knot stacks after she uses her ultimate as a sort of
          instant refund. The first way to acquire these stacks is whenever
          Acheron would gain a stack of Slashed Dream while at maximum capacity
          (9/9 energy) she gains 1 Quadrivalent Ascendence instead of wasting
          the stack essentially acting as a form of storage granting her the
          ability to bank up to 12 energy (because of the 3 possible stacks).
          She also gains 1 Quadrivlent Ascendance for free once per wave if she
          initated the fight with her own technique.
        </li>
        <li>
          Using Acheron's Technique to start a battle counts as a hit on the
          first wave but also subsequent waves and will apply any debuffs her
          Light Cone has granting an immediate stack. This effect coupled with
          the stack of Quadrivalent causes Acheron's technique to grant her 2
          stacks per wave making it a very powerful option for starting fights
          assuming she has a debuffing Light Cone.
        </li>
        <li>
          Acheron's "Instant Kill" technique ability works in the overworld and
          in Simulated Universe and grants ALL rewards including character
          experience.
        </li>
        <li>
          When Acheron or any unit applies a debuff to a target granting Acheron
          a stack of Slashed Dream they will also apply a stack of Crimson Knot
          to them. If multiple targets are debuffed in a single action the
          target with the most current Crimson Knots will receive the additional
          stack.
        </li>
        <li>
          <strong>Crimson Knot is not a debuff</strong>.
        </li>
        <li>
          Aim to have the majority of your Crimson Knots on the strongest
          enemies in the fight which are least likely to die quickly (the boss
          or elite monsters). Aim to clump your Knots on as few enemies as
          possible to maximise ultimate performance.
        </li>
        <li>
          During Acheron's Ultimate always target enemies with the most Crimson
          Knots at the time to maximise damage.
        </li>
        <li>
          Acheron's bonus ability "The Abyss" multiplies her base damage values
          by 115% and 160% respectively. This is a{' '}
          <strong>
            true damage multiplier meaning it is always functionally the size of
            the increase listed.
          </strong>
        </li>
      </ul>
    </>
  );
};
