/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { HSRCharacter } from '../common/components/hsr-character';
import { Range, getTrackBackground } from 'react-range';

export const RobinCalcs: React.FC = () => {
  const [currentLevel, setCurrentLevel] = useState([20]);
  const [currentAttack, setCurrentAttack] = useState(0);
  const [E0, setE0] = useState(17542);
  const [E1, setE1] = useState(21752);
  const [E2, setE2] = useState(21752);
  const [E3, setE3] = useState(24526);
  const [E4, setE4] = useState(24526);
  const [E5, setE5] = useState(24526);
  const [E6, setE6] = useState(68672);
  const [E6Base, setE6Base] = useState(549376);

  useEffect(() => {
    setCurrentAttack(Number(currentLevel));
  }, [currentLevel]);

  return (
    <div className="robin-calcs">
      <p>
        This calculator can give you an idea of the total amount of damage that
        can be achieved during a single Robin Ultimate. Use the slider to adjust
        how many attacks you expect to be able to launch during Robin’s 90 speed
        ultimate duration - keep in mind that all allies are advanced
        immediately and each attack counts.
      </p>
      <p>
        For example, Dr. Ratio first advanced skill would be 2 hits, his
        Ultimate would count for 3 hits and due to the slow Speed of Robin’s
        ultimate, he’ll get another skill in for another 2 hits for a total of 7
        minimum just from his standard combo. It’s safe to assume that most{' '}
        <strong>standard teams can achieve at least 12-15 hits</strong> while
        the specialist teams, like the dedicated Follow-up ones, are capable of
        achieving <strong>20-30 hits</strong> when things go their way.
      </p>
      <div className="follow-changer">
        <span className="current-level">
          <span className="level" style={{ color: '#979797' }}>
            {currentLevel}
          </span>
          <span>Ally attack count</span>
        </span>
        <div className="level-slider">
          <Range
            step={1}
            min={8}
            max={35}
            values={currentLevel}
            onChange={(values) => setCurrentLevel(values)}
            renderTrack={({ props, children }) => (
              <div
                role="button"
                tabIndex={0}
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: '36px',
                  display: 'flex',
                  width: '100%'
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: '5px',
                    width: '100%',
                    borderRadius: '0px',
                    background: getTrackBackground({
                      values: currentLevel,
                      colors: ['#979797', '#484950'],
                      min: 8,
                      max: 35
                    }),
                    alignSelf: 'center'
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '20px',
                  width: '20px',
                  borderRadius: '0px',
                  backgroundColor: '#FFF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    height: '14px',
                    width: '5px',
                    backgroundColor: isDragged ? '#979797' : '#484950'
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
      <Row xs={1} xl={1} className="dps-comparison">
        <Col>
          <div className="box">
            <h5>Robin + Flowing Nightglow (S1)</h5>
            <div className="simulation-container">
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="robin" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Eidolon 6</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar phys`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">
                        {(E6Base + E5 * (currentAttack - 8)).toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          ((E6Base + E5 * (currentAttack - 8)) /
                            (E0 * currentAttack)) *
                          100
                        ).toFixed(2)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="robin" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Eidolon 5</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar phys`}
                      style={{
                        width:
                          ((E5 * currentAttack) /
                            (E6Base + E5 * (currentAttack - 8))) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data">
                      <span className="dps">
                        {(E5 * currentAttack).toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          ((E5 * currentAttack) / (E0 * currentAttack)) *
                          100
                        ).toFixed(2)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="robin" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Eidolon 4</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar phys`}
                      style={{
                        width:
                          ((E4 * currentAttack) /
                            (E6Base + E5 * (currentAttack - 8))) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data">
                      <span className="dps">
                        {(E4 * currentAttack).toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          ((E4 * currentAttack) / (E0 * currentAttack)) *
                          100
                        ).toFixed(2)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="robin" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Eidolon 3</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar phys`}
                      style={{
                        width:
                          ((E3 * currentAttack) /
                            (E6Base + E5 * (currentAttack - 8))) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data">
                      <span className="dps">
                        {(E3 * currentAttack).toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          ((E3 * currentAttack) / (E0 * currentAttack)) *
                          100
                        ).toFixed(2)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="robin" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Eidolon 2</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar phys`}
                      style={{
                        width:
                          ((E2 * currentAttack) /
                            (E6Base + E5 * (currentAttack - 8))) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data">
                      <span className="dps">
                        {(E2 * currentAttack).toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          ((E2 * currentAttack) / (E0 * currentAttack)) *
                          100
                        ).toFixed(2)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="robin" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Eidolon 1</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar phys`}
                      style={{
                        width:
                          ((E1 * currentAttack) /
                            (E6Base + E5 * (currentAttack - 8))) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data">
                      <span className="dps">
                        {(E1 * currentAttack).toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          ((E1 * currentAttack) / (E0 * currentAttack)) *
                          100
                        ).toFixed(2)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="robin" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Eidolon 0</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar phys`}
                      style={{
                        width:
                          ((E0 * currentAttack) /
                            (E6Base + E5 * (currentAttack - 8))) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data">
                      <span className="dps">
                        {(E0 * currentAttack).toLocaleString()} dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <p>
        <strong>Robin stats in the simulation:</strong> 4648 ATK, 123 Speed, and
        38.8% Phys DMG. She's also equipped with 2-piece Musketeer of Wild Wheat
        and 2-piece Prisoner in Deep Confinement Relics Sets and Sprightly
        Vonwacq for the Planar set. Her 50% DMG% from the skill is also
        included.
      </p>
    </div>
  );
};
