import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Table } from 'react-bootstrap';

export const ProfileYunliTaunt: React.FC = () => {
  return (
    <>
      <div className={`content-header Physical `}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Taunt/Agro Chart
      </div>
      <p>
        The table below shows the chance for Yunli to be hit during the battle
        based on her team composition (
        <i>
          but we all know that if you play Tingyun with her, the fox will always
          get hit no matter what you do
        </i>
        ).
      </p>
      <Table bordered responsive className="colored-table">
        <thead>
          <tr>
            <th>Variant</th>
            <th>Yunli</th>
            <th>Support</th>
            <th>Support</th>
            <th>Sustain</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>S0 Yunli + Abundance Sustain</strong>
            </td>
            <td className="red">29.41%</td>
            <td>23.53%</td>
            <td>23.53%</td>
            <td>23.53%</td>
          </tr>
          <tr>
            <td>
              <strong>S0 Yunli + Preservation Sustain</strong>
            </td>
            <td className="red">26.32%</td>
            <td>21.05%</td>
            <td>21.05%</td>
            <td>31.58%</td>
          </tr>
          <tr>
            <td>
              <strong>S1 Yunli + Abundance Sustain</strong>
            </td>
            <td className="green-light">71.43%</td>
            <td>9.52%</td>
            <td>9.52%</td>
            <td>9.52%</td>
          </tr>
          <tr>
            <td>
              <strong>S1 Yunli + Preservation Sustain</strong>
            </td>
            <td className="green-light">68.18%</td>
            <td>9.09%</td>
            <td>9.09%</td>
            <td>13.64%</td>
          </tr>
          <tr>
            <td>
              <strong>S0 Yunli + March 7th Skill</strong>
            </td>
            <td className="green-light">68.18%</td>
            <td>9.09%</td>
            <td>9.09%</td>
            <td>13.64%</td>
          </tr>
          <tr>
            <td>
              <strong>S0 Yunli + Lynx's Skill</strong>
            </td>
            <td className="green-light">71.43%</td>
            <td>9.52%</td>
            <td>9.52%</td>
            <td>9.52%</td>
          </tr>
          <tr>
            <td>
              <strong>S1 Yunli + Lynx's Skill</strong>
            </td>
            <td className="green">82.09%</td>
            <td>5.97%</td>
            <td>5.97%</td>
            <td>5.97%</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
