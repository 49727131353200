export const HSRDPSYunlinDPS = {
  V1: {
    e0: 603418,
    e1: 833084,
    e2: 926388,
    e3: 1016971,
    e4: 1016971,
    e5: 1027764,
    e6: 1344822,
    alt_e0: 1143660,
    alt_e1: 1459674,
    alt_e2: 1621546,
    alt_e3: 1744838,
    alt_e4: 1744838,
    alt_e5: 1775099,
    alt_e6: 2286618,
    sig_e0: 712013,
    sig_e1: 972839,
    sig_e2: 1083707,
    sig_e3: 1188548,
    sig_e4: 1188548,
    sig_e5: 1200257,
    sig_e6: 1553476,
    alt_sig_e0: 1477614,
    alt_sig_e1: 1872405,
    alt_sig_e2: 2085362,
    alt_sig_e3: 2243381,
    alt_sig_e4: 2243381,
    alt_sig_e5: 2275381,
    alt_sig_e6: 2904349
  }
};
