import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

export const AveProfileCalcs: React.FC = () => {
  return (
    <div className="profile fu-ehp">
      <div className={`content-header Imaginary`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Calculations
      </div>
      <p>
        Because Aventurine damage output is super RNG and very team reliant,
        instead of trying to do (and failing) rotation with him, we instead
        opted out on listing the damage done by his abilities and the amount of
        Shield generated by his Skill and Follow-up attack.
      </p>
      <p>
        In all setups, Aventurine is using 4-PC Knight of Purity Palace / Inert
        Salsatto and S5 Destiny's Threads Forewoven Light Cone. His final Crit
        Rate sits at 75%, Crit DMG at 112% and DEF at 3977.
      </p>
      <Table bordered responsive className="colored-table">
        <thead>
          <tr>
            <th>Eidolon</th>
            <th>Follow-up</th>
            <th>Ultimate</th>
            <th>Basic</th>
            <th>Skill Shield</th>
            <th>Follow-up Shield</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>E0</td>
            <td>10,544</td>
            <td>17,707</td>
            <td>5,515</td>
            <td>1,529</td>
            <td>449</td>
          </tr>
          <tr>
            <td>E1</td>
            <td>11,405</td>
            <td>19,154</td>
            <td>5,965</td>
            <td>1,529</td>
            <td>449</td>
          </tr>
          <tr>
            <td>E2</td>
            <td>11,405</td>
            <td>19,154</td>
            <td>5,965</td>
            <td>1,529</td>
            <td>449</td>
          </tr>
          <tr>
            <td>E3</td>
            <td>11,457</td>
            <td>20,808</td>
            <td>6,592</td>
            <td>1,529</td>
            <td>449</td>
          </tr>
          <tr>
            <td>E4</td>
            <td>18,207</td>
            <td>22,958</td>
            <td>7,333</td>
            <td>1,658</td>
            <td>487</td>
          </tr>
          <tr>
            <td>E5</td>
            <td>19,664</td>
            <td>22,958</td>
            <td>7,333</td>
            <td>1,786</td>
            <td>487</td>
          </tr>
          <tr>
            <td>E6</td>
            <td>36,316</td>
            <td>42,400</td>
            <td>14,117</td>
            <td>1,786</td>
            <td>487</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
