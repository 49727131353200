import React from 'react';
import { Table } from 'react-bootstrap';

export const ProfileFuCones: React.FC = () => {
  return (
    <Table bordered responsive className="colored-table">
      <thead>
        <tr>
          <th>Light Cone</th>
          <th>Survival</th>
          <th>Support</th>
          <th>Team Healing</th>
          <th>Damage</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>She Already Shut Her Eyes</strong>
          </td>
          <td className="green-light">4.5/5</td>
          <td className="green">5/5</td>
          <td className="green">5/5</td>
          <td className="green">5/5</td>
        </tr>
        <tr>
          <td>
            <strong>Moment of Victory</strong>
          </td>
          <td className="green">5/5</td>
          <td className="green-light">4/5</td>
          <td className="yellow">3/5</td>
          <td className="yellow">3/5</td>
        </tr>
        <tr>
          <td>
            <strong>Landau's Choice</strong>
          </td>
          <td className="green-light">4/5</td>
          <td className="green-light">4/5</td>
          <td className="yellow">3/5</td>
          <td className="yellow">3/5</td>
        </tr>
        <tr>
          <td>
            <strong>Day One of My New Life </strong>
          </td>
          <td className="yellow">3/5</td>
          <td className="green-light">4/5</td>
          <td className="yellow">3/5</td>
          <td className="yellow">3/5</td>
        </tr>
        <tr>
          <td>
            <strong>Texture of Memories</strong>
          </td>
          <td className="green-light">4/5</td>
          <td className="yellow">3/5</td>
          <td className="yellow">3/5</td>
          <td className="yellow">3/5</td>
        </tr>
      </tbody>
    </Table>
  );
};
