import React, { useState } from 'react';
import { HSRCharacter } from '../common/components/hsr-character';
import { Row, Col, Accordion, Alert } from 'react-bootstrap';
import { HSRLightCone } from '../common/components/hsr-light-cone';
import { HSRRelicSet } from '../common/components/hsr-relic-set';
import { HSRStat } from '../common/components/hsr-stat';
import { HSRDPSBoothillDPS } from '../dps-rankings/boothill-array';

export const ProfileBoothillDPS: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentTab, setCurrentTab] = useState('V1');
  return (
    <>
      <Alert variant="primary alert-red ">
        <p>
          <strong>
            Don't use the damage output calculations as an indication on who is
            worth pulling or investing as those are just simulations and in
            day-to-day gameplay there's a lot variables
          </strong>
          . Use the numbers only to supplement your knowledge and treat them as
          the 'floor' of a character's damage output which you can further scale
          with proper team and investment.
        </p>
      </Alert>
      <p>
        The simulation showcases characters{' '}
        <strong>
          damage potential in the 8 cycle turn limit of Memory of Chaos against
          1 target
        </strong>
        . The section below has all the rules listed.
      </p>
      <Accordion className="sim-rules">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Simulation rules</Accordion.Header>
          <Accordion.Body>
            <p>
              <strong>General:</strong>
            </p>
            <ul>
              <li>
                Combat is simulated at 8 cycles of in-game time or 850 Action
                Points,
              </li>
              <li>
                Units are considered to gain 5 additional energy per rotation
                through combat (taking a hit, killing an enemy or through party
                members and their Light Cone),
              </li>
              <li>
                Characters and Light Cones are calculated at maximum output
                starting battle with ramp time removed from stacking buffs
                (intended to give a constant evaluation of power not dependant
                on length of fight that can be used to assess maximum power).
                Additional information about loss of damage due to ramp time can
                be round attached to related Light Cones, relics and characters,
              </li>
              <li>
                Character rotations are smoothed removing breakpoints relating
                to Speed and Energy (finishing a fight with 90% energy full or 2
                AV off next ability leading to skewed results). This removes any
                specific cut off point 850 AV, 750 AV etc, from impacting the
                results.
              </li>
            </ul>
            <p>
              <strong>Enemies:</strong>
            </p>
            <ul>
              <li>Enemies speed is set to 134,</li>
              <li>
                Enemies are assumed to be at 100% of HP at start of battle and
                to die at exactly at the end of combat. Which means halfway
                through the fight they are at 50% HP,
              </li>
              <li>Enemies are assumed to have 0 Resistance,</li>
              <li>
                Enemies are always considered vulnerable to the attackers
                element,
              </li>
              <li>Enemies are considered level 95 and with DEF included.</li>
            </ul>
            <p>
              <strong>Units</strong>:
            </p>
            <ul>
              <li>All units are level 80,</li>
              <li>
                Traces are all unlocked, skills at Level 10 and Basic at Level
                6,
              </li>
              <li>
                All units have fully upgraded 5 star relics with 24 desired sub
                stats out of a possible 54,
              </li>
              <li>All units use their Best In Slot 2 and 4 piece sets,</li>
              <li>
                All units use Light Cones that are realistically obtainable by
                all players (including those in Herta and FH Stores and Battle
                Pass),
              </li>
              <ul>
                <li>
                  Because the Herta Store and farmable Light Cones are easily
                  accessible to playerbase, we are simulating them at
                  Superimposition 5.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <p>
        Below we compare the damage output of Boothill when using S1 Sailing
        Towards A Second Life and S5 Cruising in the Stellar Sea Light Cones.
      </p>
      <Row xs={1} xl={1} className="dps-comparison">
        <Col>
          <div className="box">
            <h5>Boothill [1 target]</h5>
            <div className="simulation-container">
              <div className="simulation-row boothill">
                <div className="character with-eidolon">
                  <HSRCharacter slug="boothill" mode="icon" />
                  <span className="eidolon">E6</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Sailing Towards A Second Life"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].sig_e6.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].sig_e6 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].e6 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Cruising in the Stellar Sea"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].e6.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].e6 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row boothill">
                <div className="character with-eidolon">
                  <HSRCharacter slug="boothill" mode="icon" />
                  <span className="eidolon">E5</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].sig_e5 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Sailing Towards A Second Life"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].sig_e5.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].sig_e5 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].e5 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Cruising in the Stellar Sea"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].e5.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].e5 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row boothill">
                <div className="character with-eidolon">
                  <HSRCharacter slug="boothill" mode="icon" />
                  <span className="eidolon">E4</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].sig_e4 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Sailing Towards A Second Life"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].sig_e4.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].sig_e4 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].e4 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Cruising in the Stellar Sea"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].e4.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].e4 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row boothill">
                <div className="character with-eidolon">
                  <HSRCharacter slug="boothill" mode="icon" />
                  <span className="eidolon">E3</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].sig_e3 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Sailing Towards A Second Life"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].sig_e3.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].sig_e3 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].e3 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Cruising in the Stellar Sea"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].e3.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].e3 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row boothill">
                <div className="character with-eidolon">
                  <HSRCharacter slug="boothill" mode="icon" />
                  <span className="eidolon">E2</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].sig_e2 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Sailing Towards A Second Life"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].sig_e2.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].sig_e2 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].e2 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Cruising in the Stellar Sea"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].e2.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].e2 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row boothill">
                <div className="character with-eidolon">
                  <HSRCharacter slug="boothill" mode="icon" />
                  <span className="eidolon">E1</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].sig_e1 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Sailing Towards A Second Life"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].sig_e1.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].sig_e1 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].e1 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Cruising in the Stellar Sea"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].e1.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].e1 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row boothill">
                <div className="character with-eidolon">
                  <HSRCharacter slug="boothill" mode="icon" />
                  <span className="eidolon">E0</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-5`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].sig_e0 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Sailing Towards A Second Life"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].sig_e0.toLocaleString()}{' '}
                        dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSBoothillDPS[currentTab].sig_e0 /
                            HSRDPSBoothillDPS[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSBoothillDPS[currentTab].e0 /
                            HSRDPSBoothillDPS[currentTab].sig_e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="Cruising in the Stellar Sea"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSBoothillDPS[currentTab].e0.toLocaleString()} dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="section-build perf">
        <div className="build-relics perf">
          <Row xs={1} xl={2} xxl={2} className="relics">
            <Col>
              <h6 className="header">Relic Set</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet name="Thief of Shooting Meteor" compactMode />
              </div>
            </Col>
            <Col>
              <h6 className="header">Planetary Sets</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet name="Talia: Kingdom of Banditry" compactMode />
              </div>
            </Col>
          </Row>
        </div>
        <h6 className="header">Relic Stats</h6>
        <div className="build-stats">
          <Row xs={1} xl={2} xxl={4} className="main-stats">
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Body</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="CRIT Rate" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Feet</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="Speed" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Planar Sphere</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="Physical DMG" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Link Rope</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="Break Effect" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="box sub-stats">
                <span>Substats:</span>
                <p>
                  Speed (18.4), Break Effect (69.96%), Crit Rate (5.8%), CRIT
                  DMG (11.7%)
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
