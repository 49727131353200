import React from 'react';
import { Table } from 'react-bootstrap';
import { HSRLightCone } from '../common/components/hsr-light-cone';
import {
  faSquareCheck,
  faSquareXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ProfileRobinEnergy: React.FC = () => {
  return (
    <>
      <Table bordered responsive className="robin-lc-table">
        <thead>
          <tr>
            <th>Light Cone</th>
            <th>ERR Rope</th>
            <th>ERR Set</th>
            <th>Total ERR</th>
            <th>Rotation Energy</th>
            <th>Required Ally Attacks</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="with-cone">
              <HSRLightCone slug="flowing-nightglow" mode="min" superImp="1" />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>39%</td>
            <td>117.1</td>
            <td className="attacks">15.0</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone slug="flowing-nightglow" mode="min" superImp="1" />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>34%</td>
            <td>112.9</td>
            <td className="attacks">17.5</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone slug="flowing-nightglow" mode="min" superImp="1" />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>15%</td>
            <td>96.6</td>
            <td className="attacks">27.6</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="for-tomorrows-journey"
                mode="min"
                superImp="5"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>24%</td>
            <td>104.5</td>
            <td className="attacks">22.3</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="for-tomorrows-journey"
                mode="min"
                superImp="5"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>19%</td>
            <td>100.3</td>
            <td className="attacks">25.0</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="for-tomorrows-journey"
                mode="min"
                superImp="5"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>0%</td>
            <td>84.0</td>
            <td className="attacks">38.0</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="but-the-battle-isnt-over"
                mode="min"
                superImp="1"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>34%</td>
            <td>112.9</td>
            <td className="attacks">18.0</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="but-the-battle-isnt-over"
                mode="min"
                superImp="1"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>29%</td>
            <td>108.7</td>
            <td className="attacks">19.8</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="but-the-battle-isnt-over"
                mode="min"
                superImp="1"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>10%</td>
            <td>92.4</td>
            <td className="attacks">30.7</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="memories-of-the-past"
                mode="min"
                superImp="5"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>24%</td>
            <td>124.4</td>
            <td className="attacks">14.3</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="memories-of-the-past"
                mode="min"
                superImp="5"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>19%</td>
            <td>119.4</td>
            <td className="attacks">17.0</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone
                slug="memories-of-the-past"
                mode="min"
                superImp="5"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>0%</td>
            <td>100.0</td>
            <td className="attacks">30.0</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone slug="meshing-cogs" mode="min" superImp="5" />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>24%</td>
            <td>124.4</td>
            <td className="attacks">14.3</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone slug="meshing-cogs" mode="min" superImp="5" />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareCheck}
                width="18"
                className="green"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>19%</td>
            <td>119.4</td>
            <td className="attacks">17.0</td>
          </tr>
          <tr>
            <td className="with-cone">
              <HSRLightCone slug="meshing-cogs" mode="min" superImp="5" />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>
              <FontAwesomeIcon
                icon={faSquareXmark}
                width="18"
                className="red"
              />
            </td>
            <td>0%</td>
            <td>100.0</td>
            <td className="attacks">30.0</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
