import React from 'react';
import { Table } from 'react-bootstrap';

export const ProfileMeiCones: React.FC = () => {
  return (
    <Table bordered responsive className="colored-table">
      <thead>
        <tr>
          <th>Light Cone</th>
          <th>Personal Stats</th>
          <th>Energy Regen</th>
          <th>Team Buffs</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>Meshing Cogs</strong>
          </td>
          <td className="red-light">1/5</td>
          <td className="green">5/5</td>
          <td className="red">0/5</td>
        </tr>
        <tr>
          <td>
            <strong>Memories of the Past</strong>
          </td>
          <td className="green-light">4/5</td>
          <td className="green-light">4.5/5</td>
          <td className="red">0/5</td>
        </tr>
        <tr>
          <td>
            <strong>Past Self in Mirror</strong>
          </td>
          <td className="green">5/5</td>
          <td className="orange">2/5</td>
          <td className="green">5/5</td>
        </tr>
        <tr>
          <td>
            <strong>Carve the Moon, Weave the Clouds</strong>
          </td>
          <td className="orange">2.5/5</td>
          <td className="red-light">1/5</td>
          <td className="orange">2/5</td>
        </tr>
        <tr>
          <td>
            <strong>Dance! Dance! Dance!</strong>
          </td>
          <td className="orange">2/5</td>
          <td className="red">0/5</td>
          <td className="yellow">3.5/5</td>
        </tr>
      </tbody>
    </Table>
  );
};
