export const PFDuos = [
  {
    char: 'Argenti',
    round: 39622,
    char_1: 'Tingyun',
    app_rate_1: 62.25,
    avg_round_1: 39920,
    app_flat_1: 682,
    char_2: 'Sparkle',
    app_rate_2: 40.62,
    avg_round_2: 40000,
    app_flat_2: 424,
    char_3: 'Huohuo',
    app_rate_3: 34.21,
    avg_round_3: 40000,
    app_flat_3: 351,
    char_4: 'fu-xuan',
    app_rate_4: 30.18,
    avg_round_4: 39670,
    app_flat_4: 274,
    char_5: 'ruan-mei',
    app_rate_5: 21.56,
    avg_round_5: 39066,
    app_flat_5: 176,
    char_6: 'Robin',
    app_rate_6: 20.65,
    avg_round_6: 40000,
    app_flat_6: 189,
    char_7: 'Jade',
    app_rate_7: 16.0,
    avg_round_7: 40000,
    app_flat_7: 143,
    char_8: 'Bronya',
    app_rate_8: 15.03,
    avg_round_8: 39038,
    app_flat_8: 161,
    char_9: 'Luocha',
    app_rate_9: 14.78,
    avg_round_9: 39188,
    app_flat_9: 163,
    char_10: 'Herta',
    app_rate_10: 11.12,
    avg_round_10: 38591,
    app_flat_10: 112,
    char_11: 'Aventurine',
    app_rate_11: 9.71,
    avg_round_11: 39524,
    app_flat_11: 95,
    char_12: 'Hanya',
    app_rate_12: 5.19,
    avg_round_12: 39862,
    app_flat_12: 48,
    char_13: 'Gallagher',
    app_rate_13: 3.36,
    avg_round_13: 35867,
    app_flat_13: 40,
    char_14: 'Clara',
    app_rate_14: 2.44,
    avg_round_14: 35009,
    app_flat_14: 25,
    char_15: 'Yunli',
    app_rate_15: 2.14,
    avg_round_15: 38895,
    app_flat_15: 21,
    char_16: 'Himeko',
    app_rate_16: 1.22,
    avg_round_16: 32475,
    app_flat_16: 11,
    char_17: 'Jiaoqiu',
    app_rate_17: 1.22,
    avg_round_17: 40000,
    app_flat_17: 12,
    char_18: 'Pela',
    app_rate_18: 1.04,
    avg_round_18: 36924,
    app_flat_18: 10,
    char_19: 'Lynx',
    app_rate_19: 0.73,
    avg_round_19: 36413,
    app_flat_19: 10,
    char_20: 'Blade',
    app_rate_20: 0.73,
    avg_round_20: 28872,
    app_flat_20: 2,
    char_21: 'Asta',
    app_rate_21: 0.67,
    avg_round_21: 35137,
    app_flat_21: 9,
    char_22: 'march-7th-swordmaster',
    app_rate_22: 0.61,
    avg_round_22: 31739,
    app_flat_22: 8,
    char_23: 'Boothill',
    app_rate_23: 0.55,
    avg_round_23: 34051,
    app_flat_23: 3,
    char_24: 'Bailu',
    app_rate_24: 0.55,
    avg_round_24: 36486,
    app_flat_24: 7,
    char_25: 'Seele',
    app_rate_25: 0.43,
    avg_round_25: 36579,
    app_flat_25: 2,
    char_26: 'trailblazer-imaginary',
    app_rate_26: 0.43,
    avg_round_26: 33587,
    app_flat_26: 5,
    char_27: 'Gepard',
    app_rate_27: 0.31,
    avg_round_27: 36399,
    app_flat_27: 4,
    char_28: 'Acheron',
    app_rate_28: 0.31,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Firefly',
    app_rate_29: 0.24,
    avg_round_29: 33760,
    app_flat_29: 2,
    char_30: 'imbibitor-lunae',
    app_rate_30: 0.24,
    avg_round_30: 35750,
    app_flat_30: 1
  },
  {
    char: 'Hanya',
    round: 39241,
    char_1: 'Argenti',
    app_rate_1: 75.89,
    avg_round_1: 39862,
    app_flat_1: 48,
    char_2: 'Tingyun',
    app_rate_2: 61.61,
    avg_round_2: 39700,
    app_flat_2: 42,
    char_3: 'fu-xuan',
    app_rate_3: 30.36,
    avg_round_3: 39400,
    app_flat_3: 11,
    char_4: 'Huohuo',
    app_rate_4: 21.43,
    avg_round_4: 40000,
    app_flat_4: 15,
    char_5: 'Luocha',
    app_rate_5: 16.96,
    avg_round_5: 39156,
    app_flat_5: 14,
    char_6: 'Herta',
    app_rate_6: 13.39,
    avg_round_6: 37442,
    app_flat_6: 10,
    char_7: 'Aventurine',
    app_rate_7: 10.71,
    avg_round_7: 38762,
    app_flat_7: 6,
    char_8: 'Yunli',
    app_rate_8: 8.93,
    avg_round_8: 40000,
    app_flat_8: 5,
    char_9: 'Bronya',
    app_rate_9: 8.93,
    avg_round_9: 35713,
    app_flat_9: 6,
    char_10: 'Robin',
    app_rate_10: 7.14,
    avg_round_10: 38825,
    app_flat_10: 6,
    char_11: 'ruan-mei',
    app_rate_11: 6.25,
    avg_round_11: 37449,
    app_flat_11: 3,
    char_12: 'Jade',
    app_rate_12: 4.46,
    avg_round_12: 40000,
    app_flat_12: 4,
    char_13: 'Gallagher',
    app_rate_13: 4.46,
    avg_round_13: 31846,
    app_flat_13: 4,
    char_14: 'Clara',
    app_rate_14: 4.46,
    avg_round_14: 36050,
    app_flat_14: 3,
    char_15: 'Lynx',
    app_rate_15: 3.57,
    avg_round_15: 38050,
    app_flat_15: 2,
    char_16: 'Himeko',
    app_rate_16: 2.68,
    avg_round_16: 22632,
    app_flat_16: 2,
    char_17: 'Sparkle',
    app_rate_17: 2.68,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'imbibitor-lunae',
    app_rate_18: 2.68,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'jing-yuan',
    app_rate_19: 1.79,
    avg_round_19: 25100,
    app_flat_19: 1,
    char_20: 'Pela',
    app_rate_20: 1.79,
    avg_round_20: 25300,
    app_flat_20: 1,
    char_21: 'Bailu',
    app_rate_21: 0.89,
    avg_round_21: 40000,
    app_flat_21: 1,
    char_22: 'Kafka',
    app_rate_22: 0.89,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'black-swan',
    app_rate_23: 0.89,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Guinaifen',
    app_rate_24: 0.89,
    avg_round_24: 20798,
    app_flat_24: 1,
    char_25: 'trailblazer-fire',
    app_rate_25: 0.89,
    avg_round_25: 20798,
    app_flat_25: 1,
    char_26: 'Boothill',
    app_rate_26: 0.89,
    avg_round_26: 40000,
    app_flat_26: 1,
    char_27: 'Acheron',
    app_rate_27: 0.89,
    avg_round_27: 25300,
    app_flat_27: 1,
    char_28: 'Sushang',
    app_rate_28: 0.89,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Asta',
    app_rate_29: 0.89,
    avg_round_29: 40000,
    app_flat_29: 1,
    char_30: 'march-7th-swordmaster',
    app_rate_30: 0.89,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Jade',
    round: 39162,
    char_1: 'Herta',
    app_rate_1: 50.41,
    avg_round_1: 39401,
    app_flat_1: 851,
    char_2: 'Robin',
    app_rate_2: 47.16,
    avg_round_2: 40000,
    app_flat_2: 726,
    char_3: 'fu-xuan',
    app_rate_3: 28.66,
    avg_round_3: 39034,
    app_flat_3: 404,
    char_4: 'Huohuo',
    app_rate_4: 22.7,
    avg_round_4: 39757,
    app_flat_4: 355,
    char_5: 'ruan-mei',
    app_rate_5: 22.58,
    avg_round_5: 38177,
    app_flat_5: 304,
    char_6: 'Aventurine',
    app_rate_6: 22.07,
    avg_round_6: 39592,
    app_flat_6: 328,
    char_7: 'Yunli',
    app_rate_7: 15.8,
    avg_round_7: 39583,
    app_flat_7: 226,
    char_8: 'Clara',
    app_rate_8: 12.94,
    avg_round_8: 38112,
    app_flat_8: 210,
    char_9: 'Luocha',
    app_rate_9: 11.49,
    avg_round_9: 38868,
    app_flat_9: 175,
    char_10: 'Argenti',
    app_rate_10: 10.27,
    avg_round_10: 40000,
    app_flat_10: 143,
    char_11: 'Sparkle',
    app_rate_11: 10.19,
    avg_round_11: 37882,
    app_flat_11: 129,
    char_12: 'Blade',
    app_rate_12: 8.47,
    avg_round_12: 37537,
    app_flat_12: 101,
    char_13: 'Himeko',
    app_rate_13: 7.76,
    avg_round_13: 32170,
    app_flat_13: 110,
    char_14: 'Bronya',
    app_rate_14: 6.55,
    avg_round_14: 38130,
    app_flat_14: 81,
    char_15: 'Tingyun',
    app_rate_15: 4.94,
    avg_round_15: 38594,
    app_flat_15: 86,
    char_16: 'Gallagher',
    app_rate_16: 4.19,
    avg_round_16: 36765,
    app_flat_16: 62,
    char_17: 'Lynx',
    app_rate_17: 3.25,
    avg_round_17: 36947,
    app_flat_17: 50,
    char_18: 'topaz',
    app_rate_18: 2.23,
    avg_round_18: 30687,
    app_flat_18: 11,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 0.94,
    avg_round_19: 35322,
    app_flat_19: 15,
    char_20: 'Bailu',
    app_rate_20: 0.9,
    avg_round_20: 37704,
    app_flat_20: 15,
    char_21: 'Seele',
    app_rate_21: 0.67,
    avg_round_21: 32220,
    app_flat_21: 3,
    char_22: 'Pela',
    app_rate_22: 0.63,
    avg_round_22: 36700,
    app_flat_22: 7,
    char_23: 'Asta',
    app_rate_23: 0.63,
    avg_round_23: 35798,
    app_flat_23: 12,
    char_24: 'jing-yuan',
    app_rate_24: 0.59,
    avg_round_24: 28918,
    app_flat_24: 9,
    char_25: 'Acheron',
    app_rate_25: 0.59,
    avg_round_25: 40000,
    app_flat_25: 3,
    char_26: 'Xueyi',
    app_rate_26: 0.55,
    avg_round_26: 32450,
    app_flat_26: 7,
    char_27: 'Gepard',
    app_rate_27: 0.51,
    avg_round_27: 38158,
    app_flat_27: 10,
    char_28: 'Jingliu',
    app_rate_28: 0.31,
    avg_round_28: 31526,
    app_flat_28: 3,
    char_29: 'silver-wolf',
    app_rate_29: 0.27,
    avg_round_29: 34956,
    app_flat_29: 2,
    char_30: 'Kafka',
    app_rate_30: 0.27,
    avg_round_30: 35700,
    app_flat_30: 1
  },
  {
    char: 'Robin',
    round: 38597,
    char_1: 'Herta',
    app_rate_1: 48.27,
    avg_round_1: 39081,
    app_flat_1: 1425,
    char_2: 'Jade',
    app_rate_2: 28.39,
    avg_round_2: 40000,
    app_flat_2: 726,
    char_3: 'Yunli',
    app_rate_3: 27.68,
    avg_round_3: 39300,
    app_flat_3: 722,
    char_4: 'Aventurine',
    app_rate_4: 26.98,
    avg_round_4: 38466,
    app_flat_4: 689,
    char_5: 'Huohuo',
    app_rate_5: 26.5,
    avg_round_5: 39440,
    app_flat_5: 672,
    char_6: 'Clara',
    app_rate_6: 22.63,
    avg_round_6: 38447,
    app_flat_6: 694,
    char_7: 'fu-xuan',
    app_rate_7: 19.47,
    avg_round_7: 39072,
    app_flat_7: 465,
    char_8: 'Tingyun',
    app_rate_8: 15.41,
    avg_round_8: 38071,
    app_flat_8: 404,
    char_9: 'Himeko',
    app_rate_9: 13.08,
    avg_round_9: 31485,
    app_flat_9: 340,
    char_10: 'Luocha',
    app_rate_10: 8.64,
    avg_round_10: 38199,
    app_flat_10: 255,
    char_11: 'Argenti',
    app_rate_11: 7.98,
    avg_round_11: 40000,
    app_flat_11: 189,
    char_12: 'Sparkle',
    app_rate_12: 7.69,
    avg_round_12: 38060,
    app_flat_12: 140,
    char_13: 'topaz',
    app_rate_13: 7.51,
    avg_round_13: 32555,
    app_flat_13: 123,
    char_14: 'Gallagher',
    app_rate_14: 6.33,
    avg_round_14: 34794,
    app_flat_14: 163,
    char_15: 'ruan-mei',
    app_rate_15: 4.41,
    avg_round_15: 33608,
    app_flat_15: 89,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 3.21,
    avg_round_16: 36191,
    app_flat_16: 98,
    char_17: 'Lynx',
    app_rate_17: 3.12,
    avg_round_17: 37233,
    app_flat_17: 106,
    char_18: 'Acheron',
    app_rate_18: 2.57,
    avg_round_18: 36872,
    app_flat_18: 23,
    char_19: 'jing-yuan',
    app_rate_19: 2.45,
    avg_round_19: 27146,
    app_flat_19: 49,
    char_20: 'Blade',
    app_rate_20: 2.36,
    avg_round_20: 36084,
    app_flat_20: 50,
    char_21: 'Seele',
    app_rate_21: 2.22,
    avg_round_21: 36980,
    app_flat_21: 39,
    char_22: 'Kafka',
    app_rate_22: 1.96,
    avg_round_22: 31916,
    app_flat_22: 27,
    char_23: 'black-swan',
    app_rate_23: 1.75,
    avg_round_23: 33063,
    app_flat_23: 15,
    char_24: 'Bronya',
    app_rate_24: 1.68,
    avg_round_24: 37847,
    app_flat_24: 32,
    char_25: 'Pela',
    app_rate_25: 1.56,
    avg_round_25: 37537,
    app_flat_25: 19,
    char_26: 'Jiaoqiu',
    app_rate_26: 1.06,
    avg_round_26: 36753,
    app_flat_26: 17,
    char_27: 'Xueyi',
    app_rate_27: 0.92,
    avg_round_27: 33596,
    app_flat_27: 28,
    char_28: 'Bailu',
    app_rate_28: 0.73,
    avg_round_28: 38551,
    app_flat_28: 23,
    char_29: 'dr-ratio',
    app_rate_29: 0.61,
    avg_round_29: 29440,
    app_flat_29: 9,
    char_30: 'Jingliu',
    app_rate_30: 0.5,
    avg_round_30: 30560,
    app_flat_30: 5
  },
  {
    char: 'Yunli',
    round: 38596,
    char_1: 'Robin',
    app_rate_1: 55.78,
    avg_round_1: 39300,
    app_flat_1: 722,
    char_2: 'Huohuo',
    app_rate_2: 45.84,
    avg_round_2: 39160,
    app_flat_2: 593,
    char_3: 'Tingyun',
    app_rate_3: 42.37,
    avg_round_3: 37881,
    app_flat_3: 581,
    char_4: 'Sparkle',
    app_rate_4: 26.01,
    avg_round_4: 38380,
    app_flat_4: 316,
    char_5: 'Jade',
    app_rate_5: 19.16,
    avg_round_5: 39583,
    app_flat_5: 226,
    char_6: 'Herta',
    app_rate_6: 18.64,
    avg_round_6: 39098,
    app_flat_6: 304,
    char_7: 'fu-xuan',
    app_rate_7: 17.74,
    avg_round_7: 38906,
    app_flat_7: 184,
    char_8: 'Aventurine',
    app_rate_8: 12.22,
    avg_round_8: 39530,
    app_flat_8: 161,
    char_9: 'ruan-mei',
    app_rate_9: 10.46,
    avg_round_9: 38508,
    app_flat_9: 112,
    char_10: 'Lynx',
    app_rate_10: 9.03,
    avg_round_10: 35149,
    app_flat_10: 146,
    char_11: 'Clara',
    app_rate_11: 8.42,
    avg_round_11: 35816,
    app_flat_11: 111,
    char_12: 'Luocha',
    app_rate_12: 7.61,
    avg_round_12: 36693,
    app_flat_12: 102,
    char_13: 'topaz',
    app_rate_13: 5.9,
    avg_round_13: 38080,
    app_flat_13: 51,
    char_14: 'march-7th-swordmaster',
    app_rate_14: 5.23,
    avg_round_14: 37316,
    app_flat_14: 76,
    char_15: 'Himeko',
    app_rate_15: 1.9,
    avg_round_15: 34125,
    app_flat_15: 27,
    char_16: 'Gallagher',
    app_rate_16: 1.9,
    avg_round_16: 37374,
    app_flat_16: 35,
    char_17: 'Argenti',
    app_rate_17: 1.66,
    avg_round_17: 38895,
    app_flat_17: 21,
    char_18: 'Pela',
    app_rate_18: 1.62,
    avg_round_18: 36048,
    app_flat_18: 25,
    char_19: 'Blade',
    app_rate_19: 1.33,
    avg_round_19: 34839,
    app_flat_19: 18,
    char_20: 'Bronya',
    app_rate_20: 0.81,
    avg_round_20: 33134,
    app_flat_20: 8,
    char_21: 'Acheron',
    app_rate_21: 0.71,
    avg_round_21: 36635,
    app_flat_21: 5,
    char_22: 'Bailu',
    app_rate_22: 0.67,
    avg_round_22: 36555,
    app_flat_22: 9,
    char_23: 'Xueyi',
    app_rate_23: 0.62,
    avg_round_23: 36103,
    app_flat_23: 7,
    char_24: 'Seele',
    app_rate_24: 0.57,
    avg_round_24: 35662,
    app_flat_24: 7,
    char_25: 'Hanya',
    app_rate_25: 0.48,
    avg_round_25: 40000,
    app_flat_25: 5,
    char_26: 'march-7th',
    app_rate_26: 0.43,
    avg_round_26: 35436,
    app_flat_26: 7,
    char_27: 'black-swan',
    app_rate_27: 0.38,
    avg_round_27: 33151,
    app_flat_27: 4,
    char_28: 'Firefly',
    app_rate_28: 0.33,
    avg_round_28: 25404,
    app_flat_28: 4,
    char_29: 'Kafka',
    app_rate_29: 0.29,
    avg_round_29: 33471,
    app_flat_29: 5,
    char_30: 'Jingliu',
    app_rate_30: 0.24,
    avg_round_30: 35890,
    app_flat_30: 2
  },
  {
    char: 'Huohuo',
    round: 37905,
    char_1: 'Tingyun',
    app_rate_1: 35.13,
    avg_round_1: 38641,
    app_flat_1: 715,
    char_2: 'Robin',
    app_rate_2: 35.01,
    avg_round_2: 39440,
    app_flat_2: 672,
    char_3: 'Yunli',
    app_rate_3: 30.05,
    avg_round_3: 39160,
    app_flat_3: 593,
    char_4: 'Herta',
    app_rate_4: 25.62,
    avg_round_4: 38101,
    app_flat_4: 560,
    char_5: 'Sparkle',
    app_rate_5: 24.69,
    avg_round_5: 38865,
    app_flat_5: 448,
    char_6: 'ruan-mei',
    app_rate_6: 23.22,
    avg_round_6: 33959,
    app_flat_6: 291,
    char_7: 'Kafka',
    app_rate_7: 18.77,
    avg_round_7: 29507,
    app_flat_7: 172,
    char_8: 'Jade',
    app_rate_8: 18.05,
    avg_round_8: 39757,
    app_flat_8: 355,
    char_9: 'Argenti',
    app_rate_9: 17.46,
    avg_round_9: 40000,
    app_flat_9: 351,
    char_10: 'black-swan',
    app_rate_10: 17.05,
    avg_round_10: 29733,
    app_flat_10: 148,
    char_11: 'Clara',
    app_rate_11: 13.25,
    avg_round_11: 35760,
    app_flat_11: 280,
    char_12: 'Acheron',
    app_rate_12: 10.54,
    avg_round_12: 30334,
    app_flat_12: 122,
    char_13: 'Himeko',
    app_rate_13: 6.23,
    avg_round_13: 28874,
    app_flat_13: 108,
    char_14: 'Bronya',
    app_rate_14: 3.52,
    avg_round_14: 37338,
    app_flat_14: 62,
    char_15: 'march-7th-swordmaster',
    app_rate_15: 2.87,
    avg_round_15: 35564,
    app_flat_15: 63,
    char_16: 'Pela',
    app_rate_16: 2.43,
    avg_round_16: 31191,
    app_flat_16: 42,
    char_17: 'Blade',
    app_rate_17: 2.28,
    avg_round_17: 36269,
    app_flat_17: 35,
    char_18: 'topaz',
    app_rate_18: 1.84,
    avg_round_18: 36635,
    app_flat_18: 22,
    char_19: 'jing-yuan',
    app_rate_19: 1.75,
    avg_round_19: 25168,
    app_flat_19: 25,
    char_20: 'imbibitor-lunae',
    app_rate_20: 1.59,
    avg_round_20: 32209,
    app_flat_20: 1,
    char_21: 'Jingliu',
    app_rate_21: 1.25,
    avg_round_21: 27870,
    app_flat_21: 15,
    char_22: 'Jiaoqiu',
    app_rate_22: 1.15,
    avg_round_22: 38328,
    app_flat_22: 13,
    char_23: 'Firefly',
    app_rate_23: 0.94,
    avg_round_23: 24706,
    app_flat_23: 3,
    char_24: 'Seele',
    app_rate_24: 0.9,
    avg_round_24: 35105,
    app_flat_24: 14,
    char_25: 'Hanya',
    app_rate_25: 0.75,
    avg_round_25: 40000,
    app_flat_25: 15,
    char_26: 'Serval',
    app_rate_26: 0.56,
    avg_round_26: 25394,
    app_flat_26: 9,
    char_27: 'Guinaifen',
    app_rate_27: 0.56,
    avg_round_27: 22997,
    app_flat_27: 9,
    char_28: 'trailblazer-imaginary',
    app_rate_28: 0.53,
    avg_round_28: 20000,
    app_flat_28: 1,
    char_29: 'Asta',
    app_rate_29: 0.5,
    avg_round_29: 32502,
    app_flat_29: 6,
    char_30: 'silver-wolf',
    app_rate_30: 0.47,
    avg_round_30: 27252,
    app_flat_30: 4
  },
  {
    char: 'Tingyun',
    round: 37309,
    char_1: 'Sparkle',
    app_rate_1: 45.05,
    avg_round_1: 37815,
    app_flat_1: 824,
    char_2: 'Huohuo',
    app_rate_2: 38.35,
    avg_round_2: 38641,
    app_flat_2: 715,
    char_3: 'Argenti',
    app_rate_3: 34.67,
    avg_round_3: 39920,
    app_flat_3: 682,
    char_4: 'Yunli',
    app_rate_4: 30.32,
    avg_round_4: 37881,
    app_flat_4: 581,
    char_5: 'fu-xuan',
    app_rate_5: 24.29,
    avg_round_5: 37498,
    app_flat_5: 397,
    char_6: 'Robin',
    app_rate_6: 22.22,
    avg_round_6: 38071,
    app_flat_6: 404,
    char_7: 'Herta',
    app_rate_7: 13.2,
    avg_round_7: 35007,
    app_flat_7: 274,
    char_8: 'ruan-mei',
    app_rate_8: 11.3,
    avg_round_8: 36202,
    app_flat_8: 176,
    char_9: 'Luocha',
    app_rate_9: 10.28,
    avg_round_9: 37479,
    app_flat_9: 201,
    char_10: 'Aventurine',
    app_rate_10: 9.02,
    avg_round_10: 36430,
    app_flat_10: 191,
    char_11: 'Clara',
    app_rate_11: 8.64,
    avg_round_11: 32855,
    app_flat_11: 177,
    char_12: 'jing-yuan',
    app_rate_12: 7.15,
    avg_round_12: 25446,
    app_flat_12: 122,
    char_13: 'Bronya',
    app_rate_13: 6.26,
    avg_round_13: 38216,
    app_flat_13: 128,
    char_14: 'Gallagher',
    app_rate_14: 5.75,
    avg_round_14: 31389,
    app_flat_14: 124,
    char_15: 'Seele',
    app_rate_15: 4.63,
    avg_round_15: 34843,
    app_flat_15: 59,
    char_16: 'Jade',
    app_rate_16: 4.29,
    avg_round_16: 38594,
    app_flat_16: 86,
    char_17: 'Lynx',
    app_rate_17: 3.84,
    avg_round_17: 34244,
    app_flat_17: 95,
    char_18: 'imbibitor-lunae',
    app_rate_18: 2.72,
    avg_round_18: 30997,
    app_flat_18: 2,
    char_19: 'Himeko',
    app_rate_19: 2.55,
    avg_round_19: 26210,
    app_flat_19: 56,
    char_20: 'Kafka',
    app_rate_20: 2.55,
    avg_round_20: 27645,
    app_flat_20: 46,
    char_21: 'Hanya',
    app_rate_21: 2.35,
    avg_round_21: 39700,
    app_flat_21: 42,
    char_22: 'Pela',
    app_rate_22: 1.7,
    avg_round_22: 33531,
    app_flat_22: 38,
    char_23: 'black-swan',
    app_rate_23: 1.29,
    avg_round_23: 26589,
    app_flat_23: 19,
    char_24: 'march-7th-swordmaster',
    app_rate_24: 1.19,
    avg_round_24: 33032,
    app_flat_24: 24,
    char_25: 'Serval',
    app_rate_25: 1.05,
    avg_round_25: 25432,
    app_flat_25: 26,
    char_26: 'Asta',
    app_rate_26: 0.78,
    avg_round_26: 28932,
    app_flat_26: 17,
    char_27: 'topaz',
    app_rate_27: 0.71,
    avg_round_27: 29088,
    app_flat_27: 12,
    char_28: 'Bailu',
    app_rate_28: 0.54,
    avg_round_28: 30914,
    app_flat_28: 8,
    char_29: 'Jiaoqiu',
    app_rate_29: 0.51,
    avg_round_29: 40000,
    app_flat_29: 9,
    char_30: 'Blade',
    app_rate_30: 0.44,
    avg_round_30: 33239,
    app_flat_30: 10
  },
  {
    char: 'Natasha',
    round: 36881,
    char_1: 'Herta',
    app_rate_1: 38.1,
    avg_round_1: 37742,
    app_flat_1: 6,
    char_2: 'Robin',
    app_rate_2: 28.57,
    avg_round_2: 40000,
    app_flat_2: 3,
    char_3: 'Kafka',
    app_rate_3: 28.57,
    avg_round_3: 27894,
    app_flat_3: 2,
    char_4: 'Clara',
    app_rate_4: 23.81,
    avg_round_4: 36989,
    app_flat_4: 3,
    char_5: 'black-swan',
    app_rate_5: 23.81,
    avg_round_5: 31500,
    app_flat_5: 1,
    char_6: 'ruan-mei',
    app_rate_6: 23.81,
    avg_round_6: 32144,
    app_flat_6: 2,
    char_7: 'Himeko',
    app_rate_7: 19.05,
    avg_round_7: 26074,
    app_flat_7: 3,
    char_8: 'Acheron',
    app_rate_8: 19.05,
    avg_round_8: 33600,
    app_flat_8: 2,
    char_9: 'Yunli',
    app_rate_9: 14.29,
    avg_round_9: 40000,
    app_flat_9: 1,
    char_10: 'Jade',
    app_rate_10: 14.29,
    avg_round_10: 40000,
    app_flat_10: 2,
    char_11: 'Bronya',
    app_rate_11: 14.29,
    avg_round_11: 40000,
    app_flat_11: 1,
    char_12: 'Tingyun',
    app_rate_12: 9.52,
    avg_round_12: 40000,
    app_flat_12: 1,
    char_13: 'Blade',
    app_rate_13: 9.52,
    avg_round_13: 22969,
    app_flat_13: 1,
    char_14: 'Sparkle',
    app_rate_14: 9.52,
    avg_round_14: 40000,
    app_flat_14: 1,
    char_15: 'Argenti',
    app_rate_15: 9.52,
    avg_round_15: 40000,
    app_flat_15: 2,
    char_16: 'Seele',
    app_rate_16: 4.76,
    avg_round_16: 35700,
    app_flat_16: 1,
    char_17: 'Pela',
    app_rate_17: 4.76,
    avg_round_17: 35700,
    app_flat_17: 1,
    char_18: 'Guinaifen',
    app_rate_18: 4.76,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Clara',
    round: 36468,
    char_1: 'Herta',
    app_rate_1: 56.19,
    avg_round_1: 37477,
    app_flat_1: 865,
    char_2: 'Robin',
    app_rate_2: 46.73,
    avg_round_2: 38447,
    app_flat_2: 694,
    char_3: 'Aventurine',
    app_rate_3: 25.58,
    avg_round_3: 37323,
    app_flat_3: 374,
    char_4: 'fu-xuan',
    app_rate_4: 24.95,
    avg_round_4: 36834,
    app_flat_4: 303,
    char_5: 'ruan-mei',
    app_rate_5: 22.27,
    avg_round_5: 34758,
    app_flat_5: 282,
    char_6: 'Huohuo',
    app_rate_6: 20.71,
    avg_round_6: 35760,
    app_flat_6: 280,
    char_7: 'Sparkle',
    app_rate_7: 19.01,
    avg_round_7: 34304,
    app_flat_7: 227,
    char_8: 'Jade',
    app_rate_8: 16.08,
    avg_round_8: 38112,
    app_flat_8: 210,
    char_9: 'Luocha',
    app_rate_9: 14.23,
    avg_round_9: 36115,
    app_flat_9: 215,
    char_10: 'Tingyun',
    app_rate_10: 12.38,
    avg_round_10: 32855,
    app_flat_10: 177,
    char_11: 'Yunli',
    app_rate_11: 8.63,
    avg_round_11: 35816,
    app_flat_11: 111,
    char_12: 'topaz',
    app_rate_12: 4.92,
    avg_round_12: 30478,
    app_flat_12: 48,
    char_13: 'Lynx',
    app_rate_13: 4.73,
    avg_round_13: 32947,
    app_flat_13: 82,
    char_14: 'Himeko',
    app_rate_14: 3.9,
    avg_round_14: 30071,
    app_flat_14: 57,
    char_15: 'Gallagher',
    app_rate_15: 3.51,
    avg_round_15: 36565,
    app_flat_15: 53,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 3.02,
    avg_round_16: 31020,
    app_flat_16: 46,
    char_17: 'Argenti',
    app_rate_17: 1.95,
    avg_round_17: 35009,
    app_flat_17: 25,
    char_18: 'Blade',
    app_rate_18: 1.66,
    avg_round_18: 31462,
    app_flat_18: 22,
    char_19: 'Bronya',
    app_rate_19: 1.32,
    avg_round_19: 31548,
    app_flat_19: 16,
    char_20: 'Seele',
    app_rate_20: 1.07,
    avg_round_20: 30144,
    app_flat_20: 10,
    char_21: 'Xueyi',
    app_rate_21: 0.88,
    avg_round_21: 30382,
    app_flat_21: 10,
    char_22: 'Pela',
    app_rate_22: 0.73,
    avg_round_22: 31369,
    app_flat_22: 11,
    char_23: 'Bailu',
    app_rate_23: 0.68,
    avg_round_23: 35834,
    app_flat_23: 9,
    char_24: 'Acheron',
    app_rate_24: 0.54,
    avg_round_24: 35037,
    app_flat_24: 4,
    char_25: 'march-7th',
    app_rate_25: 0.49,
    avg_round_25: 35292,
    app_flat_25: 9,
    char_26: 'Jingliu',
    app_rate_26: 0.44,
    avg_round_26: 28848,
    app_flat_26: 2,
    char_27: 'black-swan',
    app_rate_27: 0.39,
    avg_round_27: 29549,
    app_flat_27: 7,
    char_28: 'imbibitor-lunae',
    app_rate_28: 0.39,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Firefly',
    app_rate_29: 0.34,
    avg_round_29: 24344,
    app_flat_29: 2,
    char_30: 'Kafka',
    app_rate_30: 0.29,
    avg_round_30: 27694,
    app_flat_30: 6
  },
  {
    char: 'Sparkle',
    round: 36361,
    char_1: 'Tingyun',
    app_rate_1: 36.32,
    avg_round_1: 37815,
    app_flat_1: 824,
    char_2: 'fu-xuan',
    app_rate_2: 33.99,
    avg_round_2: 36517,
    app_flat_2: 454,
    char_3: 'Acheron',
    app_rate_3: 27.16,
    avg_round_3: 28940,
    app_flat_3: 134,
    char_4: 'Huohuo',
    app_rate_4: 21.73,
    avg_round_4: 38865,
    app_flat_4: 448,
    char_5: 'Herta',
    app_rate_5: 18.82,
    avg_round_5: 36099,
    app_flat_5: 395,
    char_6: 'Argenti',
    app_rate_6: 18.24,
    avg_round_6: 40000,
    app_flat_6: 424,
    char_7: 'Aventurine',
    app_rate_7: 16.32,
    avg_round_7: 33232,
    app_flat_7: 198,
    char_8: 'Yunli',
    app_rate_8: 15.01,
    avg_round_8: 38380,
    app_flat_8: 316,
    char_9: 'Pela',
    app_rate_9: 13.33,
    avg_round_9: 29275,
    app_flat_9: 103,
    char_10: 'Clara',
    app_rate_10: 10.7,
    avg_round_10: 34304,
    app_flat_10: 227,
    char_11: 'Robin',
    app_rate_11: 8.94,
    avg_round_11: 38060,
    app_flat_11: 140,
    char_12: 'Jiaoqiu',
    app_rate_12: 8.18,
    avg_round_12: 30181,
    app_flat_12: 31,
    char_13: 'Luocha',
    app_rate_13: 7.38,
    avg_round_13: 36133,
    app_flat_13: 130,
    char_14: 'Jade',
    app_rate_14: 7.13,
    avg_round_14: 37882,
    app_flat_14: 129,
    char_15: 'Gallagher',
    app_rate_15: 7.08,
    avg_round_15: 29454,
    app_flat_15: 108,
    char_16: 'jing-yuan',
    app_rate_16: 6.23,
    avg_round_16: 24862,
    app_flat_16: 125,
    char_17: 'Seele',
    app_rate_17: 6.2,
    avg_round_17: 34758,
    app_flat_17: 80,
    char_18: 'ruan-mei',
    app_rate_18: 6.2,
    avg_round_18: 31384,
    app_flat_18: 85,
    char_19: 'Himeko',
    app_rate_19: 5.24,
    avg_round_19: 26730,
    app_flat_19: 82,
    char_20: 'Bronya',
    app_rate_20: 4.06,
    avg_round_20: 34856,
    app_flat_20: 68,
    char_21: 'imbibitor-lunae',
    app_rate_21: 3.59,
    avg_round_21: 33194,
    app_flat_21: 5,
    char_22: 'silver-wolf',
    app_rate_22: 2.83,
    avg_round_22: 26795,
    app_flat_22: 5,
    char_23: 'Lynx',
    app_rate_23: 2.74,
    avg_round_23: 35000,
    app_flat_23: 75,
    char_24: 'Blade',
    app_rate_24: 2.17,
    avg_round_24: 34066,
    app_flat_24: 42,
    char_25: 'black-swan',
    app_rate_25: 1.92,
    avg_round_25: 27168,
    app_flat_25: 9,
    char_26: 'Gepard',
    app_rate_26: 1.78,
    avg_round_26: 26359,
    app_flat_26: 12,
    char_27: 'Kafka',
    app_rate_27: 1.34,
    avg_round_27: 26565,
    app_flat_27: 5,
    char_28: 'march-7th-swordmaster',
    app_rate_28: 0.58,
    avg_round_28: 33913,
    app_flat_28: 8,
    char_29: 'Serval',
    app_rate_29: 0.52,
    avg_round_29: 26853,
    app_flat_29: 10,
    char_30: 'Qingque',
    app_rate_30: 0.52,
    avg_round_30: 29245,
    app_flat_30: 11
  },
  {
    char: 'Bronya',
    round: 36340,
    char_1: 'Argenti',
    app_rate_1: 26.8,
    avg_round_1: 39038,
    app_flat_1: 161,
    char_2: 'Acheron',
    app_rate_2: 23.75,
    avg_round_2: 27329,
    app_flat_2: 14,
    char_3: 'fu-xuan',
    app_rate_3: 23.53,
    avg_round_3: 35975,
    app_flat_3: 77,
    char_4: 'Luocha',
    app_rate_4: 21.24,
    avg_round_4: 37478,
    app_flat_4: 106,
    char_5: 'Tingyun',
    app_rate_5: 20.04,
    avg_round_5: 38216,
    app_flat_5: 128,
    char_6: 'Jade',
    app_rate_6: 18.19,
    avg_round_6: 38130,
    app_flat_6: 81,
    char_7: 'Blade',
    app_rate_7: 17.21,
    avg_round_7: 36519,
    app_flat_7: 77,
    char_8: 'Pela',
    app_rate_8: 16.56,
    avg_round_8: 29302,
    app_flat_8: 18,
    char_9: 'Sparkle',
    app_rate_9: 16.12,
    avg_round_9: 34856,
    app_flat_9: 68,
    char_10: 'Herta',
    app_rate_10: 16.01,
    avg_round_10: 34085,
    app_flat_10: 90,
    char_11: 'ruan-mei',
    app_rate_11: 14.16,
    avg_round_11: 31961,
    app_flat_11: 49,
    char_12: 'Huohuo',
    app_rate_12: 12.31,
    avg_round_12: 37338,
    app_flat_12: 62,
    char_13: 'Gallagher',
    app_rate_13: 11.55,
    avg_round_13: 30570,
    app_flat_13: 41,
    char_14: 'Aventurine',
    app_rate_14: 11.33,
    avg_round_14: 37194,
    app_flat_14: 33,
    char_15: 'Robin',
    app_rate_15: 7.73,
    avg_round_15: 37847,
    app_flat_15: 32,
    char_16: 'Seele',
    app_rate_16: 5.01,
    avg_round_16: 31864,
    app_flat_16: 24,
    char_17: 'Jiaoqiu',
    app_rate_17: 4.68,
    avg_round_17: 30963,
    app_flat_17: 5,
    char_18: 'Jingliu',
    app_rate_18: 3.92,
    avg_round_18: 29057,
    app_flat_18: 17,
    char_19: 'Firefly',
    app_rate_19: 3.7,
    avg_round_19: 22224,
    app_flat_19: 1,
    char_20: 'trailblazer-imaginary',
    app_rate_20: 3.16,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Clara',
    app_rate_21: 2.94,
    avg_round_21: 31548,
    app_flat_21: 16,
    char_22: 'Gepard',
    app_rate_22: 2.61,
    avg_round_22: 29341,
    app_flat_22: 4,
    char_23: 'Himeko',
    app_rate_23: 2.4,
    avg_round_23: 26350,
    app_flat_23: 12,
    char_24: 'jing-yuan',
    app_rate_24: 1.96,
    avg_round_24: 22903,
    app_flat_24: 9,
    char_25: 'Yunli',
    app_rate_25: 1.85,
    avg_round_25: 33134,
    app_flat_25: 8,
    char_26: 'black-swan',
    app_rate_26: 1.74,
    avg_round_26: 29748,
    app_flat_26: 2,
    char_27: 'Lynx',
    app_rate_27: 1.2,
    avg_round_27: 35930,
    app_flat_27: 5,
    char_28: 'silver-wolf',
    app_rate_28: 1.09,
    avg_round_28: 22050,
    app_flat_28: 1,
    char_29: 'Kafka',
    app_rate_29: 1.09,
    avg_round_29: 21775,
    app_flat_29: 1,
    char_30: 'Hanya',
    app_rate_30: 1.09,
    avg_round_30: 35713,
    app_flat_30: 6
  },
  {
    char: 'Herta',
    round: 36288,
    char_1: 'ruan-mei',
    app_rate_1: 39.35,
    avg_round_1: 32787,
    app_flat_1: 1230,
    char_2: 'Robin',
    app_rate_2: 38.05,
    avg_round_2: 39081,
    app_flat_2: 1425,
    char_3: 'Himeko',
    app_rate_3: 30.01,
    avg_round_3: 30217,
    app_flat_3: 1034,
    char_4: 'fu-xuan',
    app_rate_4: 27.11,
    avg_round_4: 36614,
    app_flat_4: 860,
    char_5: 'Jade',
    app_rate_5: 23.93,
    avg_round_5: 39401,
    app_flat_5: 851,
    char_6: 'Aventurine',
    app_rate_6: 22.79,
    avg_round_6: 37705,
    app_flat_6: 824,
    char_7: 'Clara',
    app_rate_7: 21.45,
    avg_round_7: 37477,
    app_flat_7: 865,
    char_8: 'Huohuo',
    app_rate_8: 15.29,
    avg_round_8: 38101,
    app_flat_8: 560,
    char_9: 'Sparkle',
    app_rate_9: 12.76,
    avg_round_9: 36099,
    app_flat_9: 395,
    char_10: 'Gallagher',
    app_rate_10: 12.11,
    avg_round_10: 30947,
    app_flat_10: 459,
    char_11: 'Luocha',
    app_rate_11: 9.54,
    avg_round_11: 36913,
    app_flat_11: 349,
    char_12: 'Yunli',
    app_rate_12: 7.29,
    avg_round_12: 39098,
    app_flat_12: 304,
    char_13: 'Tingyun',
    app_rate_13: 7.22,
    avg_round_13: 35007,
    app_flat_13: 274,
    char_14: 'Argenti',
    app_rate_14: 3.39,
    avg_round_14: 38591,
    app_flat_14: 112,
    char_15: 'Bronya',
    app_rate_15: 2.73,
    avg_round_15: 34085,
    app_flat_15: 90,
    char_16: 'Acheron',
    app_rate_16: 2.64,
    avg_round_16: 34545,
    app_flat_16: 74,
    char_17: 'Firefly',
    app_rate_17: 2.62,
    avg_round_17: 28335,
    app_flat_17: 35,
    char_18: 'Lynx',
    app_rate_18: 2.44,
    avg_round_18: 34412,
    app_flat_18: 106,
    char_19: 'Pela',
    app_rate_19: 2.12,
    avg_round_19: 32092,
    app_flat_19: 70,
    char_20: 'topaz',
    app_rate_20: 2.1,
    avg_round_20: 31774,
    app_flat_20: 64,
    char_21: 'Blade',
    app_rate_21: 2.07,
    avg_round_21: 36032,
    app_flat_21: 69,
    char_22: 'march-7th-swordmaster',
    app_rate_22: 1.71,
    avg_round_22: 32994,
    app_flat_22: 72,
    char_23: 'Seele',
    app_rate_23: 1.27,
    avg_round_23: 35414,
    app_flat_23: 33,
    char_24: 'Xueyi',
    app_rate_24: 1.27,
    avg_round_24: 31968,
    app_flat_24: 41,
    char_25: 'trailblazer-imaginary',
    app_rate_25: 1.12,
    avg_round_25: 24143,
    app_flat_25: 17,
    char_26: 'Jingliu',
    app_rate_26: 0.93,
    avg_round_26: 31580,
    app_flat_26: 22,
    char_27: 'Kafka',
    app_rate_27: 0.8,
    avg_round_27: 27625,
    app_flat_27: 20,
    char_28: 'jing-yuan',
    app_rate_28: 0.8,
    avg_round_28: 27930,
    app_flat_28: 22,
    char_29: 'Asta',
    app_rate_29: 0.65,
    avg_round_29: 30337,
    app_flat_29: 22,
    char_30: 'Bailu',
    app_rate_30: 0.65,
    avg_round_30: 34821,
    app_flat_30: 25
  },
  {
    char: 'Blade',
    round: 34843,
    char_1: 'Jade',
    app_rate_1: 46.55,
    avg_round_1: 37537,
    app_flat_1: 101,
    char_2: 'Bronya',
    app_rate_2: 34.05,
    avg_round_2: 36519,
    app_flat_2: 77,
    char_3: 'Luocha',
    app_rate_3: 33.62,
    avg_round_3: 34777,
    app_flat_3: 77,
    char_4: 'ruan-mei',
    app_rate_4: 27.59,
    avg_round_4: 34564,
    app_flat_4: 50,
    char_5: 'Herta',
    app_rate_5: 23.92,
    avg_round_5: 36032,
    app_flat_5: 69,
    char_6: 'Robin',
    app_rate_6: 21.55,
    avg_round_6: 36084,
    app_flat_6: 50,
    char_7: 'fu-xuan',
    app_rate_7: 18.97,
    avg_round_7: 35510,
    app_flat_7: 34,
    char_8: 'Sparkle',
    app_rate_8: 17.03,
    avg_round_8: 34066,
    app_flat_8: 42,
    char_9: 'Huohuo',
    app_rate_9: 15.73,
    avg_round_9: 36269,
    app_flat_9: 35,
    char_10: 'Jingliu',
    app_rate_10: 12.93,
    avg_round_10: 30497,
    app_flat_10: 24,
    char_11: 'Lynx',
    app_rate_11: 7.97,
    avg_round_11: 36262,
    app_flat_11: 19,
    char_12: 'Clara',
    app_rate_12: 7.33,
    avg_round_12: 31462,
    app_flat_12: 22,
    char_13: 'Yunli',
    app_rate_13: 6.03,
    avg_round_13: 34839,
    app_flat_13: 18,
    char_14: 'Gallagher',
    app_rate_14: 5.39,
    avg_round_14: 36461,
    app_flat_14: 14,
    char_15: 'Himeko',
    app_rate_15: 4.09,
    avg_round_15: 30569,
    app_flat_15: 14,
    char_16: 'Tingyun',
    app_rate_16: 2.8,
    avg_round_16: 33239,
    app_flat_16: 10,
    char_17: 'Argenti',
    app_rate_17: 2.59,
    avg_round_17: 28872,
    app_flat_17: 2,
    char_18: 'Pela',
    app_rate_18: 2.16,
    avg_round_18: 29123,
    app_flat_18: 5,
    char_19: 'Aventurine',
    app_rate_19: 1.72,
    avg_round_19: 35628,
    app_flat_19: 3,
    char_20: 'march-7th-swordmaster',
    app_rate_20: 1.08,
    avg_round_20: 32840,
    app_flat_20: 4,
    char_21: 'Jiaoqiu',
    app_rate_21: 0.65,
    avg_round_21: 29264,
    app_flat_21: 2,
    char_22: 'imbibitor-lunae',
    app_rate_22: 0.65,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Qingque',
    app_rate_23: 0.65,
    avg_round_23: 29265,
    app_flat_23: 3,
    char_24: 'Firefly',
    app_rate_24: 0.65,
    avg_round_24: 24493,
    app_flat_24: 1,
    char_25: 'black-swan',
    app_rate_25: 0.43,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Boothill',
    app_rate_26: 0.43,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'topaz',
    app_rate_27: 0.43,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Seele',
    app_rate_28: 0.43,
    avg_round_28: 30674,
    app_flat_28: 1,
    char_29: 'trailblazer-imaginary',
    app_rate_29: 0.43,
    avg_round_29: 25930,
    app_flat_29: 2,
    char_30: 'Natasha',
    app_rate_30: 0.43,
    avg_round_30: 22969,
    app_flat_30: 1
  },
  {
    char: 'Luocha',
    round: 34578,
    char_1: 'Herta',
    app_rate_1: 32.88,
    avg_round_1: 36913,
    app_flat_1: 349,
    char_2: 'ruan-mei',
    app_rate_2: 29.49,
    avg_round_2: 33186,
    app_flat_2: 220,
    char_3: 'Robin',
    app_rate_3: 23.46,
    avg_round_3: 38199,
    app_flat_3: 255,
    char_4: 'Tingyun',
    app_rate_4: 19.36,
    avg_round_4: 37479,
    app_flat_4: 201,
    char_5: 'Jade',
    app_rate_5: 18.78,
    avg_round_5: 38868,
    app_flat_5: 175,
    char_6: 'Clara',
    app_rate_6: 18.72,
    avg_round_6: 36115,
    app_flat_6: 215,
    char_7: 'Acheron',
    app_rate_7: 17.24,
    avg_round_7: 30894,
    app_flat_7: 93,
    char_8: 'Sparkle',
    app_rate_8: 17.24,
    avg_round_8: 36133,
    app_flat_8: 130,
    char_9: 'Kafka',
    app_rate_9: 16.6,
    avg_round_9: 30386,
    app_flat_9: 89,
    char_10: 'Argenti',
    app_rate_10: 15.51,
    avg_round_10: 39188,
    app_flat_10: 163,
    char_11: 'black-swan',
    app_rate_11: 14.87,
    avg_round_11: 30877,
    app_flat_11: 72,
    char_12: 'Bronya',
    app_rate_12: 12.5,
    avg_round_12: 37478,
    app_flat_12: 106,
    char_13: 'Himeko',
    app_rate_13: 11.47,
    avg_round_13: 28932,
    app_flat_13: 106,
    char_14: 'Yunli',
    app_rate_14: 10.26,
    avg_round_14: 36693,
    app_flat_14: 102,
    char_15: 'Blade',
    app_rate_15: 10.0,
    avg_round_15: 34777,
    app_flat_15: 77,
    char_16: 'Pela',
    app_rate_16: 5.9,
    avg_round_16: 29768,
    app_flat_16: 50,
    char_17: 'Jiaoqiu',
    app_rate_17: 3.46,
    avg_round_17: 34872,
    app_flat_17: 23,
    char_18: 'imbibitor-lunae',
    app_rate_18: 3.01,
    avg_round_18: 33007,
    app_flat_18: 2,
    char_19: 'jing-yuan',
    app_rate_19: 2.63,
    avg_round_19: 25217,
    app_flat_19: 20,
    char_20: 'Firefly',
    app_rate_20: 2.05,
    avg_round_20: 23599,
    app_flat_20: 5,
    char_21: 'Jingliu',
    app_rate_21: 1.99,
    avg_round_21: 29516,
    app_flat_21: 14,
    char_22: 'march-7th-swordmaster',
    app_rate_22: 1.92,
    avg_round_22: 31725,
    app_flat_22: 26,
    char_23: 'topaz',
    app_rate_23: 1.47,
    avg_round_23: 31553,
    app_flat_23: 13,
    char_24: 'trailblazer-imaginary',
    app_rate_24: 1.35,
    avg_round_24: 25828,
    app_flat_24: 4,
    char_25: 'Seele',
    app_rate_25: 1.22,
    avg_round_25: 33296,
    app_flat_25: 12,
    char_26: 'Hanya',
    app_rate_26: 1.22,
    avg_round_26: 39156,
    app_flat_26: 14,
    char_27: 'silver-wolf',
    app_rate_27: 1.09,
    avg_round_27: 24736,
    app_flat_27: 11,
    char_28: 'Guinaifen',
    app_rate_28: 0.96,
    avg_round_28: 21901,
    app_flat_28: 6,
    char_29: 'Boothill',
    app_rate_29: 0.64,
    avg_round_29: 37318,
    app_flat_29: 2,
    char_30: 'Xueyi',
    app_rate_30: 0.64,
    avg_round_30: 28295,
    app_flat_30: 7
  },
  {
    char: 'Seele',
    round: 34549,
    char_1: 'Sparkle',
    app_rate_1: 67.26,
    avg_round_1: 34758,
    app_flat_1: 80,
    char_2: 'fu-xuan',
    app_rate_2: 58.93,
    avg_round_2: 34386,
    app_flat_2: 72,
    char_3: 'Tingyun',
    app_rate_3: 40.48,
    avg_round_3: 34843,
    app_flat_3: 59,
    char_4: 'Robin',
    app_rate_4: 27.98,
    avg_round_4: 36980,
    app_flat_4: 39,
    char_5: 'Herta',
    app_rate_5: 20.24,
    avg_round_5: 35414,
    app_flat_5: 33,
    char_6: 'Bronya',
    app_rate_6: 13.69,
    avg_round_6: 31864,
    app_flat_6: 24,
    char_7: 'ruan-mei',
    app_rate_7: 11.31,
    avg_round_7: 33353,
    app_flat_7: 13,
    char_8: 'Huohuo',
    app_rate_8: 8.63,
    avg_round_8: 35105,
    app_flat_8: 14,
    char_9: 'Clara',
    app_rate_9: 6.55,
    avg_round_9: 30144,
    app_flat_9: 10,
    char_10: 'Luocha',
    app_rate_10: 5.65,
    avg_round_10: 33296,
    app_flat_10: 12,
    char_11: 'Jade',
    app_rate_11: 5.06,
    avg_round_11: 32220,
    app_flat_11: 3,
    char_12: 'Aventurine',
    app_rate_12: 5.06,
    avg_round_12: 36615,
    app_flat_12: 13,
    char_13: 'Gallagher',
    app_rate_13: 5.06,
    avg_round_13: 40000,
    app_flat_13: 6,
    char_14: 'Pela',
    app_rate_14: 4.17,
    avg_round_14: 35605,
    app_flat_14: 5,
    char_15: 'silver-wolf',
    app_rate_15: 4.17,
    avg_round_15: 27624,
    app_flat_15: 3,
    char_16: 'Yunli',
    app_rate_16: 3.57,
    avg_round_16: 35662,
    app_flat_16: 7,
    char_17: 'Argenti',
    app_rate_17: 2.08,
    avg_round_17: 36579,
    app_flat_17: 2,
    char_18: 'march-7th-swordmaster',
    app_rate_18: 2.08,
    avg_round_18: 33465,
    app_flat_18: 3,
    char_19: 'Firefly',
    app_rate_19: 1.49,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Himeko',
    app_rate_20: 0.89,
    avg_round_20: 31059,
    app_flat_20: 1,
    char_21: 'Acheron',
    app_rate_21: 0.89,
    avg_round_21: 35656,
    app_flat_21: 2,
    char_22: 'Lynx',
    app_rate_22: 0.89,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Xueyi',
    app_rate_23: 0.6,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Blade',
    app_rate_24: 0.6,
    avg_round_24: 30674,
    app_flat_24: 1,
    char_25: 'Bailu',
    app_rate_25: 0.6,
    avg_round_25: 31693,
    app_flat_25: 1,
    char_26: 'trailblazer-imaginary',
    app_rate_26: 0.6,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Boothill',
    app_rate_27: 0.3,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Natasha',
    app_rate_28: 0.3,
    avg_round_28: 35700,
    app_flat_28: 1,
    char_29: 'Yukong',
    app_rate_29: 0.3,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Sampo',
    app_rate_30: 0.3,
    avg_round_30: 31882,
    app_flat_30: 1
  },
  {
    char: 'fu-xuan',
    round: 34491,
    char_1: 'Acheron',
    app_rate_1: 36.19,
    avg_round_1: 32129,
    app_flat_1: 613,
    char_2: 'Herta',
    app_rate_2: 33.43,
    avg_round_2: 36614,
    app_flat_2: 860,
    char_3: 'Sparkle',
    app_rate_3: 28.43,
    avg_round_3: 36517,
    app_flat_3: 454,
    char_4: 'ruan-mei',
    app_rate_4: 21.18,
    avg_round_4: 33356,
    app_flat_4: 441,
    char_5: 'Robin',
    app_rate_5: 18.93,
    avg_round_5: 39072,
    app_flat_5: 465,
    char_6: 'Pela',
    app_rate_6: 17.39,
    avg_round_6: 31137,
    app_flat_6: 342,
    char_7: 'Jade',
    app_rate_7: 16.77,
    avg_round_7: 39034,
    app_flat_7: 404,
    char_8: 'Tingyun',
    app_rate_8: 16.38,
    avg_round_8: 37498,
    app_flat_8: 397,
    char_9: 'Himeko',
    app_rate_9: 14.46,
    avg_round_9: 31371,
    app_flat_9: 330,
    char_10: 'Kafka',
    app_rate_10: 14.34,
    avg_round_10: 32452,
    app_flat_10: 277,
    char_11: 'black-swan',
    app_rate_11: 13.65,
    avg_round_11: 32682,
    app_flat_11: 263,
    char_12: 'Clara',
    app_rate_12: 11.75,
    avg_round_12: 36834,
    app_flat_12: 303,
    char_13: 'Argenti',
    app_rate_13: 11.34,
    avg_round_13: 39670,
    app_flat_13: 274,
    char_14: 'Yunli',
    app_rate_14: 8.56,
    avg_round_14: 38906,
    app_flat_14: 184,
    char_15: 'Jiaoqiu',
    app_rate_15: 6.61,
    avg_round_15: 35445,
    app_flat_15: 103,
    char_16: 'silver-wolf',
    app_rate_16: 6.49,
    avg_round_16: 28197,
    app_flat_16: 116,
    char_17: 'Bronya',
    app_rate_17: 4.96,
    avg_round_17: 35975,
    app_flat_17: 77,
    char_18: 'Seele',
    app_rate_18: 4.54,
    avg_round_18: 34386,
    app_flat_18: 72,
    char_19: 'jing-yuan',
    app_rate_19: 2.25,
    avg_round_19: 25199,
    app_flat_19: 46,
    char_20: 'Blade',
    app_rate_20: 2.02,
    avg_round_20: 35510,
    app_flat_20: 34,
    char_21: 'Guinaifen',
    app_rate_21: 1.45,
    avg_round_21: 29992,
    app_flat_21: 33,
    char_22: 'march-7th-swordmaster',
    app_rate_22: 1.12,
    avg_round_22: 33536,
    app_flat_22: 26,
    char_23: 'Firefly',
    app_rate_23: 1.08,
    avg_round_23: 25716,
    app_flat_23: 12,
    char_24: 'Jingliu',
    app_rate_24: 1.03,
    avg_round_24: 32441,
    app_flat_24: 16,
    char_25: 'topaz',
    app_rate_25: 0.94,
    avg_round_25: 28008,
    app_flat_25: 12,
    char_26: 'Xueyi',
    app_rate_26: 0.87,
    avg_round_26: 30909,
    app_flat_26: 23,
    char_27: 'Hanya',
    app_rate_27: 0.78,
    avg_round_27: 39400,
    app_flat_27: 11,
    char_28: 'Serval',
    app_rate_28: 0.64,
    avg_round_28: 29247,
    app_flat_28: 15,
    char_29: 'trailblazer-imaginary',
    app_rate_29: 0.6,
    avg_round_29: 27770,
    app_flat_29: 4,
    char_30: 'Asta',
    app_rate_30: 0.46,
    avg_round_30: 32911,
    app_flat_30: 8
  },
  {
    char: 'Lynx',
    round: 34434,
    char_1: 'Yunli',
    app_rate_1: 51.77,
    avg_round_1: 35149,
    app_flat_1: 146,
    char_2: 'Robin',
    app_rate_2: 35.97,
    avg_round_2: 37233,
    app_flat_2: 106,
    char_3: 'Herta',
    app_rate_3: 35.69,
    avg_round_3: 34412,
    app_flat_3: 106,
    char_4: 'Tingyun',
    app_rate_4: 30.79,
    avg_round_4: 34244,
    app_flat_4: 95,
    char_5: 'Sparkle',
    app_rate_5: 27.25,
    avg_round_5: 35000,
    app_flat_5: 75,
    char_6: 'Clara',
    app_rate_6: 26.43,
    avg_round_6: 32947,
    app_flat_6: 82,
    char_7: 'Jade',
    app_rate_7: 22.62,
    avg_round_7: 36947,
    app_flat_7: 50,
    char_8: 'ruan-mei',
    app_rate_8: 18.8,
    avg_round_8: 32335,
    app_flat_8: 42,
    char_9: 'Blade',
    app_rate_9: 10.08,
    avg_round_9: 36262,
    app_flat_9: 19,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 5.99,
    avg_round_10: 31589,
    app_flat_10: 19,
    char_11: 'Kafka',
    app_rate_11: 4.36,
    avg_round_11: 27363,
    app_flat_11: 9,
    char_12: 'Acheron',
    app_rate_12: 4.09,
    avg_round_12: 29750,
    app_flat_12: 5,
    char_13: 'Himeko',
    app_rate_13: 4.09,
    avg_round_13: 29209,
    app_flat_13: 11,
    char_14: 'black-swan',
    app_rate_14: 3.81,
    avg_round_14: 28987,
    app_flat_14: 6,
    char_15: 'Argenti',
    app_rate_15: 3.27,
    avg_round_15: 36413,
    app_flat_15: 10,
    char_16: 'Bronya',
    app_rate_16: 3.0,
    avg_round_16: 35930,
    app_flat_16: 5,
    char_17: 'topaz',
    app_rate_17: 2.72,
    avg_round_17: 29908,
    app_flat_17: 5,
    char_18: 'Pela',
    app_rate_18: 2.18,
    avg_round_18: 34658,
    app_flat_18: 6,
    char_19: 'Jingliu',
    app_rate_19: 1.63,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Jiaoqiu',
    app_rate_20: 1.09,
    avg_round_20: 27009,
    app_flat_20: 3,
    char_21: 'Hanya',
    app_rate_21: 1.09,
    avg_round_21: 38050,
    app_flat_21: 2,
    char_22: 'Seele',
    app_rate_22: 0.82,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Asta',
    app_rate_23: 0.54,
    avg_round_23: 31454,
    app_flat_23: 2,
    char_24: 'trailblazer-fire',
    app_rate_24: 0.27,
    avg_round_24: 20613,
    app_flat_24: 1,
    char_25: 'Guinaifen',
    app_rate_25: 0.27,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'silver-wolf',
    app_rate_26: 0.27,
    avg_round_26: 26650,
    app_flat_26: 1,
    char_27: 'Sushang',
    app_rate_27: 0.27,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Qingque',
    app_rate_28: 0.27,
    avg_round_28: 25683,
    app_flat_28: 1,
    char_29: 'Xueyi',
    app_rate_29: 0.27,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Firefly',
    app_rate_30: 0.27,
    avg_round_30: 27035,
    app_flat_30: 1
  },
  {
    char: 'Jiaoqiu',
    round: 33894,
    char_1: 'Acheron',
    app_rate_1: 87.16,
    avg_round_1: 34212,
    app_flat_1: 486,
    char_2: 'Pela',
    app_rate_2: 33.2,
    avg_round_2: 34098,
    app_flat_2: 291,
    char_3: 'Aventurine',
    app_rate_3: 32.23,
    avg_round_3: 33711,
    app_flat_3: 154,
    char_4: 'Gallagher',
    app_rate_4: 26.33,
    avg_round_4: 33527,
    app_flat_4: 196,
    char_5: 'Sparkle',
    app_rate_5: 24.07,
    avg_round_5: 30181,
    app_flat_5: 31,
    char_6: 'fu-xuan',
    app_rate_6: 23.26,
    avg_round_6: 35445,
    app_flat_6: 103,
    char_7: 'Kafka',
    app_rate_7: 13.57,
    avg_round_7: 34691,
    app_flat_7: 67,
    char_8: 'black-swan',
    app_rate_8: 10.1,
    avg_round_8: 33524,
    app_flat_8: 51,
    char_9: 'Himeko',
    app_rate_9: 9.37,
    avg_round_9: 34375,
    app_flat_9: 62,
    char_10: 'ruan-mei',
    app_rate_10: 9.21,
    avg_round_10: 33289,
    app_flat_10: 47,
    char_11: 'Luocha',
    app_rate_11: 4.36,
    avg_round_11: 34872,
    app_flat_11: 23,
    char_12: 'Robin',
    app_rate_12: 3.63,
    avg_round_12: 36753,
    app_flat_12: 17,
    char_13: 'Bronya',
    app_rate_13: 3.47,
    avg_round_13: 30963,
    app_flat_13: 5,
    char_14: 'Huohuo',
    app_rate_14: 2.99,
    avg_round_14: 38328,
    app_flat_14: 13,
    char_15: 'Gepard',
    app_rate_15: 2.67,
    avg_round_15: 31725,
    app_flat_15: 18,
    char_16: 'silver-wolf',
    app_rate_16: 2.18,
    avg_round_16: 26177,
    app_flat_16: 21,
    char_17: 'jing-yuan',
    app_rate_17: 2.1,
    avg_round_17: 27396,
    app_flat_17: 10,
    char_18: 'Herta',
    app_rate_18: 1.7,
    avg_round_18: 39010,
    app_flat_18: 9,
    char_19: 'Argenti',
    app_rate_19: 1.62,
    avg_round_19: 40000,
    app_flat_19: 12,
    char_20: 'Tingyun',
    app_rate_20: 1.21,
    avg_round_20: 40000,
    app_flat_20: 9,
    char_21: 'Guinaifen',
    app_rate_21: 0.81,
    avg_round_21: 29721,
    app_flat_21: 6,
    char_22: 'trailblazer-imaginary',
    app_rate_22: 0.4,
    avg_round_22: 23980,
    app_flat_22: 2,
    char_23: 'Asta',
    app_rate_23: 0.4,
    avg_round_23: 32750,
    app_flat_23: 1,
    char_24: 'trailblazer-fire',
    app_rate_24: 0.32,
    avg_round_24: 28021,
    app_flat_24: 3,
    char_25: 'Firefly',
    app_rate_25: 0.32,
    avg_round_25: 30140,
    app_flat_25: 1,
    char_26: 'Lynx',
    app_rate_26: 0.32,
    avg_round_26: 27009,
    app_flat_26: 3,
    char_27: 'Jade',
    app_rate_27: 0.32,
    avg_round_27: 34609,
    app_flat_27: 3,
    char_28: 'Clara',
    app_rate_28: 0.32,
    avg_round_28: 27999,
    app_flat_28: 2,
    char_29: 'Serval',
    app_rate_29: 0.32,
    avg_round_29: 30514,
    app_flat_29: 2,
    char_30: 'Welt',
    app_rate_30: 0.32,
    avg_round_30: 28750,
    app_flat_30: 1
  },
  {
    char: 'march-7th',
    round: 33527,
    char_1: 'Herta',
    app_rate_1: 48.72,
    avg_round_1: 34077,
    app_flat_1: 16,
    char_2: 'Robin',
    app_rate_2: 28.21,
    avg_round_2: 35063,
    app_flat_2: 9,
    char_3: 'Clara',
    app_rate_3: 25.64,
    avg_round_3: 35292,
    app_flat_3: 9,
    char_4: 'Tingyun',
    app_rate_4: 23.08,
    avg_round_4: 34409,
    app_flat_4: 7,
    char_5: 'Yunli',
    app_rate_5: 23.08,
    avg_round_5: 35436,
    app_flat_5: 7,
    char_6: 'ruan-mei',
    app_rate_6: 23.08,
    avg_round_6: 34495,
    app_flat_6: 7,
    char_7: 'Acheron',
    app_rate_7: 23.08,
    avg_round_7: 28616,
    app_flat_7: 2,
    char_8: 'Sparkle',
    app_rate_8: 20.51,
    avg_round_8: 32452,
    app_flat_8: 6,
    char_9: 'Pela',
    app_rate_9: 12.82,
    avg_round_9: 28616,
    app_flat_9: 2,
    char_10: 'Himeko',
    app_rate_10: 12.82,
    avg_round_10: 29825,
    app_flat_10: 5,
    char_11: 'Jade',
    app_rate_11: 10.26,
    avg_round_11: 37360,
    app_flat_11: 3,
    char_12: 'Kafka',
    app_rate_12: 7.69,
    avg_round_12: 23492,
    app_flat_12: 1,
    char_13: 'black-swan',
    app_rate_13: 7.69,
    avg_round_13: 29334,
    app_flat_13: 1,
    char_14: 'silver-wolf',
    app_rate_14: 5.13,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Asta',
    app_rate_15: 5.13,
    avg_round_15: 23492,
    app_flat_15: 1,
    char_16: 'topaz',
    app_rate_16: 5.13,
    avg_round_16: 32222,
    app_flat_16: 1,
    char_17: 'Qingque',
    app_rate_17: 2.56,
    avg_round_17: 27823,
    app_flat_17: 1,
    char_18: 'Blade',
    app_rate_18: 2.56,
    avg_round_18: 32250,
    app_flat_18: 1,
    char_19: 'Gallagher',
    app_rate_19: 2.56,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Serval',
    app_rate_20: 2.56,
    avg_round_20: 27897,
    app_flat_20: 1,
    char_21: 'Xueyi',
    app_rate_21: 2.56,
    avg_round_21: 40000,
    app_flat_21: 1,
    char_22: 'Bronya',
    app_rate_22: 2.56,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Guinaifen',
    app_rate_23: 2.56,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Aventurine',
    round: 33524,
    char_1: 'Acheron',
    app_rate_1: 37.37,
    avg_round_1: 31366,
    app_flat_1: 583,
    char_2: 'Herta',
    app_rate_2: 34.14,
    avg_round_2: 37705,
    app_flat_2: 824,
    char_3: 'Robin',
    app_rate_3: 31.86,
    avg_round_3: 38466,
    app_flat_3: 689,
    char_4: 'ruan-mei',
    app_rate_4: 21.01,
    avg_round_4: 32490,
    app_flat_4: 365,
    char_5: 'Himeko',
    app_rate_5: 20.35,
    avg_round_5: 30460,
    app_flat_5: 423,
    char_6: 'Sparkle',
    app_rate_6: 16.58,
    avg_round_6: 33232,
    app_flat_6: 198,
    char_7: 'Pela',
    app_rate_7: 16.19,
    avg_round_7: 30459,
    app_flat_7: 309,
    char_8: 'Jade',
    app_rate_8: 15.69,
    avg_round_8: 39592,
    app_flat_8: 328,
    char_9: 'Kafka',
    app_rate_9: 14.8,
    avg_round_9: 31700,
    app_flat_9: 262,
    char_10: 'Clara',
    app_rate_10: 14.63,
    avg_round_10: 37323,
    app_flat_10: 374,
    char_11: 'black-swan',
    app_rate_11: 13.55,
    avg_round_11: 31276,
    app_flat_11: 232,
    char_12: 'Jiaoqiu',
    app_rate_12: 11.12,
    avg_round_12: 33711,
    app_flat_12: 154,
    char_13: 'topaz',
    app_rate_13: 9.84,
    avg_round_13: 29970,
    app_flat_13: 145,
    char_14: 'Tingyun',
    app_rate_14: 7.39,
    avg_round_14: 36430,
    app_flat_14: 191,
    char_15: 'Yunli',
    app_rate_15: 7.16,
    avg_round_15: 39530,
    app_flat_15: 161,
    char_16: 'jing-yuan',
    app_rate_16: 5.96,
    avg_round_16: 24980,
    app_flat_16: 108,
    char_17: 'Argenti',
    app_rate_17: 4.43,
    avg_round_17: 39524,
    app_flat_17: 95,
    char_18: 'silver-wolf',
    app_rate_18: 3.82,
    avg_round_18: 26550,
    app_flat_18: 79,
    char_19: 'Bronya',
    app_rate_19: 2.9,
    avg_round_19: 37194,
    app_flat_19: 33,
    char_20: 'Guinaifen',
    app_rate_20: 2.01,
    avg_round_20: 26319,
    app_flat_20: 44,
    char_21: 'march-7th-swordmaster',
    app_rate_21: 1.67,
    avg_round_21: 32219,
    app_flat_21: 38,
    char_22: 'Firefly',
    app_rate_22: 1.09,
    avg_round_22: 26250,
    app_flat_22: 12,
    char_23: 'dr-ratio',
    app_rate_23: 1.09,
    avg_round_23: 24665,
    app_flat_23: 15,
    char_24: 'Serval',
    app_rate_24: 0.81,
    avg_round_24: 26679,
    app_flat_24: 17,
    char_25: 'Asta',
    app_rate_25: 0.78,
    avg_round_25: 24192,
    app_flat_25: 16,
    char_26: 'imbibitor-lunae',
    app_rate_26: 0.67,
    avg_round_26: 29785,
    app_flat_26: 1,
    char_27: 'Xueyi',
    app_rate_27: 0.67,
    avg_round_27: 34264,
    app_flat_27: 13,
    char_28: 'trailblazer-imaginary',
    app_rate_28: 0.53,
    avg_round_28: 22359,
    app_flat_28: 5,
    char_29: 'Seele',
    app_rate_29: 0.47,
    avg_round_29: 36615,
    app_flat_29: 13,
    char_30: 'Welt',
    app_rate_30: 0.36,
    avg_round_30: 25976,
    app_flat_30: 10
  },
  {
    char: 'Bailu',
    round: 32476,
    char_1: 'Kafka',
    app_rate_1: 34.11,
    avg_round_1: 30063,
    app_flat_1: 19,
    char_2: 'Acheron',
    app_rate_2: 33.33,
    avg_round_2: 29652,
    app_flat_2: 19,
    char_3: 'black-swan',
    app_rate_3: 27.91,
    avg_round_3: 29794,
    app_flat_3: 15,
    char_4: 'Herta',
    app_rate_4: 27.13,
    avg_round_4: 34821,
    app_flat_4: 25,
    char_5: 'Robin',
    app_rate_5: 24.03,
    avg_round_5: 38551,
    app_flat_5: 23,
    char_6: 'ruan-mei',
    app_rate_6: 21.71,
    avg_round_6: 30888,
    app_flat_6: 13,
    char_7: 'Himeko',
    app_rate_7: 18.6,
    avg_round_7: 28514,
    app_flat_7: 17,
    char_8: 'Jade',
    app_rate_8: 17.83,
    avg_round_8: 37704,
    app_flat_8: 15,
    char_9: 'Sparkle',
    app_rate_9: 14.73,
    avg_round_9: 30470,
    app_flat_9: 9,
    char_10: 'Tingyun',
    app_rate_10: 12.4,
    avg_round_10: 30914,
    app_flat_10: 8,
    char_11: 'Yunli',
    app_rate_11: 10.85,
    avg_round_11: 36555,
    app_flat_11: 9,
    char_12: 'Clara',
    app_rate_12: 10.85,
    avg_round_12: 35834,
    app_flat_12: 9,
    char_13: 'Pela',
    app_rate_13: 8.53,
    avg_round_13: 28754,
    app_flat_13: 7,
    char_14: 'Argenti',
    app_rate_14: 6.98,
    avg_round_14: 36486,
    app_flat_14: 7,
    char_15: 'jing-yuan',
    app_rate_15: 3.88,
    avg_round_15: 21956,
    app_flat_15: 2,
    char_16: 'Bronya',
    app_rate_16: 3.88,
    avg_round_16: 31346,
    app_flat_16: 4,
    char_17: 'Xueyi',
    app_rate_17: 3.1,
    avg_round_17: 35095,
    app_flat_17: 1,
    char_18: 'march-7th-swordmaster',
    app_rate_18: 2.33,
    avg_round_18: 33600,
    app_flat_18: 3,
    char_19: 'Serval',
    app_rate_19: 2.33,
    avg_round_19: 24328,
    app_flat_19: 1,
    char_20: 'topaz',
    app_rate_20: 2.33,
    avg_round_20: 36900,
    app_flat_20: 2,
    char_21: 'imbibitor-lunae',
    app_rate_21: 1.55,
    avg_round_21: 35750,
    app_flat_21: 1,
    char_22: 'Guinaifen',
    app_rate_22: 1.55,
    avg_round_22: 26744,
    app_flat_22: 2,
    char_23: 'Seele',
    app_rate_23: 1.55,
    avg_round_23: 31693,
    app_flat_23: 1,
    char_24: 'Blade',
    app_rate_24: 1.55,
    avg_round_24: 31826,
    app_flat_24: 2,
    char_25: 'Aventurine',
    app_rate_25: 0.78,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Hanya',
    app_rate_26: 0.78,
    avg_round_26: 40000,
    app_flat_26: 1,
    char_27: 'Firefly',
    app_rate_27: 0.78,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'trailblazer-imaginary',
    app_rate_28: 0.78,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Qingque',
    app_rate_29: 0.78,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'trailblazer-physical',
    app_rate_30: 0.78,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'march-7th-swordmaster',
    round: 32295,
    char_1: 'Robin',
    app_rate_1: 42.5,
    avg_round_1: 36191,
    app_flat_1: 98,
    char_2: 'Yunli',
    app_rate_2: 34.38,
    avg_round_2: 37316,
    app_flat_2: 76,
    char_3: 'Herta',
    app_rate_3: 28.75,
    avg_round_3: 32994,
    app_flat_3: 72,
    char_4: 'Huohuo',
    app_rate_4: 28.75,
    avg_round_4: 35564,
    app_flat_4: 63,
    char_5: 'Clara',
    app_rate_5: 19.38,
    avg_round_5: 31020,
    app_flat_5: 46,
    char_6: 'Aventurine',
    app_rate_6: 18.75,
    avg_round_6: 32219,
    app_flat_6: 38,
    char_7: 'fu-xuan',
    app_rate_7: 15.31,
    avg_round_7: 33536,
    app_flat_7: 26,
    char_8: 'ruan-mei',
    app_rate_8: 14.69,
    avg_round_8: 30449,
    app_flat_8: 30,
    char_9: 'Gallagher',
    app_rate_9: 11.25,
    avg_round_9: 24037,
    app_flat_9: 26,
    char_10: 'Tingyun',
    app_rate_10: 10.94,
    avg_round_10: 33032,
    app_flat_10: 24,
    char_11: 'Himeko',
    app_rate_11: 10.62,
    avg_round_11: 23542,
    app_flat_11: 27,
    char_12: 'Luocha',
    app_rate_12: 9.38,
    avg_round_12: 31725,
    app_flat_12: 26,
    char_13: 'Jade',
    app_rate_13: 7.5,
    avg_round_13: 35322,
    app_flat_13: 15,
    char_14: 'Lynx',
    app_rate_14: 6.88,
    avg_round_14: 31589,
    app_flat_14: 19,
    char_15: 'Sparkle',
    app_rate_15: 6.56,
    avg_round_15: 33913,
    app_flat_15: 8,
    char_16: 'trailblazer-imaginary',
    app_rate_16: 4.69,
    avg_round_16: 21934,
    app_flat_16: 9,
    char_17: 'Firefly',
    app_rate_17: 4.69,
    avg_round_17: 21973,
    app_flat_17: 7,
    char_18: 'topaz',
    app_rate_18: 4.06,
    avg_round_18: 21249,
    app_flat_18: 7,
    char_19: 'Argenti',
    app_rate_19: 3.12,
    avg_round_19: 31739,
    app_flat_19: 8,
    char_20: 'Seele',
    app_rate_20: 2.19,
    avg_round_20: 33465,
    app_flat_20: 3,
    char_21: 'Acheron',
    app_rate_21: 2.19,
    avg_round_21: 30800,
    app_flat_21: 1,
    char_22: 'Pela',
    app_rate_22: 1.88,
    avg_round_22: 27385,
    app_flat_22: 4,
    char_23: 'Blade',
    app_rate_23: 1.56,
    avg_round_23: 32840,
    app_flat_23: 4,
    char_24: 'Kafka',
    app_rate_24: 1.56,
    avg_round_24: 22711,
    app_flat_24: 2,
    char_25: 'jing-yuan',
    app_rate_25: 1.25,
    avg_round_25: 21147,
    app_flat_25: 3,
    char_26: 'Bailu',
    app_rate_26: 0.94,
    avg_round_26: 33600,
    app_flat_26: 3,
    char_27: 'Asta',
    app_rate_27: 0.94,
    avg_round_27: 26875,
    app_flat_27: 3,
    char_28: 'Xueyi',
    app_rate_28: 0.94,
    avg_round_28: 24560,
    app_flat_28: 3,
    char_29: 'imbibitor-lunae',
    app_rate_29: 0.94,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Bronya',
    app_rate_30: 0.62,
    avg_round_30: 24634,
    app_flat_30: 1
  },
  {
    char: 'Xueyi',
    round: 31673,
    char_1: 'Herta',
    app_rate_1: 61.82,
    avg_round_1: 31968,
    app_flat_1: 41,
    char_2: 'Robin',
    app_rate_2: 35.45,
    avg_round_2: 33596,
    app_flat_2: 28,
    char_3: 'fu-xuan',
    app_rate_3: 34.55,
    avg_round_3: 30909,
    app_flat_3: 23,
    char_4: 'ruan-mei',
    app_rate_4: 32.73,
    avg_round_4: 30884,
    app_flat_4: 13,
    char_5: 'Aventurine',
    app_rate_5: 21.82,
    avg_round_5: 34264,
    app_flat_5: 13,
    char_6: 'Clara',
    app_rate_6: 16.36,
    avg_round_6: 30382,
    app_flat_6: 10,
    char_7: 'Jade',
    app_rate_7: 12.73,
    avg_round_7: 32450,
    app_flat_7: 7,
    char_8: 'Yunli',
    app_rate_8: 11.82,
    avg_round_8: 36103,
    app_flat_8: 7,
    char_9: 'Huohuo',
    app_rate_9: 10.91,
    avg_round_9: 35600,
    app_flat_9: 4,
    char_10: 'Sparkle',
    app_rate_10: 10.0,
    avg_round_10: 32660,
    app_flat_10: 4,
    char_11: 'Luocha',
    app_rate_11: 9.09,
    avg_round_11: 28295,
    app_flat_11: 7,
    char_12: 'Tingyun',
    app_rate_12: 5.45,
    avg_round_12: 23841,
    app_flat_12: 3,
    char_13: 'Himeko',
    app_rate_13: 4.55,
    avg_round_13: 28928,
    app_flat_13: 4,
    char_14: 'Gallagher',
    app_rate_14: 4.55,
    avg_round_14: 29203,
    app_flat_14: 3,
    char_15: 'Bailu',
    app_rate_15: 3.64,
    avg_round_15: 35095,
    app_flat_15: 1,
    char_16: 'Argenti',
    app_rate_16: 3.64,
    avg_round_16: 29360,
    app_flat_16: 1,
    char_17: 'march-7th-swordmaster',
    app_rate_17: 2.73,
    avg_round_17: 24560,
    app_flat_17: 3,
    char_18: 'trailblazer-imaginary',
    app_rate_18: 2.73,
    avg_round_18: 20353,
    app_flat_18: 1,
    char_19: 'Seele',
    app_rate_19: 1.82,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Jingliu',
    app_rate_20: 1.82,
    avg_round_20: 22310,
    app_flat_20: 1,
    char_21: 'Bronya',
    app_rate_21: 1.82,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'topaz',
    app_rate_22: 0.91,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Sushang',
    app_rate_23: 0.91,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Kafka',
    app_rate_24: 0.91,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'trailblazer-fire',
    app_rate_25: 0.91,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Blade',
    app_rate_26: 0.91,
    avg_round_26: 40000,
    app_flat_26: 1,
    char_27: 'Asta',
    app_rate_27: 0.91,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'march-7th',
    app_rate_28: 0.91,
    avg_round_28: 40000,
    app_flat_28: 1,
    char_29: 'Firefly',
    app_rate_29: 0.91,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Pela',
    app_rate_30: 0.91,
    avg_round_30: 24101,
    app_flat_30: 1
  },
  {
    char: 'ruan-mei',
    round: 31126,
    char_1: 'Gallagher',
    app_rate_1: 35.2,
    avg_round_1: 28187,
    app_flat_1: 861,
    char_2: 'Himeko',
    app_rate_2: 34.61,
    avg_round_2: 29021,
    app_flat_2: 1115,
    char_3: 'Herta',
    app_rate_3: 34.0,
    avg_round_3: 32787,
    app_flat_3: 1230,
    char_4: 'Firefly',
    app_rate_4: 28.81,
    avg_round_4: 25965,
    app_flat_4: 400,
    char_5: 'trailblazer-imaginary',
    app_rate_5: 23.63,
    avg_round_5: 25210,
    app_flat_5: 317,
    char_6: 'Kafka',
    app_rate_6: 16.65,
    avg_round_6: 29433,
    app_flat_6: 383,
    char_7: 'fu-xuan',
    app_rate_7: 14.84,
    avg_round_7: 33356,
    app_flat_7: 441,
    char_8: 'Aventurine',
    app_rate_8: 12.12,
    avg_round_8: 32490,
    app_flat_8: 365,
    char_9: 'Huohuo',
    app_rate_9: 11.98,
    avg_round_9: 33959,
    app_flat_9: 291,
    char_10: 'black-swan',
    app_rate_10: 11.69,
    avg_round_10: 28834,
    app_flat_10: 223,
    char_11: 'Acheron',
    app_rate_11: 9.37,
    avg_round_11: 32734,
    app_flat_11: 220,
    char_12: 'Jade',
    app_rate_12: 9.26,
    avg_round_12: 38177,
    app_flat_12: 304,
    char_13: 'Luocha',
    app_rate_13: 7.39,
    avg_round_13: 33186,
    app_flat_13: 220,
    char_14: 'Clara',
    app_rate_14: 7.35,
    avg_round_14: 34758,
    app_flat_14: 282,
    char_15: 'Argenti',
    app_rate_15: 5.67,
    avg_round_15: 39066,
    app_flat_15: 176,
    char_16: 'Tingyun',
    app_rate_16: 5.34,
    avg_round_16: 36202,
    app_flat_16: 176,
    char_17: 'Sparkle',
    app_rate_17: 3.63,
    avg_round_17: 31384,
    app_flat_17: 85,
    char_18: 'jing-yuan',
    app_rate_18: 3.58,
    avg_round_18: 26537,
    app_flat_18: 104,
    char_19: 'Yunli',
    app_rate_19: 3.54,
    avg_round_19: 38508,
    app_flat_19: 112,
    char_20: 'Robin',
    app_rate_20: 3.01,
    avg_round_20: 33608,
    app_flat_20: 89,
    char_21: 'Bronya',
    app_rate_21: 2.09,
    avg_round_21: 31961,
    app_flat_21: 49,
    char_22: 'Blade',
    app_rate_22: 2.06,
    avg_round_22: 34564,
    app_flat_22: 50,
    char_23: 'Jiaoqiu',
    app_rate_23: 1.83,
    avg_round_23: 33289,
    app_flat_23: 47,
    char_24: 'Serval',
    app_rate_24: 1.64,
    avg_round_24: 28168,
    app_flat_24: 67,
    char_25: 'Pela',
    app_rate_25: 1.43,
    avg_round_25: 30709,
    app_flat_25: 33,
    char_26: 'topaz',
    app_rate_26: 1.37,
    avg_round_26: 25427,
    app_flat_26: 34,
    char_27: 'Jingliu',
    app_rate_27: 1.3,
    avg_round_27: 32742,
    app_flat_27: 25,
    char_28: 'Lynx',
    app_rate_28: 1.11,
    avg_round_28: 32335,
    app_flat_28: 42,
    char_29: 'march-7th-swordmaster',
    app_rate_29: 0.76,
    avg_round_29: 30449,
    app_flat_29: 30,
    char_30: 'Seele',
    app_rate_30: 0.61,
    avg_round_30: 33353,
    app_flat_30: 13
  },
  {
    char: 'Acheron',
    round: 31124,
    char_1: 'Kafka',
    app_rate_1: 41.51,
    avg_round_1: 31947,
    app_flat_1: 1080,
    char_2: 'Pela',
    app_rate_2: 39.1,
    avg_round_2: 29989,
    app_flat_2: 1118,
    char_3: 'black-swan',
    app_rate_3: 37.92,
    avg_round_3: 31451,
    app_flat_3: 1007,
    char_4: 'fu-xuan',
    app_rate_4: 28.21,
    avg_round_4: 32129,
    app_flat_4: 613,
    char_5: 'Aventurine',
    app_rate_5: 23.98,
    avg_round_5: 31366,
    app_flat_5: 583,
    char_6: 'Gallagher',
    app_rate_6: 22.36,
    avg_round_6: 30454,
    app_flat_6: 692,
    char_7: 'Jiaoqiu',
    app_rate_7: 19.3,
    avg_round_7: 34212,
    app_flat_7: 486,
    char_8: 'Sparkle',
    app_rate_8: 17.71,
    avg_round_8: 28940,
    app_flat_8: 134,
    char_9: 'Himeko',
    app_rate_9: 10.95,
    avg_round_9: 32800,
    app_flat_9: 332,
    char_10: 'ruan-mei',
    app_rate_10: 10.43,
    avg_round_10: 32734,
    app_flat_10: 220,
    char_11: 'silver-wolf',
    app_rate_11: 10.41,
    avg_round_11: 26765,
    app_flat_11: 298,
    char_12: 'Huohuo',
    app_rate_12: 6.05,
    avg_round_12: 30334,
    app_flat_12: 122,
    char_13: 'Gepard',
    app_rate_13: 5.72,
    avg_round_13: 30472,
    app_flat_13: 144,
    char_14: 'Guinaifen',
    app_rate_14: 5.65,
    avg_round_14: 27312,
    app_flat_14: 202,
    char_15: 'Luocha',
    app_rate_15: 4.81,
    avg_round_15: 30894,
    app_flat_15: 93,
    char_16: 'Bronya',
    app_rate_16: 3.9,
    avg_round_16: 27329,
    app_flat_16: 14,
    char_17: 'Herta',
    app_rate_17: 2.54,
    avg_round_17: 34545,
    app_flat_17: 74,
    char_18: 'Robin',
    app_rate_18: 1.95,
    avg_round_18: 36872,
    app_flat_18: 23,
    char_19: 'trailblazer-fire',
    app_rate_19: 1.06,
    avg_round_19: 30774,
    app_flat_19: 31,
    char_20: 'Welt',
    app_rate_20: 1.04,
    avg_round_20: 25723,
    app_flat_20: 35,
    char_21: 'Bailu',
    app_rate_21: 0.77,
    avg_round_21: 29652,
    app_flat_21: 19,
    char_22: 'Firefly',
    app_rate_22: 0.68,
    avg_round_22: 26210,
    app_flat_22: 9,
    char_23: 'trailblazer-imaginary',
    app_rate_23: 0.47,
    avg_round_23: 25627,
    app_flat_23: 8,
    char_24: 'Sampo',
    app_rate_24: 0.43,
    avg_round_24: 28086,
    app_flat_24: 18,
    char_25: 'Serval',
    app_rate_25: 0.36,
    avg_round_25: 26788,
    app_flat_25: 14,
    char_26: 'topaz',
    app_rate_26: 0.34,
    avg_round_26: 28144,
    app_flat_26: 6,
    char_27: 'Asta',
    app_rate_27: 0.29,
    avg_round_27: 30088,
    app_flat_27: 5,
    char_28: 'Yunli',
    app_rate_28: 0.27,
    avg_round_28: 36635,
    app_flat_28: 5,
    char_29: 'Lynx',
    app_rate_29: 0.27,
    avg_round_29: 29750,
    app_flat_29: 5,
    char_30: 'Jade',
    app_rate_30: 0.27,
    avg_round_30: 40000,
    app_flat_30: 3
  },
  {
    char: 'Kafka',
    round: 30873,
    char_1: 'black-swan',
    app_rate_1: 69.14,
    avg_round_1: 31056,
    app_flat_1: 968,
    char_2: 'Acheron',
    app_rate_2: 68.87,
    avg_round_2: 31947,
    app_flat_2: 1080,
    char_3: 'ruan-mei',
    app_rate_3: 30.74,
    avg_round_3: 29433,
    app_flat_3: 383,
    char_4: 'Gallagher',
    app_rate_4: 23.23,
    avg_round_4: 30131,
    app_flat_4: 428,
    char_5: 'fu-xuan',
    app_rate_5: 18.55,
    avg_round_5: 32452,
    app_flat_5: 277,
    char_6: 'Huohuo',
    app_rate_6: 17.86,
    avg_round_6: 29507,
    app_flat_6: 172,
    char_7: 'Aventurine',
    app_rate_7: 15.76,
    avg_round_7: 31700,
    app_flat_7: 262,
    char_8: 'Himeko',
    app_rate_8: 13.23,
    avg_round_8: 30627,
    app_flat_8: 254,
    char_9: 'Luocha',
    app_rate_9: 7.69,
    avg_round_9: 30386,
    app_flat_9: 89,
    char_10: 'Jiaoqiu',
    app_rate_10: 4.99,
    avg_round_10: 34691,
    app_flat_10: 67,
    char_11: 'Pela',
    app_rate_11: 4.96,
    avg_round_11: 30275,
    app_flat_11: 94,
    char_12: 'Guinaifen',
    app_rate_12: 4.21,
    avg_round_12: 27065,
    app_flat_12: 78,
    char_13: 'Gepard',
    app_rate_13: 4.01,
    avg_round_13: 32042,
    app_flat_13: 71,
    char_14: 'Robin',
    app_rate_14: 2.46,
    avg_round_14: 31916,
    app_flat_14: 27,
    char_15: 'Tingyun',
    app_rate_15: 2.23,
    avg_round_15: 27645,
    app_flat_15: 46,
    char_16: 'Sparkle',
    app_rate_16: 1.45,
    avg_round_16: 26565,
    app_flat_16: 5,
    char_17: 'Bailu',
    app_rate_17: 1.31,
    avg_round_17: 30063,
    app_flat_17: 19,
    char_18: 'Herta',
    app_rate_18: 1.28,
    avg_round_18: 27625,
    app_flat_18: 20,
    char_19: 'Asta',
    app_rate_19: 1.1,
    avg_round_19: 25612,
    app_flat_19: 16,
    char_20: 'Firefly',
    app_rate_20: 0.92,
    avg_round_20: 25357,
    app_flat_20: 7,
    char_21: 'Serval',
    app_rate_21: 0.86,
    avg_round_21: 26898,
    app_flat_21: 14,
    char_22: 'trailblazer-imaginary',
    app_rate_22: 0.77,
    avg_round_22: 24334,
    app_flat_22: 14,
    char_23: 'trailblazer-fire',
    app_rate_23: 0.65,
    avg_round_23: 30607,
    app_flat_23: 8,
    char_24: 'silver-wolf',
    app_rate_24: 0.53,
    avg_round_24: 27528,
    app_flat_24: 8,
    char_25: 'Lynx',
    app_rate_25: 0.47,
    avg_round_25: 27363,
    app_flat_25: 9,
    char_26: 'topaz',
    app_rate_26: 0.47,
    avg_round_26: 24026,
    app_flat_26: 10,
    char_27: 'Sampo',
    app_rate_27: 0.42,
    avg_round_27: 25803,
    app_flat_27: 9,
    char_28: 'Bronya',
    app_rate_28: 0.3,
    avg_round_28: 21775,
    app_flat_28: 1,
    char_29: 'jing-yuan',
    app_rate_29: 0.24,
    avg_round_29: 24462,
    app_flat_29: 7,
    char_30: 'Jade',
    app_rate_30: 0.21,
    avg_round_30: 35700,
    app_flat_30: 1
  },
  {
    char: 'black-swan',
    round: 30831,
    char_1: 'Kafka',
    app_rate_1: 81.7,
    avg_round_1: 31056,
    app_flat_1: 968,
    char_2: 'Acheron',
    app_rate_2: 74.33,
    avg_round_2: 31451,
    app_flat_2: 1007,
    char_3: 'ruan-mei',
    app_rate_3: 25.49,
    avg_round_3: 28834,
    app_flat_3: 223,
    char_4: 'fu-xuan',
    app_rate_4: 20.86,
    avg_round_4: 32682,
    app_flat_4: 263,
    char_5: 'Huohuo',
    app_rate_5: 19.18,
    avg_round_5: 29733,
    app_flat_5: 148,
    char_6: 'Gallagher',
    app_rate_6: 19.0,
    avg_round_6: 29560,
    app_flat_6: 318,
    char_7: 'Aventurine',
    app_rate_7: 17.04,
    avg_round_7: 31276,
    app_flat_7: 232,
    char_8: 'Pela',
    app_rate_8: 8.2,
    avg_round_8: 29361,
    app_flat_8: 161,
    char_9: 'Luocha',
    app_rate_9: 8.13,
    avg_round_9: 30877,
    app_flat_9: 72,
    char_10: 'Jiaoqiu',
    app_rate_10: 4.38,
    avg_round_10: 33524,
    app_flat_10: 51,
    char_11: 'Gepard',
    app_rate_11: 4.24,
    avg_round_11: 31875,
    app_flat_11: 64,
    char_12: 'Robin',
    app_rate_12: 2.59,
    avg_round_12: 33063,
    app_flat_12: 15,
    char_13: 'Himeko',
    app_rate_13: 2.52,
    avg_round_13: 27988,
    app_flat_13: 34,
    char_14: 'Sparkle',
    app_rate_14: 2.45,
    avg_round_14: 27168,
    app_flat_14: 9,
    char_15: 'Tingyun',
    app_rate_15: 1.33,
    avg_round_15: 26589,
    app_flat_15: 19,
    char_16: 'Bailu',
    app_rate_16: 1.26,
    avg_round_16: 29794,
    app_flat_16: 15,
    char_17: 'Herta',
    app_rate_17: 1.19,
    avg_round_17: 33377,
    app_flat_17: 19,
    char_18: 'Guinaifen',
    app_rate_18: 1.12,
    avg_round_18: 25390,
    app_flat_18: 16,
    char_19: 'Asta',
    app_rate_19: 0.7,
    avg_round_19: 25398,
    app_flat_19: 5,
    char_20: 'Bronya',
    app_rate_20: 0.56,
    avg_round_20: 29748,
    app_flat_20: 2,
    char_21: 'Lynx',
    app_rate_21: 0.49,
    avg_round_21: 28987,
    app_flat_21: 6,
    char_22: 'trailblazer-fire',
    app_rate_22: 0.46,
    avg_round_22: 30524,
    app_flat_22: 7,
    char_23: 'silver-wolf',
    app_rate_23: 0.39,
    avg_round_23: 25691,
    app_flat_23: 6,
    char_24: 'Serval',
    app_rate_24: 0.28,
    avg_round_24: 24184,
    app_flat_24: 4,
    char_25: 'Sampo',
    app_rate_25: 0.28,
    avg_round_25: 24353,
    app_flat_25: 5,
    char_26: 'Clara',
    app_rate_26: 0.28,
    avg_round_26: 29549,
    app_flat_26: 7,
    char_27: 'Yunli',
    app_rate_27: 0.28,
    avg_round_27: 33151,
    app_flat_27: 4,
    char_28: 'Natasha',
    app_rate_28: 0.18,
    avg_round_28: 31500,
    app_flat_28: 1,
    char_29: 'trailblazer-imaginary',
    app_rate_29: 0.18,
    avg_round_29: 21938,
    app_flat_29: 3,
    char_30: 'Firefly',
    app_rate_30: 0.14,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Gepard',
    round: 30697,
    char_1: 'Acheron',
    app_rate_1: 91.95,
    avg_round_1: 30472,
    app_flat_1: 144,
    char_2: 'Pela',
    app_rate_2: 44.25,
    avg_round_2: 28915,
    app_flat_2: 68,
    char_3: 'Kafka',
    app_rate_3: 38.79,
    avg_round_3: 32042,
    app_flat_3: 71,
    char_4: 'black-swan',
    app_rate_4: 34.77,
    avg_round_4: 31875,
    app_flat_4: 64,
    char_5: 'Sparkle',
    app_rate_5: 18.68,
    avg_round_5: 26359,
    app_flat_5: 12,
    char_6: 'silver-wolf',
    app_rate_6: 11.21,
    avg_round_6: 25796,
    app_flat_6: 18,
    char_7: 'Himeko',
    app_rate_7: 10.34,
    avg_round_7: 30255,
    app_flat_7: 21,
    char_8: 'Jiaoqiu',
    app_rate_8: 9.48,
    avg_round_8: 31725,
    app_flat_8: 18,
    char_9: 'Herta',
    app_rate_9: 7.47,
    avg_round_9: 33487,
    app_flat_9: 17,
    char_10: 'Bronya',
    app_rate_10: 6.9,
    avg_round_10: 29341,
    app_flat_10: 4,
    char_11: 'ruan-mei',
    app_rate_11: 6.03,
    avg_round_11: 32164,
    app_flat_11: 12,
    char_12: 'Guinaifen',
    app_rate_12: 5.75,
    avg_round_12: 27686,
    app_flat_12: 14,
    char_13: 'Jade',
    app_rate_13: 3.74,
    avg_round_13: 38158,
    app_flat_13: 10,
    char_14: 'Robin',
    app_rate_14: 2.3,
    avg_round_14: 38817,
    app_flat_14: 5,
    char_15: 'Argenti',
    app_rate_15: 1.44,
    avg_round_15: 36399,
    app_flat_15: 4,
    char_16: 'Tingyun',
    app_rate_16: 1.44,
    avg_round_16: 32148,
    app_flat_16: 3,
    char_17: 'Welt',
    app_rate_17: 1.15,
    avg_round_17: 20416,
    app_flat_17: 1,
    char_18: 'Asta',
    app_rate_18: 0.86,
    avg_round_18: 26087,
    app_flat_18: 2,
    char_19: 'Jingliu',
    app_rate_19: 0.57,
    avg_round_19: 29927,
    app_flat_19: 1,
    char_20: 'Sampo',
    app_rate_20: 0.57,
    avg_round_20: 27630,
    app_flat_20: 2,
    char_21: 'Serval',
    app_rate_21: 0.57,
    avg_round_21: 30450,
    app_flat_21: 1,
    char_22: 'Clara',
    app_rate_22: 0.29,
    avg_round_22: 35450,
    app_flat_22: 1,
    char_23: 'march-7th-swordmaster',
    app_rate_23: 0.29,
    avg_round_23: 25280,
    app_flat_23: 1,
    char_24: 'Yunli',
    app_rate_24: 0.29,
    avg_round_24: 36950,
    app_flat_24: 1,
    char_25: 'Luocha',
    app_rate_25: 0.29,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Boothill',
    app_rate_26: 0.29,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Firefly',
    app_rate_27: 0.29,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-fire',
    round: 30479,
    char_1: 'Acheron',
    app_rate_1: 92.19,
    avg_round_1: 30774,
    app_flat_1: 31,
    char_2: 'Pela',
    app_rate_2: 51.56,
    avg_round_2: 31735,
    app_flat_2: 18,
    char_3: 'Kafka',
    app_rate_3: 34.38,
    avg_round_3: 30607,
    app_flat_3: 8,
    char_4: 'black-swan',
    app_rate_4: 20.31,
    avg_round_4: 30524,
    app_flat_4: 7,
    char_5: 'silver-wolf',
    app_rate_5: 18.75,
    avg_round_5: 28867,
    app_flat_5: 8,
    char_6: 'Guinaifen',
    app_rate_6: 17.19,
    avg_round_6: 29084,
    app_flat_6: 9,
    char_7: 'Sparkle',
    app_rate_7: 17.19,
    avg_round_7: 32514,
    app_flat_7: 3,
    char_8: 'Himeko',
    app_rate_8: 12.5,
    avg_round_8: 31084,
    app_flat_8: 4,
    char_9: 'ruan-mei',
    app_rate_9: 7.81,
    avg_round_9: 35514,
    app_flat_9: 2,
    char_10: 'Jiaoqiu',
    app_rate_10: 6.25,
    avg_round_10: 28021,
    app_flat_10: 3,
    char_11: 'Herta',
    app_rate_11: 4.69,
    avg_round_11: 40000,
    app_flat_11: 1,
    char_12: 'Bronya',
    app_rate_12: 4.69,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Gallagher',
    app_rate_13: 3.12,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Lynx',
    app_rate_14: 1.56,
    avg_round_14: 20613,
    app_flat_14: 1,
    char_15: 'Xueyi',
    app_rate_15: 1.56,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Hanya',
    app_rate_16: 1.56,
    avg_round_16: 20798,
    app_flat_16: 1,
    char_17: 'Tingyun',
    app_rate_17: 1.56,
    avg_round_17: 20798,
    app_flat_17: 1,
    char_18: 'Firefly',
    app_rate_18: 1.56,
    avg_round_18: 31027,
    app_flat_18: 1,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 1.56,
    avg_round_19: 30800,
    app_flat_19: 1,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jingliu',
    round: 30372,
    char_1: 'ruan-mei',
    app_rate_1: 51.92,
    avg_round_1: 32742,
    app_flat_1: 25,
    char_2: 'Blade',
    app_rate_2: 38.46,
    avg_round_2: 30497,
    app_flat_2: 24,
    char_3: 'Herta',
    app_rate_3: 32.05,
    avg_round_3: 31580,
    app_flat_3: 22,
    char_4: 'fu-xuan',
    app_rate_4: 28.85,
    avg_round_4: 32441,
    app_flat_4: 16,
    char_5: 'Huohuo',
    app_rate_5: 25.64,
    avg_round_5: 27870,
    app_flat_5: 15,
    char_6: 'Bronya',
    app_rate_6: 23.08,
    avg_round_6: 29057,
    app_flat_6: 17,
    char_7: 'Luocha',
    app_rate_7: 19.87,
    avg_round_7: 29516,
    app_flat_7: 14,
    char_8: 'Robin',
    app_rate_8: 13.46,
    avg_round_8: 30560,
    app_flat_8: 5,
    char_9: 'Pela',
    app_rate_9: 9.62,
    avg_round_9: 28417,
    app_flat_9: 8,
    char_10: 'Gallagher',
    app_rate_10: 9.62,
    avg_round_10: 30917,
    app_flat_10: 6,
    char_11: 'Sparkle',
    app_rate_11: 7.69,
    avg_round_11: 29961,
    app_flat_11: 3,
    char_12: 'Clara',
    app_rate_12: 5.77,
    avg_round_12: 28848,
    app_flat_12: 2,
    char_13: 'Jade',
    app_rate_13: 5.13,
    avg_round_13: 31526,
    app_flat_13: 3,
    char_14: 'Lynx',
    app_rate_14: 3.85,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Himeko',
    app_rate_15: 3.21,
    avg_round_15: 23388,
    app_flat_15: 3,
    char_16: 'Yunli',
    app_rate_16: 3.21,
    avg_round_16: 35890,
    app_flat_16: 2,
    char_17: 'Tingyun',
    app_rate_17: 3.21,
    avg_round_17: 29806,
    app_flat_17: 3,
    char_18: 'silver-wolf',
    app_rate_18: 2.56,
    avg_round_18: 29564,
    app_flat_18: 1,
    char_19: 'Aventurine',
    app_rate_19: 2.56,
    avg_round_19: 31655,
    app_flat_19: 3,
    char_20: 'Xueyi',
    app_rate_20: 1.28,
    avg_round_20: 22310,
    app_flat_20: 1,
    char_21: 'Gepard',
    app_rate_21: 1.28,
    avg_round_21: 29927,
    app_flat_21: 1,
    char_22: 'Argenti',
    app_rate_22: 1.28,
    avg_round_22: 28900,
    app_flat_22: 1,
    char_23: 'Firefly',
    app_rate_23: 1.28,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'march-7th-swordmaster',
    app_rate_24: 0.64,
    avg_round_24: 28900,
    app_flat_24: 1,
    char_25: 'Kafka',
    app_rate_25: 0.64,
    avg_round_25: 20414,
    app_flat_25: 1,
    char_26: 'black-swan',
    app_rate_26: 0.64,
    avg_round_26: 20414,
    app_flat_26: 1,
    char_27: 'Acheron',
    app_rate_27: 0.64,
    avg_round_27: 29564,
    app_flat_27: 1,
    char_28: 'Serval',
    app_rate_28: 0.64,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Bailu',
    app_rate_29: 0.64,
    avg_round_29: 26309,
    app_flat_29: 1,
    char_30: 'Boothill',
    app_rate_30: 0.64,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Pela',
    round: 30186,
    char_1: 'Acheron',
    app_rate_1: 91.85,
    avg_round_1: 29989,
    app_flat_1: 1118,
    char_2: 'fu-xuan',
    app_rate_2: 31.85,
    avg_round_2: 31137,
    app_flat_2: 342,
    char_3: 'Aventurine',
    app_rate_3: 24.41,
    avg_round_3: 30459,
    app_flat_3: 309,
    char_4: 'Gallagher',
    app_rate_4: 21.97,
    avg_round_4: 29069,
    app_flat_4: 315,
    char_5: 'Sparkle',
    app_rate_5: 20.42,
    avg_round_5: 29275,
    app_flat_5: 103,
    char_6: 'Jiaoqiu',
    app_rate_6: 17.27,
    avg_round_6: 34098,
    app_flat_6: 291,
    char_7: 'silver-wolf',
    app_rate_7: 16.6,
    avg_round_7: 26847,
    app_flat_7: 249,
    char_8: 'black-swan',
    app_rate_8: 9.83,
    avg_round_8: 29361,
    app_flat_8: 161,
    char_9: 'Kafka',
    app_rate_9: 7.02,
    avg_round_9: 30275,
    app_flat_9: 94,
    char_10: 'Himeko',
    app_rate_10: 6.93,
    avg_round_10: 30334,
    app_flat_10: 103,
    char_11: 'Gepard',
    app_rate_11: 6.47,
    avg_round_11: 28915,
    app_flat_11: 68,
    char_12: 'Bronya',
    app_rate_12: 6.39,
    avg_round_12: 29302,
    app_flat_12: 18,
    char_13: 'Guinaifen',
    app_rate_13: 6.18,
    avg_round_13: 26705,
    app_flat_13: 109,
    char_14: 'Herta',
    app_rate_14: 4.79,
    avg_round_14: 32092,
    app_flat_14: 70,
    char_15: 'Luocha',
    app_rate_15: 3.87,
    avg_round_15: 29768,
    app_flat_15: 50,
    char_16: 'ruan-mei',
    app_rate_16: 3.74,
    avg_round_16: 30709,
    app_flat_16: 33,
    char_17: 'Huohuo',
    app_rate_17: 3.28,
    avg_round_17: 31191,
    app_flat_17: 42,
    char_18: 'Robin',
    app_rate_18: 2.77,
    avg_round_18: 37537,
    app_flat_18: 19,
    char_19: 'Tingyun',
    app_rate_19: 2.1,
    avg_round_19: 33531,
    app_flat_19: 38,
    char_20: 'Welt',
    app_rate_20: 1.72,
    avg_round_20: 26193,
    app_flat_20: 28,
    char_21: 'Yunli',
    app_rate_21: 1.43,
    avg_round_21: 36048,
    app_flat_21: 25,
    char_22: 'trailblazer-fire',
    app_rate_22: 1.39,
    avg_round_22: 31735,
    app_flat_22: 18,
    char_23: 'Argenti',
    app_rate_23: 0.71,
    avg_round_23: 36924,
    app_flat_23: 10,
    char_24: 'Jade',
    app_rate_24: 0.67,
    avg_round_24: 36700,
    app_flat_24: 7,
    char_25: 'Clara',
    app_rate_25: 0.63,
    avg_round_25: 31369,
    app_flat_25: 11,
    char_26: 'Jingliu',
    app_rate_26: 0.63,
    avg_round_26: 28417,
    app_flat_26: 8,
    char_27: 'Firefly',
    app_rate_27: 0.59,
    avg_round_27: 27793,
    app_flat_27: 2,
    char_28: 'Seele',
    app_rate_28: 0.59,
    avg_round_28: 35605,
    app_flat_28: 5,
    char_29: 'Bailu',
    app_rate_29: 0.46,
    avg_round_29: 28754,
    app_flat_29: 7,
    char_30: 'Blade',
    app_rate_30: 0.42,
    avg_round_30: 29123,
    app_flat_30: 5
  },
  {
    char: 'topaz',
    round: 29715,
    char_1: 'Aventurine',
    app_rate_1: 63.72,
    avg_round_1: 29970,
    app_flat_1: 145,
    char_2: 'Robin',
    app_rate_2: 57.4,
    avg_round_2: 32555,
    app_flat_2: 123,
    char_3: 'Himeko',
    app_rate_3: 24.73,
    avg_round_3: 24340,
    app_flat_3: 68,
    char_4: 'Yunli',
    app_rate_4: 22.38,
    avg_round_4: 38080,
    app_flat_4: 51,
    char_5: 'Herta',
    app_rate_5: 20.4,
    avg_round_5: 31774,
    app_flat_5: 64,
    char_6: 'Clara',
    app_rate_6: 18.23,
    avg_round_6: 30478,
    app_flat_6: 48,
    char_7: 'ruan-mei',
    app_rate_7: 15.34,
    avg_round_7: 25427,
    app_flat_7: 34,
    char_8: 'Huohuo',
    app_rate_8: 10.65,
    avg_round_8: 36635,
    app_flat_8: 22,
    char_9: 'Jade',
    app_rate_9: 10.29,
    avg_round_9: 30687,
    app_flat_9: 11,
    char_10: 'jing-yuan',
    app_rate_10: 9.75,
    avg_round_10: 22396,
    app_flat_10: 25,
    char_11: 'fu-xuan',
    app_rate_11: 7.4,
    avg_round_11: 28008,
    app_flat_11: 12,
    char_12: 'Gallagher',
    app_rate_12: 6.32,
    avg_round_12: 23300,
    app_flat_12: 23,
    char_13: 'dr-ratio',
    app_rate_13: 4.51,
    avg_round_13: 21708,
    app_flat_13: 8,
    char_14: 'Luocha',
    app_rate_14: 4.15,
    avg_round_14: 31553,
    app_flat_14: 13,
    char_15: 'Tingyun',
    app_rate_15: 3.79,
    avg_round_15: 29088,
    app_flat_15: 12,
    char_16: 'Acheron',
    app_rate_16: 3.43,
    avg_round_16: 28144,
    app_flat_16: 6,
    char_17: 'Sparkle',
    app_rate_17: 3.43,
    avg_round_17: 32162,
    app_flat_17: 5,
    char_18: 'Kafka',
    app_rate_18: 2.89,
    avg_round_18: 24026,
    app_flat_18: 10,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 2.35,
    avg_round_19: 21249,
    app_flat_19: 7,
    char_20: 'Lynx',
    app_rate_20: 1.81,
    avg_round_20: 29908,
    app_flat_20: 5,
    char_21: 'Firefly',
    app_rate_21: 1.26,
    avg_round_21: 22549,
    app_flat_21: 2,
    char_22: 'Asta',
    app_rate_22: 1.26,
    avg_round_22: 21368,
    app_flat_22: 5,
    char_23: 'Pela',
    app_rate_23: 0.54,
    avg_round_23: 24569,
    app_flat_23: 1,
    char_24: 'Bailu',
    app_rate_24: 0.54,
    avg_round_24: 36900,
    app_flat_24: 2,
    char_25: 'black-swan',
    app_rate_25: 0.36,
    avg_round_25: 23904,
    app_flat_25: 1,
    char_26: 'Jiaoqiu',
    app_rate_26: 0.36,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Serval',
    app_rate_27: 0.36,
    avg_round_27: 20646,
    app_flat_27: 1,
    char_28: 'Guinaifen',
    app_rate_28: 0.36,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Blade',
    app_rate_29: 0.36,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Bronya',
    app_rate_30: 0.36,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Himeko',
    round: 29626,
    char_1: 'ruan-mei',
    app_rate_1: 58.19,
    avg_round_1: 29021,
    app_flat_1: 1115,
    char_2: 'Herta',
    app_rate_2: 43.59,
    avg_round_2: 30217,
    app_flat_2: 1034,
    char_3: 'Gallagher',
    app_rate_3: 33.7,
    avg_round_3: 29029,
    app_flat_3: 755,
    char_4: 'Aventurine',
    app_rate_4: 19.73,
    avg_round_4: 30460,
    app_flat_4: 423,
    char_5: 'Firefly',
    app_rate_5: 17.41,
    avg_round_5: 26310,
    app_flat_5: 191,
    char_6: 'fu-xuan',
    app_rate_6: 17.03,
    avg_round_6: 31371,
    app_flat_6: 330,
    char_7: 'Acheron',
    app_rate_7: 16.54,
    avg_round_7: 32800,
    app_flat_7: 332,
    char_8: 'Robin',
    app_rate_8: 14.97,
    avg_round_8: 31485,
    app_flat_8: 340,
    char_9: 'Kafka',
    app_rate_9: 12.05,
    avg_round_9: 30627,
    app_flat_9: 254,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 9.51,
    avg_round_10: 24907,
    app_flat_10: 110,
    char_11: 'jing-yuan',
    app_rate_11: 6.22,
    avg_round_11: 26176,
    app_flat_11: 136,
    char_12: 'Huohuo',
    app_rate_12: 5.41,
    avg_round_12: 28874,
    app_flat_12: 108,
    char_13: 'Jade',
    app_rate_13: 5.35,
    avg_round_13: 32170,
    app_flat_13: 110,
    char_14: 'Sparkle',
    app_rate_14: 5.16,
    avg_round_14: 26730,
    app_flat_14: 82,
    char_15: 'Luocha',
    app_rate_15: 4.84,
    avg_round_15: 28932,
    app_flat_15: 106,
    char_16: 'Pela',
    app_rate_16: 4.46,
    avg_round_16: 30334,
    app_flat_16: 103,
    char_17: 'topaz',
    app_rate_17: 3.7,
    avg_round_17: 24340,
    app_flat_17: 68,
    char_18: 'Jiaoqiu',
    app_rate_18: 3.14,
    avg_round_18: 34375,
    app_flat_18: 62,
    char_19: 'Serval',
    app_rate_19: 2.92,
    avg_round_19: 27748,
    app_flat_19: 75,
    char_20: 'Clara',
    app_rate_20: 2.16,
    avg_round_20: 30071,
    app_flat_20: 57,
    char_21: 'Tingyun',
    app_rate_21: 2.03,
    avg_round_21: 26210,
    app_flat_21: 56,
    char_22: 'black-swan',
    app_rate_22: 1.95,
    avg_round_22: 27988,
    app_flat_22: 34,
    char_23: 'Asta',
    app_rate_23: 1.84,
    avg_round_23: 26123,
    app_flat_23: 44,
    char_24: 'Yunli',
    app_rate_24: 1.08,
    avg_round_24: 34125,
    app_flat_24: 27,
    char_25: 'Gepard',
    app_rate_25: 0.97,
    avg_round_25: 30255,
    app_flat_25: 21,
    char_26: 'march-7th-swordmaster',
    app_rate_26: 0.92,
    avg_round_26: 23542,
    app_flat_26: 27,
    char_27: 'Guinaifen',
    app_rate_27: 0.76,
    avg_round_27: 26329,
    app_flat_27: 16,
    char_28: 'Bailu',
    app_rate_28: 0.65,
    avg_round_28: 28514,
    app_flat_28: 17,
    char_29: 'Bronya',
    app_rate_29: 0.59,
    avg_round_29: 26350,
    app_flat_29: 12,
    char_30: 'Argenti',
    app_rate_30: 0.54,
    avg_round_30: 32475,
    app_flat_30: 11
  },
  {
    char: 'Gallagher',
    round: 29354,
    char_1: 'ruan-mei',
    app_rate_1: 55.03,
    avg_round_1: 28187,
    app_flat_1: 861,
    char_2: 'Firefly',
    app_rate_2: 36.06,
    avg_round_2: 25589,
    app_flat_2: 360,
    char_3: 'Acheron',
    app_rate_3: 31.41,
    avg_round_3: 30454,
    app_flat_3: 692,
    char_4: 'Himeko',
    app_rate_4: 31.33,
    avg_round_4: 29029,
    app_flat_4: 755,
    char_5: 'trailblazer-imaginary',
    app_rate_5: 30.48,
    avg_round_5: 24804,
    app_flat_5: 299,
    char_6: 'Kafka',
    app_rate_6: 19.67,
    avg_round_6: 30131,
    app_flat_6: 428,
    char_7: 'Herta',
    app_rate_7: 16.36,
    avg_round_7: 30947,
    app_flat_7: 459,
    char_8: 'black-swan',
    app_rate_8: 13.62,
    avg_round_8: 29560,
    app_flat_8: 318,
    char_9: 'Pela',
    app_rate_9: 13.14,
    avg_round_9: 29069,
    app_flat_9: 315,
    char_10: 'Jiaoqiu',
    app_rate_10: 8.19,
    avg_round_10: 33527,
    app_flat_10: 196,
    char_11: 'Robin',
    app_rate_11: 6.73,
    avg_round_11: 34794,
    app_flat_11: 163,
    char_12: 'Sparkle',
    app_rate_12: 6.48,
    avg_round_12: 29454,
    app_flat_12: 108,
    char_13: 'Tingyun',
    app_rate_13: 4.25,
    avg_round_13: 31389,
    app_flat_13: 124,
    char_14: 'jing-yuan',
    app_rate_14: 3.79,
    avg_round_14: 26752,
    app_flat_14: 92,
    char_15: 'Guinaifen',
    app_rate_15: 3.77,
    avg_round_15: 26446,
    app_flat_15: 97,
    char_16: 'Jade',
    app_rate_16: 2.69,
    avg_round_16: 36765,
    app_flat_16: 62,
    char_17: 'Bronya',
    app_rate_17: 2.66,
    avg_round_17: 30570,
    app_flat_17: 41,
    char_18: 'silver-wolf',
    app_rate_18: 2.24,
    avg_round_18: 24860,
    app_flat_18: 57,
    char_19: 'Serval',
    app_rate_19: 1.96,
    avg_round_19: 27725,
    app_flat_19: 61,
    char_20: 'Clara',
    app_rate_20: 1.81,
    avg_round_20: 36565,
    app_flat_20: 53,
    char_21: 'Argenti',
    app_rate_21: 1.38,
    avg_round_21: 35867,
    app_flat_21: 40,
    char_22: 'Yunli',
    app_rate_22: 1.01,
    avg_round_22: 37374,
    app_flat_22: 35,
    char_23: 'Asta',
    app_rate_23: 0.98,
    avg_round_23: 26924,
    app_flat_23: 28,
    char_24: 'march-7th-swordmaster',
    app_rate_24: 0.9,
    avg_round_24: 24037,
    app_flat_24: 26,
    char_25: 'topaz',
    app_rate_25: 0.88,
    avg_round_25: 23300,
    app_flat_25: 23,
    char_26: 'Blade',
    app_rate_26: 0.63,
    avg_round_26: 36461,
    app_flat_26: 14,
    char_27: 'Welt',
    app_rate_27: 0.43,
    avg_round_27: 25028,
    app_flat_27: 11,
    char_28: 'Seele',
    app_rate_28: 0.43,
    avg_round_28: 40000,
    app_flat_28: 6,
    char_29: 'Jingliu',
    app_rate_29: 0.38,
    avg_round_29: 30917,
    app_flat_29: 6,
    char_30: 'Sampo',
    app_rate_30: 0.33,
    avg_round_30: 27640,
    app_flat_30: 10
  },
  {
    char: 'Qingque',
    round: 29292,
    char_1: 'Sparkle',
    app_rate_1: 70.37,
    avg_round_1: 29245,
    app_flat_1: 11,
    char_2: 'fu-xuan',
    app_rate_2: 44.44,
    avg_round_2: 29051,
    app_flat_2: 5,
    char_3: 'Herta',
    app_rate_3: 37.04,
    avg_round_3: 31695,
    app_flat_3: 5,
    char_4: 'Robin',
    app_rate_4: 18.52,
    avg_round_4: 29162,
    app_flat_4: 3,
    char_5: 'Clara',
    app_rate_5: 18.52,
    avg_round_5: 26205,
    app_flat_5: 3,
    char_6: 'Luocha',
    app_rate_6: 14.81,
    avg_round_6: 30101,
    app_flat_6: 3,
    char_7: 'Huohuo',
    app_rate_7: 14.81,
    avg_round_7: 28729,
    app_flat_7: 1,
    char_8: 'Blade',
    app_rate_8: 11.11,
    avg_round_8: 29265,
    app_flat_8: 3,
    char_9: 'Jade',
    app_rate_9: 7.41,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Tingyun',
    app_rate_10: 7.41,
    avg_round_10: 28729,
    app_flat_10: 1,
    char_11: 'Bronya',
    app_rate_11: 7.41,
    avg_round_11: 28528,
    app_flat_11: 2,
    char_12: 'Aventurine',
    app_rate_12: 7.41,
    avg_round_12: 31981,
    app_flat_12: 2,
    char_13: 'march-7th',
    app_rate_13: 3.7,
    avg_round_13: 27823,
    app_flat_13: 1,
    char_14: 'silver-wolf',
    app_rate_14: 3.7,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Serval',
    app_rate_15: 3.7,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Acheron',
    app_rate_16: 3.7,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Pela',
    app_rate_17: 3.7,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Bailu',
    app_rate_18: 3.7,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Lynx',
    app_rate_19: 3.7,
    avg_round_19: 25683,
    app_flat_19: 1,
    char_20: 'Xueyi',
    app_rate_20: 3.7,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Himeko',
    app_rate_21: 3.7,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'ruan-mei',
    app_rate_22: 3.7,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Argenti',
    app_rate_23: 3.7,
    avg_round_23: 28650,
    app_flat_23: 1,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Asta',
    round: 28163,
    char_1: 'Himeko',
    app_rate_1: 43.04,
    avg_round_1: 26123,
    app_flat_1: 44,
    char_2: 'Gallagher',
    app_rate_2: 24.68,
    avg_round_2: 26924,
    app_flat_2: 28,
    char_3: 'Kafka',
    app_rate_3: 23.42,
    avg_round_3: 25612,
    app_flat_3: 16,
    char_4: 'Herta',
    app_rate_4: 22.15,
    avg_round_4: 30337,
    app_flat_4: 22,
    char_5: 'Aventurine',
    app_rate_5: 17.72,
    avg_round_5: 24192,
    app_flat_5: 16,
    char_6: 'ruan-mei',
    app_rate_6: 16.46,
    avg_round_6: 25596,
    app_flat_6: 13,
    char_7: 'jing-yuan',
    app_rate_7: 15.82,
    avg_round_7: 25831,
    app_flat_7: 15,
    char_8: 'Tingyun',
    app_rate_8: 14.56,
    avg_round_8: 28932,
    app_flat_8: 17,
    char_9: 'black-swan',
    app_rate_9: 12.66,
    avg_round_9: 25398,
    app_flat_9: 5,
    char_10: 'fu-xuan',
    app_rate_10: 12.66,
    avg_round_10: 32911,
    app_flat_10: 8,
    char_11: 'Jade',
    app_rate_11: 10.13,
    avg_round_11: 35798,
    app_flat_11: 12,
    char_12: 'Huohuo',
    app_rate_12: 10.13,
    avg_round_12: 32502,
    app_flat_12: 6,
    char_13: 'Acheron',
    app_rate_13: 10.13,
    avg_round_13: 30088,
    app_flat_13: 5,
    char_14: 'Argenti',
    app_rate_14: 6.96,
    avg_round_14: 35137,
    app_flat_14: 9,
    char_15: 'Firefly',
    app_rate_15: 6.96,
    avg_round_15: 21548,
    app_flat_15: 4,
    char_16: 'Serval',
    app_rate_16: 5.7,
    avg_round_16: 26651,
    app_flat_16: 7,
    char_17: 'Sparkle',
    app_rate_17: 5.06,
    avg_round_17: 37955,
    app_flat_17: 3,
    char_18: 'trailblazer-imaginary',
    app_rate_18: 5.06,
    avg_round_18: 22246,
    app_flat_18: 3,
    char_19: 'topaz',
    app_rate_19: 4.43,
    avg_round_19: 21368,
    app_flat_19: 5,
    char_20: 'Pela',
    app_rate_20: 3.8,
    avg_round_20: 28292,
    app_flat_20: 2,
    char_21: 'Clara',
    app_rate_21: 3.16,
    avg_round_21: 25900,
    app_flat_21: 4,
    char_22: 'Robin',
    app_rate_22: 3.16,
    avg_round_22: 26130,
    app_flat_22: 4,
    char_23: 'Luocha',
    app_rate_23: 3.16,
    avg_round_23: 29483,
    app_flat_23: 3,
    char_24: 'Jiaoqiu',
    app_rate_24: 3.16,
    avg_round_24: 32750,
    app_flat_24: 1,
    char_25: 'Bronya',
    app_rate_25: 2.53,
    avg_round_25: 31798,
    app_flat_25: 4,
    char_26: 'march-7th-swordmaster',
    app_rate_26: 1.9,
    avg_round_26: 26875,
    app_flat_26: 3,
    char_27: 'Yunli',
    app_rate_27: 1.9,
    avg_round_27: 40000,
    app_flat_27: 1,
    char_28: 'Gepard',
    app_rate_28: 1.9,
    avg_round_28: 26087,
    app_flat_28: 2,
    char_29: 'imbibitor-lunae',
    app_rate_29: 1.27,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Sampo',
    app_rate_30: 1.27,
    avg_round_30: 20156,
    app_flat_30: 1
  },
  {
    char: 'Serval',
    round: 27242,
    char_1: 'Himeko',
    app_rate_1: 57.14,
    avg_round_1: 27748,
    app_flat_1: 75,
    char_2: 'ruan-mei',
    app_rate_2: 53.97,
    avg_round_2: 28168,
    app_flat_2: 67,
    char_3: 'Gallagher',
    app_rate_3: 41.27,
    avg_round_3: 27725,
    app_flat_3: 61,
    char_4: 'Tingyun',
    app_rate_4: 16.4,
    avg_round_4: 25432,
    app_flat_4: 26,
    char_5: 'Kafka',
    app_rate_5: 15.34,
    avg_round_5: 26898,
    app_flat_5: 14,
    char_6: 'Aventurine',
    app_rate_6: 15.34,
    avg_round_6: 26679,
    app_flat_6: 17,
    char_7: 'fu-xuan',
    app_rate_7: 14.81,
    avg_round_7: 29247,
    app_flat_7: 15,
    char_8: 'Acheron',
    app_rate_8: 10.58,
    avg_round_8: 26788,
    app_flat_8: 14,
    char_9: 'Sparkle',
    app_rate_9: 10.05,
    avg_round_9: 26853,
    app_flat_9: 10,
    char_10: 'Huohuo',
    app_rate_10: 9.52,
    avg_round_10: 25394,
    app_flat_10: 9,
    char_11: 'Herta',
    app_rate_11: 8.47,
    avg_round_11: 24016,
    app_flat_11: 11,
    char_12: 'Robin',
    app_rate_12: 6.88,
    avg_round_12: 26834,
    app_flat_12: 7,
    char_13: 'Asta',
    app_rate_13: 4.76,
    avg_round_13: 26651,
    app_flat_13: 7,
    char_14: 'Luocha',
    app_rate_14: 4.76,
    avg_round_14: 26245,
    app_flat_14: 7,
    char_15: 'black-swan',
    app_rate_15: 4.23,
    avg_round_15: 24184,
    app_flat_15: 4,
    char_16: 'Pela',
    app_rate_16: 3.7,
    avg_round_16: 25170,
    app_flat_16: 6,
    char_17: 'trailblazer-imaginary',
    app_rate_17: 3.17,
    avg_round_17: 25405,
    app_flat_17: 5,
    char_18: 'Bronya',
    app_rate_18: 3.17,
    avg_round_18: 24931,
    app_flat_18: 5,
    char_19: 'Guinaifen',
    app_rate_19: 3.17,
    avg_round_19: 24032,
    app_flat_19: 3,
    char_20: 'Jiaoqiu',
    app_rate_20: 2.12,
    avg_round_20: 30514,
    app_flat_20: 2,
    char_21: 'Bailu',
    app_rate_21: 1.59,
    avg_round_21: 24328,
    app_flat_21: 1,
    char_22: 'topaz',
    app_rate_22: 1.06,
    avg_round_22: 20646,
    app_flat_22: 1,
    char_23: 'Gepard',
    app_rate_23: 1.06,
    avg_round_23: 30450,
    app_flat_23: 1,
    char_24: 'march-7th-swordmaster',
    app_rate_24: 1.06,
    avg_round_24: 26776,
    app_flat_24: 1,
    char_25: 'Argenti',
    app_rate_25: 1.06,
    avg_round_25: 32392,
    app_flat_25: 2,
    char_26: 'Firefly',
    app_rate_26: 1.06,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'jing-yuan',
    app_rate_27: 1.06,
    avg_round_27: 21628,
    app_flat_27: 1,
    char_28: 'Qingque',
    app_rate_28: 0.53,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Sampo',
    app_rate_29: 0.53,
    avg_round_29: 20293,
    app_flat_29: 1,
    char_30: 'march-7th',
    app_rate_30: 0.53,
    avg_round_30: 27897,
    app_flat_30: 1
  },
  {
    char: 'Sampo',
    round: 26839,
    char_1: 'Acheron',
    app_rate_1: 66.67,
    avg_round_1: 28086,
    app_flat_1: 18,
    char_2: 'Kafka',
    app_rate_2: 38.89,
    avg_round_2: 25803,
    app_flat_2: 9,
    char_3: 'Gallagher',
    app_rate_3: 36.11,
    avg_round_3: 27640,
    app_flat_3: 10,
    char_4: 'Pela',
    app_rate_4: 27.78,
    avg_round_4: 29210,
    app_flat_4: 8,
    char_5: 'ruan-mei',
    app_rate_5: 22.22,
    avg_round_5: 24578,
    app_flat_5: 3,
    char_6: 'black-swan',
    app_rate_6: 22.22,
    avg_round_6: 24353,
    app_flat_6: 5,
    char_7: 'fu-xuan',
    app_rate_7: 19.44,
    avg_round_7: 28693,
    app_flat_7: 5,
    char_8: 'Huohuo',
    app_rate_8: 8.33,
    avg_round_8: 25095,
    app_flat_8: 1,
    char_9: 'Guinaifen',
    app_rate_9: 8.33,
    avg_round_9: 27984,
    app_flat_9: 2,
    char_10: 'Aventurine',
    app_rate_10: 8.33,
    avg_round_10: 23050,
    app_flat_10: 1,
    char_11: 'Jiaoqiu',
    app_rate_11: 8.33,
    avg_round_11: 20990,
    app_flat_11: 1,
    char_12: 'Gepard',
    app_rate_12: 5.56,
    avg_round_12: 27630,
    app_flat_12: 2,
    char_13: 'Tingyun',
    app_rate_13: 5.56,
    avg_round_13: 26088,
    app_flat_13: 2,
    char_14: 'Asta',
    app_rate_14: 5.56,
    avg_round_14: 20156,
    app_flat_14: 1,
    char_15: 'Herta',
    app_rate_15: 5.56,
    avg_round_15: 20173,
    app_flat_15: 1,
    char_16: 'Luocha',
    app_rate_16: 2.78,
    avg_round_16: 20553,
    app_flat_16: 1,
    char_17: 'Serval',
    app_rate_17: 2.78,
    avg_round_17: 20293,
    app_flat_17: 1,
    char_18: 'Himeko',
    app_rate_18: 2.78,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Seele',
    app_rate_19: 2.78,
    avg_round_19: 31882,
    app_flat_19: 1,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    round: 26833,
    char_1: 'Acheron',
    app_rate_1: 94.63,
    avg_round_1: 26765,
    app_flat_1: 298,
    char_2: 'Pela',
    app_rate_2: 64.23,
    avg_round_2: 26847,
    app_flat_2: 249,
    char_3: 'fu-xuan',
    app_rate_3: 46.02,
    avg_round_3: 28197,
    app_flat_3: 116,
    char_4: 'Aventurine',
    app_rate_4: 22.28,
    avg_round_4: 26550,
    app_flat_4: 79,
    char_5: 'Sparkle',
    app_rate_5: 16.75,
    avg_round_5: 26795,
    app_flat_5: 5,
    char_6: 'Gallagher',
    app_rate_6: 14.47,
    avg_round_6: 24860,
    app_flat_6: 57,
    char_7: 'Gepard',
    app_rate_7: 6.34,
    avg_round_7: 25796,
    app_flat_7: 18,
    char_8: 'Jiaoqiu',
    app_rate_8: 4.39,
    avg_round_8: 26177,
    app_flat_8: 21,
    char_9: 'Himeko',
    app_rate_9: 3.09,
    avg_round_9: 26096,
    app_flat_9: 8,
    char_10: 'Kafka',
    app_rate_10: 2.93,
    avg_round_10: 27528,
    app_flat_10: 8,
    char_11: 'Luocha',
    app_rate_11: 2.76,
    avg_round_11: 24736,
    app_flat_11: 11,
    char_12: 'Huohuo',
    app_rate_12: 2.44,
    avg_round_12: 27252,
    app_flat_12: 4,
    char_13: 'Guinaifen',
    app_rate_13: 2.28,
    avg_round_13: 25640,
    app_flat_13: 9,
    char_14: 'Seele',
    app_rate_14: 2.28,
    avg_round_14: 27624,
    app_flat_14: 3,
    char_15: 'trailblazer-fire',
    app_rate_15: 1.95,
    avg_round_15: 28867,
    app_flat_15: 8,
    char_16: 'ruan-mei',
    app_rate_16: 1.95,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'black-swan',
    app_rate_17: 1.79,
    avg_round_17: 25691,
    app_flat_17: 6,
    char_18: 'Bronya',
    app_rate_18: 1.63,
    avg_round_18: 22050,
    app_flat_18: 1,
    char_19: 'Robin',
    app_rate_19: 1.3,
    avg_round_19: 28469,
    app_flat_19: 1,
    char_20: 'Jade',
    app_rate_20: 1.14,
    avg_round_20: 34956,
    app_flat_20: 2,
    char_21: 'Herta',
    app_rate_21: 0.98,
    avg_round_21: 31582,
    app_flat_21: 3,
    char_22: 'Yunli',
    app_rate_22: 0.65,
    avg_round_22: 33955,
    app_flat_22: 1,
    char_23: 'Jingliu',
    app_rate_23: 0.65,
    avg_round_23: 29564,
    app_flat_23: 1,
    char_24: 'Firefly',
    app_rate_24: 0.49,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Clara',
    app_rate_25: 0.49,
    avg_round_25: 28469,
    app_flat_25: 1,
    char_26: 'march-7th',
    app_rate_26: 0.33,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Tingyun',
    app_rate_27: 0.33,
    avg_round_27: 29097,
    app_flat_27: 1,
    char_28: 'Argenti',
    app_rate_28: 0.33,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Qingque',
    app_rate_29: 0.16,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Blade',
    app_rate_30: 0.16,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Guinaifen',
    round: 26806,
    char_1: 'Acheron',
    app_rate_1: 83.6,
    avg_round_1: 27312,
    app_flat_1: 202,
    char_2: 'Gallagher',
    app_rate_2: 39.68,
    avg_round_2: 26446,
    app_flat_2: 97,
    char_3: 'Pela',
    app_rate_3: 38.89,
    avg_round_3: 26705,
    app_flat_3: 109,
    char_4: 'Kafka',
    app_rate_4: 37.57,
    avg_round_4: 27065,
    app_flat_4: 78,
    char_5: 'Aventurine',
    app_rate_5: 19.05,
    avg_round_5: 26319,
    app_flat_5: 44,
    char_6: 'fu-xuan',
    app_rate_6: 16.67,
    avg_round_6: 29992,
    app_flat_6: 33,
    char_7: 'ruan-mei',
    app_rate_7: 9.26,
    avg_round_7: 24527,
    app_flat_7: 16,
    char_8: 'black-swan',
    app_rate_8: 8.47,
    avg_round_8: 25390,
    app_flat_8: 16,
    char_9: 'Himeko',
    app_rate_9: 7.41,
    avg_round_9: 26329,
    app_flat_9: 16,
    char_10: 'Gepard',
    app_rate_10: 5.29,
    avg_round_10: 27686,
    app_flat_10: 14,
    char_11: 'Huohuo',
    app_rate_11: 4.76,
    avg_round_11: 22997,
    app_flat_11: 9,
    char_12: 'Luocha',
    app_rate_12: 3.97,
    avg_round_12: 21901,
    app_flat_12: 6,
    char_13: 'silver-wolf',
    app_rate_13: 3.7,
    avg_round_13: 25640,
    app_flat_13: 9,
    char_14: 'Sparkle',
    app_rate_14: 3.7,
    avg_round_14: 25070,
    app_flat_14: 2,
    char_15: 'trailblazer-fire',
    app_rate_15: 2.91,
    avg_round_15: 29084,
    app_flat_15: 9,
    char_16: 'Jiaoqiu',
    app_rate_16: 2.65,
    avg_round_16: 29721,
    app_flat_16: 6,
    char_17: 'Tingyun',
    app_rate_17: 2.65,
    avg_round_17: 23583,
    app_flat_17: 9,
    char_18: 'Serval',
    app_rate_18: 1.59,
    avg_round_18: 24032,
    app_flat_18: 3,
    char_19: 'Bronya',
    app_rate_19: 1.06,
    avg_round_19: 26650,
    app_flat_19: 1,
    char_20: 'Welt',
    app_rate_20: 1.06,
    avg_round_20: 20401,
    app_flat_20: 3,
    char_21: 'Firefly',
    app_rate_21: 0.79,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Sampo',
    app_rate_22: 0.79,
    avg_round_22: 27984,
    app_flat_22: 2,
    char_23: 'jing-yuan',
    app_rate_23: 0.53,
    avg_round_23: 22928,
    app_flat_23: 1,
    char_24: 'topaz',
    app_rate_24: 0.53,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Bailu',
    app_rate_25: 0.53,
    avg_round_25: 26744,
    app_flat_25: 2,
    char_26: 'Robin',
    app_rate_26: 0.53,
    avg_round_26: 24279,
    app_flat_26: 2,
    char_27: 'Herta',
    app_rate_27: 0.53,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Asta',
    app_rate_28: 0.53,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'trailblazer-imaginary',
    app_rate_29: 0.26,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Hanya',
    app_rate_30: 0.26,
    avg_round_30: 20798,
    app_flat_30: 1
  },
  {
    char: 'Welt',
    round: 26016,
    char_1: 'Acheron',
    app_rate_1: 93.55,
    avg_round_1: 25723,
    app_flat_1: 35,
    char_2: 'Pela',
    app_rate_2: 66.13,
    avg_round_2: 26193,
    app_flat_2: 28,
    char_3: 'Gallagher',
    app_rate_3: 27.42,
    avg_round_3: 25028,
    app_flat_3: 11,
    char_4: 'Aventurine',
    app_rate_4: 20.97,
    avg_round_4: 25976,
    app_flat_4: 10,
    char_5: 'ruan-mei',
    app_rate_5: 14.52,
    avg_round_5: 26873,
    app_flat_5: 3,
    char_6: 'Luocha',
    app_rate_6: 9.68,
    avg_round_6: 23822,
    app_flat_6: 2,
    char_7: 'fu-xuan',
    app_rate_7: 9.68,
    avg_round_7: 30058,
    app_flat_7: 3,
    char_8: 'Sparkle',
    app_rate_8: 6.45,
    avg_round_8: 25259,
    app_flat_8: 3,
    char_9: 'Jiaoqiu',
    app_rate_9: 6.45,
    avg_round_9: 28750,
    app_flat_9: 1,
    char_10: 'Guinaifen',
    app_rate_10: 6.45,
    avg_round_10: 20401,
    app_flat_10: 3,
    char_11: 'Gepard',
    app_rate_11: 6.45,
    avg_round_11: 20416,
    app_flat_11: 1,
    char_12: 'Bronya',
    app_rate_12: 3.23,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Kafka',
    app_rate_13: 3.23,
    avg_round_13: 29800,
    app_flat_13: 1,
    char_14: 'Huohuo',
    app_rate_14: 3.23,
    avg_round_14: 25640,
    app_flat_14: 2,
    char_15: 'Argenti',
    app_rate_15: 3.23,
    avg_round_15: 31142,
    app_flat_15: 2,
    char_16: 'Tingyun',
    app_rate_16: 3.23,
    avg_round_16: 31142,
    app_flat_16: 2,
    char_17: 'Himeko',
    app_rate_17: 3.23,
    avg_round_17: 26695,
    app_flat_17: 1,
    char_18: 'black-swan',
    app_rate_18: 3.23,
    avg_round_18: 23033,
    app_flat_18: 2,
    char_19: 'Firefly',
    app_rate_19: 1.61,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Robin',
    app_rate_20: 1.61,
    avg_round_20: 36000,
    app_flat_20: 1,
    char_21: 'Boothill',
    app_rate_21: 1.61,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Jade',
    app_rate_22: 1.61,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Blade',
    app_rate_23: 1.61,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Herta',
    app_rate_24: 1.61,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Firefly',
    round: 25716,
    char_1: 'ruan-mei',
    app_rate_1: 90.1,
    avg_round_1: 25965,
    app_flat_1: 400,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 78.68,
    avg_round_2: 25010,
    app_flat_2: 330,
    char_3: 'Gallagher',
    app_rate_3: 72.15,
    avg_round_3: 25589,
    app_flat_3: 360,
    char_4: 'Himeko',
    app_rate_4: 32.38,
    avg_round_4: 26310,
    app_flat_4: 191,
    char_5: 'Herta',
    app_rate_5: 7.09,
    avg_round_5: 28335,
    app_flat_5: 35,
    char_6: 'fu-xuan',
    app_rate_6: 2.36,
    avg_round_6: 25716,
    app_flat_6: 12,
    char_7: 'Aventurine',
    app_rate_7: 1.96,
    avg_round_7: 26250,
    app_flat_7: 12,
    char_8: 'Acheron',
    app_rate_8: 1.91,
    avg_round_8: 26210,
    app_flat_8: 9,
    char_9: 'Bronya',
    app_rate_9: 1.71,
    avg_round_9: 22224,
    app_flat_9: 1,
    char_10: 'Luocha',
    app_rate_10: 1.61,
    avg_round_10: 23599,
    app_flat_10: 5,
    char_11: 'Kafka',
    app_rate_11: 1.56,
    avg_round_11: 25357,
    app_flat_11: 7,
    char_12: 'Huohuo',
    app_rate_12: 1.51,
    avg_round_12: 24706,
    app_flat_12: 3,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 0.75,
    avg_round_13: 21973,
    app_flat_13: 7,
    char_14: 'Pela',
    app_rate_14: 0.7,
    avg_round_14: 27793,
    app_flat_14: 2,
    char_15: 'Sparkle',
    app_rate_15: 0.7,
    avg_round_15: 23652,
    app_flat_15: 6,
    char_16: 'jing-yuan',
    app_rate_16: 0.6,
    avg_round_16: 21433,
    app_flat_16: 5,
    char_17: 'Asta',
    app_rate_17: 0.55,
    avg_round_17: 21548,
    app_flat_17: 4,
    char_18: 'topaz',
    app_rate_18: 0.35,
    avg_round_18: 22549,
    app_flat_18: 2,
    char_19: 'Yunli',
    app_rate_19: 0.35,
    avg_round_19: 25404,
    app_flat_19: 4,
    char_20: 'Clara',
    app_rate_20: 0.35,
    avg_round_20: 24344,
    app_flat_20: 2,
    char_21: 'Robin',
    app_rate_21: 0.35,
    avg_round_21: 31615,
    app_flat_21: 1,
    char_22: 'Seele',
    app_rate_22: 0.25,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Argenti',
    app_rate_23: 0.2,
    avg_round_23: 33760,
    app_flat_23: 2,
    char_24: 'black-swan',
    app_rate_24: 0.2,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Jiaoqiu',
    app_rate_25: 0.2,
    avg_round_25: 30140,
    app_flat_25: 1,
    char_26: 'Jade',
    app_rate_26: 0.2,
    avg_round_26: 31283,
    app_flat_26: 1,
    char_27: 'silver-wolf',
    app_rate_27: 0.15,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Guinaifen',
    app_rate_28: 0.15,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Tingyun',
    app_rate_29: 0.15,
    avg_round_29: 24146,
    app_flat_29: 2,
    char_30: 'Blade',
    app_rate_30: 0.15,
    avg_round_30: 24493,
    app_flat_30: 1
  },
  {
    char: 'jing-yuan',
    round: 25462,
    char_1: 'Himeko',
    app_rate_1: 38.02,
    avg_round_1: 26176,
    app_flat_1: 136,
    char_2: 'Sparkle',
    app_rate_2: 37.52,
    avg_round_2: 24862,
    app_flat_2: 125,
    char_3: 'ruan-mei',
    app_rate_3: 36.86,
    avg_round_3: 26537,
    app_flat_3: 104,
    char_4: 'Aventurine',
    app_rate_4: 35.37,
    avg_round_4: 24980,
    app_flat_4: 108,
    char_5: 'Tingyun',
    app_rate_5: 34.71,
    avg_round_5: 25446,
    app_flat_5: 122,
    char_6: 'Gallagher',
    app_rate_6: 24.96,
    avg_round_6: 26752,
    app_flat_6: 92,
    char_7: 'Robin',
    app_rate_7: 17.19,
    avg_round_7: 27146,
    app_flat_7: 49,
    char_8: 'fu-xuan',
    app_rate_8: 16.2,
    avg_round_8: 25199,
    app_flat_8: 46,
    char_9: 'Huohuo',
    app_rate_9: 9.26,
    avg_round_9: 25168,
    app_flat_9: 25,
    char_10: 'topaz',
    app_rate_10: 8.93,
    avg_round_10: 22396,
    app_flat_10: 25,
    char_11: 'Herta',
    app_rate_11: 7.11,
    avg_round_11: 27930,
    app_flat_11: 22,
    char_12: 'Luocha',
    app_rate_12: 6.78,
    avg_round_12: 25217,
    app_flat_12: 20,
    char_13: 'Jiaoqiu',
    app_rate_13: 4.3,
    avg_round_13: 27396,
    app_flat_13: 10,
    char_14: 'Asta',
    app_rate_14: 4.13,
    avg_round_14: 25831,
    app_flat_14: 15,
    char_15: 'trailblazer-imaginary',
    app_rate_15: 2.98,
    avg_round_15: 21106,
    app_flat_15: 5,
    char_16: 'Bronya',
    app_rate_16: 2.98,
    avg_round_16: 22903,
    app_flat_16: 9,
    char_17: 'Jade',
    app_rate_17: 2.48,
    avg_round_17: 28918,
    app_flat_17: 9,
    char_18: 'Firefly',
    app_rate_18: 1.98,
    avg_round_18: 21433,
    app_flat_18: 5,
    char_19: 'Acheron',
    app_rate_19: 1.49,
    avg_round_19: 29748,
    app_flat_19: 6,
    char_20: 'Kafka',
    app_rate_20: 1.32,
    avg_round_20: 24462,
    app_flat_20: 7,
    char_21: 'Bailu',
    app_rate_21: 0.83,
    avg_round_21: 21956,
    app_flat_21: 2,
    char_22: 'imbibitor-lunae',
    app_rate_22: 0.66,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'march-7th-swordmaster',
    app_rate_23: 0.66,
    avg_round_23: 21147,
    app_flat_23: 3,
    char_24: 'Clara',
    app_rate_24: 0.5,
    avg_round_24: 20358,
    app_flat_24: 1,
    char_25: 'Yunli',
    app_rate_25: 0.5,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Pela',
    app_rate_26: 0.5,
    avg_round_26: 25814,
    app_flat_26: 2,
    char_27: 'Hanya',
    app_rate_27: 0.33,
    avg_round_27: 25100,
    app_flat_27: 1,
    char_28: 'Guinaifen',
    app_rate_28: 0.33,
    avg_round_28: 22928,
    app_flat_28: 1,
    char_29: 'dr-ratio',
    app_rate_29: 0.33,
    avg_round_29: 21542,
    app_flat_29: 2,
    char_30: 'Serval',
    app_rate_30: 0.33,
    avg_round_30: 21628,
    app_flat_30: 1
  },
  {
    char: 'trailblazer-imaginary',
    round: 24981,
    char_1: 'Firefly',
    app_rate_1: 95.31,
    avg_round_1: 25010,
    app_flat_1: 330,
    char_2: 'ruan-mei',
    app_rate_2: 89.52,
    avg_round_2: 25210,
    app_flat_2: 317,
    char_3: 'Gallagher',
    app_rate_3: 73.87,
    avg_round_3: 24804,
    app_flat_3: 299,
    char_4: 'Himeko',
    app_rate_4: 21.44,
    avg_round_4: 24907,
    app_flat_4: 110,
    char_5: 'Herta',
    app_rate_5: 3.65,
    avg_round_5: 24143,
    app_flat_5: 17,
    char_6: 'Bronya',
    app_rate_6: 1.77,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Acheron',
    app_rate_7: 1.58,
    avg_round_7: 25627,
    app_flat_7: 8,
    char_8: 'Kafka',
    app_rate_8: 1.58,
    avg_round_8: 24334,
    app_flat_8: 14,
    char_9: 'fu-xuan',
    app_rate_9: 1.58,
    avg_round_9: 27770,
    app_flat_9: 4,
    char_10: 'Luocha',
    app_rate_10: 1.28,
    avg_round_10: 25828,
    app_flat_10: 4,
    char_11: 'Aventurine',
    app_rate_11: 1.16,
    avg_round_11: 22359,
    app_flat_11: 5,
    char_12: 'jing-yuan',
    app_rate_12: 1.1,
    avg_round_12: 21106,
    app_flat_12: 5,
    char_13: 'Huohuo',
    app_rate_13: 1.04,
    avg_round_13: 20000,
    app_flat_13: 1,
    char_14: 'march-7th-swordmaster',
    app_rate_14: 0.91,
    avg_round_14: 21934,
    app_flat_14: 9,
    char_15: 'Asta',
    app_rate_15: 0.49,
    avg_round_15: 22246,
    app_flat_15: 3,
    char_16: 'Pela',
    app_rate_16: 0.49,
    avg_round_16: 26793,
    app_flat_16: 2,
    char_17: 'Argenti',
    app_rate_17: 0.43,
    avg_round_17: 33587,
    app_flat_17: 5,
    char_18: 'Serval',
    app_rate_18: 0.37,
    avg_round_18: 25405,
    app_flat_18: 5,
    char_19: 'Jiaoqiu',
    app_rate_19: 0.3,
    avg_round_19: 23980,
    app_flat_19: 2,
    char_20: 'black-swan',
    app_rate_20: 0.3,
    avg_round_20: 21938,
    app_flat_20: 3,
    char_21: 'Sparkle',
    app_rate_21: 0.3,
    avg_round_21: 23134,
    app_flat_21: 1,
    char_22: 'Clara',
    app_rate_22: 0.24,
    avg_round_22: 27692,
    app_flat_22: 2,
    char_23: 'Yunli',
    app_rate_23: 0.18,
    avg_round_23: 24612,
    app_flat_23: 3,
    char_24: 'Tingyun',
    app_rate_24: 0.18,
    avg_round_24: 24837,
    app_flat_24: 2,
    char_25: 'Boothill',
    app_rate_25: 0.18,
    avg_round_25: 27518,
    app_flat_25: 1,
    char_26: 'Xueyi',
    app_rate_26: 0.18,
    avg_round_26: 20353,
    app_flat_26: 1,
    char_27: 'Blade',
    app_rate_27: 0.12,
    avg_round_27: 25930,
    app_flat_27: 2,
    char_28: 'Seele',
    app_rate_28: 0.12,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Guinaifen',
    app_rate_29: 0.06,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Jade',
    app_rate_30: 0.06,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    round: 24292,
    char_1: 'Aventurine',
    app_rate_1: 90.7,
    avg_round_1: 24665,
    app_flat_1: 15,
    char_2: 'Robin',
    app_rate_2: 60.47,
    avg_round_2: 29440,
    app_flat_2: 9,
    char_3: 'topaz',
    app_rate_3: 58.14,
    avg_round_3: 21708,
    app_flat_3: 8,
    char_4: 'Herta',
    app_rate_4: 25.58,
    avg_round_4: 31697,
    app_flat_4: 5,
    char_5: 'Acheron',
    app_rate_5: 9.3,
    avg_round_5: 25024,
    app_flat_5: 2,
    char_6: 'Kafka',
    app_rate_6: 9.3,
    avg_round_6: 22194,
    app_flat_6: 2,
    char_7: 'ruan-mei',
    app_rate_7: 6.98,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Himeko',
    app_rate_8: 6.98,
    avg_round_8: 23260,
    app_flat_8: 2,
    char_9: 'jing-yuan',
    app_rate_9: 4.65,
    avg_round_9: 21542,
    app_flat_9: 2,
    char_10: 'Clara',
    app_rate_10: 4.65,
    avg_round_10: 27600,
    app_flat_10: 1,
    char_11: 'Sparkle',
    app_rate_11: 2.33,
    avg_round_11: 20494,
    app_flat_11: 1,
    char_12: 'Gallagher',
    app_rate_12: 2.33,
    avg_round_12: 20494,
    app_flat_12: 1,
    char_13: 'Jiaoqiu',
    app_rate_13: 2.33,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'fu-xuan',
    app_rate_14: 2.33,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Pela',
    app_rate_15: 2.33,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Tingyun',
    app_rate_16: 2.33,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Yunli',
    app_rate_17: 2.33,
    avg_round_17: 36764,
    app_flat_17: 1,
    char_18: 'Luocha',
    app_rate_18: 2.33,
    avg_round_18: 26200,
    app_flat_18: 1,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 2.33,
    avg_round_19: 20201,
    app_flat_19: 1,
    char_20: 'Jade',
    app_rate_20: 2.33,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    round: 0,
    char_1: 'Sparkle',
    app_rate_1: 81.88,
    avg_round_1: 33194,
    app_flat_1: 5,
    char_2: 'Tingyun',
    app_rate_2: 50.0,
    avg_round_2: 30997,
    app_flat_2: 2,
    char_3: 'Huohuo',
    app_rate_3: 31.88,
    avg_round_3: 32209,
    app_flat_3: 1,
    char_4: 'Luocha',
    app_rate_4: 29.38,
    avg_round_4: 33007,
    app_flat_4: 2,
    char_5: 'ruan-mei',
    app_rate_5: 20.62,
    avg_round_5: 35750,
    app_flat_5: 1,
    char_6: 'Aventurine',
    app_rate_6: 15.0,
    avg_round_6: 29785,
    app_flat_6: 1,
    char_7: 'Robin',
    app_rate_7: 12.5,
    avg_round_7: 33536,
    app_flat_7: 1,
    char_8: 'fu-xuan',
    app_rate_8: 10.0,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Herta',
    app_rate_9: 9.38,
    avg_round_9: 33921,
    app_flat_9: 3,
    char_10: 'Gallagher',
    app_rate_10: 7.5,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Clara',
    app_rate_11: 5.0,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Pela',
    app_rate_12: 4.38,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Bronya',
    app_rate_13: 3.12,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'jing-yuan',
    app_rate_14: 2.5,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Argenti',
    app_rate_15: 2.5,
    avg_round_15: 35750,
    app_flat_15: 1,
    char_16: 'Blade',
    app_rate_16: 1.88,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Jiaoqiu',
    app_rate_17: 1.88,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'march-7th-swordmaster',
    app_rate_18: 1.88,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Hanya',
    app_rate_19: 1.88,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Asta',
    app_rate_20: 1.25,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Bailu',
    app_rate_21: 1.25,
    avg_round_21: 35750,
    app_flat_21: 1,
    char_22: 'Yunli',
    app_rate_22: 1.25,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Himeko',
    app_rate_23: 1.25,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Firefly',
    app_rate_24: 0.62,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Jade',
    app_rate_25: 0.62,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Yukong',
    app_rate_26: 0.62,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Boothill',
    round: 0,
    char_1: 'ruan-mei',
    app_rate_1: 44.44,
    avg_round_1: 40000,
    app_flat_1: 1,
    char_2: 'Luocha',
    app_rate_2: 37.04,
    avg_round_2: 37318,
    app_flat_2: 2,
    char_3: 'Argenti',
    app_rate_3: 33.33,
    avg_round_3: 34051,
    app_flat_3: 3,
    char_4: 'Gallagher',
    app_rate_4: 25.93,
    avg_round_4: 33759,
    app_flat_4: 2,
    char_5: 'Herta',
    app_rate_5: 22.22,
    avg_round_5: 40000,
    app_flat_5: 2,
    char_6: 'Jade',
    app_rate_6: 14.81,
    avg_round_6: 40000,
    app_flat_6: 2,
    char_7: 'Acheron',
    app_rate_7: 14.81,
    avg_round_7: 32668,
    app_flat_7: 1,
    char_8: 'Bronya',
    app_rate_8: 14.81,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Huohuo',
    app_rate_9: 11.11,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 11.11,
    avg_round_10: 27518,
    app_flat_10: 1,
    char_11: 'fu-xuan',
    app_rate_11: 7.41,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Blade',
    app_rate_12: 7.41,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Pela',
    app_rate_13: 7.41,
    avg_round_13: 32668,
    app_flat_13: 1,
    char_14: 'Seele',
    app_rate_14: 3.7,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Hanya',
    app_rate_15: 3.7,
    avg_round_15: 40000,
    app_flat_15: 1,
    char_16: 'Clara',
    app_rate_16: 3.7,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Firefly',
    app_rate_17: 3.7,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Robin',
    app_rate_18: 3.7,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Tingyun',
    app_rate_19: 3.7,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Welt',
    app_rate_20: 3.7,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Gepard',
    app_rate_21: 3.7,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Sparkle',
    app_rate_22: 3.7,
    avg_round_22: 32668,
    app_flat_22: 1,
    char_23: 'Jingliu',
    app_rate_23: 3.7,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'silver-wolf',
    app_rate_24: 3.7,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Misha',
    app_rate_25: 3.7,
    avg_round_25: 34636,
    app_flat_25: 1,
    char_26: 'Aventurine',
    app_rate_26: 3.7,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yukong',
    round: 0,
    char_1: 'fu-xuan',
    app_rate_1: 27.27,
    avg_round_1: 40000,
    app_flat_1: 1,
    char_2: 'Tingyun',
    app_rate_2: 27.27,
    avg_round_2: 40000,
    app_flat_2: 1,
    char_3: 'Herta',
    app_rate_3: 27.27,
    avg_round_3: 40000,
    app_flat_3: 3,
    char_4: 'Clara',
    app_rate_4: 27.27,
    avg_round_4: 40000,
    app_flat_4: 1,
    char_5: 'Aventurine',
    app_rate_5: 27.27,
    avg_round_5: 40000,
    app_flat_5: 1,
    char_6: 'Argenti',
    app_rate_6: 18.18,
    avg_round_6: 40000,
    app_flat_6: 1,
    char_7: 'Gallagher',
    app_rate_7: 18.18,
    avg_round_7: 40000,
    app_flat_7: 1,
    char_8: 'Sparkle',
    app_rate_8: 18.18,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Jade',
    app_rate_9: 18.18,
    avg_round_9: 40000,
    app_flat_9: 1,
    char_10: 'Acheron',
    app_rate_10: 9.09,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'black-swan',
    app_rate_11: 9.09,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'ruan-mei',
    app_rate_12: 9.09,
    avg_round_12: 40000,
    app_flat_12: 1,
    char_13: 'Pela',
    app_rate_13: 9.09,
    avg_round_13: 40000,
    app_flat_13: 1,
    char_14: 'imbibitor-lunae',
    app_rate_14: 9.09,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Bailu',
    app_rate_15: 9.09,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Seele',
    app_rate_16: 9.09,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Robin',
    app_rate_17: 9.09,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Huohuo',
    app_rate_18: 9.09,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Yunli',
    app_rate_19: 9.09,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sushang',
    round: 0,
    char_1: 'Yunli',
    app_rate_1: 66.67,
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Xueyi',
    app_rate_2: 33.33,
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Herta',
    app_rate_3: 33.33,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'ruan-mei',
    app_rate_4: 33.33,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Hanya',
    app_rate_5: 33.33,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Lynx',
    app_rate_6: 33.33,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Robin',
    app_rate_7: 33.33,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'Huohuo',
    app_rate_8: 33.33,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-physical',
    round: 0,
    char_1: 'Herta',
    app_rate_1: 100.0,
    avg_round_1: 30998,
    app_flat_1: 1,
    char_2: 'Jade',
    app_rate_2: 50.0,
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Bailu',
    app_rate_3: 50.0,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Robin',
    app_rate_4: 50.0,
    avg_round_4: 30998,
    app_flat_4: 1,
    char_5: 'Aventurine',
    app_rate_5: 50.0,
    avg_round_5: 30998,
    app_flat_5: 1,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    round: 0,
    char_1: 'Herta',
    app_rate_1: 100.0,
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Robin',
    app_rate_2: 100.0,
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Gallagher',
    app_rate_3: 100.0,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: 0.0,
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Arlan',
    round: 0,
    char_1: 'Himeko',
    app_rate_1: 100.0,
    avg_round_1: 28387,
    app_flat_1: 1,
    char_2: 'ruan-mei',
    app_rate_2: 100.0,
    avg_round_2: 28387,
    app_flat_2: 1,
    char_3: 'fu-xuan',
    app_rate_3: 100.0,
    avg_round_3: 28387,
    app_flat_3: 1,
    char_4: '-',
    app_rate_4: 0.0,
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Misha',
    round: 0,
    char_1: 'Herta',
    app_rate_1: 50.0,
    avg_round_1: 35500,
    app_flat_1: 1,
    char_2: 'Bronya',
    app_rate_2: 50.0,
    avg_round_2: 35500,
    app_flat_2: 1,
    char_3: 'fu-xuan',
    app_rate_3: 50.0,
    avg_round_3: 35500,
    app_flat_3: 1,
    char_4: 'Boothill',
    app_rate_4: 50.0,
    avg_round_4: 34636,
    app_flat_4: 1,
    char_5: 'Argenti',
    app_rate_5: 50.0,
    avg_round_5: 34636,
    app_flat_5: 1,
    char_6: 'Luocha',
    app_rate_6: 50.0,
    avg_round_6: 34636,
    app_flat_6: 1,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
