import React from 'react';

export const ProfileSparkleType: React.FC = () => {
  return (
    <div className="profile sparkle-type">
      <div className="box">
        <h5 className="wheel">Hyperspeed Sparkle</h5>
        <div className="inner">
          <p>
            The Hyperspeed Sparkle build allows her to grant 2 actions to your
            damage dealer on the 1st and 2nd cycle of every new wave without
            investing any Speed in on him at all (in time restricted modes like
            Memory of Chaos).
          </p>
          <p>
            Sparkles 50% action advance she grants with her skill, allows her to
            pull any DPS that is at least half as fast as her, to her in the
            action order.{' '}
            <strong>
              You can think of this as transferring Sparkles Speed stat to the
              carry, meaning any Speed investment of their own isn't required.
            </strong>{' '}
            The result of this is that all damage dealers supported by
            Hyperspeed Sparkle can completely neglect the Speed stat and you
            have much more freedom with the sub-stats your pursue (and you can
            use ATK% or HP% main stat boots on them).
          </p>
          <p>
            This way of playing Sparkle means that all of her buffs (including
            her Ultimate) have 100% uptime and the team will consume less skill
            points compared to the alternative. Furthermore, 160 Speed is only
            the beginning as you can go beyond that with great gear, further
            amplifying the team's damage output.
          </p>
        </div>
      </div>
      <div className="box">
        <h5>-1 Speed Sparkle</h5>
        <div className="inner">
          <p>
            The -1 Speed Sparkle build is where you aim to make Sparkle 1-2
            Speed slower than whoever you are aiming to buff with her skill.
            Normally this involves making the main carry 135 or 136 Speed and
            Sparkle 134 Speed. Instead of granting Sparkle's Speed to the carry,
            this Speed setup instead grants the main damage dealer 50% more
            actions under ideal circumstances. Here is how it works:
          </p>
          <ul className="with-margin">
            <li>
              You carry being 1 - 2 Speed faster than Sparkle will move first
              and use their action,
            </li>
            <li>
              Sparkle will move right after using her skill on the carry
              boosting their action by 50% (the carry is now 50% ahead of
              Sparkle in the action order),
            </li>
            <li>
              Your carry will now move next (note they are 50% ahead of Sparkle
              at this time),
            </li>
            <li>
              Sparkle will get her turn next (note that your carry is 50% behind
              Sparkle at this time thanks to Sparkle's first boost separating
              them) and Sparkle's boost here on the carry will pull the carry
              that is 50% behind up back into sync with Sparkle, completing at
              full 100% advancement from 2 Sparkle skills granting 1.5 more
              actions in total,
            </li>
            <li>Repeat the combo.</li>
          </ul>
          <p>
            Compared to Hyperspeed Sparkle, this setup offers a few advantages
            but also quite a few disadvantages. As for advantages, gaining 50%
            more actions allows your damage dealer more opportunities to deal
            damage and Break enemies, improving ability to deal with adds - and
            assuming your damage is already sufficient - minimizes overkill
            damage (having 3 semi powerful attacks might be better than 2 really
            big attacks).
          </p>
          <p>
            As for disadvantages, this combo does not have full uptime on
            Sparkle's skill OR Ultimate meaning some of her boosts go to waste.
            It also consumes more skill points and requires you to build a
            specific amount of Speed on your carries. This can be a little
            challenging to work out for characters who are already fast (notably
            Seele has 143 speed at E0 and 172 at E2 meaning Sparkle would need
            142 and 171 speed at minimum to play optimally with her under these
            methods).
          </p>
        </div>
      </div>
    </div>
  );
};
