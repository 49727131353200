export const ApoDuos = [
  {
    char: 'Firefly',
    round: 3695,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 99.65,
    avg_round_1: 3640,
    app_flat_1: 2686,
    char_2: 'ruan-mei',
    app_rate_2: 95.27,
    avg_round_2: 3651,
    app_flat_2: 2516,
    char_3: 'Gallagher',
    app_rate_3: 84.94,
    avg_round_3: 3654,
    app_flat_3: 2395,
    char_4: 'Huohuo',
    app_rate_4: 4.04,
    avg_round_4: 3501,
    app_flat_4: 87,
    char_5: 'Luocha',
    app_rate_5: 3.36,
    avg_round_5: 3491,
    app_flat_5: 85,
    char_6: 'fu-xuan',
    app_rate_6: 2.22,
    avg_round_6: 3553,
    app_flat_6: 50,
    char_7: 'Aventurine',
    app_rate_7: 1.85,
    avg_round_7: 3555,
    app_flat_7: 36,
    char_8: 'Bronya',
    app_rate_8: 1.41,
    avg_round_8: 3410,
    app_flat_8: 6,
    char_9: 'Pela',
    app_rate_9: 1.2,
    avg_round_9: 3483,
    app_flat_9: 50,
    char_10: 'Asta',
    app_rate_10: 1.11,
    avg_round_10: 3464,
    app_flat_10: 32,
    char_11: 'Himeko',
    app_rate_11: 1.05,
    avg_round_11: 3436,
    app_flat_11: 21,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 1.02,
    avg_round_12: 3462,
    app_flat_12: 33,
    char_13: 'Lynx',
    app_rate_13: 0.41,
    avg_round_13: 3527,
    app_flat_13: 7,
    char_14: 'Bailu',
    app_rate_14: 0.38,
    avg_round_14: 3472,
    app_flat_14: 12,
    char_15: 'Gepard',
    app_rate_15: 0.38,
    avg_round_15: 3550,
    app_flat_15: 12,
    char_16: 'Boothill',
    app_rate_16: 0.38,
    avg_round_16: 3504,
    app_flat_16: 4,
    char_17: 'Robin',
    app_rate_17: 0.3,
    avg_round_17: 3485,
    app_flat_17: 5,
    char_18: 'Sparkle',
    app_rate_18: 0.2,
    avg_round_18: 3310,
    app_flat_18: 7,
    char_19: 'silver-wolf',
    app_rate_19: 0.17,
    avg_round_19: 3391,
    app_flat_19: 2,
    char_20: 'Jingliu',
    app_rate_20: 0.08,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Natasha',
    app_rate_21: 0.08,
    avg_round_21: 3542,
    app_flat_21: 2,
    char_22: 'Acheron',
    app_rate_22: 0.06,
    avg_round_22: 3393,
    app_flat_22: 1,
    char_23: 'Clara',
    app_rate_23: 0.06,
    avg_round_23: 3327,
    app_flat_23: 2,
    char_24: 'topaz',
    app_rate_24: 0.06,
    avg_round_24: 3422,
    app_flat_24: 1,
    char_25: 'Welt',
    app_rate_25: 0.05,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Yunli',
    app_rate_26: 0.05,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Tingyun',
    app_rate_27: 0.03,
    avg_round_27: 3260,
    app_flat_27: 1,
    char_28: 'Hanya',
    app_rate_28: 0.03,
    avg_round_28: 3533,
    app_flat_28: 1,
    char_29: 'Guinaifen',
    app_rate_29: 0.03,
    avg_round_29: 3456,
    app_flat_29: 2,
    char_30: 'Argenti',
    app_rate_30: 0.02,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-imaginary',
    round: 3678,
    char_1: 'ruan-mei',
    app_rate_1: 94.58,
    avg_round_1: 3629,
    app_flat_1: 3020,
    char_2: 'Firefly',
    app_rate_2: 88.02,
    avg_round_2: 3640,
    app_flat_2: 2686,
    char_3: 'Gallagher',
    app_rate_3: 84.81,
    avg_round_3: 3631,
    app_flat_3: 2886,
    char_4: 'Boothill',
    app_rate_4: 6.25,
    avg_round_4: 3578,
    app_flat_4: 291,
    char_5: 'Huohuo',
    app_rate_5: 3.93,
    avg_round_5: 3495,
    app_flat_5: 102,
    char_6: 'Luocha',
    app_rate_6: 3.37,
    avg_round_6: 3475,
    app_flat_6: 106,
    char_7: 'Himeko',
    app_rate_7: 3.19,
    avg_round_7: 3395,
    app_flat_7: 132,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 3.01,
    avg_round_8: 3423,
    app_flat_8: 139,
    char_9: 'fu-xuan',
    app_rate_9: 2.29,
    avg_round_9: 3511,
    app_flat_9: 69,
    char_10: 'Aventurine',
    app_rate_10: 2.2,
    avg_round_10: 3486,
    app_flat_10: 60,
    char_11: 'Bronya',
    app_rate_11: 1.81,
    avg_round_11: 3450,
    app_flat_11: 37,
    char_12: 'Asta',
    app_rate_12: 1.21,
    avg_round_12: 3426,
    app_flat_12: 44,
    char_13: 'Pela',
    app_rate_13: 1.19,
    avg_round_13: 3479,
    app_flat_13: 59,
    char_14: 'Gepard',
    app_rate_14: 0.39,
    avg_round_14: 3527,
    app_flat_14: 16,
    char_15: 'Sushang',
    app_rate_15: 0.38,
    avg_round_15: 3407,
    app_flat_15: 21,
    char_16: 'Lynx',
    app_rate_16: 0.38,
    avg_round_16: 3481,
    app_flat_16: 8,
    char_17: 'Bailu',
    app_rate_17: 0.34,
    avg_round_17: 3480,
    app_flat_17: 13,
    char_18: 'Robin',
    app_rate_18: 0.25,
    avg_round_18: 3446,
    app_flat_18: 7,
    char_19: 'Sparkle',
    app_rate_19: 0.23,
    avg_round_19: 3345,
    app_flat_19: 8,
    char_20: 'topaz',
    app_rate_20: 0.23,
    avg_round_20: 3335,
    app_flat_20: 8,
    char_21: 'Guinaifen',
    app_rate_21: 0.19,
    avg_round_21: 3421,
    app_flat_21: 12,
    char_22: 'dr-ratio',
    app_rate_22: 0.19,
    avg_round_22: 3250,
    app_flat_22: 10,
    char_23: 'silver-wolf',
    app_rate_23: 0.19,
    avg_round_23: 3363,
    app_flat_23: 3,
    char_24: 'Luka',
    app_rate_24: 0.17,
    avg_round_24: 3334,
    app_flat_24: 10,
    char_25: 'Clara',
    app_rate_25: 0.15,
    avg_round_25: 3200,
    app_flat_25: 8,
    char_26: 'imbibitor-lunae',
    app_rate_26: 0.12,
    avg_round_26: 3243,
    app_flat_26: 1,
    char_27: 'Xueyi',
    app_rate_27: 0.11,
    avg_round_27: 3442,
    app_flat_27: 6,
    char_28: 'Tingyun',
    app_rate_28: 0.09,
    avg_round_28: 3229,
    app_flat_28: 5,
    char_29: 'Natasha',
    app_rate_29: 0.09,
    avg_round_29: 3412,
    app_flat_29: 4,
    char_30: 'Argenti',
    app_rate_30: 0.08,
    avg_round_30: 3103,
    app_flat_30: 3
  },
  {
    char: 'Gallagher',
    round: 3678,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 90.56,
    avg_round_1: 3631,
    app_flat_1: 2886,
    char_2: 'ruan-mei',
    app_rate_2: 89.96,
    avg_round_2: 3636,
    app_flat_2: 2808,
    char_3: 'Firefly',
    app_rate_3: 80.11,
    avg_round_3: 3654,
    app_flat_3: 2395,
    char_4: 'Boothill',
    app_rate_4: 7.9,
    avg_round_4: 3544,
    app_flat_4: 359,
    char_5: 'Bronya',
    app_rate_5: 5.6,
    avg_round_5: 3452,
    app_flat_5: 221,
    char_6: 'Pela',
    app_rate_6: 3.27,
    avg_round_6: 3391,
    app_flat_6: 128,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 3.21,
    avg_round_7: 3418,
    app_flat_7: 151,
    char_8: 'Himeko',
    app_rate_8: 3.04,
    avg_round_8: 3396,
    app_flat_8: 131,
    char_9: 'Acheron',
    app_rate_9: 2.06,
    avg_round_9: 3256,
    app_flat_9: 40,
    char_10: 'Robin',
    app_rate_10: 2.01,
    avg_round_10: 3437,
    app_flat_10: 62,
    char_11: 'Jingliu',
    app_rate_11: 1.81,
    avg_round_11: 3393,
    app_flat_11: 58,
    char_12: 'Sparkle',
    app_rate_12: 1.42,
    avg_round_12: 3255,
    app_flat_12: 33,
    char_13: 'silver-wolf',
    app_rate_13: 1.25,
    avg_round_13: 3272,
    app_flat_13: 37,
    char_14: 'dr-ratio',
    app_rate_14: 1.15,
    avg_round_14: 3294,
    app_flat_14: 53,
    char_15: 'Tingyun',
    app_rate_15: 0.9,
    avg_round_15: 3301,
    app_flat_15: 33,
    char_16: 'Asta',
    app_rate_16: 0.9,
    avg_round_16: 3441,
    app_flat_16: 37,
    char_17: 'black-swan',
    app_rate_17: 0.8,
    avg_round_17: 3218,
    app_flat_17: 17,
    char_18: 'topaz',
    app_rate_18: 0.57,
    avg_round_18: 3389,
    app_flat_18: 22,
    char_19: 'Kafka',
    app_rate_19: 0.43,
    avg_round_19: 3183,
    app_flat_19: 9,
    char_20: 'imbibitor-lunae',
    app_rate_20: 0.42,
    avg_round_20: 3148,
    app_flat_20: 10,
    char_21: 'Sushang',
    app_rate_21: 0.37,
    avg_round_21: 3434,
    app_flat_21: 19,
    char_22: 'Guinaifen',
    app_rate_22: 0.32,
    avg_round_22: 3414,
    app_flat_22: 13,
    char_23: 'Clara',
    app_rate_23: 0.27,
    avg_round_23: 3193,
    app_flat_23: 12,
    char_24: 'Yunli',
    app_rate_24: 0.24,
    avg_round_24: 3392,
    app_flat_24: 10,
    char_25: 'Blade',
    app_rate_25: 0.23,
    avg_round_25: 3301,
    app_flat_25: 8,
    char_26: 'Argenti',
    app_rate_26: 0.23,
    avg_round_26: 3202,
    app_flat_26: 7,
    char_27: 'Luka',
    app_rate_27: 0.17,
    avg_round_27: 3346,
    app_flat_27: 6,
    char_28: 'Jade',
    app_rate_28: 0.14,
    avg_round_28: 3393,
    app_flat_28: 1,
    char_29: 'Xueyi',
    app_rate_29: 0.11,
    avg_round_29: 3442,
    app_flat_29: 6,
    char_30: 'jing-yuan',
    app_rate_30: 0.09,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'ruan-mei',
    round: 3662,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 87.81,
    avg_round_1: 3629,
    app_flat_1: 3020,
    char_2: 'Gallagher',
    app_rate_2: 78.22,
    avg_round_2: 3636,
    app_flat_2: 2808,
    char_3: 'Firefly',
    app_rate_3: 78.13,
    avg_round_3: 3651,
    app_flat_3: 2516,
    char_4: 'Boothill',
    app_rate_4: 8.75,
    avg_round_4: 3518,
    app_flat_4: 413,
    char_5: 'Bronya',
    app_rate_5: 5.89,
    avg_round_5: 3416,
    app_flat_5: 197,
    char_6: 'Huohuo',
    app_rate_6: 5.42,
    avg_round_6: 3443,
    app_flat_6: 146,
    char_7: 'Luocha',
    app_rate_7: 4.7,
    avg_round_7: 3417,
    app_flat_7: 149,
    char_8: 'Aventurine',
    app_rate_8: 4.02,
    avg_round_8: 3445,
    app_flat_8: 115,
    char_9: 'fu-xuan',
    app_rate_9: 3.81,
    avg_round_9: 3421,
    app_flat_9: 110,
    char_10: 'Himeko',
    app_rate_10: 2.73,
    avg_round_10: 3395,
    app_flat_10: 109,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 2.44,
    avg_round_11: 3408,
    app_flat_11: 106,
    char_12: 'Jingliu',
    app_rate_12: 2.28,
    avg_round_12: 3335,
    app_flat_12: 51,
    char_13: 'dr-ratio',
    app_rate_13: 1.77,
    avg_round_13: 3341,
    app_flat_13: 69,
    char_14: 'topaz',
    app_rate_14: 1.69,
    avg_round_14: 3369,
    app_flat_14: 52,
    char_15: 'Sparkle',
    app_rate_15: 1.52,
    avg_round_15: 3276,
    app_flat_15: 30,
    char_16: 'imbibitor-lunae',
    app_rate_16: 1.51,
    avg_round_16: 3240,
    app_flat_16: 26,
    char_17: 'Tingyun',
    app_rate_17: 0.76,
    avg_round_17: 3361,
    app_flat_17: 20,
    char_18: 'Pela',
    app_rate_18: 0.72,
    avg_round_18: 3319,
    app_flat_18: 7,
    char_19: 'black-swan',
    app_rate_19: 0.69,
    avg_round_19: 3207,
    app_flat_19: 4,
    char_20: 'Acheron',
    app_rate_20: 0.67,
    avg_round_20: 3120,
    app_flat_20: 1,
    char_21: 'Blade',
    app_rate_21: 0.62,
    avg_round_21: 3262,
    app_flat_21: 16,
    char_22: 'Kafka',
    app_rate_22: 0.59,
    avg_round_22: 3104,
    app_flat_22: 2,
    char_23: 'Yunli',
    app_rate_23: 0.57,
    avg_round_23: 3448,
    app_flat_23: 22,
    char_24: 'Bailu',
    app_rate_24: 0.5,
    avg_round_24: 3435,
    app_flat_24: 16,
    char_25: 'Lynx',
    app_rate_25: 0.46,
    avg_round_25: 3441,
    app_flat_25: 12,
    char_26: 'Robin',
    app_rate_26: 0.44,
    avg_round_26: 3408,
    app_flat_26: 8,
    char_27: 'Sushang',
    app_rate_27: 0.41,
    avg_round_27: 3394,
    app_flat_27: 21,
    char_28: 'Gepard',
    app_rate_28: 0.41,
    avg_round_28: 3533,
    app_flat_28: 15,
    char_29: 'silver-wolf',
    app_rate_29: 0.4,
    avg_round_29: 3209,
    app_flat_29: 5,
    char_30: 'Asta',
    app_rate_30: 0.37,
    avg_round_30: 3402,
    app_flat_30: 5
  },
  {
    char: 'Boothill',
    round: 3515,
    char_1: 'ruan-mei',
    app_rate_1: 76.39,
    avg_round_1: 3518,
    app_flat_1: 413,
    char_2: 'Gallagher',
    app_rate_2: 59.96,
    avg_round_2: 3544,
    app_flat_2: 359,
    char_3: 'trailblazer-imaginary',
    app_rate_3: 50.71,
    avg_round_3: 3578,
    app_flat_3: 291,
    char_4: 'Bronya',
    app_rate_4: 47.33,
    avg_round_4: 3387,
    app_flat_4: 280,
    char_5: 'Aventurine',
    app_rate_5: 11.43,
    avg_round_5: 3285,
    app_flat_5: 63,
    char_6: 'Luocha',
    app_rate_6: 8.81,
    avg_round_6: 3321,
    app_flat_6: 57,
    char_7: 'fu-xuan',
    app_rate_7: 7.73,
    avg_round_7: 3344,
    app_flat_7: 44,
    char_8: 'Huohuo',
    app_rate_8: 7.07,
    avg_round_8: 3343,
    app_flat_8: 37,
    char_9: 'Pela',
    app_rate_9: 6.53,
    avg_round_9: 3367,
    app_flat_9: 45,
    char_10: 'Sparkle',
    app_rate_10: 3.59,
    avg_round_10: 3206,
    app_flat_10: 17,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 3.48,
    avg_round_11: 3243,
    app_flat_11: 24,
    char_12: 'Robin',
    app_rate_12: 2.72,
    avg_round_12: 3249,
    app_flat_12: 15,
    char_13: 'Firefly',
    app_rate_13: 2.72,
    avg_round_13: 3504,
    app_flat_13: 4,
    char_14: 'Clara',
    app_rate_14: 1.96,
    avg_round_14: 3144,
    app_flat_14: 12,
    char_15: 'silver-wolf',
    app_rate_15: 1.74,
    avg_round_15: 3209,
    app_flat_15: 11,
    char_16: 'Luka',
    app_rate_16: 0.76,
    avg_round_16: 3118,
    app_flat_16: 4,
    char_17: 'Yunli',
    app_rate_17: 0.76,
    avg_round_17: 3182,
    app_flat_17: 5,
    char_18: 'Asta',
    app_rate_18: 0.76,
    avg_round_18: 3277,
    app_flat_18: 5,
    char_19: 'Lynx',
    app_rate_19: 0.76,
    avg_round_19: 3343,
    app_flat_19: 6,
    char_20: 'topaz',
    app_rate_20: 0.65,
    avg_round_20: 3534,
    app_flat_20: 1,
    char_21: 'Hanya',
    app_rate_21: 0.65,
    avg_round_21: 3312,
    app_flat_21: 6,
    char_22: 'dr-ratio',
    app_rate_22: 0.65,
    avg_round_22: 3237,
    app_flat_22: 4,
    char_23: 'Gepard',
    app_rate_23: 0.54,
    avg_round_23: 3504,
    app_flat_23: 3,
    char_24: 'Himeko',
    app_rate_24: 0.54,
    avg_round_24: 3182,
    app_flat_24: 4,
    char_25: 'Bailu',
    app_rate_25: 0.33,
    avg_round_25: 3375,
    app_flat_25: 3,
    char_26: 'imbibitor-lunae',
    app_rate_26: 0.33,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Blade',
    app_rate_27: 0.22,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Jingliu',
    app_rate_28: 0.22,
    avg_round_28: 3136,
    app_flat_28: 1,
    char_29: 'Tingyun',
    app_rate_29: 0.22,
    avg_round_29: 3056,
    app_flat_29: 2,
    char_30: 'black-swan',
    app_rate_30: 0.11,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Jade',
    round: 3466,
    char_1: 'Robin',
    app_rate_1: 48.78,
    avg_round_1: 3307,
    app_flat_1: 6,
    char_2: 'Aventurine',
    app_rate_2: 38.21,
    avg_round_2: 3264,
    app_flat_2: 7,
    char_3: 'Blade',
    app_rate_3: 36.59,
    avg_round_3: 3092,
    app_flat_3: 9,
    char_4: 'topaz',
    app_rate_4: 27.64,
    avg_round_4: 3152,
    app_flat_4: 2,
    char_5: 'Bronya',
    app_rate_5: 21.14,
    avg_round_5: 3092,
    app_flat_5: 7,
    char_6: 'Yunli',
    app_rate_6: 19.51,
    avg_round_6: 3255,
    app_flat_6: 5,
    char_7: 'fu-xuan',
    app_rate_7: 17.89,
    avg_round_7: 2949,
    app_flat_7: 2,
    char_8: 'Luocha',
    app_rate_8: 17.07,
    avg_round_8: 3106,
    app_flat_8: 3,
    char_9: 'Huohuo',
    app_rate_9: 15.45,
    avg_round_9: 3168,
    app_flat_9: 4,
    char_10: 'ruan-mei',
    app_rate_10: 9.76,
    avg_round_10: 3328,
    app_flat_10: 2,
    char_11: 'Sparkle',
    app_rate_11: 9.76,
    avg_round_11: 2848,
    app_flat_11: 1,
    char_12: 'Gallagher',
    app_rate_12: 8.13,
    avg_round_12: 3393,
    app_flat_12: 1,
    char_13: 'dr-ratio',
    app_rate_13: 7.32,
    avg_round_13: 3327,
    app_flat_13: 1,
    char_14: 'Clara',
    app_rate_14: 6.5,
    avg_round_14: 3176,
    app_flat_14: 1,
    char_15: 'silver-wolf',
    app_rate_15: 4.07,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Lynx',
    app_rate_16: 2.44,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Herta',
    app_rate_17: 1.63,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Asta',
    app_rate_18: 1.63,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 1.63,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Sushang',
    app_rate_20: 0.81,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Jingliu',
    app_rate_21: 0.81,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Himeko',
    app_rate_22: 0.81,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Hanya',
    app_rate_23: 0.81,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Argenti',
    app_rate_24: 0.81,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Firefly',
    app_rate_25: 0.81,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Robin',
    round: 3459,
    char_1: 'topaz',
    app_rate_1: 51.53,
    avg_round_1: 3426,
    app_flat_1: 1112,
    char_2: 'Aventurine',
    app_rate_2: 48.58,
    avg_round_2: 3442,
    app_flat_2: 1074,
    char_3: 'dr-ratio',
    app_rate_3: 43.87,
    avg_round_3: 3427,
    app_flat_3: 1019,
    char_4: 'Yunli',
    app_rate_4: 27.94,
    avg_round_4: 3406,
    app_flat_4: 617,
    char_5: 'Huohuo',
    app_rate_5: 22.43,
    avg_round_5: 3360,
    app_flat_5: 461,
    char_6: 'march-7th-swordmaster',
    app_rate_6: 14.96,
    avg_round_6: 3378,
    app_flat_6: 404,
    char_7: 'Clara',
    app_rate_7: 14.32,
    avg_round_7: 3190,
    app_flat_7: 324,
    char_8: 'fu-xuan',
    app_rate_8: 13.32,
    avg_round_8: 3319,
    app_flat_8: 285,
    char_9: 'Tingyun',
    app_rate_9: 10.19,
    avg_round_9: 3425,
    app_flat_9: 246,
    char_10: 'Sparkle',
    app_rate_10: 9.27,
    avg_round_10: 3298,
    app_flat_10: 123,
    char_11: 'Luocha',
    app_rate_11: 8.08,
    avg_round_11: 3242,
    app_flat_11: 179,
    char_12: 'silver-wolf',
    app_rate_12: 3.82,
    avg_round_12: 3381,
    app_flat_12: 83,
    char_13: 'Gallagher',
    app_rate_13: 3.69,
    avg_round_13: 3437,
    app_flat_13: 62,
    char_14: 'Bronya',
    app_rate_14: 3.45,
    avg_round_14: 3324,
    app_flat_14: 59,
    char_15: 'Jingliu',
    app_rate_15: 3.34,
    avg_round_15: 3320,
    app_flat_15: 52,
    char_16: 'Pela',
    app_rate_16: 2.98,
    avg_round_16: 3380,
    app_flat_16: 52,
    char_17: 'imbibitor-lunae',
    app_rate_17: 2.95,
    avg_round_17: 3233,
    app_flat_17: 29,
    char_18: 'Acheron',
    app_rate_18: 1.76,
    avg_round_18: 3237,
    app_flat_18: 5,
    char_19: 'Lynx',
    app_rate_19: 1.71,
    avg_round_19: 3233,
    app_flat_19: 41,
    char_20: 'Blade',
    app_rate_20: 1.63,
    avg_round_20: 3133,
    app_flat_20: 31,
    char_21: 'Jade',
    app_rate_21: 1.58,
    avg_round_21: 3307,
    app_flat_21: 6,
    char_22: 'black-swan',
    app_rate_22: 1.29,
    avg_round_22: 3352,
    app_flat_22: 6,
    char_23: 'Himeko',
    app_rate_23: 1.24,
    avg_round_23: 3275,
    app_flat_23: 18,
    char_24: 'ruan-mei',
    app_rate_24: 0.92,
    avg_round_24: 3408,
    app_flat_24: 8,
    char_25: 'Bailu',
    app_rate_25: 0.71,
    avg_round_25: 3300,
    app_flat_25: 18,
    char_26: 'Boothill',
    app_rate_26: 0.66,
    avg_round_26: 3249,
    app_flat_26: 15,
    char_27: 'Kafka',
    app_rate_27: 0.63,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Firefly',
    app_rate_28: 0.53,
    avg_round_28: 3485,
    app_flat_28: 5,
    char_29: 'trailblazer-imaginary',
    app_rate_29: 0.5,
    avg_round_29: 3446,
    app_flat_29: 7,
    char_30: 'Argenti',
    app_rate_30: 0.5,
    avg_round_30: 3381,
    app_flat_30: 7
  },
  {
    char: 'Aventurine',
    round: 3454,
    char_1: 'dr-ratio',
    app_rate_1: 54.1,
    avg_round_1: 3391,
    app_flat_1: 1151,
    char_2: 'Robin',
    app_rate_2: 53.63,
    avg_round_2: 3442,
    app_flat_2: 1074,
    char_3: 'topaz',
    app_rate_3: 50.67,
    avg_round_3: 3415,
    app_flat_3: 975,
    char_4: 'Sparkle',
    app_rate_4: 19.91,
    avg_round_4: 3243,
    app_flat_4: 282,
    char_5: 'silver-wolf',
    app_rate_5: 13.58,
    avg_round_5: 3266,
    app_flat_5: 285,
    char_6: 'Acheron',
    app_rate_6: 12.03,
    avg_round_6: 3190,
    app_flat_6: 116,
    char_7: 'Pela',
    app_rate_7: 11.02,
    avg_round_7: 3247,
    app_flat_7: 186,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 10.9,
    avg_round_8: 3332,
    app_flat_8: 273,
    char_9: 'Tingyun',
    app_rate_9: 9.51,
    avg_round_9: 3271,
    app_flat_9: 190,
    char_10: 'ruan-mei',
    app_rate_10: 9.39,
    avg_round_10: 3445,
    app_flat_10: 115,
    char_11: 'Clara',
    app_rate_11: 8.9,
    avg_round_11: 3202,
    app_flat_11: 184,
    char_12: 'imbibitor-lunae',
    app_rate_12: 8.37,
    avg_round_12: 3209,
    app_flat_12: 118,
    char_13: 'Yunli',
    app_rate_13: 8.02,
    avg_round_13: 3391,
    app_flat_13: 160,
    char_14: 'Bronya',
    app_rate_14: 5.26,
    avg_round_14: 3220,
    app_flat_14: 96,
    char_15: 'trailblazer-imaginary',
    app_rate_15: 4.77,
    avg_round_15: 3486,
    app_flat_15: 60,
    char_16: 'black-swan',
    app_rate_16: 4.04,
    avg_round_16: 3140,
    app_flat_16: 44,
    char_17: 'Firefly',
    app_rate_17: 3.55,
    avg_round_17: 3555,
    app_flat_17: 36,
    char_18: 'Boothill',
    app_rate_18: 3.05,
    avg_round_18: 3285,
    app_flat_18: 63,
    char_19: 'Kafka',
    app_rate_19: 1.51,
    avg_round_19: 3171,
    app_flat_19: 11,
    char_20: 'Jade',
    app_rate_20: 1.37,
    avg_round_20: 3264,
    app_flat_20: 7,
    char_21: 'Himeko',
    app_rate_21: 1.16,
    avg_round_21: 3204,
    app_flat_21: 15,
    char_22: 'Guinaifen',
    app_rate_22: 0.87,
    avg_round_22: 3128,
    app_flat_22: 16,
    char_23: 'Asta',
    app_rate_23: 0.84,
    avg_round_23: 3220,
    app_flat_23: 18,
    char_24: 'Hanya',
    app_rate_24: 0.76,
    avg_round_24: 3300,
    app_flat_24: 8,
    char_25: 'Yukong',
    app_rate_25: 0.47,
    avg_round_25: 3237,
    app_flat_25: 3,
    char_26: 'Welt',
    app_rate_26: 0.44,
    avg_round_26: 3052,
    app_flat_26: 5,
    char_27: 'Blade',
    app_rate_27: 0.32,
    avg_round_27: 3232,
    app_flat_27: 4,
    char_28: 'Argenti',
    app_rate_28: 0.26,
    avg_round_28: 3402,
    app_flat_28: 3,
    char_29: 'Luocha',
    app_rate_29: 0.23,
    avg_round_29: 3193,
    app_flat_29: 1,
    char_30: 'Jingliu',
    app_rate_30: 0.23,
    avg_round_30: 3125,
    app_flat_30: 4
  },
  {
    char: 'Yunli',
    round: 3452,
    char_1: 'Robin',
    app_rate_1: 63.84,
    avg_round_1: 3406,
    app_flat_1: 617,
    char_2: 'Huohuo',
    app_rate_2: 48.68,
    avg_round_2: 3393,
    app_flat_2: 491,
    char_3: 'Tingyun',
    app_rate_3: 47.29,
    avg_round_3: 3365,
    app_flat_3: 543,
    char_4: 'Sparkle',
    app_rate_4: 34.66,
    avg_round_4: 3350,
    app_flat_4: 334,
    char_5: 'topaz',
    app_rate_5: 22.8,
    avg_round_5: 3394,
    app_flat_5: 206,
    char_6: 'fu-xuan',
    app_rate_6: 18.11,
    avg_round_6: 3356,
    app_flat_6: 171,
    char_7: 'Aventurine',
    app_rate_7: 16.61,
    avg_round_7: 3391,
    app_flat_7: 160,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 12.03,
    avg_round_8: 3368,
    app_flat_8: 125,
    char_9: 'Luocha',
    app_rate_9: 8.36,
    avg_round_9: 3312,
    app_flat_9: 87,
    char_10: 'Lynx',
    app_rate_10: 5.66,
    avg_round_10: 3245,
    app_flat_10: 64,
    char_11: 'Clara',
    app_rate_11: 5.05,
    avg_round_11: 3180,
    app_flat_11: 51,
    char_12: 'ruan-mei',
    app_rate_12: 2.77,
    avg_round_12: 3448,
    app_flat_12: 22,
    char_13: 'Pela',
    app_rate_13: 2.11,
    avg_round_13: 3330,
    app_flat_13: 23,
    char_14: 'Jade',
    app_rate_14: 1.44,
    avg_round_14: 3255,
    app_flat_14: 5,
    char_15: 'Bronya',
    app_rate_15: 1.38,
    avg_round_15: 3213,
    app_flat_15: 13,
    char_16: 'dr-ratio',
    app_rate_16: 1.32,
    avg_round_16: 3185,
    app_flat_16: 20,
    char_17: 'Gallagher',
    app_rate_17: 1.02,
    avg_round_17: 3392,
    app_flat_17: 10,
    char_18: 'Bailu',
    app_rate_18: 0.96,
    avg_round_18: 3378,
    app_flat_18: 12,
    char_19: 'Jingliu',
    app_rate_19: 0.72,
    avg_round_19: 3336,
    app_flat_19: 2,
    char_20: 'Blade',
    app_rate_20: 0.66,
    avg_round_20: 3067,
    app_flat_20: 2,
    char_21: 'Himeko',
    app_rate_21: 0.66,
    avg_round_21: 3187,
    app_flat_21: 8,
    char_22: 'Hanya',
    app_rate_22: 0.6,
    avg_round_22: 3403,
    app_flat_22: 6,
    char_23: 'silver-wolf',
    app_rate_23: 0.6,
    avg_round_23: 3187,
    app_flat_23: 4,
    char_24: 'Acheron',
    app_rate_24: 0.42,
    avg_round_24: 2980,
    app_flat_24: 1,
    char_25: 'Boothill',
    app_rate_25: 0.42,
    avg_round_25: 3182,
    app_flat_25: 5,
    char_26: 'trailblazer-imaginary',
    app_rate_26: 0.3,
    avg_round_26: 3287,
    app_flat_26: 2,
    char_27: 'Yukong',
    app_rate_27: 0.24,
    avg_round_27: 3183,
    app_flat_27: 2,
    char_28: 'imbibitor-lunae',
    app_rate_28: 0.24,
    avg_round_28: 3066,
    app_flat_28: 1,
    char_29: 'Argenti',
    app_rate_29: 0.24,
    avg_round_29: 3162,
    app_flat_29: 2,
    char_30: 'Natasha',
    app_rate_30: 0.18,
    avg_round_30: 3243,
    app_flat_30: 3
  },
  {
    char: 'topaz',
    round: 3446,
    char_1: 'Robin',
    app_rate_1: 75.56,
    avg_round_1: 3426,
    app_flat_1: 1112,
    char_2: 'Aventurine',
    app_rate_2: 67.3,
    avg_round_2: 3415,
    app_flat_2: 975,
    char_3: 'dr-ratio',
    app_rate_3: 63.82,
    avg_round_3: 3398,
    app_flat_3: 948,
    char_4: 'Yunli',
    app_rate_4: 14.63,
    avg_round_4: 3394,
    app_flat_4: 206,
    char_5: 'fu-xuan',
    app_rate_5: 13.28,
    avg_round_5: 3288,
    app_flat_5: 197,
    char_6: 'Huohuo',
    app_rate_6: 11.08,
    avg_round_6: 3336,
    app_flat_6: 148,
    char_7: 'Clara',
    app_rate_7: 10.93,
    avg_round_7: 3174,
    app_flat_7: 148,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 9.73,
    avg_round_8: 3345,
    app_flat_8: 168,
    char_9: 'Sparkle',
    app_rate_9: 6.6,
    avg_round_9: 3226,
    app_flat_9: 94,
    char_10: 'ruan-mei',
    app_rate_10: 5.25,
    avg_round_10: 3369,
    app_flat_10: 52,
    char_11: 'Luocha',
    app_rate_11: 5.17,
    avg_round_11: 3238,
    app_flat_11: 83,
    char_12: 'silver-wolf',
    app_rate_12: 3.17,
    avg_round_12: 3178,
    app_flat_12: 54,
    char_13: 'Tingyun',
    app_rate_13: 2.36,
    avg_round_13: 3233,
    app_flat_13: 34,
    char_14: 'Gallagher',
    app_rate_14: 1.54,
    avg_round_14: 3389,
    app_flat_14: 22,
    char_15: 'Jade',
    app_rate_15: 1.31,
    avg_round_15: 3152,
    app_flat_15: 2,
    char_16: 'Pela',
    app_rate_16: 1.16,
    avg_round_16: 3176,
    app_flat_16: 18,
    char_17: 'Bronya',
    app_rate_17: 1.0,
    avg_round_17: 3239,
    app_flat_17: 14,
    char_18: 'Asta',
    app_rate_18: 0.97,
    avg_round_18: 3249,
    app_flat_18: 13,
    char_19: 'Lynx',
    app_rate_19: 0.93,
    avg_round_19: 3306,
    app_flat_19: 14,
    char_20: 'Himeko',
    app_rate_20: 0.85,
    avg_round_20: 3283,
    app_flat_20: 4,
    char_21: 'trailblazer-imaginary',
    app_rate_21: 0.66,
    avg_round_21: 3335,
    app_flat_21: 8,
    char_22: 'Bailu',
    app_rate_22: 0.39,
    avg_round_22: 3303,
    app_flat_22: 7,
    char_23: 'Acheron',
    app_rate_23: 0.39,
    avg_round_23: 2958,
    app_flat_23: 1,
    char_24: 'Hanya',
    app_rate_24: 0.23,
    avg_round_24: 3386,
    app_flat_24: 2,
    char_25: 'Boothill',
    app_rate_25: 0.23,
    avg_round_25: 3534,
    app_flat_25: 1,
    char_26: 'Jingliu',
    app_rate_26: 0.23,
    avg_round_26: 3257,
    app_flat_26: 2,
    char_27: 'Blade',
    app_rate_27: 0.19,
    avg_round_27: 2878,
    app_flat_27: 1,
    char_28: 'Gepard',
    app_rate_28: 0.15,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Firefly',
    app_rate_29: 0.15,
    avg_round_29: 3422,
    app_flat_29: 1,
    char_30: 'imbibitor-lunae',
    app_rate_30: 0.15,
    avg_round_30: 3148,
    app_flat_30: 1
  },
  {
    char: 'Gepard',
    round: 3427,
    char_1: 'Acheron',
    app_rate_1: 73.91,
    avg_round_1: 3220,
    app_flat_1: 26,
    char_2: 'Pela',
    app_rate_2: 53.42,
    avg_round_2: 3236,
    app_flat_2: 20,
    char_3: 'silver-wolf',
    app_rate_3: 31.06,
    avg_round_3: 3231,
    app_flat_3: 19,
    char_4: 'ruan-mei',
    app_rate_4: 20.5,
    avg_round_4: 3533,
    app_flat_4: 15,
    char_5: 'Sparkle',
    app_rate_5: 19.88,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'trailblazer-imaginary',
    app_rate_6: 18.01,
    avg_round_6: 3527,
    app_flat_6: 16,
    char_7: 'black-swan',
    app_rate_7: 17.39,
    avg_round_7: 3192,
    app_flat_7: 7,
    char_8: 'Bronya',
    app_rate_8: 16.77,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Firefly',
    app_rate_9: 15.53,
    avg_round_9: 3550,
    app_flat_9: 12,
    char_10: 'Kafka',
    app_rate_10: 8.7,
    avg_round_10: 3220,
    app_flat_10: 5,
    char_11: 'Robin',
    app_rate_11: 6.21,
    avg_round_11: 3431,
    app_flat_11: 1,
    char_12: 'Boothill',
    app_rate_12: 3.11,
    avg_round_12: 3504,
    app_flat_12: 3,
    char_13: 'Jingliu',
    app_rate_13: 3.11,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'topaz',
    app_rate_14: 2.48,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Guinaifen',
    app_rate_15: 2.48,
    avg_round_15: 3017,
    app_flat_15: 1,
    char_16: 'dr-ratio',
    app_rate_16: 1.86,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Qingque',
    app_rate_17: 0.62,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Gallagher',
    app_rate_18: 0.62,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Yanqing',
    app_rate_19: 0.62,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'imbibitor-lunae',
    app_rate_20: 0.62,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Tingyun',
    app_rate_21: 0.62,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Luka',
    app_rate_22: 0.62,
    avg_round_22: 3321,
    app_flat_22: 1,
    char_23: 'Asta',
    app_rate_23: 0.62,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'march-7th-swordmaster',
    app_rate_24: 0.62,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Huohuo',
    app_rate_25: 0.62,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dr-ratio',
    round: 3426,
    char_1: 'Aventurine',
    app_rate_1: 70.44,
    avg_round_1: 3391,
    app_flat_1: 1151,
    char_2: 'Robin',
    app_rate_2: 63.06,
    avg_round_2: 3427,
    app_flat_2: 1019,
    char_3: 'topaz',
    app_rate_3: 62.57,
    avg_round_3: 3398,
    app_flat_3: 948,
    char_4: 'silver-wolf',
    app_rate_4: 18.85,
    avg_round_4: 3238,
    app_flat_4: 337,
    char_5: 'Sparkle',
    app_rate_5: 12.49,
    avg_round_5: 3228,
    app_flat_5: 209,
    char_6: 'fu-xuan',
    app_rate_6: 12.38,
    avg_round_6: 3228,
    app_flat_6: 199,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 11.66,
    avg_round_7: 3243,
    app_flat_7: 233,
    char_8: 'Huohuo',
    app_rate_8: 7.95,
    avg_round_8: 3221,
    app_flat_8: 132,
    char_9: 'Pela',
    app_rate_9: 5.9,
    avg_round_9: 3243,
    app_flat_9: 117,
    char_10: 'Tingyun',
    app_rate_10: 5.79,
    avg_round_10: 3246,
    app_flat_10: 100,
    char_11: 'Luocha',
    app_rate_11: 5.45,
    avg_round_11: 3204,
    app_flat_11: 94,
    char_12: 'ruan-mei',
    app_rate_12: 5.37,
    avg_round_12: 3341,
    app_flat_12: 69,
    char_13: 'Clara',
    app_rate_13: 5.0,
    avg_round_13: 3175,
    app_flat_13: 91,
    char_14: 'Bronya',
    app_rate_14: 3.03,
    avg_round_14: 3198,
    app_flat_14: 52,
    char_15: 'Gallagher',
    app_rate_15: 3.03,
    avg_round_15: 3294,
    app_flat_15: 53,
    char_16: 'black-swan',
    app_rate_16: 1.1,
    avg_round_16: 3178,
    app_flat_16: 18,
    char_17: 'Yunli',
    app_rate_17: 0.83,
    avg_round_17: 3185,
    app_flat_17: 20,
    char_18: 'Welt',
    app_rate_18: 0.76,
    avg_round_18: 3142,
    app_flat_18: 9,
    char_19: 'Asta',
    app_rate_19: 0.64,
    avg_round_19: 3274,
    app_flat_19: 11,
    char_20: 'Guinaifen',
    app_rate_20: 0.57,
    avg_round_20: 3206,
    app_flat_20: 9,
    char_21: 'trailblazer-imaginary',
    app_rate_21: 0.53,
    avg_round_21: 3250,
    app_flat_21: 10,
    char_22: 'Hanya',
    app_rate_22: 0.42,
    avg_round_22: 3248,
    app_flat_22: 6,
    char_23: 'Jade',
    app_rate_23: 0.34,
    avg_round_23: 3327,
    app_flat_23: 1,
    char_24: 'Bailu',
    app_rate_24: 0.3,
    avg_round_24: 3242,
    app_flat_24: 3,
    char_25: 'Himeko',
    app_rate_25: 0.26,
    avg_round_25: 3241,
    app_flat_25: 4,
    char_26: 'Boothill',
    app_rate_26: 0.23,
    avg_round_26: 3237,
    app_flat_26: 4,
    char_27: 'Lynx',
    app_rate_27: 0.23,
    avg_round_27: 3217,
    app_flat_27: 4,
    char_28: 'Blade',
    app_rate_28: 0.15,
    avg_round_28: 3200,
    app_flat_28: 4,
    char_29: 'Gepard',
    app_rate_29: 0.11,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'imbibitor-lunae',
    app_rate_30: 0.11,
    avg_round_30: 2867,
    app_flat_30: 1
  },
  {
    char: 'Huohuo',
    round: 3419,
    char_1: 'Robin',
    app_rate_1: 39.7,
    avg_round_1: 3360,
    app_flat_1: 461,
    char_2: 'Yunli',
    app_rate_2: 37.7,
    avg_round_2: 3393,
    app_flat_2: 491,
    char_3: 'Sparkle',
    app_rate_3: 33.08,
    avg_round_3: 3271,
    app_flat_3: 335,
    char_4: 'Tingyun',
    app_rate_4: 30.29,
    avg_round_4: 3338,
    app_flat_4: 385,
    char_5: 'ruan-mei',
    app_rate_5: 20.27,
    avg_round_5: 3443,
    app_flat_5: 146,
    char_6: 'trailblazer-imaginary',
    app_rate_6: 13.65,
    avg_round_6: 3495,
    app_flat_6: 102,
    char_7: 'topaz',
    app_rate_7: 13.37,
    avg_round_7: 3336,
    app_flat_7: 148,
    char_8: 'Firefly',
    app_rate_8: 12.4,
    avg_round_8: 3501,
    app_flat_8: 87,
    char_9: 'imbibitor-lunae',
    app_rate_9: 11.37,
    avg_round_9: 3142,
    app_flat_9: 75,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 11.23,
    avg_round_10: 3289,
    app_flat_10: 154,
    char_11: 'dr-ratio',
    app_rate_11: 9.79,
    avg_round_11: 3221,
    app_flat_11: 132,
    char_12: 'Bronya',
    app_rate_12: 9.32,
    avg_round_12: 3221,
    app_flat_12: 89,
    char_13: 'Clara',
    app_rate_13: 7.92,
    avg_round_13: 3145,
    app_flat_13: 100,
    char_14: 'Jingliu',
    app_rate_14: 7.83,
    avg_round_14: 3207,
    app_flat_14: 64,
    char_15: 'Pela',
    app_rate_15: 6.57,
    avg_round_15: 3204,
    app_flat_15: 69,
    char_16: 'black-swan',
    app_rate_16: 5.87,
    avg_round_16: 3075,
    app_flat_16: 21,
    char_17: 'silver-wolf',
    app_rate_17: 5.82,
    avg_round_17: 3197,
    app_flat_17: 60,
    char_18: 'Acheron',
    app_rate_18: 5.73,
    avg_round_18: 3173,
    app_flat_18: 26,
    char_19: 'Kafka',
    app_rate_19: 4.43,
    avg_round_19: 3081,
    app_flat_19: 14,
    char_20: 'Blade',
    app_rate_20: 3.03,
    avg_round_20: 3103,
    app_flat_20: 33,
    char_21: 'Boothill',
    app_rate_21: 3.03,
    avg_round_21: 3343,
    app_flat_21: 37,
    char_22: 'Argenti',
    app_rate_22: 1.86,
    avg_round_22: 3291,
    app_flat_22: 26,
    char_23: 'Hanya',
    app_rate_23: 0.98,
    avg_round_23: 3274,
    app_flat_23: 11,
    char_24: 'Asta',
    app_rate_24: 0.98,
    avg_round_24: 3223,
    app_flat_24: 12,
    char_25: 'Jade',
    app_rate_25: 0.89,
    avg_round_25: 3168,
    app_flat_25: 4,
    char_26: 'Himeko',
    app_rate_26: 0.56,
    avg_round_26: 3250,
    app_flat_26: 6,
    char_27: 'Guinaifen',
    app_rate_27: 0.51,
    avg_round_27: 3086,
    app_flat_27: 4,
    char_28: 'Sushang',
    app_rate_28: 0.37,
    avg_round_28: 3203,
    app_flat_28: 4,
    char_29: 'Welt',
    app_rate_29: 0.33,
    avg_round_29: 3161,
    app_flat_29: 6,
    char_30: 'fu-xuan',
    app_rate_30: 0.28,
    avg_round_30: 3189,
    app_flat_30: 3
  },
  {
    char: 'march-7th-swordmaster',
    round: 3413,
    char_1: 'Robin',
    app_rate_1: 47.29,
    avg_round_1: 3378,
    app_flat_1: 404,
    char_2: 'Aventurine',
    app_rate_2: 31.22,
    avg_round_2: 3332,
    app_flat_2: 273,
    char_3: 'dr-ratio',
    app_rate_3: 25.65,
    avg_round_3: 3243,
    app_flat_3: 233,
    char_4: 'topaz',
    app_rate_4: 20.98,
    avg_round_4: 3345,
    app_flat_4: 168,
    char_5: 'Huohuo',
    app_rate_5: 20.07,
    avg_round_5: 3289,
    app_flat_5: 154,
    char_6: 'fu-xuan',
    app_rate_6: 18.65,
    avg_round_6: 3255,
    app_flat_6: 143,
    char_7: 'Gallagher',
    app_rate_7: 18.65,
    avg_round_7: 3418,
    app_flat_7: 151,
    char_8: 'trailblazer-imaginary',
    app_rate_8: 18.65,
    avg_round_8: 3423,
    app_flat_8: 139,
    char_9: 'Yunli',
    app_rate_9: 16.65,
    avg_round_9: 3368,
    app_flat_9: 125,
    char_10: 'ruan-mei',
    app_rate_10: 16.32,
    avg_round_10: 3408,
    app_flat_10: 106,
    char_11: 'Sparkle',
    app_rate_11: 8.83,
    avg_round_11: 3152,
    app_flat_11: 56,
    char_12: 'Clara',
    app_rate_12: 8.41,
    avg_round_12: 3186,
    app_flat_12: 73,
    char_13: 'Luocha',
    app_rate_13: 7.49,
    avg_round_13: 3193,
    app_flat_13: 60,
    char_14: 'silver-wolf',
    app_rate_14: 6.91,
    avg_round_14: 3130,
    app_flat_14: 60,
    char_15: 'Bronya',
    app_rate_15: 5.75,
    avg_round_15: 3209,
    app_flat_15: 50,
    char_16: 'Firefly',
    app_rate_16: 5.58,
    avg_round_16: 3462,
    app_flat_16: 33,
    char_17: 'Tingyun',
    app_rate_17: 4.83,
    avg_round_17: 3173,
    app_flat_17: 42,
    char_18: 'imbibitor-lunae',
    app_rate_18: 4.33,
    avg_round_18: 3185,
    app_flat_18: 28,
    char_19: 'Boothill',
    app_rate_19: 2.66,
    avg_round_19: 3243,
    app_flat_19: 24,
    char_20: 'Blade',
    app_rate_20: 2.08,
    avg_round_20: 3107,
    app_flat_20: 18,
    char_21: 'Lynx',
    app_rate_21: 1.75,
    avg_round_21: 3100,
    app_flat_21: 13,
    char_22: 'Pela',
    app_rate_22: 1.42,
    avg_round_22: 3094,
    app_flat_22: 16,
    char_23: 'Sushang',
    app_rate_23: 1.08,
    avg_round_23: 3223,
    app_flat_23: 9,
    char_24: 'Asta',
    app_rate_24: 0.92,
    avg_round_24: 3300,
    app_flat_24: 6,
    char_25: 'Himeko',
    app_rate_25: 0.83,
    avg_round_25: 3233,
    app_flat_25: 6,
    char_26: 'Bailu',
    app_rate_26: 0.83,
    avg_round_26: 3200,
    app_flat_26: 6,
    char_27: 'Welt',
    app_rate_27: 0.42,
    avg_round_27: 2965,
    app_flat_27: 4,
    char_28: 'black-swan',
    app_rate_28: 0.25,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Hanya',
    app_rate_29: 0.25,
    avg_round_29: 3253,
    app_flat_29: 2,
    char_30: 'Jingliu',
    app_rate_30: 0.17,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Himeko',
    round: 3397,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 71.69,
    avg_round_1: 3395,
    app_flat_1: 132,
    char_2: 'ruan-mei',
    app_rate_2: 65.96,
    avg_round_2: 3395,
    app_flat_2: 109,
    char_3: 'Gallagher',
    app_rate_3: 63.86,
    avg_round_3: 3396,
    app_flat_3: 131,
    char_4: 'Firefly',
    app_rate_4: 20.78,
    avg_round_4: 3436,
    app_flat_4: 21,
    char_5: 'Robin',
    app_rate_5: 14.16,
    avg_round_5: 3275,
    app_flat_5: 18,
    char_6: 'Aventurine',
    app_rate_6: 12.05,
    avg_round_6: 3204,
    app_flat_6: 15,
    char_7: 'topaz',
    app_rate_7: 6.63,
    avg_round_7: 3283,
    app_flat_7: 4,
    char_8: 'fu-xuan',
    app_rate_8: 6.33,
    avg_round_8: 3217,
    app_flat_8: 6,
    char_9: 'Sparkle',
    app_rate_9: 4.52,
    avg_round_9: 3060,
    app_flat_9: 4,
    char_10: 'Jingliu',
    app_rate_10: 4.22,
    avg_round_10: 3206,
    app_flat_10: 4,
    char_11: 'Huohuo',
    app_rate_11: 3.61,
    avg_round_11: 3250,
    app_flat_11: 6,
    char_12: 'Yunli',
    app_rate_12: 3.31,
    avg_round_12: 3187,
    app_flat_12: 8,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 3.01,
    avg_round_13: 3233,
    app_flat_13: 6,
    char_14: 'Luocha',
    app_rate_14: 2.71,
    avg_round_14: 3290,
    app_flat_14: 1,
    char_15: 'Bronya',
    app_rate_15: 2.11,
    avg_round_15: 3182,
    app_flat_15: 2,
    char_16: 'dr-ratio',
    app_rate_16: 2.11,
    avg_round_16: 3241,
    app_flat_16: 4,
    char_17: 'Bailu',
    app_rate_17: 1.51,
    avg_round_17: 3176,
    app_flat_17: 1,
    char_18: 'Boothill',
    app_rate_18: 1.51,
    avg_round_18: 3182,
    app_flat_18: 4,
    char_19: 'Clara',
    app_rate_19: 1.51,
    avg_round_19: 2946,
    app_flat_19: 1,
    char_20: 'imbibitor-lunae',
    app_rate_20: 1.2,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Tingyun',
    app_rate_21: 1.2,
    avg_round_21: 3108,
    app_flat_21: 2,
    char_22: 'Asta',
    app_rate_22: 0.9,
    avg_round_22: 3084,
    app_flat_22: 2,
    char_23: 'Argenti',
    app_rate_23: 0.9,
    avg_round_23: 3010,
    app_flat_23: 1,
    char_24: 'Acheron',
    app_rate_24: 0.9,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'black-swan',
    app_rate_25: 0.9,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Lynx',
    app_rate_26: 0.9,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'silver-wolf',
    app_rate_27: 0.3,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Jade',
    app_rate_28: 0.3,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Herta',
    app_rate_29: 0.3,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Pela',
    app_rate_30: 0.3,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Bronya',
    round: 3390,
    char_1: 'ruan-mei',
    app_rate_1: 32.96,
    avg_round_1: 3416,
    app_flat_1: 197,
    char_2: 'Boothill',
    app_rate_2: 30.31,
    avg_round_2: 3387,
    app_flat_2: 280,
    char_3: 'Jingliu',
    app_rate_3: 28.08,
    avg_round_3: 3281,
    app_flat_3: 158,
    char_4: 'Gallagher',
    app_rate_4: 27.25,
    avg_round_4: 3452,
    app_flat_4: 221,
    char_5: 'Pela',
    app_rate_5: 20.28,
    avg_round_5: 3280,
    app_flat_5: 99,
    char_6: 'fu-xuan',
    app_rate_6: 18.33,
    avg_round_6: 3246,
    app_flat_6: 65,
    char_7: 'Luocha',
    app_rate_7: 16.45,
    avg_round_7: 3224,
    app_flat_7: 112,
    char_8: 'Huohuo',
    app_rate_8: 13.94,
    avg_round_8: 3221,
    app_flat_8: 89,
    char_9: 'Acheron',
    app_rate_9: 13.45,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Aventurine',
    app_rate_10: 12.61,
    avg_round_10: 3220,
    app_flat_10: 96,
    char_11: 'Blade',
    app_rate_11: 9.55,
    avg_round_11: 3109,
    app_flat_11: 61,
    char_12: 'trailblazer-imaginary',
    app_rate_12: 9.41,
    avg_round_12: 3450,
    app_flat_12: 37,
    char_13: 'Robin',
    app_rate_13: 9.13,
    avg_round_13: 3324,
    app_flat_13: 59,
    char_14: 'silver-wolf',
    app_rate_14: 8.5,
    avg_round_14: 3183,
    app_flat_14: 34,
    char_15: 'Sparkle',
    app_rate_15: 7.94,
    avg_round_15: 3166,
    app_flat_15: 48,
    char_16: 'Firefly',
    app_rate_16: 6.48,
    avg_round_16: 3410,
    app_flat_16: 6,
    char_17: 'dr-ratio',
    app_rate_17: 5.57,
    avg_round_17: 3198,
    app_flat_17: 52,
    char_18: 'march-7th-swordmaster',
    app_rate_18: 4.81,
    avg_round_18: 3209,
    app_flat_18: 50,
    char_19: 'imbibitor-lunae',
    app_rate_19: 4.18,
    avg_round_19: 3104,
    app_flat_19: 26,
    char_20: 'Tingyun',
    app_rate_20: 3.41,
    avg_round_20: 3191,
    app_flat_20: 25,
    char_21: 'Gepard',
    app_rate_21: 1.88,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'topaz',
    app_rate_22: 1.81,
    avg_round_22: 3239,
    app_flat_22: 14,
    char_23: 'Jade',
    app_rate_23: 1.81,
    avg_round_23: 3092,
    app_flat_23: 7,
    char_24: 'Clara',
    app_rate_24: 1.67,
    avg_round_24: 3159,
    app_flat_24: 7,
    char_25: 'Lynx',
    app_rate_25: 1.67,
    avg_round_25: 3187,
    app_flat_25: 9,
    char_26: 'Yunli',
    app_rate_26: 1.6,
    avg_round_26: 3213,
    app_flat_26: 13,
    char_27: 'black-swan',
    app_rate_27: 1.39,
    avg_round_27: 3104,
    app_flat_27: 2,
    char_28: 'Argenti',
    app_rate_28: 1.18,
    avg_round_28: 3231,
    app_flat_28: 5,
    char_29: 'Bailu',
    app_rate_29: 1.05,
    avg_round_29: 3149,
    app_flat_29: 8,
    char_30: 'Hanya',
    app_rate_30: 0.63,
    avg_round_30: 3270,
    app_flat_30: 6
  },
  {
    char: 'Yukong',
    round: 3390,
    char_1: 'imbibitor-lunae',
    app_rate_1: 81.82,
    avg_round_1: 3230,
    app_flat_1: 6,
    char_2: 'Aventurine',
    app_rate_2: 48.48,
    avg_round_2: 3237,
    app_flat_2: 3,
    char_3: 'Sparkle',
    app_rate_3: 45.45,
    avg_round_3: 3222,
    app_flat_3: 3,
    char_4: 'Luocha',
    app_rate_4: 30.3,
    avg_round_4: 3235,
    app_flat_4: 2,
    char_5: 'Huohuo',
    app_rate_5: 18.18,
    avg_round_5: 3276,
    app_flat_5: 2,
    char_6: 'Tingyun',
    app_rate_6: 15.15,
    avg_round_6: 3040,
    app_flat_6: 2,
    char_7: 'Yunli',
    app_rate_7: 12.12,
    avg_round_7: 3183,
    app_flat_7: 2,
    char_8: 'topaz',
    app_rate_8: 9.09,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Hanya',
    app_rate_9: 9.09,
    avg_round_9: 3322,
    app_flat_9: 2,
    char_10: 'Robin',
    app_rate_10: 9.09,
    avg_round_10: 3355,
    app_flat_10: 1,
    char_11: 'Bronya',
    app_rate_11: 6.06,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'ruan-mei',
    app_rate_12: 3.03,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 3.03,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Bailu',
    app_rate_14: 3.03,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'dr-ratio',
    app_rate_15: 3.03,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Gallagher',
    app_rate_16: 3.03,
    avg_round_16: 3011,
    app_flat_16: 1,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Guinaifen',
    round: 3386,
    char_1: 'Acheron',
    app_rate_1: 64.41,
    avg_round_1: 3092,
    app_flat_1: 32,
    char_2: 'fu-xuan',
    app_rate_2: 35.59,
    avg_round_2: 3091,
    app_flat_2: 19,
    char_3: 'Pela',
    app_rate_3: 34.75,
    avg_round_3: 3112,
    app_flat_3: 22,
    char_4: 'Aventurine',
    app_rate_4: 25.42,
    avg_round_4: 3128,
    app_flat_4: 16,
    char_5: 'black-swan',
    app_rate_5: 21.19,
    avg_round_5: 3048,
    app_flat_5: 8,
    char_6: 'Gallagher',
    app_rate_6: 18.64,
    avg_round_6: 3414,
    app_flat_6: 13,
    char_7: 'Kafka',
    app_rate_7: 12.71,
    avg_round_7: 3084,
    app_flat_7: 3,
    char_8: 'dr-ratio',
    app_rate_8: 12.71,
    avg_round_8: 3206,
    app_flat_8: 9,
    char_9: 'trailblazer-imaginary',
    app_rate_9: 11.86,
    avg_round_9: 3421,
    app_flat_9: 12,
    char_10: 'Huohuo',
    app_rate_10: 9.32,
    avg_round_10: 3086,
    app_flat_10: 4,
    char_11: 'ruan-mei',
    app_rate_11: 9.32,
    avg_round_11: 3411,
    app_flat_11: 8,
    char_12: 'silver-wolf',
    app_rate_12: 6.78,
    avg_round_12: 3086,
    app_flat_12: 4,
    char_13: 'Luocha',
    app_rate_13: 6.78,
    avg_round_13: 3202,
    app_flat_13: 3,
    char_14: 'Robin',
    app_rate_14: 6.78,
    avg_round_14: 3322,
    app_flat_14: 3,
    char_15: 'Sparkle',
    app_rate_15: 5.93,
    avg_round_15: 3411,
    app_flat_15: 1,
    char_16: 'Bronya',
    app_rate_16: 5.08,
    avg_round_16: 3076,
    app_flat_16: 2,
    char_17: 'Gepard',
    app_rate_17: 3.39,
    avg_round_17: 3017,
    app_flat_17: 1,
    char_18: 'topaz',
    app_rate_18: 2.54,
    avg_round_18: 3001,
    app_flat_18: 3,
    char_19: 'Firefly',
    app_rate_19: 1.69,
    avg_round_19: 3456,
    app_flat_19: 2,
    char_20: 'Tingyun',
    app_rate_20: 1.69,
    avg_round_20: 3318,
    app_flat_20: 1,
    char_21: 'Sushang',
    app_rate_21: 0.85,
    avg_round_21: 3473,
    app_flat_21: 1,
    char_22: 'Jingliu',
    app_rate_22: 0.85,
    avg_round_22: 2998,
    app_flat_22: 1,
    char_23: 'march-7th-swordmaster',
    app_rate_23: 0.85,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Clara',
    app_rate_24: 0.85,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sushang',
    round: 3379,
    char_1: 'ruan-mei',
    app_rate_1: 62.26,
    avg_round_1: 3394,
    app_flat_1: 21,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 52.83,
    avg_round_2: 3407,
    app_flat_2: 21,
    char_3: 'Gallagher',
    app_rate_3: 49.06,
    avg_round_3: 3434,
    app_flat_3: 19,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 24.53,
    avg_round_4: 3223,
    app_flat_4: 9,
    char_5: 'Robin',
    app_rate_5: 22.64,
    avg_round_5: 3317,
    app_flat_5: 6,
    char_6: 'Huohuo',
    app_rate_6: 15.09,
    avg_round_6: 3203,
    app_flat_6: 4,
    char_7: 'Aventurine',
    app_rate_7: 9.43,
    avg_round_7: 3264,
    app_flat_7: 3,
    char_8: 'fu-xuan',
    app_rate_8: 9.43,
    avg_round_8: 3193,
    app_flat_8: 1,
    char_9: 'Bronya',
    app_rate_9: 9.43,
    avg_round_9: 3104,
    app_flat_9: 5,
    char_10: 'Tingyun',
    app_rate_10: 7.55,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Luocha',
    app_rate_11: 7.55,
    avg_round_11: 3130,
    app_flat_11: 3,
    char_12: 'Sparkle',
    app_rate_12: 3.77,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Jingliu',
    app_rate_13: 3.77,
    avg_round_13: 3111,
    app_flat_13: 2,
    char_14: 'Hanya',
    app_rate_14: 1.89,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Jade',
    app_rate_15: 1.89,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Clara',
    app_rate_16: 1.89,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'dr-ratio',
    app_rate_17: 1.89,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Welt',
    app_rate_18: 1.89,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Asta',
    app_rate_19: 1.89,
    avg_round_19: 3324,
    app_flat_19: 1,
    char_20: 'Guinaifen',
    app_rate_20: 1.89,
    avg_round_20: 3473,
    app_flat_20: 1,
    char_21: 'Boothill',
    app_rate_21: 1.89,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'march-7th',
    app_rate_22: 1.89,
    avg_round_22: 3465,
    app_flat_22: 1,
    char_23: 'Lynx',
    app_rate_23: 1.89,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Bailu',
    app_rate_24: 1.89,
    avg_round_24: 3035,
    app_flat_24: 1,
    char_25: 'Natasha',
    app_rate_25: 1.89,
    avg_round_25: 3195,
    app_flat_25: 1,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Bailu',
    round: 3377,
    char_1: 'ruan-mei',
    app_rate_1: 40.0,
    avg_round_1: 3435,
    app_flat_1: 16,
    char_2: 'Robin',
    app_rate_2: 27.0,
    avg_round_2: 3300,
    app_flat_2: 18,
    char_3: 'Firefly',
    app_rate_3: 25.0,
    avg_round_3: 3472,
    app_flat_3: 12,
    char_4: 'trailblazer-imaginary',
    app_rate_4: 25.0,
    avg_round_4: 3480,
    app_flat_4: 13,
    char_5: 'Acheron',
    app_rate_5: 16.0,
    avg_round_5: 3113,
    app_flat_5: 3,
    char_6: 'Yunli',
    app_rate_6: 16.0,
    avg_round_6: 3378,
    app_flat_6: 12,
    char_7: 'Jingliu',
    app_rate_7: 16.0,
    avg_round_7: 3115,
    app_flat_7: 6,
    char_8: 'Bronya',
    app_rate_8: 15.0,
    avg_round_8: 3149,
    app_flat_8: 8,
    char_9: 'Sparkle',
    app_rate_9: 14.0,
    avg_round_9: 3344,
    app_flat_9: 4,
    char_10: 'black-swan',
    app_rate_10: 12.0,
    avg_round_10: 3113,
    app_flat_10: 3,
    char_11: 'Kafka',
    app_rate_11: 11.0,
    avg_round_11: 3113,
    app_flat_11: 3,
    char_12: 'Tingyun',
    app_rate_12: 10.0,
    avg_round_12: 3304,
    app_flat_12: 6,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 10.0,
    avg_round_13: 3200,
    app_flat_13: 6,
    char_14: 'topaz',
    app_rate_14: 10.0,
    avg_round_14: 3303,
    app_flat_14: 7,
    char_15: 'Pela',
    app_rate_15: 10.0,
    avg_round_15: 3134,
    app_flat_15: 2,
    char_16: 'imbibitor-lunae',
    app_rate_16: 8.0,
    avg_round_16: 2951,
    app_flat_16: 1,
    char_17: 'dr-ratio',
    app_rate_17: 8.0,
    avg_round_17: 3242,
    app_flat_17: 3,
    char_18: 'silver-wolf',
    app_rate_18: 8.0,
    avg_round_18: 3225,
    app_flat_18: 3,
    char_19: 'Clara',
    app_rate_19: 5.0,
    avg_round_19: 3153,
    app_flat_19: 4,
    char_20: 'Himeko',
    app_rate_20: 5.0,
    avg_round_20: 3176,
    app_flat_20: 1,
    char_21: 'Boothill',
    app_rate_21: 3.0,
    avg_round_21: 3375,
    app_flat_21: 3,
    char_22: 'Asta',
    app_rate_22: 2.0,
    avg_round_22: 3302,
    app_flat_22: 2,
    char_23: 'Hanya',
    app_rate_23: 1.0,
    avg_round_23: 3594,
    app_flat_23: 1,
    char_24: 'Blade',
    app_rate_24: 1.0,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Yukong',
    app_rate_25: 1.0,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Sushang',
    app_rate_26: 1.0,
    avg_round_26: 3035,
    app_flat_26: 1,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Acheron',
    round: 3376,
    char_1: 'Pela',
    app_rate_1: 60.11,
    avg_round_1: 3215,
    app_flat_1: 438,
    char_2: 'fu-xuan',
    app_rate_2: 55.44,
    avg_round_2: 3198,
    app_flat_2: 331,
    char_3: 'silver-wolf',
    app_rate_3: 48.75,
    avg_round_3: 3227,
    app_flat_3: 367,
    char_4: 'Sparkle',
    app_rate_4: 31.91,
    avg_round_4: 3150,
    app_flat_4: 20,
    char_5: 'black-swan',
    app_rate_5: 25.65,
    avg_round_5: 3141,
    app_flat_5: 161,
    char_6: 'Aventurine',
    app_rate_6: 19.92,
    avg_round_6: 3190,
    app_flat_6: 116,
    char_7: 'Kafka',
    app_rate_7: 13.28,
    avg_round_7: 3156,
    app_flat_7: 82,
    char_8: 'Bronya',
    app_rate_8: 9.29,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Gallagher',
    app_rate_9: 6.93,
    avg_round_9: 3256,
    app_flat_9: 40,
    char_10: 'Huohuo',
    app_rate_10: 5.92,
    avg_round_10: 3173,
    app_flat_10: 26,
    char_11: 'Gepard',
    app_rate_11: 5.73,
    avg_round_11: 3220,
    app_flat_11: 26,
    char_12: 'Guinaifen',
    app_rate_12: 3.66,
    avg_round_12: 3092,
    app_flat_12: 32,
    char_13: 'Robin',
    app_rate_13: 3.22,
    avg_round_13: 3237,
    app_flat_13: 5,
    char_14: 'Luocha',
    app_rate_14: 2.94,
    avg_round_14: 3142,
    app_flat_14: 13,
    char_15: 'ruan-mei',
    app_rate_15: 2.6,
    avg_round_15: 3120,
    app_flat_15: 1,
    char_16: 'Bailu',
    app_rate_16: 0.77,
    avg_round_16: 3113,
    app_flat_16: 3,
    char_17: 'Lynx',
    app_rate_17: 0.58,
    avg_round_17: 3225,
    app_flat_17: 2,
    char_18: 'Welt',
    app_rate_18: 0.53,
    avg_round_18: 3090,
    app_flat_18: 2,
    char_19: 'topaz',
    app_rate_19: 0.48,
    avg_round_19: 2958,
    app_flat_19: 1,
    char_20: 'Yunli',
    app_rate_20: 0.34,
    avg_round_20: 2980,
    app_flat_20: 1,
    char_21: 'Firefly',
    app_rate_21: 0.19,
    avg_round_21: 3393,
    app_flat_21: 1,
    char_22: 'Clara',
    app_rate_22: 0.19,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Asta',
    app_rate_23: 0.19,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Sampo',
    app_rate_24: 0.19,
    avg_round_24: 2980,
    app_flat_24: 2,
    char_25: 'Tingyun',
    app_rate_25: 0.14,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Himeko',
    app_rate_26: 0.14,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'dr-ratio',
    app_rate_27: 0.14,
    avg_round_27: 3321,
    app_flat_27: 1,
    char_28: 'Natasha',
    app_rate_28: 0.14,
    avg_round_28: 3053,
    app_flat_28: 1,
    char_29: 'Jingliu',
    app_rate_29: 0.1,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'march-7th-swordmaster',
    app_rate_30: 0.1,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Luocha',
    round: 3376,
    char_1: 'ruan-mei',
    app_rate_1: 33.57,
    avg_round_1: 3417,
    app_flat_1: 149,
    char_2: 'Robin',
    app_rate_2: 27.34,
    avg_round_2: 3242,
    app_flat_2: 179,
    char_3: 'Sparkle',
    app_rate_3: 22.71,
    avg_round_3: 3178,
    app_flat_3: 87,
    char_4: 'trailblazer-imaginary',
    app_rate_4: 22.35,
    avg_round_4: 3475,
    app_flat_4: 106,
    char_5: 'Bronya',
    app_rate_5: 21.02,
    avg_round_5: 3224,
    app_flat_5: 112,
    char_6: 'Firefly',
    app_rate_6: 19.68,
    avg_round_6: 3491,
    app_flat_6: 85,
    char_7: 'imbibitor-lunae',
    app_rate_7: 17.63,
    avg_round_7: 3128,
    app_flat_7: 41,
    char_8: 'Tingyun',
    app_rate_8: 16.65,
    avg_round_8: 3255,
    app_flat_8: 81,
    char_9: 'dr-ratio',
    app_rate_9: 12.82,
    avg_round_9: 3204,
    app_flat_9: 94,
    char_10: 'Yunli',
    app_rate_10: 12.38,
    avg_round_10: 3312,
    app_flat_10: 87,
    char_11: 'topaz',
    app_rate_11: 11.93,
    avg_round_11: 3238,
    app_flat_11: 83,
    char_12: 'Jingliu',
    app_rate_12: 11.13,
    avg_round_12: 3218,
    app_flat_12: 53,
    char_13: 'Blade',
    app_rate_13: 10.15,
    avg_round_13: 3130,
    app_flat_13: 48,
    char_14: 'march-7th-swordmaster',
    app_rate_14: 8.01,
    avg_round_14: 3193,
    app_flat_14: 60,
    char_15: 'Clara',
    app_rate_15: 7.93,
    avg_round_15: 3074,
    app_flat_15: 45,
    char_16: 'Pela',
    app_rate_16: 7.84,
    avg_round_16: 3188,
    app_flat_16: 44,
    char_17: 'silver-wolf',
    app_rate_17: 7.39,
    avg_round_17: 3173,
    app_flat_17: 36,
    char_18: 'Boothill',
    app_rate_18: 7.21,
    avg_round_18: 3321,
    app_flat_18: 57,
    char_19: 'Acheron',
    app_rate_19: 5.43,
    avg_round_19: 3142,
    app_flat_19: 13,
    char_20: 'black-swan',
    app_rate_20: 4.19,
    avg_round_20: 3153,
    app_flat_20: 9,
    char_21: 'Kafka',
    app_rate_21: 2.76,
    avg_round_21: 3161,
    app_flat_21: 4,
    char_22: 'Jade',
    app_rate_22: 1.87,
    avg_round_22: 3106,
    app_flat_22: 3,
    char_23: 'Hanya',
    app_rate_23: 1.42,
    avg_round_23: 3153,
    app_flat_23: 6,
    char_24: 'Asta',
    app_rate_24: 0.98,
    avg_round_24: 3233,
    app_flat_24: 4,
    char_25: 'Argenti',
    app_rate_25: 0.89,
    avg_round_25: 3305,
    app_flat_25: 3,
    char_26: 'Yukong',
    app_rate_26: 0.89,
    avg_round_26: 3235,
    app_flat_26: 2,
    char_27: 'Himeko',
    app_rate_27: 0.8,
    avg_round_27: 3290,
    app_flat_27: 1,
    char_28: 'Guinaifen',
    app_rate_28: 0.71,
    avg_round_28: 3202,
    app_flat_28: 3,
    char_29: 'Aventurine',
    app_rate_29: 0.71,
    avg_round_29: 3193,
    app_flat_29: 1,
    char_30: 'Welt',
    app_rate_30: 0.36,
    avg_round_30: 3215,
    app_flat_30: 1
  },
  {
    char: 'Asta',
    round: 3372,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 52.63,
    avg_round_1: 3426,
    app_flat_1: 44,
    char_2: 'Firefly',
    app_rate_2: 42.69,
    avg_round_2: 3464,
    app_flat_2: 32,
    char_3: 'Gallagher',
    app_rate_3: 36.84,
    avg_round_3: 3441,
    app_flat_3: 37,
    char_4: 'ruan-mei',
    app_rate_4: 17.54,
    avg_round_4: 3402,
    app_flat_4: 5,
    char_5: 'Aventurine',
    app_rate_5: 16.96,
    avg_round_5: 3220,
    app_flat_5: 18,
    char_6: 'topaz',
    app_rate_6: 14.62,
    avg_round_6: 3249,
    app_flat_6: 13,
    char_7: 'imbibitor-lunae',
    app_rate_7: 13.45,
    avg_round_7: 3078,
    app_flat_7: 13,
    char_8: 'Huohuo',
    app_rate_8: 12.28,
    avg_round_8: 3223,
    app_flat_8: 12,
    char_9: 'fu-xuan',
    app_rate_9: 11.7,
    avg_round_9: 3134,
    app_flat_9: 4,
    char_10: 'Sparkle',
    app_rate_10: 10.53,
    avg_round_10: 3107,
    app_flat_10: 10,
    char_11: 'dr-ratio',
    app_rate_11: 9.94,
    avg_round_11: 3274,
    app_flat_11: 11,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 6.43,
    avg_round_12: 3300,
    app_flat_12: 6,
    char_13: 'Luocha',
    app_rate_13: 6.43,
    avg_round_13: 3233,
    app_flat_13: 4,
    char_14: 'Jingliu',
    app_rate_14: 5.26,
    avg_round_14: 3260,
    app_flat_14: 4,
    char_15: 'Kafka',
    app_rate_15: 4.68,
    avg_round_15: 2978,
    app_flat_15: 1,
    char_16: 'black-swan',
    app_rate_16: 4.68,
    avg_round_16: 2978,
    app_flat_16: 1,
    char_17: 'Bronya',
    app_rate_17: 4.09,
    avg_round_17: 3082,
    app_flat_17: 3,
    char_18: 'Boothill',
    app_rate_18: 4.09,
    avg_round_18: 3277,
    app_flat_18: 5,
    char_19: 'Tingyun',
    app_rate_19: 4.09,
    avg_round_19: 3138,
    app_flat_19: 5,
    char_20: 'Pela',
    app_rate_20: 3.51,
    avg_round_20: 3355,
    app_flat_20: 2,
    char_21: 'Acheron',
    app_rate_21: 2.34,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Himeko',
    app_rate_22: 1.75,
    avg_round_22: 3084,
    app_flat_22: 2,
    char_23: 'Robin',
    app_rate_23: 1.75,
    avg_round_23: 3288,
    app_flat_23: 2,
    char_24: 'Lynx',
    app_rate_24: 1.75,
    avg_round_24: 3255,
    app_flat_24: 3,
    char_25: 'Clara',
    app_rate_25: 1.17,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Jade',
    app_rate_26: 1.17,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Natasha',
    app_rate_27: 1.17,
    avg_round_27: 3370,
    app_flat_27: 2,
    char_28: 'Yunli',
    app_rate_28: 1.17,
    avg_round_28: 3381,
    app_flat_28: 2,
    char_29: 'Hanya',
    app_rate_29: 1.17,
    avg_round_29: 3124,
    app_flat_29: 1,
    char_30: 'Bailu',
    app_rate_30: 1.17,
    avg_round_30: 3302,
    app_flat_30: 2
  },
  {
    char: 'Sparkle',
    round: 3370,
    char_1: 'fu-xuan',
    app_rate_1: 33.63,
    avg_round_1: 3188,
    app_flat_1: 219,
    char_2: 'Tingyun',
    app_rate_2: 27.42,
    avg_round_2: 3279,
    app_flat_2: 391,
    char_3: 'imbibitor-lunae',
    app_rate_3: 26.51,
    avg_round_3: 3173,
    app_flat_3: 242,
    char_4: 'Huohuo',
    app_rate_4: 24.89,
    avg_round_4: 3271,
    app_flat_4: 335,
    char_5: 'Aventurine',
    app_rate_5: 24.02,
    avg_round_5: 3243,
    app_flat_5: 282,
    char_6: 'Acheron',
    app_rate_6: 23.25,
    avg_round_6: 3150,
    app_flat_6: 20,
    char_7: 'silver-wolf',
    app_rate_7: 22.34,
    avg_round_7: 3189,
    app_flat_7: 145,
    char_8: 'Yunli',
    app_rate_8: 20.2,
    avg_round_8: 3350,
    app_flat_8: 334,
    char_9: 'Robin',
    app_rate_9: 12.34,
    avg_round_9: 3298,
    app_flat_9: 123,
    char_10: 'Pela',
    app_rate_10: 12.27,
    avg_round_10: 3152,
    app_flat_10: 28,
    char_11: 'dr-ratio',
    app_rate_11: 11.57,
    avg_round_11: 3228,
    app_flat_11: 209,
    char_12: 'Luocha',
    app_rate_12: 8.94,
    avg_round_12: 3178,
    app_flat_12: 87,
    char_13: 'topaz',
    app_rate_13: 6.0,
    avg_round_13: 3226,
    app_flat_13: 94,
    char_14: 'Clara',
    app_rate_14: 4.56,
    avg_round_14: 3003,
    app_flat_14: 66,
    char_15: 'ruan-mei',
    app_rate_15: 4.28,
    avg_round_15: 3276,
    app_flat_15: 30,
    char_16: 'Jingliu',
    app_rate_16: 4.24,
    avg_round_16: 3178,
    app_flat_16: 41,
    char_17: 'Seele',
    app_rate_17: 4.03,
    avg_round_17: 3055,
    app_flat_17: 18,
    char_18: 'Bronya',
    app_rate_18: 4.0,
    avg_round_18: 3166,
    app_flat_18: 48,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 3.72,
    avg_round_19: 3152,
    app_flat_19: 56,
    char_20: 'Gallagher',
    app_rate_20: 3.47,
    avg_round_20: 3255,
    app_flat_20: 33,
    char_21: 'Blade',
    app_rate_21: 3.4,
    avg_round_21: 3107,
    app_flat_21: 41,
    char_22: 'Lynx',
    app_rate_22: 2.24,
    avg_round_22: 3227,
    app_flat_22: 38,
    char_23: 'black-swan',
    app_rate_23: 1.86,
    avg_round_23: 3098,
    app_flat_23: 4,
    char_24: 'Argenti',
    app_rate_24: 1.86,
    avg_round_24: 3258,
    app_flat_24: 30,
    char_25: 'Qingque',
    app_rate_25: 1.68,
    avg_round_25: 3134,
    app_flat_25: 17,
    char_26: 'Boothill',
    app_rate_26: 1.16,
    avg_round_26: 3206,
    app_flat_26: 17,
    char_27: 'Gepard',
    app_rate_27: 1.12,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Asta',
    app_rate_28: 0.63,
    avg_round_28: 3107,
    app_flat_28: 10,
    char_29: 'trailblazer-imaginary',
    app_rate_29: 0.6,
    avg_round_29: 3345,
    app_flat_29: 8,
    char_30: 'Himeko',
    app_rate_30: 0.53,
    avg_round_30: 3060,
    app_flat_30: 4
  },
  {
    char: 'Jingliu',
    round: 3361,
    char_1: 'Bronya',
    app_rate_1: 62.58,
    avg_round_1: 3281,
    app_flat_1: 158,
    char_2: 'ruan-mei',
    app_rate_2: 28.42,
    avg_round_2: 3335,
    app_flat_2: 51,
    char_3: 'fu-xuan',
    app_rate_3: 26.24,
    avg_round_3: 3253,
    app_flat_3: 47,
    char_4: 'Huohuo',
    app_rate_4: 26.09,
    avg_round_4: 3207,
    app_flat_4: 64,
    char_5: 'Pela',
    app_rate_5: 25.31,
    avg_round_5: 3240,
    app_flat_5: 71,
    char_6: 'Robin',
    app_rate_6: 19.72,
    avg_round_6: 3320,
    app_flat_6: 52,
    char_7: 'Gallagher',
    app_rate_7: 19.57,
    avg_round_7: 3393,
    app_flat_7: 58,
    char_8: 'Luocha',
    app_rate_8: 19.41,
    avg_round_8: 3218,
    app_flat_8: 53,
    char_9: 'Sparkle',
    app_rate_9: 18.79,
    avg_round_9: 3178,
    app_flat_9: 41,
    char_10: 'Blade',
    app_rate_10: 18.48,
    avg_round_10: 3203,
    app_flat_10: 53,
    char_11: 'Tingyun',
    app_rate_11: 6.21,
    avg_round_11: 3263,
    app_flat_11: 12,
    char_12: 'silver-wolf',
    app_rate_12: 6.21,
    avg_round_12: 3227,
    app_flat_12: 12,
    char_13: 'Clara',
    app_rate_13: 5.28,
    avg_round_13: 3129,
    app_flat_13: 10,
    char_14: 'Bailu',
    app_rate_14: 2.48,
    avg_round_14: 3115,
    app_flat_14: 6,
    char_15: 'Lynx',
    app_rate_15: 2.33,
    avg_round_15: 3252,
    app_flat_15: 7,
    char_16: 'Himeko',
    app_rate_16: 2.17,
    avg_round_16: 3206,
    app_flat_16: 4,
    char_17: 'Yunli',
    app_rate_17: 1.86,
    avg_round_17: 3336,
    app_flat_17: 2,
    char_18: 'Asta',
    app_rate_18: 1.4,
    avg_round_18: 3260,
    app_flat_18: 4,
    char_19: 'Aventurine',
    app_rate_19: 1.24,
    avg_round_19: 3125,
    app_flat_19: 4,
    char_20: 'topaz',
    app_rate_20: 0.93,
    avg_round_20: 3257,
    app_flat_20: 2,
    char_21: 'Firefly',
    app_rate_21: 0.78,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Gepard',
    app_rate_22: 0.78,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Hanya',
    app_rate_23: 0.78,
    avg_round_23: 3248,
    app_flat_23: 2,
    char_24: 'trailblazer-imaginary',
    app_rate_24: 0.62,
    avg_round_24: 3378,
    app_flat_24: 2,
    char_25: 'Acheron',
    app_rate_25: 0.31,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'march-7th-swordmaster',
    app_rate_26: 0.31,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Boothill',
    app_rate_27: 0.31,
    avg_round_27: 3136,
    app_flat_27: 1,
    char_28: 'Sushang',
    app_rate_28: 0.31,
    avg_round_28: 3111,
    app_flat_28: 2,
    char_29: 'Jade',
    app_rate_29: 0.16,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Argenti',
    app_rate_30: 0.16,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Tingyun',
    round: 3359,
    char_1: 'Yunli',
    app_rate_1: 50.06,
    avg_round_1: 3365,
    app_flat_1: 543,
    char_2: 'Sparkle',
    app_rate_2: 49.81,
    avg_round_2: 3279,
    app_flat_2: 391,
    char_3: 'Huohuo',
    app_rate_3: 41.4,
    avg_round_3: 3338,
    app_flat_3: 385,
    char_4: 'imbibitor-lunae',
    app_rate_4: 29.17,
    avg_round_4: 3149,
    app_flat_4: 149,
    char_5: 'Robin',
    app_rate_5: 24.65,
    avg_round_5: 3425,
    app_flat_5: 246,
    char_6: 'Aventurine',
    app_rate_6: 20.83,
    avg_round_6: 3271,
    app_flat_6: 190,
    char_7: 'fu-xuan',
    app_rate_7: 16.24,
    avg_round_7: 3278,
    app_flat_7: 127,
    char_8: 'Luocha',
    app_rate_8: 11.91,
    avg_round_8: 3255,
    app_flat_8: 81,
    char_9: 'dr-ratio',
    app_rate_9: 9.75,
    avg_round_9: 3246,
    app_flat_9: 100,
    char_10: 'Argenti',
    app_rate_10: 4.78,
    avg_round_10: 3264,
    app_flat_10: 39,
    char_11: 'Gallagher',
    app_rate_11: 4.01,
    avg_round_11: 3301,
    app_flat_11: 33,
    char_12: 'ruan-mei',
    app_rate_12: 3.89,
    avg_round_12: 3361,
    app_flat_12: 20,
    char_13: 'topaz',
    app_rate_13: 3.89,
    avg_round_13: 3233,
    app_flat_13: 34,
    char_14: 'Pela',
    app_rate_14: 3.69,
    avg_round_14: 3263,
    app_flat_14: 38,
    char_15: 'march-7th-swordmaster',
    app_rate_15: 3.69,
    avg_round_15: 3173,
    app_flat_15: 42,
    char_16: 'silver-wolf',
    app_rate_16: 3.57,
    avg_round_16: 3242,
    app_flat_16: 35,
    char_17: 'Lynx',
    app_rate_17: 3.57,
    avg_round_17: 3225,
    app_flat_17: 42,
    char_18: 'Bronya',
    app_rate_18: 3.12,
    avg_round_18: 3191,
    app_flat_18: 25,
    char_19: 'Clara',
    app_rate_19: 2.8,
    avg_round_19: 2992,
    app_flat_19: 24,
    char_20: 'Jingliu',
    app_rate_20: 2.55,
    avg_round_20: 3263,
    app_flat_20: 12,
    char_21: 'Hanya',
    app_rate_21: 1.91,
    avg_round_21: 3232,
    app_flat_21: 10,
    char_22: 'Bailu',
    app_rate_22: 0.64,
    avg_round_22: 3304,
    app_flat_22: 6,
    char_23: 'black-swan',
    app_rate_23: 0.45,
    avg_round_23: 3227,
    app_flat_23: 1,
    char_24: 'trailblazer-imaginary',
    app_rate_24: 0.45,
    avg_round_24: 3229,
    app_flat_24: 5,
    char_25: 'Asta',
    app_rate_25: 0.45,
    avg_round_25: 3138,
    app_flat_25: 5,
    char_26: 'jing-yuan',
    app_rate_26: 0.38,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Yukong',
    app_rate_27: 0.32,
    avg_round_27: 3040,
    app_flat_27: 2,
    char_28: 'Welt',
    app_rate_28: 0.25,
    avg_round_28: 3166,
    app_flat_28: 3,
    char_29: 'Sushang',
    app_rate_29: 0.25,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Himeko',
    app_rate_30: 0.25,
    avg_round_30: 3108,
    app_flat_30: 2
  },
  {
    char: 'fu-xuan',
    round: 3356,
    char_1: 'Acheron',
    app_rate_1: 43.16,
    avg_round_1: 3198,
    app_flat_1: 331,
    char_2: 'silver-wolf',
    app_rate_2: 36.04,
    avg_round_2: 3193,
    app_flat_2: 339,
    char_3: 'Sparkle',
    app_rate_3: 35.93,
    avg_round_3: 3188,
    app_flat_3: 219,
    char_4: 'Pela',
    app_rate_4: 28.62,
    avg_round_4: 3217,
    app_flat_4: 294,
    char_5: 'Robin',
    app_rate_5: 18.96,
    avg_round_5: 3319,
    app_flat_5: 285,
    char_6: 'topaz',
    app_rate_6: 12.89,
    avg_round_6: 3288,
    app_flat_6: 197,
    char_7: 'dr-ratio',
    app_rate_7: 12.25,
    avg_round_7: 3228,
    app_flat_7: 199,
    char_8: 'ruan-mei',
    app_rate_8: 11.46,
    avg_round_8: 3421,
    app_flat_8: 110,
    char_9: 'Yunli',
    app_rate_9: 11.28,
    avg_round_9: 3356,
    app_flat_9: 171,
    char_10: 'Bronya',
    app_rate_10: 9.85,
    avg_round_10: 3246,
    app_flat_10: 65,
    char_11: 'black-swan',
    app_rate_11: 9.7,
    avg_round_11: 3142,
    app_flat_11: 84,
    char_12: 'Tingyun',
    app_rate_12: 9.55,
    avg_round_12: 3278,
    app_flat_12: 127,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 8.39,
    avg_round_13: 3255,
    app_flat_13: 143,
    char_14: 'trailblazer-imaginary',
    app_rate_14: 6.41,
    avg_round_14: 3511,
    app_flat_14: 69,
    char_15: 'Jingliu',
    app_rate_15: 6.33,
    avg_round_15: 3253,
    app_flat_15: 47,
    char_16: 'Clara',
    app_rate_16: 5.84,
    avg_round_16: 3116,
    app_flat_16: 75,
    char_17: 'Firefly',
    app_rate_17: 5.47,
    avg_round_17: 3553,
    app_flat_17: 50,
    char_18: 'Kafka',
    app_rate_18: 5.06,
    avg_round_18: 3154,
    app_flat_18: 41,
    char_19: 'Seele',
    app_rate_19: 4.42,
    avg_round_19: 3055,
    app_flat_19: 18,
    char_20: 'imbibitor-lunae',
    app_rate_20: 4.2,
    avg_round_20: 3108,
    app_flat_20: 33,
    char_21: 'Blade',
    app_rate_21: 2.81,
    avg_round_21: 3122,
    app_flat_21: 33,
    char_22: 'Boothill',
    app_rate_22: 2.66,
    avg_round_22: 3344,
    app_flat_22: 44,
    char_23: 'Qingque',
    app_rate_23: 1.76,
    avg_round_23: 3134,
    app_flat_23: 17,
    char_24: 'Guinaifen',
    app_rate_24: 1.57,
    avg_round_24: 3091,
    app_flat_24: 19,
    char_25: 'Argenti',
    app_rate_25: 1.24,
    avg_round_25: 3075,
    app_flat_25: 9,
    char_26: 'Jade',
    app_rate_26: 0.82,
    avg_round_26: 2949,
    app_flat_26: 2,
    char_27: 'Himeko',
    app_rate_27: 0.79,
    avg_round_27: 3217,
    app_flat_27: 6,
    char_28: 'Asta',
    app_rate_28: 0.75,
    avg_round_28: 3134,
    app_flat_28: 4,
    char_29: 'Hanya',
    app_rate_29: 0.67,
    avg_round_29: 3248,
    app_flat_29: 7,
    char_30: 'Huohuo',
    app_rate_30: 0.22,
    avg_round_30: 3189,
    app_flat_30: 3
  },
  {
    char: 'imbibitor-lunae',
    round: 3350,
    char_1: 'Sparkle',
    app_rate_1: 86.01,
    avg_round_1: 3173,
    app_flat_1: 242,
    char_2: 'Tingyun',
    app_rate_2: 52.1,
    avg_round_2: 3149,
    app_flat_2: 149,
    char_3: 'Aventurine',
    app_rate_3: 32.76,
    avg_round_3: 3209,
    app_flat_3: 118,
    char_4: 'Huohuo',
    app_rate_4: 27.76,
    avg_round_4: 3142,
    app_flat_4: 75,
    char_5: 'Luocha',
    app_rate_5: 22.53,
    avg_round_5: 3128,
    app_flat_5: 41,
    char_6: 'ruan-mei',
    app_rate_6: 13.77,
    avg_round_6: 3240,
    app_flat_6: 26,
    char_7: 'fu-xuan',
    app_rate_7: 12.74,
    avg_round_7: 3108,
    app_flat_7: 33,
    char_8: 'Robin',
    app_rate_8: 12.74,
    avg_round_8: 3233,
    app_flat_8: 29,
    char_9: 'Bronya',
    app_rate_9: 6.83,
    avg_round_9: 3104,
    app_flat_9: 26,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 5.92,
    avg_round_10: 3185,
    app_flat_10: 28,
    char_11: 'Hanya',
    app_rate_11: 4.78,
    avg_round_11: 3180,
    app_flat_11: 14,
    char_12: 'silver-wolf',
    app_rate_12: 3.75,
    avg_round_12: 3084,
    app_flat_12: 9,
    char_13: 'Gallagher',
    app_rate_13: 3.3,
    avg_round_13: 3148,
    app_flat_13: 10,
    char_14: 'Yukong',
    app_rate_14: 3.07,
    avg_round_14: 3230,
    app_flat_14: 6,
    char_15: 'Asta',
    app_rate_15: 2.62,
    avg_round_15: 3078,
    app_flat_15: 13,
    char_16: 'Pela',
    app_rate_16: 1.59,
    avg_round_16: 3047,
    app_flat_16: 3,
    char_17: 'Clara',
    app_rate_17: 1.37,
    avg_round_17: 3007,
    app_flat_17: 5,
    char_18: 'trailblazer-imaginary',
    app_rate_18: 1.02,
    avg_round_18: 3243,
    app_flat_18: 1,
    char_19: 'Blade',
    app_rate_19: 1.02,
    avg_round_19: 3150,
    app_flat_19: 1,
    char_20: 'Bailu',
    app_rate_20: 0.91,
    avg_round_20: 2951,
    app_flat_20: 1,
    char_21: 'Lynx',
    app_rate_21: 0.57,
    avg_round_21: 3042,
    app_flat_21: 1,
    char_22: 'Himeko',
    app_rate_22: 0.46,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Yunli',
    app_rate_23: 0.46,
    avg_round_23: 3066,
    app_flat_23: 1,
    char_24: 'topaz',
    app_rate_24: 0.46,
    avg_round_24: 3148,
    app_flat_24: 1,
    char_25: 'dr-ratio',
    app_rate_25: 0.34,
    avg_round_25: 2867,
    app_flat_25: 1,
    char_26: 'Welt',
    app_rate_26: 0.34,
    avg_round_26: 2989,
    app_flat_26: 2,
    char_27: 'black-swan',
    app_rate_27: 0.34,
    avg_round_27: 3059,
    app_flat_27: 1,
    char_28: 'Boothill',
    app_rate_28: 0.34,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Gepard',
    app_rate_29: 0.11,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Luka',
    round: 3348,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 52.0,
    avg_round_1: 3334,
    app_flat_1: 10,
    char_2: 'ruan-mei',
    app_rate_2: 48.0,
    avg_round_2: 3321,
    app_flat_2: 9,
    char_3: 'Gallagher',
    app_rate_3: 48.0,
    avg_round_3: 3346,
    app_flat_3: 6,
    char_4: 'Boothill',
    app_rate_4: 28.0,
    avg_round_4: 3118,
    app_flat_4: 4,
    char_5: 'Aventurine',
    app_rate_5: 24.0,
    avg_round_5: 3094,
    app_flat_5: 5,
    char_6: 'black-swan',
    app_rate_6: 16.0,
    avg_round_6: 3131,
    app_flat_6: 1,
    char_7: 'Bronya',
    app_rate_7: 12.0,
    avg_round_7: 3095,
    app_flat_7: 2,
    char_8: 'Huohuo',
    app_rate_8: 12.0,
    avg_round_8: 3243,
    app_flat_8: 1,
    char_9: 'dr-ratio',
    app_rate_9: 8.0,
    avg_round_9: 3217,
    app_flat_9: 1,
    char_10: 'Kafka',
    app_rate_10: 8.0,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 8.0,
    avg_round_11: 3084,
    app_flat_11: 2,
    char_12: 'Acheron',
    app_rate_12: 8.0,
    avg_round_12: 3131,
    app_flat_12: 1,
    char_13: 'Sparkle',
    app_rate_13: 4.0,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Asta',
    app_rate_14: 4.0,
    avg_round_14: 3237,
    app_flat_14: 1,
    char_15: 'Lynx',
    app_rate_15: 4.0,
    avg_round_15: 3237,
    app_flat_15: 1,
    char_16: 'Natasha',
    app_rate_16: 4.0,
    avg_round_16: 3370,
    app_flat_16: 1,
    char_17: 'Tingyun',
    app_rate_17: 4.0,
    avg_round_17: 3217,
    app_flat_17: 1,
    char_18: 'Gepard',
    app_rate_18: 4.0,
    avg_round_18: 3321,
    app_flat_18: 1,
    char_19: 'fu-xuan',
    app_rate_19: 4.0,
    avg_round_19: 3295,
    app_flat_19: 1,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'black-swan',
    round: 3347,
    char_1: 'Acheron',
    app_rate_1: 79.08,
    avg_round_1: 3141,
    app_flat_1: 161,
    char_2: 'Kafka',
    app_rate_2: 52.97,
    avg_round_2: 3147,
    app_flat_2: 84,
    char_3: 'fu-xuan',
    app_rate_3: 38.43,
    avg_round_3: 3142,
    app_flat_3: 84,
    char_4: 'Aventurine',
    app_rate_4: 20.62,
    avg_round_4: 3140,
    app_flat_4: 44,
    char_5: 'Pela',
    app_rate_5: 20.33,
    avg_round_5: 3151,
    app_flat_5: 62,
    char_6: 'Huohuo',
    app_rate_6: 18.69,
    avg_round_6: 3075,
    app_flat_6: 21,
    char_7: 'Gallagher',
    app_rate_7: 8.31,
    avg_round_7: 3218,
    app_flat_7: 17,
    char_8: 'ruan-mei',
    app_rate_8: 8.16,
    avg_round_8: 3207,
    app_flat_8: 4,
    char_9: 'Sparkle',
    app_rate_9: 7.86,
    avg_round_9: 3098,
    app_flat_9: 4,
    char_10: 'Robin',
    app_rate_10: 7.27,
    avg_round_10: 3352,
    app_flat_10: 6,
    char_11: 'Luocha',
    app_rate_11: 6.97,
    avg_round_11: 3153,
    app_flat_11: 9,
    char_12: 'silver-wolf',
    app_rate_12: 5.64,
    avg_round_12: 3070,
    app_flat_12: 13,
    char_13: 'dr-ratio',
    app_rate_13: 4.3,
    avg_round_13: 3178,
    app_flat_13: 18,
    char_14: 'Gepard',
    app_rate_14: 4.15,
    avg_round_14: 3192,
    app_flat_14: 7,
    char_15: 'Guinaifen',
    app_rate_15: 3.71,
    avg_round_15: 3048,
    app_flat_15: 8,
    char_16: 'Bronya',
    app_rate_16: 2.97,
    avg_round_16: 3104,
    app_flat_16: 2,
    char_17: 'Bailu',
    app_rate_17: 1.78,
    avg_round_17: 3113,
    app_flat_17: 3,
    char_18: 'Sampo',
    app_rate_18: 1.48,
    avg_round_18: 2939,
    app_flat_18: 3,
    char_19: 'Asta',
    app_rate_19: 1.19,
    avg_round_19: 2978,
    app_flat_19: 1,
    char_20: 'Tingyun',
    app_rate_20: 1.04,
    avg_round_20: 3227,
    app_flat_20: 1,
    char_21: 'trailblazer-imaginary',
    app_rate_21: 0.59,
    avg_round_21: 3309,
    app_flat_21: 1,
    char_22: 'Luka',
    app_rate_22: 0.59,
    avg_round_22: 3131,
    app_flat_22: 1,
    char_23: 'march-7th-swordmaster',
    app_rate_23: 0.45,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'imbibitor-lunae',
    app_rate_24: 0.45,
    avg_round_24: 3059,
    app_flat_24: 1,
    char_25: 'Himeko',
    app_rate_25: 0.45,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Clara',
    app_rate_26: 0.45,
    avg_round_26: 2800,
    app_flat_26: 1,
    char_27: 'topaz',
    app_rate_27: 0.45,
    avg_round_27: 3035,
    app_flat_27: 3,
    char_28: 'Lynx',
    app_rate_28: 0.45,
    avg_round_28: 3154,
    app_flat_28: 1,
    char_29: 'Welt',
    app_rate_29: 0.3,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Yunli',
    app_rate_30: 0.3,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Pela',
    round: 3346,
    char_1: 'Acheron',
    app_rate_1: 70.93,
    avg_round_1: 3215,
    app_flat_1: 438,
    char_2: 'fu-xuan',
    app_rate_2: 43.38,
    avg_round_2: 3217,
    app_flat_2: 294,
    char_3: 'silver-wolf',
    app_rate_3: 33.33,
    avg_round_3: 3236,
    app_flat_3: 339,
    char_4: 'Aventurine',
    app_rate_4: 21.52,
    avg_round_4: 3247,
    app_flat_4: 186,
    char_5: 'Sparkle',
    app_rate_5: 19.88,
    avg_round_5: 3152,
    app_flat_5: 28,
    char_6: 'Bronya',
    app_rate_6: 16.52,
    avg_round_6: 3280,
    app_flat_6: 99,
    char_7: 'Gallagher',
    app_rate_7: 12.95,
    avg_round_7: 3391,
    app_flat_7: 128,
    char_8: 'Jingliu',
    app_rate_8: 9.26,
    avg_round_8: 3240,
    app_flat_8: 71,
    char_9: 'dr-ratio',
    app_rate_9: 8.86,
    avg_round_9: 3243,
    app_flat_9: 117,
    char_10: 'Huohuo',
    app_rate_10: 8.01,
    avg_round_10: 3204,
    app_flat_10: 69,
    char_11: 'black-swan',
    app_rate_11: 7.78,
    avg_round_11: 3151,
    app_flat_11: 62,
    char_12: 'Robin',
    app_rate_12: 6.42,
    avg_round_12: 3380,
    app_flat_12: 52,
    char_13: 'trailblazer-imaginary',
    app_rate_13: 5.05,
    avg_round_13: 3479,
    app_flat_13: 59,
    char_14: 'Luocha',
    app_rate_14: 5.0,
    avg_round_14: 3188,
    app_flat_14: 44,
    char_15: 'Gepard',
    app_rate_15: 4.88,
    avg_round_15: 3236,
    app_flat_15: 20,
    char_16: 'Firefly',
    app_rate_16: 4.49,
    avg_round_16: 3483,
    app_flat_16: 50,
    char_17: 'Boothill',
    app_rate_17: 3.41,
    avg_round_17: 3367,
    app_flat_17: 45,
    char_18: 'ruan-mei',
    app_rate_18: 3.29,
    avg_round_18: 3319,
    app_flat_18: 7,
    char_19: 'Tingyun',
    app_rate_19: 3.29,
    avg_round_19: 3263,
    app_flat_19: 38,
    char_20: 'Guinaifen',
    app_rate_20: 2.33,
    avg_round_20: 3112,
    app_flat_20: 22,
    char_21: 'Yunli',
    app_rate_21: 1.99,
    avg_round_21: 3330,
    app_flat_21: 23,
    char_22: 'topaz',
    app_rate_22: 1.7,
    avg_round_22: 3176,
    app_flat_22: 18,
    char_23: 'march-7th-swordmaster',
    app_rate_23: 0.97,
    avg_round_23: 3094,
    app_flat_23: 16,
    char_24: 'imbibitor-lunae',
    app_rate_24: 0.8,
    avg_round_24: 3047,
    app_flat_24: 3,
    char_25: 'Lynx',
    app_rate_25: 0.74,
    avg_round_25: 3332,
    app_flat_25: 5,
    char_26: 'Bailu',
    app_rate_26: 0.57,
    avg_round_26: 3134,
    app_flat_26: 2,
    char_27: 'Kafka',
    app_rate_27: 0.45,
    avg_round_27: 3159,
    app_flat_27: 3,
    char_28: 'Clara',
    app_rate_28: 0.45,
    avg_round_28: 3175,
    app_flat_28: 1,
    char_29: 'Blade',
    app_rate_29: 0.45,
    avg_round_29: 3164,
    app_flat_29: 4,
    char_30: 'Asta',
    app_rate_30: 0.34,
    avg_round_30: 3355,
    app_flat_30: 2
  },
  {
    char: 'Lynx',
    round: 3346,
    char_1: 'Yunli',
    app_rate_1: 50.27,
    avg_round_1: 3245,
    app_flat_1: 64,
    char_2: 'Robin',
    app_rate_2: 34.76,
    avg_round_2: 3233,
    app_flat_2: 41,
    char_3: 'Sparkle',
    app_rate_3: 34.22,
    avg_round_3: 3227,
    app_flat_3: 38,
    char_4: 'Tingyun',
    app_rate_4: 29.95,
    avg_round_4: 3225,
    app_flat_4: 42,
    char_5: 'ruan-mei',
    app_rate_5: 19.79,
    avg_round_5: 3441,
    app_flat_5: 12,
    char_6: 'trailblazer-imaginary',
    app_rate_6: 14.97,
    avg_round_6: 3481,
    app_flat_6: 8,
    char_7: 'Firefly',
    app_rate_7: 14.44,
    avg_round_7: 3527,
    app_flat_7: 7,
    char_8: 'Bronya',
    app_rate_8: 12.83,
    avg_round_8: 3187,
    app_flat_8: 9,
    char_9: 'topaz',
    app_rate_9: 12.83,
    avg_round_9: 3306,
    app_flat_9: 14,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 11.23,
    avg_round_10: 3100,
    app_flat_10: 13,
    char_11: 'Blade',
    app_rate_11: 9.63,
    avg_round_11: 3185,
    app_flat_11: 5,
    char_12: 'Jingliu',
    app_rate_12: 8.02,
    avg_round_12: 3252,
    app_flat_12: 7,
    char_13: 'Pela',
    app_rate_13: 6.95,
    avg_round_13: 3332,
    app_flat_13: 5,
    char_14: 'Acheron',
    app_rate_14: 6.42,
    avg_round_14: 3225,
    app_flat_14: 2,
    char_15: 'silver-wolf',
    app_rate_15: 5.88,
    avg_round_15: 3296,
    app_flat_15: 1,
    char_16: 'Clara',
    app_rate_16: 5.35,
    avg_round_16: 3052,
    app_flat_16: 7,
    char_17: 'Boothill',
    app_rate_17: 3.74,
    avg_round_17: 3343,
    app_flat_17: 6,
    char_18: 'dr-ratio',
    app_rate_18: 3.21,
    avg_round_18: 3217,
    app_flat_18: 4,
    char_19: 'imbibitor-lunae',
    app_rate_19: 2.67,
    avg_round_19: 3042,
    app_flat_19: 1,
    char_20: 'Hanya',
    app_rate_20: 2.14,
    avg_round_20: 3061,
    app_flat_20: 2,
    char_21: 'Asta',
    app_rate_21: 1.6,
    avg_round_21: 3255,
    app_flat_21: 3,
    char_22: 'Himeko',
    app_rate_22: 1.6,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Jade',
    app_rate_23: 1.6,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'black-swan',
    app_rate_24: 1.6,
    avg_round_24: 3154,
    app_flat_24: 1,
    char_25: 'fu-xuan',
    app_rate_25: 1.07,
    avg_round_25: 2965,
    app_flat_25: 1,
    char_26: 'Kafka',
    app_rate_26: 1.07,
    avg_round_26: 3154,
    app_flat_26: 1,
    char_27: 'Argenti',
    app_rate_27: 0.53,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Luka',
    app_rate_28: 0.53,
    avg_round_28: 3237,
    app_flat_28: 1,
    char_29: 'Sushang',
    app_rate_29: 0.53,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Seele',
    app_rate_30: 0.53,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Natasha',
    round: 3338,
    char_1: 'ruan-mei',
    app_rate_1: 44.44,
    avg_round_1: 3388,
    app_flat_1: 4,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 38.89,
    avg_round_2: 3412,
    app_flat_2: 4,
    char_3: 'Firefly',
    app_rate_3: 27.78,
    avg_round_3: 3542,
    app_flat_3: 2,
    char_4: 'Pela',
    app_rate_4: 22.22,
    avg_round_4: 3073,
    app_flat_4: 2,
    char_5: 'Acheron',
    app_rate_5: 16.67,
    avg_round_5: 3053,
    app_flat_5: 1,
    char_6: 'Robin',
    app_rate_6: 16.67,
    avg_round_6: 3182,
    app_flat_6: 3,
    char_7: 'Yunli',
    app_rate_7: 16.67,
    avg_round_7: 3243,
    app_flat_7: 3,
    char_8: 'dr-ratio',
    app_rate_8: 11.11,
    avg_round_8: 3103,
    app_flat_8: 2,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 11.11,
    avg_round_9: 3164,
    app_flat_9: 2,
    char_10: 'Asta',
    app_rate_10: 11.11,
    avg_round_10: 3370,
    app_flat_10: 2,
    char_11: 'Blade',
    app_rate_11: 11.11,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Bronya',
    app_rate_12: 11.11,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: 'Tingyun',
    app_rate_13: 11.11,
    avg_round_13: 3257,
    app_flat_13: 2,
    char_14: 'silver-wolf',
    app_rate_14: 11.11,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'black-swan',
    app_rate_15: 5.56,
    avg_round_15: 3053,
    app_flat_15: 1,
    char_16: 'Hanya',
    app_rate_16: 5.56,
    avg_round_16: 3093,
    app_flat_16: 1,
    char_17: 'Luka',
    app_rate_17: 5.56,
    avg_round_17: 3370,
    app_flat_17: 1,
    char_18: 'Sparkle',
    app_rate_18: 5.56,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Jingliu',
    app_rate_19: 5.56,
    avg_round_19: 3348,
    app_flat_19: 1,
    char_20: 'Himeko',
    app_rate_20: 5.56,
    avg_round_20: 3348,
    app_flat_20: 1,
    char_21: 'Sushang',
    app_rate_21: 5.56,
    avg_round_21: 3195,
    app_flat_21: 1,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Kafka',
    round: 3334,
    char_1: 'black-swan',
    app_rate_1: 95.2,
    avg_round_1: 3147,
    app_flat_1: 84,
    char_2: 'Acheron',
    app_rate_2: 73.6,
    avg_round_2: 3156,
    app_flat_2: 82,
    char_3: 'fu-xuan',
    app_rate_3: 36.0,
    avg_round_3: 3154,
    app_flat_3: 41,
    char_4: 'Huohuo',
    app_rate_4: 25.33,
    avg_round_4: 3081,
    app_flat_4: 14,
    char_5: 'Aventurine',
    app_rate_5: 13.87,
    avg_round_5: 3171,
    app_flat_5: 11,
    char_6: 'ruan-mei',
    app_rate_6: 12.53,
    avg_round_6: 3104,
    app_flat_6: 2,
    char_7: 'Luocha',
    app_rate_7: 8.27,
    avg_round_7: 3161,
    app_flat_7: 4,
    char_8: 'Gallagher',
    app_rate_8: 8.0,
    avg_round_8: 3183,
    app_flat_8: 9,
    char_9: 'Robin',
    app_rate_9: 6.4,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Guinaifen',
    app_rate_10: 4.0,
    avg_round_10: 3084,
    app_flat_10: 3,
    char_11: 'Gepard',
    app_rate_11: 3.73,
    avg_round_11: 3220,
    app_flat_11: 5,
    char_12: 'Bailu',
    app_rate_12: 2.93,
    avg_round_12: 3113,
    app_flat_12: 3,
    char_13: 'Pela',
    app_rate_13: 2.13,
    avg_round_13: 3159,
    app_flat_13: 3,
    char_14: 'Asta',
    app_rate_14: 2.13,
    avg_round_14: 2978,
    app_flat_14: 1,
    char_15: 'silver-wolf',
    app_rate_15: 1.87,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Sampo',
    app_rate_16: 1.6,
    avg_round_16: 2857,
    app_flat_16: 1,
    char_17: 'Sparkle',
    app_rate_17: 0.53,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Luka',
    app_rate_18: 0.53,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Lynx',
    app_rate_19: 0.53,
    avg_round_19: 3154,
    app_flat_19: 1,
    char_20: 'march-7th-swordmaster',
    app_rate_20: 0.27,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Clara',
    app_rate_21: 0.27,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'Tingyun',
    app_rate_22: 0.27,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    round: 3307,
    char_1: 'Acheron',
    app_rate_1: 56.06,
    avg_round_1: 3227,
    app_flat_1: 367,
    char_2: 'fu-xuan',
    app_rate_2: 53.24,
    avg_round_2: 3193,
    app_flat_2: 339,
    char_3: 'Sparkle',
    app_rate_3: 35.25,
    avg_round_3: 3189,
    app_flat_3: 145,
    char_4: 'Pela',
    app_rate_4: 32.48,
    avg_round_4: 3236,
    app_flat_4: 339,
    char_5: 'dr-ratio',
    app_rate_5: 27.56,
    avg_round_5: 3238,
    app_flat_5: 337,
    char_6: 'Aventurine',
    app_rate_6: 25.84,
    avg_round_6: 3266,
    app_flat_6: 285,
    char_7: 'Robin',
    app_rate_7: 8.02,
    avg_round_7: 3381,
    app_flat_7: 83,
    char_8: 'Huohuo',
    app_rate_8: 6.92,
    avg_round_8: 3197,
    app_flat_8: 60,
    char_9: 'Bronya',
    app_rate_9: 6.75,
    avg_round_9: 3183,
    app_flat_9: 34,
    char_10: 'Seele',
    app_rate_10: 6.59,
    avg_round_10: 3055,
    app_flat_10: 18,
    char_11: 'Gallagher',
    app_rate_11: 4.81,
    avg_round_11: 3272,
    app_flat_11: 37,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 4.59,
    avg_round_12: 3130,
    app_flat_12: 60,
    char_13: 'Luocha',
    app_rate_13: 4.59,
    avg_round_13: 3173,
    app_flat_13: 36,
    char_14: 'topaz',
    app_rate_14: 4.54,
    avg_round_14: 3178,
    app_flat_14: 54,
    char_15: 'Tingyun',
    app_rate_15: 3.1,
    avg_round_15: 3242,
    app_flat_15: 35,
    char_16: 'Gepard',
    app_rate_16: 2.77,
    avg_round_16: 3231,
    app_flat_16: 19,
    char_17: 'Qingque',
    app_rate_17: 2.66,
    avg_round_17: 3134,
    app_flat_17: 17,
    char_18: 'Jingliu',
    app_rate_18: 2.21,
    avg_round_18: 3227,
    app_flat_18: 12,
    char_19: 'black-swan',
    app_rate_19: 2.1,
    avg_round_19: 3070,
    app_flat_19: 13,
    char_20: 'imbibitor-lunae',
    app_rate_20: 1.83,
    avg_round_20: 3084,
    app_flat_20: 9,
    char_21: 'ruan-mei',
    app_rate_21: 1.77,
    avg_round_21: 3209,
    app_flat_21: 5,
    char_22: 'Boothill',
    app_rate_22: 0.89,
    avg_round_22: 3209,
    app_flat_22: 11,
    char_23: 'trailblazer-imaginary',
    app_rate_23: 0.77,
    avg_round_23: 3363,
    app_flat_23: 3,
    char_24: 'Lynx',
    app_rate_24: 0.61,
    avg_round_24: 3296,
    app_flat_24: 1,
    char_25: 'Firefly',
    app_rate_25: 0.61,
    avg_round_25: 3391,
    app_flat_25: 2,
    char_26: 'Yunli',
    app_rate_26: 0.55,
    avg_round_26: 3187,
    app_flat_26: 4,
    char_27: 'Guinaifen',
    app_rate_27: 0.44,
    avg_round_27: 3086,
    app_flat_27: 4,
    char_28: 'Bailu',
    app_rate_28: 0.44,
    avg_round_28: 3225,
    app_flat_28: 3,
    char_29: 'Blade',
    app_rate_29: 0.39,
    avg_round_29: 3020,
    app_flat_29: 3,
    char_30: 'Kafka',
    app_rate_30: 0.39,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Argenti',
    round: 3305,
    char_1: 'Tingyun',
    app_rate_1: 68.81,
    avg_round_1: 3264,
    app_flat_1: 39,
    char_2: 'Sparkle',
    app_rate_2: 48.62,
    avg_round_2: 3258,
    app_flat_2: 30,
    char_3: 'Huohuo',
    app_rate_3: 36.7,
    avg_round_3: 3291,
    app_flat_3: 26,
    char_4: 'fu-xuan',
    app_rate_4: 30.28,
    avg_round_4: 3075,
    app_flat_4: 9,
    char_5: 'ruan-mei',
    app_rate_5: 19.27,
    avg_round_5: 3191,
    app_flat_5: 7,
    char_6: 'Robin',
    app_rate_6: 17.43,
    avg_round_6: 3381,
    app_flat_6: 7,
    char_7: 'Bronya',
    app_rate_7: 15.6,
    avg_round_7: 3231,
    app_flat_7: 5,
    char_8: 'Gallagher',
    app_rate_8: 14.68,
    avg_round_8: 3202,
    app_flat_8: 7,
    char_9: 'Luocha',
    app_rate_9: 9.17,
    avg_round_9: 3305,
    app_flat_9: 3,
    char_10: 'Hanya',
    app_rate_10: 9.17,
    avg_round_10: 3388,
    app_flat_10: 1,
    char_11: 'Aventurine',
    app_rate_11: 8.26,
    avg_round_11: 3402,
    app_flat_11: 3,
    char_12: 'trailblazer-imaginary',
    app_rate_12: 5.5,
    avg_round_12: 3103,
    app_flat_12: 3,
    char_13: 'Yunli',
    app_rate_13: 3.67,
    avg_round_13: 3162,
    app_flat_13: 2,
    char_14: 'Himeko',
    app_rate_14: 2.75,
    avg_round_14: 3010,
    app_flat_14: 1,
    char_15: 'Clara',
    app_rate_15: 1.83,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Firefly',
    app_rate_16: 0.92,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'Lynx',
    app_rate_17: 0.92,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Jingliu',
    app_rate_18: 0.92,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'Blade',
    app_rate_19: 0.92,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Pela',
    app_rate_20: 0.92,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'Boothill',
    app_rate_21: 0.92,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'silver-wolf',
    app_rate_22: 0.92,
    avg_round_22: 0,
    app_flat_22: 0,
    char_23: 'Jade',
    app_rate_23: 0.92,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'topaz',
    app_rate_24: 0.92,
    avg_round_24: 3513,
    app_flat_24: 1,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hanya',
    round: 3303,
    char_1: 'imbibitor-lunae',
    app_rate_1: 46.15,
    avg_round_1: 3180,
    app_flat_1: 14,
    char_2: 'Tingyun',
    app_rate_2: 32.97,
    avg_round_2: 3232,
    app_flat_2: 10,
    char_3: 'Aventurine',
    app_rate_3: 28.57,
    avg_round_3: 3300,
    app_flat_3: 8,
    char_4: 'Huohuo',
    app_rate_4: 23.08,
    avg_round_4: 3274,
    app_flat_4: 11,
    char_5: 'fu-xuan',
    app_rate_5: 19.78,
    avg_round_5: 3248,
    app_flat_5: 7,
    char_6: 'Luocha',
    app_rate_6: 17.58,
    avg_round_6: 3153,
    app_flat_6: 6,
    char_7: 'Sparkle',
    app_rate_7: 13.19,
    avg_round_7: 3213,
    app_flat_7: 3,
    char_8: 'dr-ratio',
    app_rate_8: 12.09,
    avg_round_8: 3248,
    app_flat_8: 6,
    char_9: 'Yunli',
    app_rate_9: 10.99,
    avg_round_9: 3403,
    app_flat_9: 6,
    char_10: 'Argenti',
    app_rate_10: 10.99,
    avg_round_10: 3388,
    app_flat_10: 1,
    char_11: 'Bronya',
    app_rate_11: 9.89,
    avg_round_11: 3270,
    app_flat_11: 6,
    char_12: 'ruan-mei',
    app_rate_12: 8.79,
    avg_round_12: 3232,
    app_flat_12: 4,
    char_13: 'topaz',
    app_rate_13: 6.59,
    avg_round_13: 3386,
    app_flat_13: 2,
    char_14: 'Boothill',
    app_rate_14: 6.59,
    avg_round_14: 3312,
    app_flat_14: 6,
    char_15: 'Robin',
    app_rate_15: 5.49,
    avg_round_15: 3265,
    app_flat_15: 4,
    char_16: 'trailblazer-imaginary',
    app_rate_16: 5.49,
    avg_round_16: 3499,
    app_flat_16: 3,
    char_17: 'Jingliu',
    app_rate_17: 5.49,
    avg_round_17: 3248,
    app_flat_17: 2,
    char_18: 'Gallagher',
    app_rate_18: 5.49,
    avg_round_18: 3546,
    app_flat_18: 2,
    char_19: 'Lynx',
    app_rate_19: 4.4,
    avg_round_19: 3061,
    app_flat_19: 2,
    char_20: 'Pela',
    app_rate_20: 4.4,
    avg_round_20: 3093,
    app_flat_20: 1,
    char_21: 'silver-wolf',
    app_rate_21: 3.3,
    avg_round_21: 3132,
    app_flat_21: 2,
    char_22: 'Yukong',
    app_rate_22: 3.3,
    avg_round_22: 3322,
    app_flat_22: 2,
    char_23: 'march-7th-swordmaster',
    app_rate_23: 3.3,
    avg_round_23: 3253,
    app_flat_23: 2,
    char_24: 'Firefly',
    app_rate_24: 2.2,
    avg_round_24: 3533,
    app_flat_24: 1,
    char_25: 'Acheron',
    app_rate_25: 2.2,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Asta',
    app_rate_26: 2.2,
    avg_round_26: 3124,
    app_flat_26: 1,
    char_27: 'Sushang',
    app_rate_27: 1.1,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Bailu',
    app_rate_28: 1.1,
    avg_round_28: 3594,
    app_flat_28: 1,
    char_29: 'Jade',
    app_rate_29: 1.1,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Blade',
    app_rate_30: 1.1,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Blade',
    round: 3264,
    char_1: 'Bronya',
    app_rate_1: 44.92,
    avg_round_1: 3109,
    app_flat_1: 61,
    char_2: 'Jingliu',
    app_rate_2: 39.02,
    avg_round_2: 3203,
    app_flat_2: 53,
    char_3: 'Luocha',
    app_rate_3: 37.38,
    avg_round_3: 3130,
    app_flat_3: 48,
    char_4: 'Sparkle',
    app_rate_4: 31.8,
    avg_round_4: 3107,
    app_flat_4: 41,
    char_5: 'fu-xuan',
    app_rate_5: 24.59,
    avg_round_5: 3122,
    app_flat_5: 33,
    char_6: 'Huohuo',
    app_rate_6: 21.31,
    avg_round_6: 3103,
    app_flat_6: 33,
    char_7: 'Robin',
    app_rate_7: 20.33,
    avg_round_7: 3133,
    app_flat_7: 31,
    char_8: 'ruan-mei',
    app_rate_8: 16.39,
    avg_round_8: 3262,
    app_flat_8: 16,
    char_9: 'Jade',
    app_rate_9: 14.75,
    avg_round_9: 3092,
    app_flat_9: 9,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 8.2,
    avg_round_10: 3107,
    app_flat_10: 18,
    char_11: 'Clara',
    app_rate_11: 7.21,
    avg_round_11: 2992,
    app_flat_11: 15,
    char_12: 'Lynx',
    app_rate_12: 5.9,
    avg_round_12: 3185,
    app_flat_12: 5,
    char_13: 'Gallagher',
    app_rate_13: 5.25,
    avg_round_13: 3301,
    app_flat_13: 8,
    char_14: 'Aventurine',
    app_rate_14: 3.61,
    avg_round_14: 3232,
    app_flat_14: 4,
    char_15: 'Yunli',
    app_rate_15: 3.61,
    avg_round_15: 3067,
    app_flat_15: 2,
    char_16: 'imbibitor-lunae',
    app_rate_16: 2.95,
    avg_round_16: 3150,
    app_flat_16: 1,
    char_17: 'Pela',
    app_rate_17: 2.62,
    avg_round_17: 3164,
    app_flat_17: 4,
    char_18: 'silver-wolf',
    app_rate_18: 2.3,
    avg_round_18: 3020,
    app_flat_18: 3,
    char_19: 'topaz',
    app_rate_19: 1.64,
    avg_round_19: 2878,
    app_flat_19: 1,
    char_20: 'dr-ratio',
    app_rate_20: 1.31,
    avg_round_20: 3200,
    app_flat_20: 4,
    char_21: 'Tingyun',
    app_rate_21: 0.98,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: 'trailblazer-imaginary',
    app_rate_22: 0.98,
    avg_round_22: 3410,
    app_flat_22: 3,
    char_23: 'Boothill',
    app_rate_23: 0.66,
    avg_round_23: 0,
    app_flat_23: 0,
    char_24: 'Natasha',
    app_rate_24: 0.66,
    avg_round_24: 0,
    app_flat_24: 0,
    char_25: 'Bailu',
    app_rate_25: 0.33,
    avg_round_25: 0,
    app_flat_25: 0,
    char_26: 'Argenti',
    app_rate_26: 0.33,
    avg_round_26: 0,
    app_flat_26: 0,
    char_27: 'Hanya',
    app_rate_27: 0.33,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'Asta',
    app_rate_28: 0.33,
    avg_round_28: 0,
    app_flat_28: 0,
    char_29: 'Acheron',
    app_rate_29: 0.33,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingque',
    round: 3248,
    char_1: 'silver-wolf',
    app_rate_1: 100.0,
    avg_round_1: 3134,
    app_flat_1: 17,
    char_2: 'Sparkle',
    app_rate_2: 100.0,
    avg_round_2: 3134,
    app_flat_2: 17,
    char_3: 'fu-xuan',
    app_rate_3: 97.92,
    avg_round_3: 3134,
    app_flat_3: 17,
    char_4: 'Gepard',
    app_rate_4: 2.08,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seele',
    round: 3238,
    char_1: 'silver-wolf',
    app_rate_1: 100.0,
    avg_round_1: 3055,
    app_flat_1: 18,
    char_2: 'fu-xuan',
    app_rate_2: 99.16,
    avg_round_2: 3055,
    app_flat_2: 18,
    char_3: 'Sparkle',
    app_rate_3: 96.64,
    avg_round_3: 3055,
    app_flat_3: 18,
    char_4: 'Bronya',
    app_rate_4: 1.68,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'ruan-mei',
    app_rate_5: 0.84,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Robin',
    app_rate_6: 0.84,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Lynx',
    app_rate_7: 0.84,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Clara',
    round: 3234,
    char_1: 'Robin',
    app_rate_1: 71.96,
    avg_round_1: 3190,
    app_flat_1: 324,
    char_2: 'Aventurine',
    app_rate_2: 40.48,
    avg_round_2: 3202,
    app_flat_2: 184,
    char_3: 'topaz',
    app_rate_3: 37.43,
    avg_round_3: 3174,
    app_flat_3: 148,
    char_4: 'Huohuo',
    app_rate_4: 22.49,
    avg_round_4: 3145,
    app_flat_4: 100,
    char_5: 'fu-xuan',
    app_rate_5: 20.63,
    avg_round_5: 3116,
    app_flat_5: 75,
    char_6: 'dr-ratio',
    app_rate_6: 17.46,
    avg_round_6: 3175,
    app_flat_6: 91,
    char_7: 'Sparkle',
    app_rate_7: 17.2,
    avg_round_7: 3003,
    app_flat_7: 66,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 13.36,
    avg_round_8: 3186,
    app_flat_8: 73,
    char_9: 'Luocha',
    app_rate_9: 11.77,
    avg_round_9: 3074,
    app_flat_9: 45,
    char_10: 'Yunli',
    app_rate_10: 11.11,
    avg_round_10: 3180,
    app_flat_10: 51,
    char_11: 'Tingyun',
    app_rate_11: 5.82,
    avg_round_11: 2992,
    app_flat_11: 24,
    char_12: 'Jingliu',
    app_rate_12: 4.5,
    avg_round_12: 3129,
    app_flat_12: 10,
    char_13: 'ruan-mei',
    app_rate_13: 3.7,
    avg_round_13: 3188,
    app_flat_13: 10,
    char_14: 'Bronya',
    app_rate_14: 3.17,
    avg_round_14: 3159,
    app_flat_14: 7,
    char_15: 'Blade',
    app_rate_15: 2.91,
    avg_round_15: 2992,
    app_flat_15: 15,
    char_16: 'Gallagher',
    app_rate_16: 2.51,
    avg_round_16: 3193,
    app_flat_16: 12,
    char_17: 'Boothill',
    app_rate_17: 2.38,
    avg_round_17: 3144,
    app_flat_17: 12,
    char_18: 'imbibitor-lunae',
    app_rate_18: 1.59,
    avg_round_18: 3007,
    app_flat_18: 5,
    char_19: 'trailblazer-imaginary',
    app_rate_19: 1.46,
    avg_round_19: 3200,
    app_flat_19: 8,
    char_20: 'Lynx',
    app_rate_20: 1.32,
    avg_round_20: 3052,
    app_flat_20: 7,
    char_21: 'Pela',
    app_rate_21: 1.06,
    avg_round_21: 3175,
    app_flat_21: 1,
    char_22: 'Jade',
    app_rate_22: 1.06,
    avg_round_22: 3176,
    app_flat_22: 1,
    char_23: 'silver-wolf',
    app_rate_23: 0.79,
    avg_round_23: 2905,
    app_flat_23: 3,
    char_24: 'Bailu',
    app_rate_24: 0.66,
    avg_round_24: 3153,
    app_flat_24: 4,
    char_25: 'Himeko',
    app_rate_25: 0.66,
    avg_round_25: 2946,
    app_flat_25: 1,
    char_26: 'Firefly',
    app_rate_26: 0.53,
    avg_round_26: 3327,
    app_flat_26: 2,
    char_27: 'Acheron',
    app_rate_27: 0.53,
    avg_round_27: 0,
    app_flat_27: 0,
    char_28: 'black-swan',
    app_rate_28: 0.4,
    avg_round_28: 2800,
    app_flat_28: 1,
    char_29: 'Asta',
    app_rate_29: 0.26,
    avg_round_29: 0,
    app_flat_29: 0,
    char_30: 'Argenti',
    app_rate_30: 0.26,
    avg_round_30: 0,
    app_flat_30: 0
  },
  {
    char: 'Welt',
    round: 0,
    char_1: 'dr-ratio',
    app_rate_1: 50.0,
    avg_round_1: 3142,
    app_flat_1: 9,
    char_2: 'Aventurine',
    app_rate_2: 37.5,
    avg_round_2: 3052,
    app_flat_2: 5,
    char_3: 'Acheron',
    app_rate_3: 27.5,
    avg_round_3: 3090,
    app_flat_3: 2,
    char_4: 'Sparkle',
    app_rate_4: 27.5,
    avg_round_4: 3040,
    app_flat_4: 5,
    char_5: 'Huohuo',
    app_rate_5: 17.5,
    avg_round_5: 3161,
    app_flat_5: 6,
    char_6: 'ruan-mei',
    app_rate_6: 15.0,
    avg_round_6: 3004,
    app_flat_6: 1,
    char_7: 'Robin',
    app_rate_7: 12.5,
    avg_round_7: 3276,
    app_flat_7: 3,
    char_8: 'fu-xuan',
    app_rate_8: 12.5,
    avg_round_8: 2962,
    app_flat_8: 1,
    char_9: 'Gallagher',
    app_rate_9: 12.5,
    avg_round_9: 3039,
    app_flat_9: 3,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 12.5,
    avg_round_10: 2965,
    app_flat_10: 4,
    char_11: 'Luocha',
    app_rate_11: 10.0,
    avg_round_11: 3215,
    app_flat_11: 1,
    char_12: 'Pela',
    app_rate_12: 10.0,
    avg_round_12: 3090,
    app_flat_12: 2,
    char_13: 'Tingyun',
    app_rate_13: 10.0,
    avg_round_13: 3166,
    app_flat_13: 3,
    char_14: 'trailblazer-imaginary',
    app_rate_14: 10.0,
    avg_round_14: 2962,
    app_flat_14: 1,
    char_15: 'imbibitor-lunae',
    app_rate_15: 7.5,
    avg_round_15: 2989,
    app_flat_15: 2,
    char_16: 'Firefly',
    app_rate_16: 7.5,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: 'black-swan',
    app_rate_17: 5.0,
    avg_round_17: 0,
    app_flat_17: 0,
    char_18: 'Bronya',
    app_rate_18: 5.0,
    avg_round_18: 0,
    app_flat_18: 0,
    char_19: 'silver-wolf',
    app_rate_19: 5.0,
    avg_round_19: 0,
    app_flat_19: 0,
    char_20: 'Sushang',
    app_rate_20: 2.5,
    avg_round_20: 0,
    app_flat_20: 0,
    char_21: 'topaz',
    app_rate_21: 2.5,
    avg_round_21: 0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sampo',
    round: 0,
    char_1: 'black-swan',
    app_rate_1: 76.92,
    avg_round_1: 2939,
    app_flat_1: 3,
    char_2: 'Kafka',
    app_rate_2: 46.15,
    avg_round_2: 2857,
    app_flat_2: 1,
    char_3: 'Aventurine',
    app_rate_3: 46.15,
    avg_round_3: 3216,
    app_flat_3: 3,
    char_4: 'Luocha',
    app_rate_4: 30.77,
    avg_round_4: 2889,
    app_flat_4: 1,
    char_5: 'Acheron',
    app_rate_5: 30.77,
    avg_round_5: 2980,
    app_flat_5: 2,
    char_6: 'ruan-mei',
    app_rate_6: 15.38,
    avg_round_6: 3402,
    app_flat_6: 2,
    char_7: 'dr-ratio',
    app_rate_7: 15.38,
    avg_round_7: 3396,
    app_flat_7: 2,
    char_8: 'fu-xuan',
    app_rate_8: 7.69,
    avg_round_8: 3072,
    app_flat_8: 1,
    char_9: 'Huohuo',
    app_rate_9: 7.69,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 7.69,
    avg_round_10: 3321,
    app_flat_10: 1,
    char_11: 'Gallagher',
    app_rate_11: 7.69,
    avg_round_11: 3321,
    app_flat_11: 1,
    char_12: 'Tingyun',
    app_rate_12: 7.69,
    avg_round_12: 3308,
    app_flat_12: 1,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Xueyi',
    round: 0,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 88.89,
    avg_round_1: 3442,
    app_flat_1: 6,
    char_2: 'Gallagher',
    app_rate_2: 88.89,
    avg_round_2: 3442,
    app_flat_2: 6,
    char_3: 'ruan-mei',
    app_rate_3: 77.78,
    avg_round_3: 3406,
    app_flat_3: 5,
    char_4: 'Firefly',
    app_rate_4: 11.11,
    avg_round_4: 3620,
    app_flat_4: 1,
    char_5: 'silver-wolf',
    app_rate_5: 11.11,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Sparkle',
    app_rate_6: 11.11,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'fu-xuan',
    app_rate_7: 11.11,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jing-yuan',
    round: 0,
    char_1: 'Tingyun',
    app_rate_1: 100.0,
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Gallagher',
    app_rate_2: 100.0,
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Robin',
    app_rate_3: 50.0,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'ruan-mei',
    app_rate_4: 50.0,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Herta',
    round: 0,
    char_1: 'Robin',
    app_rate_1: 45.45,
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 36.36,
    avg_round_2: 3261,
    app_flat_2: 2,
    char_3: 'Gallagher',
    app_rate_3: 36.36,
    avg_round_3: 3261,
    app_flat_3: 2,
    char_4: 'Aventurine',
    app_rate_4: 27.27,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'ruan-mei',
    app_rate_5: 27.27,
    avg_round_5: 3206,
    app_flat_5: 1,
    char_6: 'Jade',
    app_rate_6: 18.18,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'fu-xuan',
    app_rate_7: 18.18,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: 'topaz',
    app_rate_8: 18.18,
    avg_round_8: 0,
    app_flat_8: 0,
    char_9: 'Sparkle',
    app_rate_9: 9.09,
    avg_round_9: 0,
    app_flat_9: 0,
    char_10: 'Himeko',
    app_rate_10: 9.09,
    avg_round_10: 0,
    app_flat_10: 0,
    char_11: 'Luocha',
    app_rate_11: 9.09,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'Firefly',
    app_rate_12: 9.09,
    avg_round_12: 3316,
    app_flat_12: 1,
    char_13: 'Pela',
    app_rate_13: 9.09,
    avg_round_13: 0,
    app_flat_13: 0,
    char_14: 'Huohuo',
    app_rate_14: 9.09,
    avg_round_14: 0,
    app_flat_14: 0,
    char_15: 'Jingliu',
    app_rate_15: 9.09,
    avg_round_15: 0,
    app_flat_15: 0,
    char_16: 'Bronya',
    app_rate_16: 9.09,
    avg_round_16: 0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Misha',
    round: 0,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 100.0,
    avg_round_1: 3467,
    app_flat_1: 2,
    char_2: 'ruan-mei',
    app_rate_2: 100.0,
    avg_round_2: 3467,
    app_flat_2: 2,
    char_3: 'Gallagher',
    app_rate_3: 100.0,
    avg_round_3: 3467,
    app_flat_3: 2,
    char_4: '-',
    app_rate_4: 0.0,
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yanqing',
    round: 0,
    char_1: 'Robin',
    app_rate_1: 50.0,
    avg_round_1: 3459,
    app_flat_1: 1,
    char_2: 'Aventurine',
    app_rate_2: 50.0,
    avg_round_2: 3459,
    app_flat_2: 1,
    char_3: 'ruan-mei',
    app_rate_3: 33.33,
    avg_round_3: 3009,
    app_flat_3: 1,
    char_4: 'Gallagher',
    app_rate_4: 33.33,
    avg_round_4: 3260,
    app_flat_4: 2,
    char_5: 'Sparkle',
    app_rate_5: 16.67,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'Gepard',
    app_rate_6: 16.67,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Clara',
    app_rate_7: 16.67,
    avg_round_7: 3009,
    app_flat_7: 1,
    char_8: 'topaz',
    app_rate_8: 16.67,
    avg_round_8: 3459,
    app_flat_8: 1,
    char_9: 'Firefly',
    app_rate_9: 16.67,
    avg_round_9: 3512,
    app_flat_9: 1,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 16.67,
    avg_round_10: 3512,
    app_flat_10: 1,
    char_11: 'Yunli',
    app_rate_11: 16.67,
    avg_round_11: 0,
    app_flat_11: 0,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 16.67,
    avg_round_12: 0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hook',
    round: 0,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 100.0,
    avg_round_1: 3420,
    app_flat_1: 2,
    char_2: 'ruan-mei',
    app_rate_2: 100.0,
    avg_round_2: 3420,
    app_flat_2: 2,
    char_3: 'Gallagher',
    app_rate_3: 100.0,
    avg_round_3: 3420,
    app_flat_3: 2,
    char_4: '-',
    app_rate_4: 0.0,
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    round: 0,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 66.67,
    avg_round_1: 3515,
    app_flat_1: 2,
    char_2: 'ruan-mei',
    app_rate_2: 66.67,
    avg_round_2: 3515,
    app_flat_2: 2,
    char_3: 'Firefly',
    app_rate_3: 33.33,
    avg_round_3: 3565,
    app_flat_3: 1,
    char_4: 'Sushang',
    app_rate_4: 33.33,
    avg_round_4: 3465,
    app_flat_4: 1,
    char_5: 'Yunli',
    app_rate_5: 33.33,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: 'topaz',
    app_rate_6: 33.33,
    avg_round_6: 0,
    app_flat_6: 0,
    char_7: 'Tingyun',
    app_rate_7: 33.33,
    avg_round_7: 0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-fire',
    round: 0,
    char_1: 'Pela',
    app_rate_1: 100.0,
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'Jingliu',
    app_rate_2: 50.0,
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'ruan-mei',
    app_rate_3: 50.0,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: 'Acheron',
    app_rate_4: 50.0,
    avg_round_4: 0,
    app_flat_4: 0,
    char_5: 'Bronya',
    app_rate_5: 50.0,
    avg_round_5: 0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    round: 0,
    char_1: 'Robin',
    app_rate_1: 100.0,
    avg_round_1: 0,
    app_flat_1: 0,
    char_2: 'ruan-mei',
    app_rate_2: 100.0,
    avg_round_2: 0,
    app_flat_2: 0,
    char_3: 'Gallagher',
    app_rate_3: 100.0,
    avg_round_3: 0,
    app_flat_3: 0,
    char_4: '-',
    app_rate_4: 0.0,
    avg_round_4: 0.0,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
