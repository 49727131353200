import React, { useState } from 'react';
import { HSRCharacter } from '../common/components/hsr-character';
import { Row, Col, Accordion, Alert } from 'react-bootstrap';
import { HSRLightCone } from '../common/components/hsr-light-cone';
import { HSRRelicSet } from '../common/components/hsr-relic-set';
import { HSRStat } from '../common/components/hsr-stat';
import { HSRDPSJade } from '../dps-rankings/jade-array';

export const ProfileJadeDPS: React.FC = () => {
  const [currentTab, setCurrentTab] = useState('V3');
  return (
    <>
      <Alert variant="primary alert-red ">
        <p>
          <strong>
            Don't use the damage output calculations as an indication on who is
            worth pulling or investing as those are just simulations and in
            day-to-day gameplay there's a lot variables
          </strong>
          . Use the numbers only to supplement your knowledge and treat them as
          the 'floor' of a character's damage output which you can further scale
          with proper team and investment.
        </p>
      </Alert>
      <p>
        The simulation showcases characters{' '}
        <strong>
          damage potential in the 8 cycle turn limit of Memory of Chaos against
          1, 3 and 5 targets
        </strong>
        . The section below has all the rules listed.
      </p>
      <Accordion className="sim-rules">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Simulation rules</Accordion.Header>
          <Accordion.Body>
            <p>
              <strong>General:</strong>
            </p>
            <ul>
              <li>
                Combat is simulated at 8 cycles of in-game time or 850 Action
                Points,
              </li>
              <li>
                Units are considered to gain 5 additional energy per rotation
                through combat (taking a hit, killing an enemy or through party
                members and their Light Cone),
              </li>
              <li>
                Characters and Light Cones are calculated at maximum output
                starting battle with ramp time removed from stacking buffs
                (intended to give a constant evaluation of power not dependant
                on length of fight that can be used to assess maximum power).
                Additional information about loss of damage due to ramp time can
                be round attached to related Light Cones, relics and characters,
              </li>
              <li>
                Character rotations are smoothed removing breakpoints relating
                to Speed and Energy (finishing a fight with 90% energy full or 2
                AV off next ability leading to skewed results). This removes any
                specific cut off point 850 AV, 750 AV etc, from impacting the
                results.
              </li>
            </ul>
            <p>
              <strong>Enemies:</strong>
            </p>
            <ul>
              <li>
                Enemies are considered to always have a Break Bar resulting in
                units all doing 90% of real damage,
              </li>
              <li>Enemies speed is set to 134,</li>
              <li>
                Enemies are assumed to be at 100% of HP at start of battle and
                to die at exactly at the end of combat. Which means halfway
                through the fight they are at 50% HP,
              </li>
              <li>Enemies are assumed to have 0 Resistance,</li>
              <li>
                Enemies are always considered vulnerable to the attackers
                element,
              </li>
              <li>Enemies are considered level 95 and with DEF included.</li>
            </ul>
            <p>
              <strong>Units</strong>:
            </p>
            <ul>
              <li>All units are level 80,</li>
              <li>
                Traces are all unlocked, skills at Level 10 and Basic at Level
                6,
              </li>
              <li>
                All units have fully upgraded 5 star relics with 24 desired sub
                stats out of a possible 54,
              </li>
              <li>All units use their Best In Slot 2 and 4 piece sets,</li>
              <li>
                All units use Light Cones that are realistically obtainable by
                all players (including those in Herta and FH Stores and Battle
                Pass),
              </li>
              <ul>
                <li>
                  Because the Herta Store and farmable Light Cones are easily
                  accessible to playerbase, we are simulating them at
                  Superimposition 5.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <h5 className="red">Jade disclaimers</h5>
      <p>
        Jade's damage output is incredibly reliant on how many enemies she's
        facing, how often her allies attack, how many enemies her allies can
        attack and the supportive characters you place alongside her. All of
        these factors combine to create a character with some of the most
        variable damage in the game, for this reason some Cones, sets and
        eidolons can perform better or worse even more so than other characters.
        We've done our best to include notes where possible to offer more
        information where this applies, or offered a comparison between multiple
        scenarios (damage between 1,3,5 targets for E0 and E1) but with so many
        factors values can still vary based on the fight.
      </p>
      <div className="tabs jade">
        <div
          className={`single-tab ${currentTab === 'V1' ? 'active' : ''}`}
          onClick={() => setCurrentTab('V1')}
        >
          <p>1 Target</p>
        </div>
        <div
          className={`single-tab ${currentTab === 'V2' ? 'active' : ''}`}
          onClick={() => setCurrentTab('V2')}
        >
          <p>3 Targets</p>
        </div>
        <div
          className={`single-tab ${currentTab === 'V3' ? 'active' : ''}`}
          onClick={() => setCurrentTab('V3')}
        >
          <p>5 Targets</p>
        </div>
      </div>
      <Row xs={1} xl={1} className="dps-comparison">
        <Col>
          <div className="box">
            <h5>
              Jade [{currentTab === 'V1' && '1 Target'}
              {currentTab === 'V2' && '3 Targets'}
              {currentTab === 'V3' && '5 Targets'}]
            </h5>
            <div className="simulation-container">
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="jade" mode="icon" />
                  <span className="eidolon">E6</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSJade[currentTab].e6 /
                            HSRDPSJade[currentTab].e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="The Seriousness of Breakfast"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSJade[currentTab].e6.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSJade[currentTab].e6 /
                            HSRDPSJade[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="jade" mode="icon" />
                  <span className="eidolon">E5</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSJade[currentTab].e5 /
                            HSRDPSJade[currentTab].e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="The Seriousness of Breakfast"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSJade[currentTab].e5.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSJade[currentTab].e5 /
                            HSRDPSJade[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="jade" mode="icon" />
                  <span className="eidolon">E4</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSJade[currentTab].e4 /
                            HSRDPSJade[currentTab].e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="The Seriousness of Breakfast"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSJade[currentTab].e4.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSJade[currentTab].e4 /
                            HSRDPSJade[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="jade" mode="icon" />
                  <span className="eidolon">E3</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSJade[currentTab].e3 /
                            HSRDPSJade[currentTab].e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="The Seriousness of Breakfast"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSJade[currentTab].e3.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSJade[currentTab].e3 /
                            HSRDPSJade[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="jade" mode="icon" />
                  <span className="eidolon">E2</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSJade[currentTab].e2 /
                            HSRDPSJade[currentTab].e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="The Seriousness of Breakfast"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSJade[currentTab].e2.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSJade[currentTab].e2 /
                            HSRDPSJade[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="jade" mode="icon" />
                  <span className="eidolon">E1</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSJade[currentTab].e1 /
                            HSRDPSJade[currentTab].e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="The Seriousness of Breakfast"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSJade[currentTab].e1.toLocaleString()} dmg
                      </span>
                      <span className="percent">
                        {(
                          (HSRDPSJade[currentTab].e1 /
                            HSRDPSJade[currentTab].e0) *
                          100
                        ).toFixed(0)}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row acheron">
                <div className="character with-eidolon">
                  <HSRCharacter slug="jade" mode="icon" />
                  <span className="eidolon">E0</span>
                </div>
                <div className="chart">
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar star-4`}
                      style={{
                        width:
                          (HSRDPSJade[currentTab].e0 /
                            HSRDPSJade[currentTab].e6) *
                            100 +
                          '%'
                      }}
                    ></div>
                    <div className="data smaller">
                      <HSRLightCone
                        name="The Seriousness of Breakfast"
                        mode="min"
                        hidename
                      />
                      <span className="dps with-eidolon">
                        {HSRDPSJade[currentTab].e0.toLocaleString()} dmg
                      </span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <h6 className="header">Light Cone</h6>
      <div className="build-cones perf">
        <Row xs={1} xl={1} xxl={1} className="simple-cones">
          <Col>
            <HSRLightCone
              slug="the-seriousness-of-breakfast"
              mode="profile"
              superImp="5"
            />
          </Col>
        </Row>
      </div>
      <div className="section-build perf">
        <div className="build-relics perf">
          <Row xs={1} xl={2} xxl={2} className="relics">
            <Col>
              <h6 className="header">Relic Set</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet name="Genius of Brilliant Stars" compactMode />
              </div>
            </Col>
            <Col>
              <h6 className="header">Planetary Sets</h6>
              <div className="relic-sets-rec">
                <HSRRelicSet name="Inert Salsotto" compactMode />
              </div>
            </Col>
          </Row>
        </div>
        <h6 className="header">Relic Stats</h6>
        <div className="build-stats">
          <Row xs={1} xl={2} xxl={4} className="main-stats">
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Body</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="CRIT Rate" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Feet</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="ATK" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Planar Sphere</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="Quantum DMG" />
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <div className="stats-header">
                  <span>Link Rope</span>
                </div>
                <div className="list-stats">
                  <HSRStat stat="ATK" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="box sub-stats">
                <span>Substats:</span>
                <p>Crit Rate (40.8%), CRIT DMG (58.3%)</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
