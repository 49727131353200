export const MoCDuos = [
  {
    char: 'Jiaoqiu',
    round: 7.18,
    char_1: 'Acheron',
    app_rate_1: 88.98,
    avg_round_1: 7.15,
    app_flat_1: 1197,
    char_2: 'Pela',
    app_rate_2: 37.52,
    avg_round_2: 7.09,
    app_flat_2: 804,
    char_3: 'fu-xuan',
    app_rate_3: 36.07,
    avg_round_3: 6.87,
    app_flat_3: 488,
    char_4: 'Aventurine',
    app_rate_4: 35.25,
    avg_round_4: 7.22,
    app_flat_4: 487,
    char_5: 'Sparkle',
    app_rate_5: 28.35,
    avg_round_5: 7.05,
    app_flat_5: 84,
    char_6: 'silver-wolf',
    app_rate_6: 10.17,
    avg_round_6: 7.12,
    app_flat_6: 172,
    char_7: 'black-swan',
    app_rate_7: 9.17,
    avg_round_7: 7.75,
    app_flat_7: 125,
    char_8: 'Huohuo',
    app_rate_8: 7.36,
    avg_round_8: 8.25,
    app_flat_8: 103,
    char_9: 'Gallagher',
    app_rate_9: 7.23,
    avg_round_9: 7.29,
    app_flat_9: 113,
    char_10: 'Robin',
    app_rate_10: 6.53,
    avg_round_10: 6.7,
    app_flat_10: 54,
    char_11: 'Luocha',
    app_rate_11: 6.14,
    avg_round_11: 7.95,
    app_flat_11: 76,
    char_12: 'Bronya',
    app_rate_12: 4.85,
    avg_round_12: 8.5,
    app_flat_12: 8,
    char_13: 'Kafka',
    app_rate_13: 4.26,
    avg_round_13: 8.35,
    app_flat_13: 43,
    char_14: 'dr-ratio',
    app_rate_14: 3.8,
    avg_round_14: 7.69,
    app_flat_14: 55,
    char_15: 'Gepard',
    app_rate_15: 2.81,
    avg_round_15: 7.88,
    app_flat_15: 33,
    char_16: 'ruan-mei',
    app_rate_16: 2.38,
    avg_round_16: 7.43,
    app_flat_16: 7,
    char_17: 'Tingyun',
    app_rate_17: 1.39,
    avg_round_17: 8,
    app_flat_17: 12,
    char_18: 'imbibitor-lunae',
    app_rate_18: 1.29,
    avg_round_18: 9.0,
    app_flat_18: 8,
    char_19: 'Yunli',
    app_rate_19: 0.92,
    avg_round_19: 7.29,
    app_flat_19: 13,
    char_20: 'Blade',
    app_rate_20: 0.89,
    avg_round_20: 8.75,
    app_flat_20: 8,
    char_21: 'Bailu',
    app_rate_21: 0.53,
    avg_round_21: 8.8,
    app_flat_21: 9,
    char_22: 'Welt',
    app_rate_22: 0.46,
    avg_round_22: 9,
    app_flat_22: 8,
    char_23: 'Sampo',
    app_rate_23: 0.4,
    avg_round_23: 7.25,
    app_flat_23: 4,
    char_24: 'Lynx',
    app_rate_24: 0.36,
    avg_round_24: 7,
    app_flat_24: 3,
    char_25: 'march-7th-swordmaster',
    app_rate_25: 0.33,
    avg_round_25: 8.14,
    app_flat_25: 7,
    char_26: 'Boothill',
    app_rate_26: 0.3,
    avg_round_26: 6.25,
    app_flat_26: 4,
    char_27: 'trailblazer-imaginary',
    app_rate_27: 0.26,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Argenti',
    app_rate_28: 0.26,
    avg_round_28: 8,
    app_flat_28: 2,
    char_29: 'topaz',
    app_rate_29: 0.26,
    avg_round_29: 9.33,
    app_flat_29: 3,
    char_30: 'Firefly',
    app_rate_30: 0.2,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Yunli',
    round: 7.34,
    char_1: 'Robin',
    app_rate_1: 58.03,
    avg_round_1: 7.26,
    app_flat_1: 708,
    char_2: 'Tingyun',
    app_rate_2: 53.38,
    avg_round_2: 7.13,
    app_flat_2: 702,
    char_3: 'Huohuo',
    app_rate_3: 47.93,
    avg_round_3: 7.11,
    app_flat_3: 598,
    char_4: 'Sparkle',
    app_rate_4: 34.19,
    avg_round_4: 7.16,
    app_flat_4: 397,
    char_5: 'fu-xuan',
    app_rate_5: 18.69,
    avg_round_5: 7.57,
    app_flat_5: 200,
    char_6: 'Aventurine',
    app_rate_6: 15.45,
    avg_round_6: 7.55,
    app_flat_6: 197,
    char_7: 'topaz',
    app_rate_7: 11.97,
    avg_round_7: 6.96,
    app_flat_7: 131,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 11.26,
    avg_round_8: 7.85,
    app_flat_8: 161,
    char_9: 'ruan-mei',
    app_rate_9: 7.98,
    avg_round_9: 8.95,
    app_flat_9: 73,
    char_10: 'Luocha',
    app_rate_10: 7.63,
    avg_round_10: 7.43,
    app_flat_10: 103,
    char_11: 'Clara',
    app_rate_11: 6.16,
    avg_round_11: 8.47,
    app_flat_11: 83,
    char_12: 'Lynx',
    app_rate_12: 4.49,
    avg_round_12: 8.1,
    app_flat_12: 67,
    char_13: 'Pela',
    app_rate_13: 3.64,
    avg_round_13: 8.62,
    app_flat_13: 48,
    char_14: 'Jade',
    app_rate_14: 2.73,
    avg_round_14: 8.93,
    app_flat_14: 28,
    char_15: 'Jingliu',
    app_rate_15: 2.47,
    avg_round_15: 9.33,
    app_flat_15: 17,
    char_16: 'Gallagher',
    app_rate_16: 2.37,
    avg_round_16: 7.74,
    app_flat_16: 34,
    char_17: 'Jiaoqiu',
    app_rate_17: 1.41,
    avg_round_17: 7.29,
    app_flat_17: 13,
    char_18: 'Bronya',
    app_rate_18: 1.41,
    avg_round_18: 8.64,
    app_flat_18: 14,
    char_19: 'Bailu',
    app_rate_19: 0.91,
    avg_round_19: 8.54,
    app_flat_19: 13,
    char_20: 'Acheron',
    app_rate_20: 0.81,
    avg_round_20: 9.25,
    app_flat_20: 4,
    char_21: 'Blade',
    app_rate_21: 0.76,
    avg_round_21: 9.25,
    app_flat_21: 6,
    char_22: 'black-swan',
    app_rate_22: 0.56,
    avg_round_22: 8.57,
    app_flat_22: 7,
    char_23: 'dr-ratio',
    app_rate_23: 0.56,
    avg_round_23: 8.4,
    app_flat_23: 10,
    char_24: 'Herta',
    app_rate_24: 0.51,
    avg_round_24: 8.4,
    app_flat_24: 5,
    char_25: 'Boothill',
    app_rate_25: 0.45,
    avg_round_25: 9,
    app_flat_25: 3,
    char_26: 'Gepard',
    app_rate_26: 0.45,
    avg_round_26: 10.0,
    app_flat_26: 4,
    char_27: 'Hanya',
    app_rate_27: 0.4,
    avg_round_27: 7.8,
    app_flat_27: 5,
    char_28: 'trailblazer-imaginary',
    app_rate_28: 0.4,
    avg_round_28: 8.67,
    app_flat_28: 3,
    char_29: 'Kafka',
    app_rate_29: 0.4,
    avg_round_29: 8.5,
    app_flat_29: 2,
    char_30: 'silver-wolf',
    app_rate_30: 0.35,
    avg_round_30: 8,
    app_flat_30: 3
  },
  {
    char: 'Robin',
    round: 7.42,
    char_1: 'Aventurine',
    app_rate_1: 40.81,
    avg_round_1: 7.51,
    app_flat_1: 825,
    char_2: 'dr-ratio',
    app_rate_2: 32.72,
    avg_round_2: 7.63,
    app_flat_2: 678,
    char_3: 'topaz',
    app_rate_3: 32.12,
    avg_round_3: 7.33,
    app_flat_3: 610,
    char_4: 'Yunli',
    app_rate_4: 31.38,
    avg_round_4: 7.26,
    app_flat_4: 708,
    char_5: 'Huohuo',
    app_rate_5: 26.63,
    avg_round_5: 7.35,
    app_flat_5: 539,
    char_6: 'Tingyun',
    app_rate_6: 17.26,
    avg_round_6: 7.09,
    app_flat_6: 386,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 13.49,
    avg_round_7: 8.31,
    app_flat_7: 359,
    char_8: 'Clara',
    app_rate_8: 13.19,
    avg_round_8: 8.29,
    app_flat_8: 323,
    char_9: 'fu-xuan',
    app_rate_9: 12.73,
    avg_round_9: 8.33,
    app_flat_9: 221,
    char_10: 'Sparkle',
    app_rate_10: 10.87,
    avg_round_10: 7.77,
    app_flat_10: 122,
    char_11: 'Luocha',
    app_rate_11: 8.19,
    avg_round_11: 7.89,
    app_flat_11: 165,
    char_12: 'black-swan',
    app_rate_12: 5.95,
    avg_round_12: 8.12,
    app_flat_12: 83,
    char_13: 'Jiaoqiu',
    app_rate_13: 5.41,
    avg_round_13: 6.7,
    app_flat_13: 54,
    char_14: 'Acheron',
    app_rate_14: 5.33,
    avg_round_14: 6.88,
    app_flat_14: 33,
    char_15: 'Kafka',
    app_rate_15: 5.3,
    avg_round_15: 8.14,
    app_flat_15: 73,
    char_16: 'Gallagher',
    app_rate_16: 4.37,
    avg_round_16: 7.11,
    app_flat_16: 85,
    char_17: 'imbibitor-lunae',
    app_rate_17: 3.88,
    avg_round_17: 7.64,
    app_flat_17: 28,
    char_18: 'Jingliu',
    app_rate_18: 3.77,
    avg_round_18: 9.0,
    app_flat_18: 36,
    char_19: 'Pela',
    app_rate_19: 3.66,
    avg_round_19: 7.91,
    app_flat_19: 64,
    char_20: 'Jade',
    app_rate_20: 3.31,
    avg_round_20: 8.95,
    app_flat_20: 38,
    char_21: 'ruan-mei',
    app_rate_21: 3.03,
    avg_round_21: 8.11,
    app_flat_21: 35,
    char_22: 'Bronya',
    app_rate_22: 2.92,
    avg_round_22: 7.47,
    app_flat_22: 30,
    char_23: 'silver-wolf',
    app_rate_23: 2.49,
    avg_round_23: 8.67,
    app_flat_23: 39,
    char_24: 'Blade',
    app_rate_24: 2.32,
    avg_round_24: 7.74,
    app_flat_24: 34,
    char_25: 'Lynx',
    app_rate_25: 1.94,
    avg_round_25: 8.79,
    app_flat_25: 48,
    char_26: 'jing-yuan',
    app_rate_26: 0.87,
    avg_round_26: 7.93,
    app_flat_26: 14,
    char_27: 'Yanqing',
    app_rate_27: 0.79,
    avg_round_27: 8.0,
    app_flat_27: 19,
    char_28: 'Argenti',
    app_rate_28: 0.63,
    avg_round_28: 10.0,
    app_flat_28: 5,
    char_29: 'Bailu',
    app_rate_29: 0.63,
    avg_round_29: 8.55,
    app_flat_29: 11,
    char_30: 'Herta',
    app_rate_30: 0.55,
    avg_round_30: 8.6,
    app_flat_30: 7
  },
  {
    char: 'Tingyun',
    round: 7.5,
    char_1: 'Sparkle',
    app_rate_1: 51.32,
    avg_round_1: 7.51,
    app_flat_1: 589,
    char_2: 'Yunli',
    app_rate_2: 44.94,
    avg_round_2: 7.13,
    app_flat_2: 702,
    char_3: 'Huohuo',
    app_rate_3: 41.58,
    avg_round_3: 7.27,
    app_flat_3: 562,
    char_4: 'imbibitor-lunae',
    app_rate_4: 31.12,
    avg_round_4: 8.03,
    app_flat_4: 272,
    char_5: 'Robin',
    app_rate_5: 26.87,
    avg_round_5: 7.09,
    app_flat_5: 386,
    char_6: 'fu-xuan',
    app_rate_6: 18.49,
    avg_round_6: 7.5,
    app_flat_6: 212,
    char_7: 'Aventurine',
    app_rate_7: 17.9,
    avg_round_7: 8.2,
    app_flat_7: 267,
    char_8: 'Luocha',
    app_rate_8: 11.31,
    avg_round_8: 7.74,
    app_flat_8: 120,
    char_9: 'Clara',
    app_rate_9: 8.38,
    avg_round_9: 8.15,
    app_flat_9: 125,
    char_10: 'ruan-mei',
    app_rate_10: 6.97,
    avg_round_10: 8.45,
    app_flat_10: 57,
    char_11: 'dr-ratio',
    app_rate_11: 5.36,
    avg_round_11: 7.98,
    app_flat_11: 89,
    char_12: 'Pela',
    app_rate_12: 3.78,
    avg_round_12: 8.31,
    app_flat_12: 50,
    char_13: 'march-7th-swordmaster',
    app_rate_13: 3.15,
    avg_round_13: 8.36,
    app_flat_13: 56,
    char_14: 'Lynx',
    app_rate_14: 3.1,
    avg_round_14: 8.04,
    app_flat_14: 56,
    char_15: 'Gallagher',
    app_rate_15: 2.89,
    avg_round_15: 7.33,
    app_flat_15: 39,
    char_16: 'topaz',
    app_rate_16: 2.3,
    avg_round_16: 7.28,
    app_flat_16: 29,
    char_17: 'Jingliu',
    app_rate_17: 2.04,
    avg_round_17: 9.4,
    app_flat_17: 9,
    char_18: 'jing-yuan',
    app_rate_18: 1.96,
    avg_round_18: 7.83,
    app_flat_18: 18,
    char_19: 'Argenti',
    app_rate_19: 1.87,
    avg_round_19: 8.82,
    app_flat_19: 21,
    char_20: 'Bronya',
    app_rate_20: 1.87,
    avg_round_20: 9.3,
    app_flat_20: 20,
    char_21: 'silver-wolf',
    app_rate_21: 1.87,
    avg_round_21: 7.79,
    app_flat_21: 28,
    char_22: 'Jiaoqiu',
    app_rate_22: 1.79,
    avg_round_22: 8,
    app_flat_22: 12,
    char_23: 'black-swan',
    app_rate_23: 1.62,
    avg_round_23: 8.38,
    app_flat_23: 14,
    char_24: 'Kafka',
    app_rate_24: 1.32,
    avg_round_24: 8.67,
    app_flat_24: 6,
    char_25: 'Hanya',
    app_rate_25: 1.15,
    avg_round_25: 9.33,
    app_flat_25: 15,
    char_26: 'Yanqing',
    app_rate_26: 1.11,
    avg_round_26: 8.0,
    app_flat_26: 15,
    char_27: 'Bailu',
    app_rate_27: 0.64,
    avg_round_27: 9,
    app_flat_27: 8,
    char_28: 'Seele',
    app_rate_28: 0.6,
    avg_round_28: 8,
    app_flat_28: 3,
    char_29: 'Yukong',
    app_rate_29: 0.38,
    avg_round_29: 7.2,
    app_flat_29: 5,
    char_30: 'Blade',
    app_rate_30: 0.34,
    avg_round_30: 7.83,
    app_flat_30: 6
  },
  {
    char: 'topaz',
    round: 7.52,
    char_1: 'Robin',
    app_rate_1: 79.25,
    avg_round_1: 7.33,
    app_flat_1: 610,
    char_2: 'Aventurine',
    app_rate_2: 73.38,
    avg_round_2: 7.41,
    app_flat_2: 561,
    char_3: 'dr-ratio',
    app_rate_3: 66.91,
    avg_round_3: 7.65,
    app_flat_3: 508,
    char_4: 'Yunli',
    app_rate_4: 15.97,
    avg_round_4: 6.96,
    app_flat_4: 131,
    char_5: 'Huohuo',
    app_rate_5: 10.78,
    avg_round_5: 7.47,
    app_flat_5: 86,
    char_6: 'Clara',
    app_rate_6: 10.44,
    avg_round_6: 7.73,
    app_flat_6: 88,
    char_7: 'fu-xuan',
    app_rate_7: 7.48,
    avg_round_7: 8.64,
    app_flat_7: 54,
    char_8: 'ruan-mei',
    app_rate_8: 7.14,
    avg_round_8: 8.54,
    app_flat_8: 48,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 5.66,
    avg_round_9: 7.79,
    app_flat_9: 48,
    char_10: 'Sparkle',
    app_rate_10: 4.65,
    avg_round_10: 8.09,
    app_flat_10: 35,
    char_11: 'Luocha',
    app_rate_11: 4.51,
    avg_round_11: 8.37,
    app_flat_11: 37,
    char_12: 'Tingyun',
    app_rate_12: 3.64,
    avg_round_12: 7.28,
    app_flat_12: 29,
    char_13: 'Gallagher',
    app_rate_13: 2.29,
    avg_round_13: 7.33,
    app_flat_13: 18,
    char_14: 'Jade',
    app_rate_14: 1.82,
    avg_round_14: 8,
    app_flat_14: 1,
    char_15: 'silver-wolf',
    app_rate_15: 1.35,
    avg_round_15: 9,
    app_flat_15: 10,
    char_16: 'Bronya',
    app_rate_16: 0.81,
    avg_round_16: 9.5,
    app_flat_16: 8,
    char_17: 'Lynx',
    app_rate_17: 0.61,
    avg_round_17: 9,
    app_flat_17: 9,
    char_18: 'Pela',
    app_rate_18: 0.54,
    avg_round_18: 9.67,
    app_flat_18: 6,
    char_19: 'Jiaoqiu',
    app_rate_19: 0.54,
    avg_round_19: 9.33,
    app_flat_19: 3,
    char_20: 'jing-yuan',
    app_rate_20: 0.34,
    avg_round_20: 9,
    app_flat_20: 1,
    char_21: 'Herta',
    app_rate_21: 0.27,
    avg_round_21: 4,
    app_flat_21: 1,
    char_22: 'trailblazer-imaginary',
    app_rate_22: 0.2,
    avg_round_22: 9,
    app_flat_22: 2,
    char_23: 'Acheron',
    app_rate_23: 0.2,
    avg_round_23: 9,
    app_flat_23: 1,
    char_24: 'Yanqing',
    app_rate_24: 0.13,
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'Gepard',
    app_rate_25: 0.13,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Hanya',
    app_rate_26: 0.13,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'march-7th',
    app_rate_27: 0.13,
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'Blade',
    app_rate_28: 0.13,
    avg_round_28: 8,
    app_flat_28: 2,
    char_29: 'Jingliu',
    app_rate_29: 0.07,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Asta',
    app_rate_30: 0.07,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Aventurine',
    round: 7.69,
    char_1: 'Acheron',
    app_rate_1: 38.62,
    avg_round_1: 7.61,
    app_flat_1: 765,
    char_2: 'Robin',
    app_rate_2: 34.88,
    avg_round_2: 7.51,
    app_flat_2: 825,
    char_3: 'dr-ratio',
    app_rate_3: 28.95,
    avg_round_3: 8.11,
    app_flat_3: 694,
    char_4: 'topaz',
    app_rate_4: 25.43,
    avg_round_4: 7.41,
    app_flat_4: 561,
    char_5: 'Jiaoqiu',
    app_rate_5: 24.94,
    avg_round_5: 7.22,
    app_flat_5: 487,
    char_6: 'Sparkle',
    app_rate_6: 22.55,
    avg_round_6: 8.17,
    app_flat_6: 300,
    char_7: 'Pela',
    app_rate_7: 22.2,
    avg_round_7: 7.58,
    app_flat_7: 603,
    char_8: 'ruan-mei',
    app_rate_8: 12.33,
    avg_round_8: 8.38,
    app_flat_8: 248,
    char_9: 'silver-wolf',
    app_rate_9: 11.16,
    avg_round_9: 8.05,
    app_flat_9: 289,
    char_10: 'black-swan',
    app_rate_10: 10.76,
    avg_round_10: 8.1,
    app_flat_10: 241,
    char_11: 'Tingyun',
    app_rate_11: 9.83,
    avg_round_11: 8.2,
    app_flat_11: 267,
    char_12: 'Yunli',
    app_rate_12: 7.14,
    avg_round_12: 7.55,
    app_flat_12: 197,
    char_13: 'Kafka',
    app_rate_13: 7.14,
    avg_round_13: 8.31,
    app_flat_13: 151,
    char_14: 'march-7th-swordmaster',
    app_rate_14: 6.86,
    avg_round_14: 8.49,
    app_flat_14: 210,
    char_15: 'imbibitor-lunae',
    app_rate_15: 6.63,
    avg_round_15: 8.3,
    app_flat_15: 126,
    char_16: 'Clara',
    app_rate_16: 5.51,
    avg_round_16: 8.1,
    app_flat_16: 166,
    char_17: 'trailblazer-imaginary',
    app_rate_17: 4.93,
    avg_round_17: 8.91,
    app_flat_17: 107,
    char_18: 'Bronya',
    app_rate_18: 4.41,
    avg_round_18: 8.42,
    app_flat_18: 69,
    char_19: 'Boothill',
    app_rate_19: 3.08,
    avg_round_19: 8.25,
    app_flat_19: 83,
    char_20: 'Firefly',
    app_rate_20: 2.87,
    avg_round_20: 8.84,
    app_flat_20: 47,
    char_21: 'Jade',
    app_rate_21: 1.47,
    avg_round_21: 9.57,
    app_flat_21: 13,
    char_22: 'Yanqing',
    app_rate_22: 1.33,
    avg_round_22: 8.25,
    app_flat_22: 32,
    char_23: 'Jingliu',
    app_rate_23: 1.05,
    avg_round_23: 9.44,
    app_flat_23: 15,
    char_24: 'Argenti',
    app_rate_24: 0.79,
    avg_round_24: 9.44,
    app_flat_24: 15,
    char_25: 'jing-yuan',
    app_rate_25: 0.79,
    avg_round_25: 8.5,
    app_flat_25: 16,
    char_26: 'Guinaifen',
    app_rate_26: 0.63,
    avg_round_26: 8.25,
    app_flat_26: 16,
    char_27: 'Welt',
    app_rate_27: 0.58,
    avg_round_27: 9,
    app_flat_27: 12,
    char_28: 'Blade',
    app_rate_28: 0.56,
    avg_round_28: 9.4,
    app_flat_28: 9,
    char_29: 'Sampo',
    app_rate_29: 0.42,
    avg_round_29: 8.71,
    app_flat_29: 7,
    char_30: 'Hanya',
    app_rate_30: 0.4,
    avg_round_30: 8.86,
    app_flat_30: 7
  },
  {
    char: 'Acheron',
    round: 7.7,
    char_1: 'Pela',
    app_rate_1: 50.53,
    avg_round_1: 7.62,
    app_flat_1: 1604,
    char_2: 'Jiaoqiu',
    app_rate_2: 46.26,
    avg_round_2: 7.15,
    app_flat_2: 1197,
    char_3: 'fu-xuan',
    app_rate_3: 45.88,
    avg_round_3: 7.57,
    app_flat_3: 1053,
    char_4: 'Aventurine',
    app_rate_4: 28.38,
    avg_round_4: 7.61,
    app_flat_4: 765,
    char_5: 'Sparkle',
    app_rate_5: 27.78,
    avg_round_5: 7.67,
    app_flat_5: 126,
    char_6: 'silver-wolf',
    app_rate_6: 27.2,
    avg_round_6: 7.86,
    app_flat_6: 778,
    char_7: 'black-swan',
    app_rate_7: 21.62,
    avg_round_7: 8.25,
    app_flat_7: 539,
    char_8: 'Kafka',
    app_rate_8: 15.12,
    avg_round_8: 8.5,
    app_flat_8: 364,
    char_9: 'Huohuo',
    app_rate_9: 7.41,
    avg_round_9: 8.57,
    app_flat_9: 178,
    char_10: 'Gallagher',
    app_rate_10: 6.07,
    avg_round_10: 8.06,
    app_flat_10: 166,
    char_11: 'Bronya',
    app_rate_11: 5.11,
    avg_round_11: 8.5,
    app_flat_11: 2,
    char_12: 'Luocha',
    app_rate_12: 4.86,
    avg_round_12: 8.27,
    app_flat_12: 112,
    char_13: 'Gepard',
    app_rate_13: 3.64,
    avg_round_13: 8.03,
    app_flat_13: 71,
    char_14: 'Robin',
    app_rate_14: 3.35,
    avg_round_14: 6.88,
    app_flat_14: 33,
    char_15: 'ruan-mei',
    app_rate_15: 2.14,
    avg_round_15: 9.0,
    app_flat_15: 15,
    char_16: 'Guinaifen',
    app_rate_16: 0.96,
    avg_round_16: 8.88,
    app_flat_16: 32,
    char_17: 'Bailu',
    app_rate_17: 0.77,
    avg_round_17: 8.91,
    app_flat_17: 19,
    char_18: 'Welt',
    app_rate_18: 0.75,
    avg_round_18: 8.94,
    app_flat_18: 18,
    char_19: 'Lynx',
    app_rate_19: 0.36,
    avg_round_19: 5.5,
    app_flat_19: 2,
    char_20: 'Yunli',
    app_rate_20: 0.27,
    avg_round_20: 9.25,
    app_flat_20: 4,
    char_21: 'Sampo',
    app_rate_21: 0.24,
    avg_round_21: 8.86,
    app_flat_21: 7,
    char_22: 'Luka',
    app_rate_22: 0.22,
    avg_round_22: 8.86,
    app_flat_22: 13,
    char_23: 'march-7th',
    app_rate_23: 0.12,
    avg_round_23: 8,
    app_flat_23: 3,
    char_24: 'Jingliu',
    app_rate_24: 0.1,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Boothill',
    app_rate_25: 0.09,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Clara',
    app_rate_26: 0.09,
    avg_round_26: 9,
    app_flat_26: 2,
    char_27: 'Blade',
    app_rate_27: 0.07,
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'march-7th-swordmaster',
    app_rate_28: 0.07,
    avg_round_28: 8,
    app_flat_28: 2,
    char_29: 'Tingyun',
    app_rate_29: 0.05,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'dr-ratio',
    app_rate_30: 0.05,
    avg_round_30: 6,
    app_flat_30: 1
  },
  {
    char: 'Boothill',
    round: 7.7,
    char_1: 'ruan-mei',
    app_rate_1: 91.25,
    avg_round_1: 7.66,
    app_flat_1: 537,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 51.66,
    avg_round_2: 8.01,
    app_flat_2: 351,
    char_3: 'Bronya',
    app_rate_3: 45.4,
    avg_round_3: 7.29,
    app_flat_3: 238,
    char_4: 'Gallagher',
    app_rate_4: 45.12,
    avg_round_4: 7.5,
    app_flat_4: 339,
    char_5: 'Pela',
    app_rate_5: 14.83,
    avg_round_5: 8,
    app_flat_5: 24,
    char_6: 'Aventurine',
    app_rate_6: 12.15,
    avg_round_6: 8.25,
    app_flat_6: 83,
    char_7: 'Luocha',
    app_rate_7: 10.96,
    avg_round_7: 7.78,
    app_flat_7: 81,
    char_8: 'fu-xuan',
    app_rate_8: 9.58,
    avg_round_8: 8.15,
    app_flat_8: 60,
    char_9: 'Huohuo',
    app_rate_9: 4.05,
    avg_round_9: 8.23,
    app_flat_9: 30,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 2.21,
    avg_round_10: 8.07,
    app_flat_10: 14,
    char_11: 'Robin',
    app_rate_11: 1.38,
    avg_round_11: 9.25,
    app_flat_11: 4,
    char_12: 'Firefly',
    app_rate_12: 1.2,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Sparkle',
    app_rate_13: 1.1,
    avg_round_13: 9,
    app_flat_13: 7,
    char_14: 'Gepard',
    app_rate_14: 1.01,
    avg_round_14: 6.86,
    app_flat_14: 7,
    char_15: 'Jiaoqiu',
    app_rate_15: 0.83,
    avg_round_15: 6.25,
    app_flat_15: 4,
    char_16: 'silver-wolf',
    app_rate_16: 0.83,
    avg_round_16: 8.33,
    app_flat_16: 6,
    char_17: 'Yunli',
    app_rate_17: 0.83,
    avg_round_17: 9,
    app_flat_17: 3,
    char_18: 'Argenti',
    app_rate_18: 0.74,
    avg_round_18: 9,
    app_flat_18: 4,
    char_19: 'Clara',
    app_rate_19: 0.74,
    avg_round_19: 9.67,
    app_flat_19: 5,
    char_20: 'Acheron',
    app_rate_20: 0.46,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Kafka',
    app_rate_21: 0.37,
    avg_round_21: 8.5,
    app_flat_21: 2,
    char_22: 'Lynx',
    app_rate_22: 0.37,
    avg_round_22: 8,
    app_flat_22: 1,
    char_23: 'march-7th',
    app_rate_23: 0.37,
    avg_round_23: 6,
    app_flat_23: 2,
    char_24: 'Hanya',
    app_rate_24: 0.37,
    avg_round_24: 7.5,
    app_flat_24: 2,
    char_25: 'Misha',
    app_rate_25: 0.37,
    avg_round_25: 8,
    app_flat_25: 3,
    char_26: 'Luka',
    app_rate_26: 0.37,
    avg_round_26: 7,
    app_flat_26: 3,
    char_27: 'Xueyi',
    app_rate_27: 0.28,
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'black-swan',
    app_rate_28: 0.18,
    avg_round_28: 9,
    app_flat_28: 1,
    char_29: 'Blade',
    app_rate_29: 0.18,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Herta',
    app_rate_30: 0.18,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Gallagher',
    round: 7.71,
    char_1: 'ruan-mei',
    app_rate_1: 90.1,
    avg_round_1: 7.7,
    app_flat_1: 2546,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 87.15,
    avg_round_2: 7.77,
    app_flat_2: 2467,
    char_3: 'Firefly',
    app_rate_3: 81.72,
    avg_round_3: 7.73,
    app_flat_3: 2194,
    char_4: 'Boothill',
    app_rate_4: 7.54,
    avg_round_4: 7.5,
    app_flat_4: 339,
    char_5: 'Acheron',
    app_rate_5: 5.45,
    avg_round_5: 8.06,
    app_flat_5: 166,
    char_6: 'Bronya',
    app_rate_6: 3.88,
    avg_round_6: 6.97,
    app_flat_6: 134,
    char_7: 'Pela',
    app_rate_7: 3.66,
    avg_round_7: 7.76,
    app_flat_7: 140,
    char_8: 'Jiaoqiu',
    app_rate_8: 3.37,
    avg_round_8: 7.29,
    app_flat_8: 113,
    char_9: 'Robin',
    app_rate_9: 2.46,
    avg_round_9: 7.11,
    app_flat_9: 85,
    char_10: 'black-swan',
    app_rate_10: 1.77,
    avg_round_10: 8.51,
    app_flat_10: 41,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 1.77,
    avg_round_11: 9.1,
    app_flat_11: 84,
    char_12: 'Sparkle',
    app_rate_12: 1.59,
    avg_round_12: 8.52,
    app_flat_12: 25,
    char_13: 'dr-ratio',
    app_rate_13: 1.49,
    avg_round_13: 7.31,
    app_flat_13: 62,
    char_14: 'silver-wolf',
    app_rate_14: 1.42,
    avg_round_14: 8.08,
    app_flat_14: 50,
    char_15: 'Kafka',
    app_rate_15: 1.37,
    avg_round_15: 8.42,
    app_flat_15: 31,
    char_16: 'Tingyun',
    app_rate_16: 1.05,
    avg_round_16: 7.33,
    app_flat_16: 39,
    char_17: 'Yunli',
    app_rate_17: 0.72,
    avg_round_17: 7.74,
    app_flat_17: 34,
    char_18: 'topaz',
    app_rate_18: 0.52,
    avg_round_18: 7.33,
    app_flat_18: 18,
    char_19: 'imbibitor-lunae',
    app_rate_19: 0.49,
    avg_round_19: 8.88,
    app_flat_19: 8,
    char_20: 'Clara',
    app_rate_20: 0.38,
    avg_round_20: 9.44,
    app_flat_20: 15,
    char_21: 'Asta',
    app_rate_21: 0.35,
    avg_round_21: 9.6,
    app_flat_21: 9,
    char_22: 'Blade',
    app_rate_22: 0.29,
    avg_round_22: 8.2,
    app_flat_22: 9,
    char_23: 'Jingliu',
    app_rate_23: 0.29,
    avg_round_23: 9.33,
    app_flat_23: 3,
    char_24: 'Sampo',
    app_rate_24: 0.17,
    avg_round_24: 9.0,
    app_flat_24: 4,
    char_25: 'Welt',
    app_rate_25: 0.14,
    avg_round_25: 8.14,
    app_flat_25: 7,
    char_26: 'Jade',
    app_rate_26: 0.14,
    avg_round_26: 8,
    app_flat_26: 2,
    char_27: 'Himeko',
    app_rate_27: 0.11,
    avg_round_27: 10.0,
    app_flat_27: 4,
    char_28: 'Luka',
    app_rate_28: 0.09,
    avg_round_28: 10,
    app_flat_28: 2,
    char_29: 'Guinaifen',
    app_rate_29: 0.09,
    avg_round_29: 10.0,
    app_flat_29: 4,
    char_30: 'Misha',
    app_rate_30: 0.06,
    avg_round_30: 8.33,
    app_flat_30: 3
  },
  {
    char: 'Pela',
    round: 7.72,
    char_1: 'Acheron',
    app_rate_1: 81.65,
    avg_round_1: 7.62,
    app_flat_1: 1604,
    char_2: 'fu-xuan',
    app_rate_2: 43.86,
    avg_round_2: 7.61,
    app_flat_2: 790,
    char_3: 'Jiaoqiu',
    app_rate_3: 31.52,
    avg_round_3: 7.09,
    app_flat_3: 804,
    char_4: 'Aventurine',
    app_rate_4: 26.37,
    avg_round_4: 7.58,
    app_flat_4: 603,
    char_5: 'silver-wolf',
    app_rate_5: 23.95,
    avg_round_5: 8.03,
    app_flat_5: 562,
    char_6: 'Sparkle',
    app_rate_6: 14.22,
    avg_round_6: 8.72,
    app_flat_6: 70,
    char_7: 'Bronya',
    app_rate_7: 9.45,
    avg_round_7: 9.22,
    app_flat_7: 53,
    char_8: 'Huohuo',
    app_rate_8: 7.32,
    avg_round_8: 8.62,
    app_flat_8: 148,
    char_9: 'ruan-mei',
    app_rate_9: 7.15,
    avg_round_9: 9.33,
    app_flat_9: 29,
    char_10: 'Gallagher',
    app_rate_10: 6.6,
    avg_round_10: 7.76,
    app_flat_10: 140,
    char_11: 'black-swan',
    app_rate_11: 5.57,
    avg_round_11: 8.26,
    app_flat_11: 113,
    char_12: 'Luocha',
    app_rate_12: 5.35,
    avg_round_12: 8.36,
    app_flat_12: 95,
    char_13: 'Jingliu',
    app_rate_13: 5.21,
    avg_round_13: 9.47,
    app_flat_13: 58,
    char_14: 'Boothill',
    app_rate_14: 4.46,
    avg_round_14: 8,
    app_flat_14: 24,
    char_15: 'Gepard',
    app_rate_15: 3.74,
    avg_round_15: 8.07,
    app_flat_15: 59,
    char_16: 'Robin',
    app_rate_16: 3.71,
    avg_round_16: 7.91,
    app_flat_16: 64,
    char_17: 'dr-ratio',
    app_rate_17: 3.19,
    avg_round_17: 7.98,
    app_flat_17: 86,
    char_18: 'Tingyun',
    app_rate_18: 2.47,
    avg_round_18: 8.31,
    app_flat_18: 50,
    char_19: 'Kafka',
    app_rate_19: 2.33,
    avg_round_19: 8.94,
    app_flat_19: 35,
    char_20: 'Yunli',
    app_rate_20: 2.0,
    avg_round_20: 8.62,
    app_flat_20: 48,
    char_21: 'trailblazer-imaginary',
    app_rate_21: 1.64,
    avg_round_21: 9,
    app_flat_21: 4,
    char_22: 'Clara',
    app_rate_22: 1.14,
    avg_round_22: 8.56,
    app_flat_22: 25,
    char_23: 'Guinaifen',
    app_rate_23: 1.0,
    avg_round_23: 8.52,
    app_flat_23: 25,
    char_24: 'imbibitor-lunae',
    app_rate_24: 0.83,
    avg_round_24: 8.33,
    app_flat_24: 9,
    char_25: 'march-7th-swordmaster',
    app_rate_25: 0.78,
    avg_round_25: 7.85,
    app_flat_25: 20,
    char_26: 'Bailu',
    app_rate_26: 0.78,
    avg_round_26: 9.0,
    app_flat_26: 13,
    char_27: 'Firefly',
    app_rate_27: 0.69,
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'Lynx',
    app_rate_28: 0.55,
    avg_round_28: 8,
    app_flat_28: 8,
    char_29: 'Welt',
    app_rate_29: 0.42,
    avg_round_29: 8.88,
    app_flat_29: 8,
    char_30: 'Luka',
    app_rate_30: 0.3,
    avg_round_30: 9,
    app_flat_30: 9
  },
  {
    char: 'fu-xuan',
    round: 7.75,
    char_1: 'Acheron',
    app_rate_1: 60.91,
    avg_round_1: 7.57,
    app_flat_1: 1053,
    char_2: 'Pela',
    app_rate_2: 36.04,
    avg_round_2: 7.61,
    app_flat_2: 790,
    char_3: 'Sparkle',
    app_rate_3: 34.15,
    avg_round_3: 7.92,
    app_flat_3: 299,
    char_4: 'silver-wolf',
    app_rate_4: 27.13,
    avg_round_4: 7.89,
    app_flat_4: 507,
    char_5: 'Jiaoqiu',
    app_rate_5: 24.9,
    avg_round_5: 6.87,
    app_flat_5: 488,
    char_6: 'black-swan',
    app_rate_6: 13.71,
    avg_round_6: 8.3,
    app_flat_6: 238,
    char_7: 'ruan-mei',
    app_rate_7: 11.32,
    avg_round_7: 8.69,
    app_flat_7: 161,
    char_8: 'Robin',
    app_rate_8: 10.62,
    avg_round_8: 8.33,
    app_flat_8: 221,
    char_9: 'Kafka',
    app_rate_9: 10.41,
    avg_round_9: 8.65,
    app_flat_9: 181,
    char_10: 'Tingyun',
    app_rate_10: 9.91,
    avg_round_10: 7.5,
    app_flat_10: 212,
    char_11: 'Yunli',
    app_rate_11: 8.43,
    avg_round_11: 7.57,
    app_flat_11: 200,
    char_12: 'Bronya',
    app_rate_12: 7.54,
    avg_round_12: 8.71,
    app_flat_12: 69,
    char_13: 'Jingliu',
    app_rate_13: 5.13,
    avg_round_13: 8.91,
    app_flat_13: 45,
    char_14: 'Clara',
    app_rate_14: 5.06,
    avg_round_14: 8.63,
    app_flat_14: 108,
    char_15: 'imbibitor-lunae',
    app_rate_15: 4.49,
    avg_round_15: 8.26,
    app_flat_15: 68,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 4.08,
    avg_round_16: 8.66,
    app_flat_16: 105,
    char_17: 'dr-ratio',
    app_rate_17: 3.67,
    avg_round_17: 8.94,
    app_flat_17: 97,
    char_18: 'trailblazer-imaginary',
    app_rate_18: 3.44,
    avg_round_18: 8.16,
    app_flat_18: 56,
    char_19: 'Seele',
    app_rate_19: 2.62,
    avg_round_19: 8.21,
    app_flat_19: 14,
    char_20: 'topaz',
    app_rate_20: 2.53,
    avg_round_20: 8.64,
    app_flat_20: 54,
    char_21: 'Blade',
    app_rate_21: 2.48,
    avg_round_21: 8.5,
    app_flat_21: 36,
    char_22: 'Firefly',
    app_rate_22: 2.37,
    avg_round_22: 8.46,
    app_flat_22: 26,
    char_23: 'Boothill',
    app_rate_23: 2.37,
    avg_round_23: 8.15,
    app_flat_23: 60,
    char_24: 'Jade',
    app_rate_24: 1.46,
    avg_round_24: 9.22,
    app_flat_24: 17,
    char_25: 'Qingque',
    app_rate_25: 1.34,
    avg_round_25: 9.22,
    app_flat_25: 17,
    char_26: 'Guinaifen',
    app_rate_26: 0.66,
    avg_round_26: 8.89,
    app_flat_26: 15,
    char_27: 'jing-yuan',
    app_rate_27: 0.62,
    avg_round_27: 9.0,
    app_flat_27: 6,
    char_28: 'Welt',
    app_rate_28: 0.32,
    avg_round_28: 8.33,
    app_flat_28: 3,
    char_29: 'Hanya',
    app_rate_29: 0.3,
    avg_round_29: 8.5,
    app_flat_29: 6,
    char_30: 'Argenti',
    app_rate_30: 0.27,
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'Sparkle',
    round: 7.77,
    char_1: 'Acheron',
    app_rate_1: 37.01,
    avg_round_1: 7.67,
    app_flat_1: 126,
    char_2: 'fu-xuan',
    app_rate_2: 34.26,
    avg_round_2: 7.92,
    app_flat_2: 299,
    char_3: 'Tingyun',
    app_rate_3: 27.59,
    avg_round_3: 7.51,
    app_flat_3: 589,
    char_4: 'imbibitor-lunae',
    app_rate_4: 25.97,
    avg_round_4: 8.34,
    app_flat_4: 393,
    char_5: 'Huohuo',
    app_rate_5: 22.31,
    avg_round_5: 7.51,
    app_flat_5: 432,
    char_6: 'Aventurine',
    app_rate_6: 22.08,
    avg_round_6: 8.17,
    app_flat_6: 300,
    char_7: 'Jiaoqiu',
    app_rate_7: 19.63,
    avg_round_7: 7.05,
    app_flat_7: 84,
    char_8: 'Yunli',
    app_rate_8: 15.47,
    avg_round_8: 7.16,
    app_flat_8: 397,
    char_9: 'silver-wolf',
    app_rate_9: 13.44,
    avg_round_9: 8.87,
    app_flat_9: 91,
    char_10: 'Pela',
    app_rate_10: 11.73,
    avg_round_10: 8.72,
    app_flat_10: 70,
    char_11: 'Luocha',
    app_rate_11: 10.58,
    avg_round_11: 8.37,
    app_flat_11: 177,
    char_12: 'Robin',
    app_rate_12: 9.1,
    avg_round_12: 7.77,
    app_flat_12: 122,
    char_13: 'ruan-mei',
    app_rate_13: 7.31,
    avg_round_13: 8.63,
    app_flat_13: 90,
    char_14: 'Bronya',
    app_rate_14: 4.94,
    avg_round_14: 8.08,
    app_flat_14: 78,
    char_15: 'Blade',
    app_rate_15: 4.73,
    avg_round_15: 8.01,
    app_flat_15: 100,
    char_16: 'Clara',
    app_rate_16: 4.3,
    avg_round_16: 8.26,
    app_flat_16: 98,
    char_17: 'dr-ratio',
    app_rate_17: 4.3,
    avg_round_17: 8.49,
    app_flat_17: 105,
    char_18: 'Jingliu',
    app_rate_18: 3.27,
    avg_round_18: 8.61,
    app_flat_18: 38,
    char_19: 'Seele',
    app_rate_19: 2.63,
    avg_round_19: 8.57,
    app_flat_19: 13,
    char_20: 'march-7th-swordmaster',
    app_rate_20: 2.42,
    avg_round_20: 8.89,
    app_flat_20: 56,
    char_21: 'Gallagher',
    app_rate_21: 2.35,
    avg_round_21: 8.52,
    app_flat_21: 25,
    char_22: 'Lynx',
    app_rate_22: 2.22,
    avg_round_22: 7.98,
    app_flat_22: 51,
    char_23: 'black-swan',
    app_rate_23: 1.92,
    avg_round_23: 8.4,
    app_flat_23: 15,
    char_24: 'topaz',
    app_rate_24: 1.58,
    avg_round_24: 8.09,
    app_flat_24: 35,
    char_25: 'Qingque',
    app_rate_25: 1.46,
    avg_round_25: 9.36,
    app_flat_25: 19,
    char_26: 'Gepard',
    app_rate_26: 1.23,
    avg_round_26: 10,
    app_flat_26: 2,
    char_27: 'jing-yuan',
    app_rate_27: 1.03,
    avg_round_27: 8.56,
    app_flat_27: 15,
    char_28: 'Jade',
    app_rate_28: 0.62,
    avg_round_28: 8.5,
    app_flat_28: 8,
    char_29: 'Bailu',
    app_rate_29: 0.59,
    avg_round_29: 9.2,
    app_flat_29: 9,
    char_30: 'Kafka',
    app_rate_30: 0.53,
    avg_round_30: 9,
    app_flat_30: 5
  },
  {
    char: 'Firefly',
    round: 7.79,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 99.77,
    avg_round_1: 7.8,
    app_flat_1: 2411,
    char_2: 'ruan-mei',
    app_rate_2: 98.15,
    avg_round_2: 7.78,
    app_flat_2: 2374,
    char_3: 'Gallagher',
    app_rate_3: 86.26,
    avg_round_3: 7.73,
    app_flat_3: 2194,
    char_4: 'Huohuo',
    app_rate_4: 3.57,
    avg_round_4: 8.43,
    app_flat_4: 58,
    char_5: 'Luocha',
    app_rate_5: 2.32,
    avg_round_5: 8.74,
    app_flat_5: 66,
    char_6: 'Aventurine',
    app_rate_6: 2.0,
    avg_round_6: 8.84,
    app_flat_6: 47,
    char_7: 'fu-xuan',
    app_rate_7: 1.69,
    avg_round_7: 8.46,
    app_flat_7: 26,
    char_8: 'Bronya',
    app_rate_8: 1.56,
    avg_round_8: 9,
    app_flat_8: 2,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 0.8,
    avg_round_9: 8.71,
    app_flat_9: 17,
    char_10: 'Asta',
    app_rate_10: 0.55,
    avg_round_10: 9.57,
    app_flat_10: 7,
    char_11: 'Bailu',
    app_rate_11: 0.42,
    avg_round_11: 9.2,
    app_flat_11: 5,
    char_12: 'Pela',
    app_rate_12: 0.41,
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'Himeko',
    app_rate_13: 0.37,
    avg_round_13: 10,
    app_flat_13: 3,
    char_14: 'Lynx',
    app_rate_14: 0.31,
    avg_round_14: 7.67,
    app_flat_14: 9,
    char_15: 'Gepard',
    app_rate_15: 0.26,
    avg_round_15: 8.5,
    app_flat_15: 4,
    char_16: 'Boothill',
    app_rate_16: 0.21,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Robin',
    app_rate_17: 0.21,
    avg_round_17: 9.67,
    app_flat_17: 3,
    char_18: 'Sparkle',
    app_rate_18: 0.16,
    avg_round_18: 8,
    app_flat_18: 1,
    char_19: 'Jiaoqiu',
    app_rate_19: 0.1,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'black-swan',
    app_rate_20: 0.1,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'silver-wolf',
    app_rate_21: 0.08,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Yunli',
    app_rate_22: 0.08,
    avg_round_22: 8,
    app_flat_22: 1,
    char_23: 'Welt',
    app_rate_23: 0.06,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Clara',
    app_rate_24: 0.06,
    avg_round_24: 9.5,
    app_flat_24: 2,
    char_25: 'Natasha',
    app_rate_25: 0.05,
    avg_round_25: 8,
    app_flat_25: 1,
    char_26: 'march-7th',
    app_rate_26: 0.05,
    avg_round_26: 10,
    app_flat_26: 2,
    char_27: 'Hanya',
    app_rate_27: 0.03,
    avg_round_27: 8,
    app_flat_27: 1,
    char_28: 'Acheron',
    app_rate_28: 0.03,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Jingliu',
    app_rate_29: 0.03,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Tingyun',
    app_rate_30: 0.03,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-imaginary',
    round: 7.85,
    char_1: 'ruan-mei',
    app_rate_1: 97.34,
    avg_round_1: 7.83,
    app_flat_1: 2763,
    char_2: 'Firefly',
    app_rate_2: 89.89,
    avg_round_2: 7.8,
    app_flat_2: 2411,
    char_3: 'Gallagher',
    app_rate_3: 82.88,
    avg_round_3: 7.77,
    app_flat_3: 2467,
    char_4: 'Boothill',
    app_rate_4: 8.21,
    avg_round_4: 8.01,
    app_flat_4: 351,
    char_5: 'Huohuo',
    app_rate_5: 3.7,
    avg_round_5: 8.42,
    app_flat_5: 81,
    char_6: 'Luocha',
    app_rate_6: 3.16,
    avg_round_6: 8.95,
    app_flat_6: 107,
    char_7: 'Aventurine',
    app_rate_7: 3.09,
    avg_round_7: 8.91,
    app_flat_7: 107,
    char_8: 'fu-xuan',
    app_rate_8: 2.21,
    avg_round_8: 8.16,
    app_flat_8: 56,
    char_9: 'Bronya',
    app_rate_9: 2.18,
    avg_round_9: 9.08,
    app_flat_9: 22,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 1.96,
    avg_round_10: 9.28,
    app_flat_10: 75,
    char_11: 'Pela',
    app_rate_11: 0.86,
    avg_round_11: 9,
    app_flat_11: 4,
    char_12: 'Asta',
    app_rate_12: 0.5,
    avg_round_12: 9.57,
    app_flat_12: 7,
    char_13: 'Bailu',
    app_rate_13: 0.4,
    avg_round_13: 8.86,
    app_flat_13: 7,
    char_14: 'Lynx',
    app_rate_14: 0.37,
    avg_round_14: 7.7,
    app_flat_14: 10,
    char_15: 'Himeko',
    app_rate_15: 0.34,
    avg_round_15: 10,
    app_flat_15: 3,
    char_16: 'Gepard',
    app_rate_16: 0.32,
    avg_round_16: 7.11,
    app_flat_16: 9,
    char_17: 'Blade',
    app_rate_17: 0.23,
    avg_round_17: 9.57,
    app_flat_17: 13,
    char_18: 'dr-ratio',
    app_rate_18: 0.2,
    avg_round_18: 9.5,
    app_flat_18: 10,
    char_19: 'black-swan',
    app_rate_19: 0.18,
    avg_round_19: 10,
    app_flat_19: 2,
    char_20: 'Robin',
    app_rate_20: 0.18,
    avg_round_20: 10.0,
    app_flat_20: 4,
    char_21: 'Welt',
    app_rate_21: 0.15,
    avg_round_21: 10,
    app_flat_21: 2,
    char_22: 'silver-wolf',
    app_rate_22: 0.15,
    avg_round_22: 8,
    app_flat_22: 2,
    char_23: 'Clara',
    app_rate_23: 0.15,
    avg_round_23: 9.8,
    app_flat_23: 7,
    char_24: 'imbibitor-lunae',
    app_rate_24: 0.13,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Sparkle',
    app_rate_25: 0.12,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Jiaoqiu',
    app_rate_26: 0.12,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Yunli',
    app_rate_27: 0.12,
    avg_round_27: 8.67,
    app_flat_27: 3,
    char_28: 'Misha',
    app_rate_28: 0.12,
    avg_round_28: 10.0,
    app_flat_28: 4,
    char_29: 'Luka',
    app_rate_29: 0.1,
    avg_round_29: 10.0,
    app_flat_29: 4,
    char_30: 'Hanya',
    app_rate_30: 0.1,
    avg_round_30: 7.5,
    app_flat_30: 2
  },
  {
    char: 'ruan-mei',
    round: 7.89,
    char_1: 'trailblazer-imaginary',
    app_rate_1: 73.52,
    avg_round_1: 7.83,
    app_flat_1: 2763,
    char_2: 'Firefly',
    app_rate_2: 66.78,
    avg_round_2: 7.78,
    app_flat_2: 2374,
    char_3: 'Gallagher',
    app_rate_3: 64.72,
    avg_round_3: 7.7,
    app_flat_3: 2546,
    char_4: 'Boothill',
    app_rate_4: 10.95,
    avg_round_4: 7.66,
    app_flat_4: 537,
    char_5: 'Huohuo',
    app_rate_5: 9.73,
    avg_round_5: 8.65,
    app_flat_5: 303,
    char_6: 'Bronya',
    app_rate_6: 8.81,
    avg_round_6: 7.49,
    app_flat_6: 275,
    char_7: 'Luocha',
    app_rate_7: 7.16,
    avg_round_7: 8.29,
    app_flat_7: 271,
    char_8: 'black-swan',
    app_rate_8: 6.97,
    avg_round_8: 8.21,
    app_flat_8: 195,
    char_9: 'Kafka',
    app_rate_9: 6.69,
    avg_round_9: 8.5,
    app_flat_9: 194,
    char_10: 'Aventurine',
    app_rate_10: 5.84,
    avg_round_10: 8.38,
    app_flat_10: 248,
    char_11: 'fu-xuan',
    app_rate_11: 5.49,
    avg_round_11: 8.69,
    app_flat_11: 161,
    char_12: 'Jingliu',
    app_rate_12: 3.99,
    avg_round_12: 9.25,
    app_flat_12: 96,
    char_13: 'Sparkle',
    app_rate_13: 3.54,
    avg_round_13: 8.63,
    app_flat_13: 90,
    char_14: 'imbibitor-lunae',
    app_rate_14: 3.34,
    avg_round_14: 8.25,
    app_flat_14: 79,
    char_15: 'Pela',
    app_rate_15: 2.85,
    avg_round_15: 9.33,
    app_flat_15: 29,
    char_16: 'Tingyun',
    app_rate_16: 1.81,
    avg_round_16: 8.45,
    app_flat_16: 57,
    char_17: 'dr-ratio',
    app_rate_17: 1.8,
    avg_round_17: 9.0,
    app_flat_17: 86,
    char_18: 'Yunli',
    app_rate_18: 1.75,
    avg_round_18: 8.95,
    app_flat_18: 73,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 1.68,
    avg_round_19: 9.14,
    app_flat_19: 70,
    char_20: 'Blade',
    app_rate_20: 1.51,
    avg_round_20: 8.15,
    app_flat_20: 55,
    char_21: 'Acheron',
    app_rate_21: 1.38,
    avg_round_21: 9.0,
    app_flat_21: 15,
    char_22: 'Robin',
    app_rate_22: 1.23,
    avg_round_22: 8.11,
    app_flat_22: 35,
    char_23: 'topaz',
    app_rate_23: 1.17,
    avg_round_23: 8.54,
    app_flat_23: 48,
    char_24: 'Clara',
    app_rate_24: 0.84,
    avg_round_24: 8.97,
    app_flat_24: 32,
    char_25: 'Jiaoqiu',
    app_rate_25: 0.8,
    avg_round_25: 7.43,
    app_flat_25: 7,
    char_26: 'Lynx',
    app_rate_26: 0.67,
    avg_round_26: 8.36,
    app_flat_26: 26,
    char_27: 'Bailu',
    app_rate_27: 0.66,
    avg_round_27: 9.46,
    app_flat_27: 13,
    char_28: 'Gepard',
    app_rate_28: 0.54,
    avg_round_28: 8.11,
    app_flat_28: 18,
    char_29: 'Sampo',
    app_rate_29: 0.53,
    avg_round_29: 8.4,
    app_flat_29: 20,
    char_30: 'silver-wolf',
    app_rate_30: 0.46,
    avg_round_30: 9.0,
    app_flat_30: 10
  },
  {
    char: 'jing-yuan',
    round: 7.95,
    char_1: 'Tingyun',
    app_rate_1: 48.42,
    avg_round_1: 7.83,
    app_flat_1: 18,
    char_2: 'Sparkle',
    app_rate_2: 47.37,
    avg_round_2: 8.56,
    app_flat_2: 15,
    char_3: 'Aventurine',
    app_rate_3: 35.79,
    avg_round_3: 8.5,
    app_flat_3: 16,
    char_4: 'Robin',
    app_rate_4: 33.68,
    avg_round_4: 7.93,
    app_flat_4: 14,
    char_5: 'fu-xuan',
    app_rate_5: 28.42,
    avg_round_5: 9.0,
    app_flat_5: 6,
    char_6: 'Huohuo',
    app_rate_6: 22.11,
    avg_round_6: 7.0,
    app_flat_6: 6,
    char_7: 'ruan-mei',
    app_rate_7: 21.05,
    avg_round_7: 9,
    app_flat_7: 2,
    char_8: 'Clara',
    app_rate_8: 12.63,
    avg_round_8: 9.5,
    app_flat_8: 8,
    char_9: 'Luocha',
    app_rate_9: 6.32,
    avg_round_9: 9.5,
    app_flat_9: 4,
    char_10: 'topaz',
    app_rate_10: 5.26,
    avg_round_10: 9,
    app_flat_10: 1,
    char_11: 'Jiaoqiu',
    app_rate_11: 4.21,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Gallagher',
    app_rate_12: 4.21,
    avg_round_12: 8,
    app_flat_12: 1,
    char_13: 'Jade',
    app_rate_13: 4.21,
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'Pela',
    app_rate_14: 3.16,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Yunli',
    app_rate_15: 3.16,
    avg_round_15: 7,
    app_flat_15: 1,
    char_16: 'Bronya',
    app_rate_16: 3.16,
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: 'trailblazer-imaginary',
    app_rate_17: 3.16,
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: 'Jingliu',
    app_rate_18: 2.11,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 2.11,
    avg_round_19: 7.5,
    app_flat_19: 2,
    char_20: 'Firefly',
    app_rate_20: 2.11,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Acheron',
    app_rate_21: 1.05,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Herta',
    app_rate_22: 1.05,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Bailu',
    app_rate_23: 1.05,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'silver-wolf',
    app_rate_24: 1.05,
    avg_round_24: 7,
    app_flat_24: 1,
    char_25: 'Argenti',
    app_rate_25: 1.05,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'black-swan',
    app_rate_26: 1.05,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Asta',
    app_rate_27: 1.05,
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'silver-wolf',
    round: 7.96,
    char_1: 'Acheron',
    app_rate_1: 75.48,
    avg_round_1: 7.86,
    app_flat_1: 778,
    char_2: 'fu-xuan',
    app_rate_2: 56.71,
    avg_round_2: 7.89,
    app_flat_2: 507,
    char_3: 'Pela',
    app_rate_3: 41.14,
    avg_round_3: 8.03,
    app_flat_3: 562,
    char_4: 'Sparkle',
    app_rate_4: 28.0,
    avg_round_4: 8.87,
    app_flat_4: 91,
    char_5: 'Aventurine',
    app_rate_5: 22.76,
    avg_round_5: 8.05,
    app_flat_5: 289,
    char_6: 'Jiaoqiu',
    app_rate_6: 14.67,
    avg_round_6: 7.12,
    app_flat_6: 172,
    char_7: 'dr-ratio',
    app_rate_7: 12.62,
    avg_round_7: 8.75,
    app_flat_7: 163,
    char_8: 'Huohuo',
    app_rate_8: 5.67,
    avg_round_8: 8.97,
    app_flat_8: 61,
    char_9: 'Seele',
    app_rate_9: 4.67,
    avg_round_9: 8.57,
    app_flat_9: 13,
    char_10: 'Gallagher',
    app_rate_10: 4.38,
    avg_round_10: 8.08,
    app_flat_10: 50,
    char_11: 'Robin',
    app_rate_11: 4.33,
    avg_round_11: 8.67,
    app_flat_11: 39,
    char_12: 'Luocha',
    app_rate_12: 4.14,
    avg_round_12: 8.2,
    app_flat_12: 41,
    char_13: 'Qingque',
    app_rate_13: 2.76,
    avg_round_13: 9.25,
    app_flat_13: 16,
    char_14: 'Gepard',
    app_rate_14: 2.62,
    avg_round_14: 7.88,
    app_flat_14: 26,
    char_15: 'black-swan',
    app_rate_15: 2.48,
    avg_round_15: 8.75,
    app_flat_15: 20,
    char_16: 'Bronya',
    app_rate_16: 2.38,
    avg_round_16: 8.12,
    app_flat_16: 8,
    char_17: 'Tingyun',
    app_rate_17: 2.1,
    avg_round_17: 7.79,
    app_flat_17: 28,
    char_18: 'march-7th-swordmaster',
    app_rate_18: 2.1,
    avg_round_18: 8.78,
    app_flat_18: 34,
    char_19: 'ruan-mei',
    app_rate_19: 2.0,
    avg_round_19: 9.0,
    app_flat_19: 10,
    char_20: 'topaz',
    app_rate_20: 0.95,
    avg_round_20: 9,
    app_flat_20: 10,
    char_21: 'imbibitor-lunae',
    app_rate_21: 0.95,
    avg_round_21: 9.67,
    app_flat_21: 3,
    char_22: 'Kafka',
    app_rate_22: 0.9,
    avg_round_22: 9.17,
    app_flat_22: 6,
    char_23: 'Lynx',
    app_rate_23: 0.76,
    avg_round_23: 9.0,
    app_flat_23: 6,
    char_24: 'Jingliu',
    app_rate_24: 0.71,
    avg_round_24: 9,
    app_flat_24: 1,
    char_25: 'Bailu',
    app_rate_25: 0.62,
    avg_round_25: 7.67,
    app_flat_25: 6,
    char_26: 'trailblazer-imaginary',
    app_rate_26: 0.48,
    avg_round_26: 8,
    app_flat_26: 2,
    char_27: 'Welt',
    app_rate_27: 0.43,
    avg_round_27: 9.67,
    app_flat_27: 3,
    char_28: 'Boothill',
    app_rate_28: 0.43,
    avg_round_28: 8.33,
    app_flat_28: 6,
    char_29: 'Blade',
    app_rate_29: 0.43,
    avg_round_29: 8.2,
    app_flat_29: 5,
    char_30: 'Xueyi',
    app_rate_30: 0.38,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Bronya',
    round: 7.97,
    char_1: 'ruan-mei',
    app_rate_1: 44.75,
    avg_round_1: 7.49,
    app_flat_1: 275,
    char_2: 'Boothill',
    app_rate_2: 27.68,
    avg_round_2: 7.29,
    app_flat_2: 238,
    char_3: 'Jingliu',
    app_rate_3: 23.47,
    avg_round_3: 9.08,
    app_flat_3: 122,
    char_4: 'Luocha',
    app_rate_4: 22.12,
    avg_round_4: 8.11,
    app_flat_4: 178,
    char_5: 'Pela',
    app_rate_5: 19.15,
    avg_round_5: 9.22,
    app_flat_5: 53,
    char_6: 'fu-xuan',
    app_rate_6: 18.59,
    avg_round_6: 8.71,
    app_flat_6: 69,
    char_7: 'Blade',
    app_rate_7: 17.46,
    avg_round_7: 8.08,
    app_flat_7: 145,
    char_8: 'Acheron',
    app_rate_8: 16.73,
    avg_round_8: 8.5,
    app_flat_8: 2,
    char_9: 'Gallagher',
    app_rate_9: 14.15,
    avg_round_9: 6.97,
    app_flat_9: 134,
    char_10: 'Huohuo',
    app_rate_10: 13.81,
    avg_round_10: 8.78,
    app_flat_10: 101,
    char_11: 'Sparkle',
    app_rate_11: 12.13,
    avg_round_11: 8.08,
    app_flat_11: 78,
    char_12: 'Aventurine',
    app_rate_12: 10.61,
    avg_round_12: 8.42,
    app_flat_12: 69,
    char_13: 'trailblazer-imaginary',
    app_rate_13: 8.37,
    avg_round_13: 9.08,
    app_flat_13: 22,
    char_14: 'Jiaoqiu',
    app_rate_14: 8.25,
    avg_round_14: 8.5,
    app_flat_14: 8,
    char_15: 'Robin',
    app_rate_15: 6.01,
    avg_round_15: 7.47,
    app_flat_15: 30,
    char_16: 'Firefly',
    app_rate_16: 5.39,
    avg_round_16: 9,
    app_flat_16: 2,
    char_17: 'imbibitor-lunae',
    app_rate_17: 4.72,
    avg_round_17: 8.79,
    app_flat_17: 28,
    char_18: 'Jade',
    app_rate_18: 3.54,
    avg_round_18: 8.29,
    app_flat_18: 28,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 3.48,
    avg_round_19: 8.71,
    app_flat_19: 38,
    char_20: 'silver-wolf',
    app_rate_20: 2.81,
    avg_round_20: 8.12,
    app_flat_20: 8,
    char_21: 'Tingyun',
    app_rate_21: 2.47,
    avg_round_21: 9.3,
    app_flat_21: 20,
    char_22: 'dr-ratio',
    app_rate_22: 2.3,
    avg_round_22: 9.08,
    app_flat_22: 24,
    char_23: 'Lynx',
    app_rate_23: 2.08,
    avg_round_23: 8,
    app_flat_23: 19,
    char_24: 'Gepard',
    app_rate_24: 1.63,
    avg_round_24: 8,
    app_flat_24: 1,
    char_25: 'Yunli',
    app_rate_25: 1.57,
    avg_round_25: 8.64,
    app_flat_25: 14,
    char_26: 'Bailu',
    app_rate_26: 1.46,
    avg_round_26: 8.67,
    app_flat_26: 12,
    char_27: 'Clara',
    app_rate_27: 1.29,
    avg_round_27: 8.73,
    app_flat_27: 11,
    char_28: 'black-swan',
    app_rate_28: 0.84,
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'topaz',
    app_rate_29: 0.67,
    avg_round_29: 9.5,
    app_flat_29: 8,
    char_30: 'Argenti',
    app_rate_30: 0.34,
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'Yanqing',
    round: 8.07,
    char_1: 'Aventurine',
    app_rate_1: 95.0,
    avg_round_1: 8.25,
    app_flat_1: 32,
    char_2: 'Robin',
    app_rate_2: 48.33,
    avg_round_2: 8.0,
    app_flat_2: 19,
    char_3: 'Tingyun',
    app_rate_3: 43.33,
    avg_round_3: 8.0,
    app_flat_3: 15,
    char_4: 'ruan-mei',
    app_rate_4: 41.67,
    avg_round_4: 8.43,
    app_flat_4: 14,
    char_5: 'Sparkle',
    app_rate_5: 20.0,
    avg_round_5: 7.8,
    app_flat_5: 5,
    char_6: 'march-7th-swordmaster',
    app_rate_6: 13.33,
    avg_round_6: 8.75,
    app_flat_6: 6,
    char_7: 'Pela',
    app_rate_7: 8.33,
    avg_round_7: 9,
    app_flat_7: 1,
    char_8: 'Yunli',
    app_rate_8: 6.67,
    avg_round_8: 9,
    app_flat_8: 1,
    char_9: 'Bronya',
    app_rate_9: 5.0,
    avg_round_9: 8.67,
    app_flat_9: 3,
    char_10: 'Jiaoqiu',
    app_rate_10: 5.0,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'topaz',
    app_rate_11: 3.33,
    avg_round_11: 9,
    app_flat_11: 1,
    char_12: 'Hanya',
    app_rate_12: 3.33,
    avg_round_12: 8,
    app_flat_12: 2,
    char_13: 'march-7th',
    app_rate_13: 1.67,
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'silver-wolf',
    app_rate_14: 1.67,
    avg_round_14: 8,
    app_flat_14: 1,
    char_15: 'Huohuo',
    app_rate_15: 1.67,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Gepard',
    app_rate_16: 1.67,
    avg_round_16: 8,
    app_flat_16: 1,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Gepard',
    round: 8.08,
    char_1: 'Acheron',
    app_rate_1: 78.23,
    avg_round_1: 8.03,
    app_flat_1: 71,
    char_2: 'Pela',
    app_rate_2: 49.82,
    avg_round_2: 8.07,
    app_flat_2: 59,
    char_3: 'Jiaoqiu',
    app_rate_3: 31.37,
    avg_round_3: 7.88,
    app_flat_3: 33,
    char_4: 'silver-wolf',
    app_rate_4: 20.3,
    avg_round_4: 7.88,
    app_flat_4: 26,
    char_5: 'Sparkle',
    app_rate_5: 19.93,
    avg_round_5: 10,
    app_flat_5: 2,
    char_6: 'ruan-mei',
    app_rate_6: 18.08,
    avg_round_6: 8.11,
    app_flat_6: 18,
    char_7: 'Kafka',
    app_rate_7: 15.13,
    avg_round_7: 8.29,
    app_flat_7: 14,
    char_8: 'black-swan',
    app_rate_8: 15.13,
    avg_round_8: 8.2,
    app_flat_8: 15,
    char_9: 'Bronya',
    app_rate_9: 10.7,
    avg_round_9: 8,
    app_flat_9: 1,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 8.12,
    avg_round_10: 7.11,
    app_flat_10: 9,
    char_11: 'Firefly',
    app_rate_11: 5.9,
    avg_round_11: 8.5,
    app_flat_11: 4,
    char_12: 'Robin',
    app_rate_12: 4.43,
    avg_round_12: 8.83,
    app_flat_12: 6,
    char_13: 'Boothill',
    app_rate_13: 4.06,
    avg_round_13: 6.86,
    app_flat_13: 7,
    char_14: 'Yunli',
    app_rate_14: 3.32,
    avg_round_14: 10.0,
    app_flat_14: 4,
    char_15: 'Tingyun',
    app_rate_15: 2.58,
    avg_round_15: 10,
    app_flat_15: 3,
    char_16: 'Luka',
    app_rate_16: 1.85,
    avg_round_16: 8.5,
    app_flat_16: 4,
    char_17: 'dr-ratio',
    app_rate_17: 1.11,
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'Jingliu',
    app_rate_18: 1.11,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'Asta',
    app_rate_19: 1.11,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'march-7th-swordmaster',
    app_rate_20: 1.11,
    avg_round_20: 9,
    app_flat_20: 1,
    char_21: 'topaz',
    app_rate_21: 0.74,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Sampo',
    app_rate_22: 0.74,
    avg_round_22: 9,
    app_flat_22: 1,
    char_23: 'Clara',
    app_rate_23: 0.74,
    avg_round_23: 9,
    app_flat_23: 2,
    char_24: 'Gallagher',
    app_rate_24: 0.37,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Guinaifen',
    app_rate_25: 0.37,
    avg_round_25: 6,
    app_flat_25: 1,
    char_26: 'Qingque',
    app_rate_26: 0.37,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Welt',
    app_rate_27: 0.37,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Himeko',
    app_rate_28: 0.37,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Jade',
    app_rate_29: 0.37,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Bailu',
    app_rate_30: 0.37,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Huohuo',
    round: 8.09,
    char_1: 'Tingyun',
    app_rate_1: 29.74,
    avg_round_1: 7.27,
    app_flat_1: 562,
    char_2: 'Sparkle',
    app_rate_2: 29.67,
    avg_round_2: 7.51,
    app_flat_2: 432,
    char_3: 'Robin',
    app_rate_3: 29.64,
    avg_round_3: 7.35,
    app_flat_3: 539,
    char_4: 'Yunli',
    app_rate_4: 28.85,
    avg_round_4: 7.11,
    app_flat_4: 598,
    char_5: 'ruan-mei',
    app_rate_5: 26.76,
    avg_round_5: 8.65,
    app_flat_5: 303,
    char_6: 'black-swan',
    app_rate_6: 23.02,
    avg_round_6: 8.59,
    app_flat_6: 253,
    char_7: 'Kafka',
    app_rate_7: 20.77,
    avg_round_7: 8.56,
    app_flat_7: 219,
    char_8: 'Acheron',
    app_rate_8: 13.13,
    avg_round_8: 8.57,
    app_flat_8: 178,
    char_9: 'imbibitor-lunae',
    app_rate_9: 12.56,
    avg_round_9: 8.06,
    app_flat_9: 135,
    char_10: 'Pela',
    app_rate_10: 8.03,
    avg_round_10: 8.62,
    app_flat_10: 148,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 7.84,
    avg_round_11: 8.01,
    app_flat_11: 182,
    char_12: 'trailblazer-imaginary',
    app_rate_12: 7.69,
    avg_round_12: 8.42,
    app_flat_12: 81,
    char_13: 'Bronya',
    app_rate_13: 7.48,
    avg_round_13: 8.78,
    app_flat_13: 101,
    char_14: 'Jiaoqiu',
    app_rate_14: 6.78,
    avg_round_14: 8.25,
    app_flat_14: 103,
    char_15: 'Firefly',
    app_rate_15: 6.69,
    avg_round_15: 8.43,
    app_flat_15: 58,
    char_16: 'dr-ratio',
    app_rate_16: 6.23,
    avg_round_16: 8.52,
    app_flat_16: 124,
    char_17: 'Jingliu',
    app_rate_17: 6.17,
    avg_round_17: 9.21,
    app_flat_17: 68,
    char_18: 'Clara',
    app_rate_18: 5.59,
    avg_round_18: 8.18,
    app_flat_18: 108,
    char_19: 'topaz',
    app_rate_19: 4.86,
    avg_round_19: 7.47,
    app_flat_19: 86,
    char_20: 'Blade',
    app_rate_20: 4.32,
    avg_round_20: 7.9,
    app_flat_20: 70,
    char_21: 'silver-wolf',
    app_rate_21: 3.62,
    avg_round_21: 8.97,
    app_flat_21: 61,
    char_22: 'Jade',
    app_rate_22: 1.73,
    avg_round_22: 8.17,
    app_flat_22: 30,
    char_23: 'Sampo',
    app_rate_23: 1.55,
    avg_round_23: 8.59,
    app_flat_23: 27,
    char_24: 'Boothill',
    app_rate_24: 1.34,
    avg_round_24: 8.23,
    app_flat_24: 30,
    char_25: 'Hanya',
    app_rate_25: 0.82,
    avg_round_25: 8.2,
    app_flat_25: 15,
    char_26: 'Argenti',
    app_rate_26: 0.73,
    avg_round_26: 8.86,
    app_flat_26: 13,
    char_27: 'Guinaifen',
    app_rate_27: 0.64,
    avg_round_27: 8.62,
    app_flat_27: 8,
    char_28: 'jing-yuan',
    app_rate_28: 0.64,
    avg_round_28: 7.0,
    app_flat_28: 6,
    char_29: 'Asta',
    app_rate_29: 0.46,
    avg_round_29: 8.67,
    app_flat_29: 5,
    char_30: 'Welt',
    app_rate_30: 0.36,
    avg_round_30: 9.57,
    app_flat_30: 7
  },
  {
    char: 'dr-ratio',
    round: 8.12,
    char_1: 'Aventurine',
    app_rate_1: 67.91,
    avg_round_1: 8.11,
    app_flat_1: 694,
    char_2: 'Robin',
    app_rate_2: 65.61,
    avg_round_2: 7.63,
    app_flat_2: 678,
    char_3: 'topaz',
    app_rate_3: 54.38,
    avg_round_3: 7.65,
    app_flat_3: 508,
    char_4: 'march-7th-swordmaster',
    app_rate_4: 15.01,
    avg_round_4: 8.66,
    app_flat_4: 208,
    char_5: 'silver-wolf',
    app_rate_5: 14.51,
    avg_round_5: 8.75,
    app_flat_5: 163,
    char_6: 'Huohuo',
    app_rate_6: 11.23,
    avg_round_6: 8.52,
    app_flat_6: 124,
    char_7: 'Sparkle',
    app_rate_7: 10.3,
    avg_round_7: 8.49,
    app_flat_7: 105,
    char_8: 'ruan-mei',
    app_rate_8: 8.93,
    avg_round_8: 9.0,
    app_flat_8: 86,
    char_9: 'fu-xuan',
    app_rate_9: 8.82,
    avg_round_9: 8.94,
    app_flat_9: 97,
    char_10: 'Tingyun',
    app_rate_10: 6.9,
    avg_round_10: 7.98,
    app_flat_10: 89,
    char_11: 'Pela',
    app_rate_11: 6.3,
    avg_round_11: 7.98,
    app_flat_11: 86,
    char_12: 'Jiaoqiu',
    app_rate_12: 6.3,
    avg_round_12: 7.69,
    app_flat_12: 55,
    char_13: 'Luocha',
    app_rate_13: 5.75,
    avg_round_13: 8.48,
    app_flat_13: 63,
    char_14: 'Gallagher',
    app_rate_14: 5.31,
    avg_round_14: 7.31,
    app_flat_14: 62,
    char_15: 'black-swan',
    app_rate_15: 2.74,
    avg_round_15: 8.8,
    app_flat_15: 27,
    char_16: 'Clara',
    app_rate_16: 2.3,
    avg_round_16: 8.57,
    app_flat_16: 28,
    char_17: 'Bronya',
    app_rate_17: 2.25,
    avg_round_17: 9.08,
    app_flat_17: 24,
    char_18: 'Blade',
    app_rate_18: 0.99,
    avg_round_18: 9.2,
    app_flat_18: 7,
    char_19: 'trailblazer-imaginary',
    app_rate_19: 0.77,
    avg_round_19: 9.5,
    app_flat_19: 10,
    char_20: 'Yunli',
    app_rate_20: 0.6,
    avg_round_20: 8.4,
    app_flat_20: 10,
    char_21: 'Lynx',
    app_rate_21: 0.49,
    avg_round_21: 9.17,
    app_flat_21: 6,
    char_22: 'Welt',
    app_rate_22: 0.49,
    avg_round_22: 7.83,
    app_flat_22: 6,
    char_23: 'Jade',
    app_rate_23: 0.33,
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'Hanya',
    app_rate_24: 0.22,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'imbibitor-lunae',
    app_rate_25: 0.22,
    avg_round_25: 7,
    app_flat_25: 1,
    char_26: 'Guinaifen',
    app_rate_26: 0.22,
    avg_round_26: 8.5,
    app_flat_26: 4,
    char_27: 'Gepard',
    app_rate_27: 0.16,
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'Kafka',
    app_rate_28: 0.16,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Bailu',
    app_rate_29: 0.16,
    avg_round_29: 10,
    app_flat_29: 1,
    char_30: 'Acheron',
    app_rate_30: 0.16,
    avg_round_30: 6,
    app_flat_30: 1
  },
  {
    char: 'Lynx',
    round: 8.12,
    char_1: 'Sparkle',
    app_rate_1: 37.89,
    avg_round_1: 7.98,
    app_flat_1: 51,
    char_2: 'Yunli',
    app_rate_2: 34.77,
    avg_round_2: 8.1,
    app_flat_2: 67,
    char_3: 'Tingyun',
    app_rate_3: 28.52,
    avg_round_3: 8.04,
    app_flat_3: 56,
    char_4: 'Robin',
    app_rate_4: 27.73,
    avg_round_4: 8.79,
    app_flat_4: 48,
    char_5: 'ruan-mei',
    app_rate_5: 23.83,
    avg_round_5: 8.36,
    app_flat_5: 26,
    char_6: 'Blade',
    app_rate_6: 19.14,
    avg_round_6: 8.08,
    app_flat_6: 24,
    char_7: 'march-7th-swordmaster',
    app_rate_7: 14.84,
    avg_round_7: 8.93,
    app_flat_7: 27,
    char_8: 'Bronya',
    app_rate_8: 14.45,
    avg_round_8: 8,
    app_flat_8: 19,
    char_9: 'Clara',
    app_rate_9: 10.55,
    avg_round_9: 8.56,
    app_flat_9: 16,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 9.77,
    avg_round_10: 7.7,
    app_flat_10: 10,
    char_11: 'Acheron',
    app_rate_11: 8.2,
    avg_round_11: 5.5,
    app_flat_11: 2,
    char_12: 'Pela',
    app_rate_12: 7.81,
    avg_round_12: 8,
    app_flat_12: 8,
    char_13: 'Kafka',
    app_rate_13: 7.81,
    avg_round_13: 9.0,
    app_flat_13: 6,
    char_14: 'Firefly',
    app_rate_14: 7.42,
    avg_round_14: 7.67,
    app_flat_14: 9,
    char_15: 'black-swan',
    app_rate_15: 7.42,
    avg_round_15: 9.0,
    app_flat_15: 6,
    char_16: 'silver-wolf',
    app_rate_16: 6.25,
    avg_round_16: 9.0,
    app_flat_16: 6,
    char_17: 'imbibitor-lunae',
    app_rate_17: 5.47,
    avg_round_17: 9.25,
    app_flat_17: 6,
    char_18: 'Jingliu',
    app_rate_18: 5.08,
    avg_round_18: 8.6,
    app_flat_18: 5,
    char_19: 'Jiaoqiu',
    app_rate_19: 4.3,
    avg_round_19: 7,
    app_flat_19: 3,
    char_20: 'dr-ratio',
    app_rate_20: 3.52,
    avg_round_20: 9.17,
    app_flat_20: 6,
    char_21: 'topaz',
    app_rate_21: 3.52,
    avg_round_21: 9,
    app_flat_21: 9,
    char_22: 'Jade',
    app_rate_22: 3.52,
    avg_round_22: 9,
    app_flat_22: 3,
    char_23: 'Boothill',
    app_rate_23: 1.56,
    avg_round_23: 8,
    app_flat_23: 1,
    char_24: 'Qingque',
    app_rate_24: 0.78,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Misha',
    app_rate_25: 0.78,
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'Hanya',
    app_rate_26: 0.78,
    avg_round_26: 9.5,
    app_flat_26: 2,
    char_27: 'Asta',
    app_rate_27: 0.78,
    avg_round_27: 9,
    app_flat_27: 2,
    char_28: 'Gallagher',
    app_rate_28: 0.39,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'trailblazer-fire',
    app_rate_29: 0.39,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Welt',
    app_rate_30: 0.39,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Blade',
    round: 8.14,
    char_1: 'Bronya',
    app_rate_1: 53.34,
    avg_round_1: 8.08,
    app_flat_1: 145,
    char_2: 'Luocha',
    app_rate_2: 38.77,
    avg_round_2: 8.43,
    app_flat_2: 107,
    char_3: 'Sparkle',
    app_rate_3: 35.51,
    avg_round_3: 8.01,
    app_flat_3: 100,
    char_4: 'Huohuo',
    app_rate_4: 24.36,
    avg_round_4: 7.9,
    app_flat_4: 70,
    char_5: 'ruan-mei',
    app_rate_5: 23.5,
    avg_round_5: 8.15,
    app_flat_5: 55,
    char_6: 'Jade',
    app_rate_6: 20.24,
    avg_round_6: 8.14,
    app_flat_6: 44,
    char_7: 'fu-xuan',
    app_rate_7: 18.7,
    avg_round_7: 8.5,
    app_flat_7: 36,
    char_8: 'Robin',
    app_rate_8: 14.58,
    avg_round_8: 7.74,
    app_flat_8: 34,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 13.55,
    avg_round_9: 8.46,
    app_flat_9: 46,
    char_10: 'Jingliu',
    app_rate_10: 9.26,
    avg_round_10: 8.42,
    app_flat_10: 12,
    char_11: 'Lynx',
    app_rate_11: 8.4,
    avg_round_11: 8.08,
    app_flat_11: 24,
    char_12: 'Jiaoqiu',
    app_rate_12: 4.63,
    avg_round_12: 8.75,
    app_flat_12: 8,
    char_13: 'imbibitor-lunae',
    app_rate_13: 4.29,
    avg_round_13: 8.8,
    app_flat_13: 10,
    char_14: 'Clara',
    app_rate_14: 4.29,
    avg_round_14: 9.71,
    app_flat_14: 11,
    char_15: 'Aventurine',
    app_rate_15: 4.12,
    avg_round_15: 9.4,
    app_flat_15: 9,
    char_16: 'Gallagher',
    app_rate_16: 3.26,
    avg_round_16: 8.2,
    app_flat_16: 9,
    char_17: 'dr-ratio',
    app_rate_17: 3.09,
    avg_round_17: 9.2,
    app_flat_17: 7,
    char_18: 'trailblazer-imaginary',
    app_rate_18: 2.74,
    avg_round_18: 9.57,
    app_flat_18: 13,
    char_19: 'Yunli',
    app_rate_19: 2.57,
    avg_round_19: 9.25,
    app_flat_19: 6,
    char_20: 'black-swan',
    app_rate_20: 1.89,
    avg_round_20: 9.33,
    app_flat_20: 3,
    char_21: 'Pela',
    app_rate_21: 1.89,
    avg_round_21: 9.67,
    app_flat_21: 5,
    char_22: 'silver-wolf',
    app_rate_22: 1.54,
    avg_round_22: 8.2,
    app_flat_22: 5,
    char_23: 'Tingyun',
    app_rate_23: 1.37,
    avg_round_23: 7.83,
    app_flat_23: 6,
    char_24: 'Bailu',
    app_rate_24: 1.2,
    avg_round_24: 7.0,
    app_flat_24: 4,
    char_25: 'Acheron',
    app_rate_25: 0.69,
    avg_round_25: 9,
    app_flat_25: 1,
    char_26: 'Welt',
    app_rate_26: 0.51,
    avg_round_26: 7,
    app_flat_26: 1,
    char_27: 'Kafka',
    app_rate_27: 0.51,
    avg_round_27: 9.33,
    app_flat_27: 3,
    char_28: 'Boothill',
    app_rate_28: 0.34,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'topaz',
    app_rate_29: 0.34,
    avg_round_29: 8,
    app_flat_29: 2,
    char_30: 'Argenti',
    app_rate_30: 0.17,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Clara',
    round: 8.16,
    char_1: 'Robin',
    app_rate_1: 58.26,
    avg_round_1: 8.29,
    app_flat_1: 323,
    char_2: 'Aventurine',
    app_rate_2: 28.47,
    avg_round_2: 8.1,
    app_flat_2: 166,
    char_3: 'fu-xuan',
    app_rate_3: 26.78,
    avg_round_3: 8.63,
    app_flat_3: 108,
    char_4: 'Tingyun',
    app_rate_4: 23.76,
    avg_round_4: 8.15,
    app_flat_4: 125,
    char_5: 'Sparkle',
    app_rate_5: 22.68,
    avg_round_5: 8.26,
    app_flat_5: 98,
    char_6: 'Huohuo',
    app_rate_6: 22.2,
    avg_round_6: 8.18,
    app_flat_6: 108,
    char_7: 'topaz',
    app_rate_7: 18.7,
    avg_round_7: 7.73,
    app_flat_7: 88,
    char_8: 'march-7th-swordmaster',
    app_rate_8: 17.37,
    avg_round_8: 8.67,
    app_flat_8: 104,
    char_9: 'Yunli',
    app_rate_9: 14.72,
    avg_round_9: 8.47,
    app_flat_9: 83,
    char_10: 'Luocha',
    app_rate_10: 13.63,
    avg_round_10: 8.9,
    app_flat_10: 75,
    char_11: 'ruan-mei',
    app_rate_11: 9.17,
    avg_round_11: 8.97,
    app_flat_11: 32,
    char_12: 'dr-ratio',
    app_rate_12: 5.07,
    avg_round_12: 8.57,
    app_flat_12: 28,
    char_13: 'Pela',
    app_rate_13: 4.95,
    avg_round_13: 8.56,
    app_flat_13: 25,
    char_14: 'Jingliu',
    app_rate_14: 4.7,
    avg_round_14: 9.2,
    app_flat_14: 18,
    char_15: 'Jade',
    app_rate_15: 4.22,
    avg_round_15: 10.0,
    app_flat_15: 9,
    char_16: 'Lynx',
    app_rate_16: 3.26,
    avg_round_16: 8.56,
    app_flat_16: 16,
    char_17: 'Gallagher',
    app_rate_17: 3.02,
    avg_round_17: 9.44,
    app_flat_17: 15,
    char_18: 'Blade',
    app_rate_18: 3.02,
    avg_round_18: 9.71,
    app_flat_18: 11,
    char_19: 'Bronya',
    app_rate_19: 2.77,
    avg_round_19: 8.73,
    app_flat_19: 11,
    char_20: 'Bailu',
    app_rate_20: 1.57,
    avg_round_20: 8.78,
    app_flat_20: 9,
    char_21: 'jing-yuan',
    app_rate_21: 1.45,
    avg_round_21: 9.5,
    app_flat_21: 8,
    char_22: 'trailblazer-imaginary',
    app_rate_22: 1.21,
    avg_round_22: 9.8,
    app_flat_22: 7,
    char_23: 'Argenti',
    app_rate_23: 1.09,
    avg_round_23: 10.0,
    app_flat_23: 4,
    char_24: 'Boothill',
    app_rate_24: 0.97,
    avg_round_24: 9.67,
    app_flat_24: 5,
    char_25: 'silver-wolf',
    app_rate_25: 0.72,
    avg_round_25: 9.33,
    app_flat_25: 3,
    char_26: 'Hanya',
    app_rate_26: 0.72,
    avg_round_26: 7.25,
    app_flat_26: 4,
    char_27: 'Acheron',
    app_rate_27: 0.6,
    avg_round_27: 9,
    app_flat_27: 2,
    char_28: 'black-swan',
    app_rate_28: 0.6,
    avg_round_28: 8,
    app_flat_28: 3,
    char_29: 'imbibitor-lunae',
    app_rate_29: 0.6,
    avg_round_29: 8.33,
    app_flat_29: 3,
    char_30: 'Jiaoqiu',
    app_rate_30: 0.48,
    avg_round_30: 7.5,
    app_flat_30: 2
  },
  {
    char: 'Seele',
    round: 8.21,
    char_1: 'Sparkle',
    app_rate_1: 91.27,
    avg_round_1: 8.57,
    app_flat_1: 13,
    char_2: 'fu-xuan',
    app_rate_2: 91.27,
    avg_round_2: 8.21,
    app_flat_2: 14,
    char_3: 'silver-wolf',
    app_rate_3: 77.78,
    avg_round_3: 8.57,
    app_flat_3: 13,
    char_4: 'Tingyun',
    app_rate_4: 11.11,
    avg_round_4: 8,
    app_flat_4: 3,
    char_5: 'Robin',
    app_rate_5: 10.32,
    avg_round_5: 9,
    app_flat_5: 1,
    char_6: 'Huohuo',
    app_rate_6: 4.76,
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'Jade',
    app_rate_7: 3.17,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'ruan-mei',
    app_rate_8: 3.17,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'march-7th-swordmaster',
    app_rate_9: 1.59,
    avg_round_9: 9,
    app_flat_9: 1,
    char_10: 'Clara',
    app_rate_10: 0.79,
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'Firefly',
    app_rate_11: 0.79,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Pela',
    app_rate_12: 0.79,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Yukong',
    app_rate_13: 0.79,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Yunli',
    app_rate_14: 0.79,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Jiaoqiu',
    app_rate_15: 0.79,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Bailu',
    app_rate_16: 0.79,
    avg_round_16: 9,
    app_flat_16: 1,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'black-swan',
    round: 8.24,
    char_1: 'Kafka',
    app_rate_1: 71.46,
    avg_round_1: 8.33,
    app_flat_1: 606,
    char_2: 'Acheron',
    app_rate_2: 53.5,
    avg_round_2: 8.25,
    app_flat_2: 539,
    char_3: 'Huohuo',
    app_rate_3: 32.14,
    avg_round_3: 8.59,
    app_flat_3: 253,
    char_4: 'ruan-mei',
    app_rate_4: 26.79,
    avg_round_4: 8.21,
    app_flat_4: 195,
    char_5: 'fu-xuan',
    app_rate_5: 25.56,
    avg_round_5: 8.3,
    app_flat_5: 238,
    char_6: 'Aventurine',
    app_rate_6: 19.58,
    avg_round_6: 8.1,
    app_flat_6: 241,
    char_7: 'Luocha',
    app_rate_7: 12.99,
    avg_round_7: 8.42,
    app_flat_7: 111,
    char_8: 'Jiaoqiu',
    app_rate_8: 11.8,
    avg_round_8: 7.75,
    app_flat_8: 125,
    char_9: 'Robin',
    app_rate_9: 9.26,
    avg_round_9: 8.12,
    app_flat_9: 83,
    char_10: 'Pela',
    app_rate_10: 8.54,
    avg_round_10: 8.26,
    app_flat_10: 113,
    char_11: 'Gallagher',
    app_rate_11: 4.88,
    avg_round_11: 8.51,
    app_flat_11: 41,
    char_12: 'Sampo',
    app_rate_12: 3.65,
    avg_round_12: 8.81,
    app_flat_12: 41,
    char_13: 'Sparkle',
    app_rate_13: 3.57,
    avg_round_13: 8.4,
    app_flat_13: 15,
    char_14: 'silver-wolf',
    app_rate_14: 2.21,
    avg_round_14: 8.75,
    app_flat_14: 20,
    char_15: 'dr-ratio',
    app_rate_15: 2.12,
    avg_round_15: 8.8,
    app_flat_15: 27,
    char_16: 'Gepard',
    app_rate_16: 1.74,
    avg_round_16: 8.2,
    app_flat_16: 15,
    char_17: 'Tingyun',
    app_rate_17: 1.61,
    avg_round_17: 8.38,
    app_flat_17: 14,
    char_18: 'Bailu',
    app_rate_18: 1.36,
    avg_round_18: 9,
    app_flat_18: 10,
    char_19: 'Guinaifen',
    app_rate_19: 1.23,
    avg_round_19: 8.2,
    app_flat_19: 10,
    char_20: 'Asta',
    app_rate_20: 0.98,
    avg_round_20: 9.0,
    app_flat_20: 10,
    char_21: 'Lynx',
    app_rate_21: 0.81,
    avg_round_21: 9.0,
    app_flat_21: 6,
    char_22: 'Bronya',
    app_rate_22: 0.64,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'march-7th-swordmaster',
    app_rate_23: 0.59,
    avg_round_23: 8.57,
    app_flat_23: 7,
    char_24: 'trailblazer-imaginary',
    app_rate_24: 0.51,
    avg_round_24: 10,
    app_flat_24: 2,
    char_25: 'Blade',
    app_rate_25: 0.47,
    avg_round_25: 9.33,
    app_flat_25: 3,
    char_26: 'Yunli',
    app_rate_26: 0.47,
    avg_round_26: 8.57,
    app_flat_26: 7,
    char_27: 'Luka',
    app_rate_27: 0.25,
    avg_round_27: 7,
    app_flat_27: 2,
    char_28: 'Welt',
    app_rate_28: 0.25,
    avg_round_28: 9.33,
    app_flat_28: 3,
    char_29: 'Firefly',
    app_rate_29: 0.25,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Clara',
    app_rate_30: 0.21,
    avg_round_30: 8,
    app_flat_30: 3
  },
  {
    char: 'Jade',
    round: 8.28,
    char_1: 'Robin',
    app_rate_1: 45.66,
    avg_round_1: 8.95,
    app_flat_1: 38,
    char_2: 'Blade',
    app_rate_2: 44.53,
    avg_round_2: 8.14,
    app_flat_2: 44,
    char_3: 'fu-xuan',
    app_rate_3: 24.15,
    avg_round_3: 9.22,
    app_flat_3: 17,
    char_4: 'Aventurine',
    app_rate_4: 23.77,
    avg_round_4: 9.57,
    app_flat_4: 13,
    char_5: 'Bronya',
    app_rate_5: 23.77,
    avg_round_5: 8.29,
    app_flat_5: 28,
    char_6: 'Huohuo',
    app_rate_6: 21.51,
    avg_round_6: 8.17,
    app_flat_6: 30,
    char_7: 'Yunli',
    app_rate_7: 20.38,
    avg_round_7: 8.93,
    app_flat_7: 28,
    char_8: 'Luocha',
    app_rate_8: 20.0,
    avg_round_8: 8.18,
    app_flat_8: 17,
    char_9: 'Clara',
    app_rate_9: 13.21,
    avg_round_9: 10.0,
    app_flat_9: 9,
    char_10: 'ruan-mei',
    app_rate_10: 12.45,
    avg_round_10: 7.67,
    app_flat_10: 6,
    char_11: 'Sparkle',
    app_rate_11: 10.19,
    avg_round_11: 8.5,
    app_flat_11: 8,
    char_12: 'topaz',
    app_rate_12: 10.19,
    avg_round_12: 8,
    app_flat_12: 1,
    char_13: 'Lynx',
    app_rate_13: 3.4,
    avg_round_13: 9,
    app_flat_13: 3,
    char_14: 'Gallagher',
    app_rate_14: 3.4,
    avg_round_14: 8,
    app_flat_14: 2,
    char_15: 'Jingliu',
    app_rate_15: 2.64,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'silver-wolf',
    app_rate_16: 2.64,
    avg_round_16: 10,
    app_flat_16: 2,
    char_17: 'Herta',
    app_rate_17: 2.64,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'dr-ratio',
    app_rate_18: 2.26,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 2.26,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Bailu',
    app_rate_20: 1.89,
    avg_round_20: 8.5,
    app_flat_20: 2,
    char_21: 'Seele',
    app_rate_21: 1.51,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'jing-yuan',
    app_rate_22: 1.51,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Pela',
    app_rate_23: 1.13,
    avg_round_23: 9,
    app_flat_23: 1,
    char_24: 'Tingyun',
    app_rate_24: 1.13,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Argenti',
    app_rate_25: 0.75,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Boothill',
    app_rate_26: 0.38,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Jiaoqiu',
    app_rate_27: 0.38,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Himeko',
    app_rate_28: 0.38,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Natasha',
    app_rate_29: 0.38,
    avg_round_29: 99.99,
    app_flat_29: 0,
    char_30: 'Gepard',
    app_rate_30: 0.38,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'imbibitor-lunae',
    round: 8.35,
    char_1: 'Sparkle',
    app_rate_1: 86.13,
    avg_round_1: 8.34,
    app_flat_1: 393,
    char_2: 'Tingyun',
    app_rate_2: 55.5,
    avg_round_2: 8.03,
    app_flat_2: 272,
    char_3: 'Huohuo',
    app_rate_3: 31.31,
    avg_round_3: 8.06,
    app_flat_3: 135,
    char_4: 'Luocha',
    app_rate_4: 25.25,
    avg_round_4: 8.5,
    app_flat_4: 96,
    char_5: 'ruan-mei',
    app_rate_5: 22.9,
    avg_round_5: 8.25,
    app_flat_5: 79,
    char_6: 'Aventurine',
    app_rate_6: 21.53,
    avg_round_6: 8.3,
    app_flat_6: 126,
    char_7: 'fu-xuan',
    app_rate_7: 14.94,
    avg_round_7: 8.26,
    app_flat_7: 68,
    char_8: 'Robin',
    app_rate_8: 10.77,
    avg_round_8: 7.64,
    app_flat_8: 28,
    char_9: 'Bronya',
    app_rate_9: 6.37,
    avg_round_9: 8.79,
    app_flat_9: 28,
    char_10: 'Hanya',
    app_rate_10: 3.87,
    avg_round_10: 8.53,
    app_flat_10: 19,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 3.49,
    avg_round_11: 9.18,
    app_flat_11: 21,
    char_12: 'Jiaoqiu',
    app_rate_12: 2.96,
    avg_round_12: 9.0,
    app_flat_12: 8,
    char_13: 'Gallagher',
    app_rate_13: 2.43,
    avg_round_13: 8.88,
    app_flat_13: 8,
    char_14: 'Pela',
    app_rate_14: 2.27,
    avg_round_14: 8.33,
    app_flat_14: 9,
    char_15: 'Yukong',
    app_rate_15: 2.12,
    avg_round_15: 8.2,
    app_flat_15: 5,
    char_16: 'Blade',
    app_rate_16: 1.9,
    avg_round_16: 8.8,
    app_flat_16: 10,
    char_17: 'silver-wolf',
    app_rate_17: 1.52,
    avg_round_17: 9.67,
    app_flat_17: 3,
    char_18: 'Lynx',
    app_rate_18: 1.06,
    avg_round_18: 9.25,
    app_flat_18: 6,
    char_19: 'Bailu',
    app_rate_19: 0.83,
    avg_round_19: 9.33,
    app_flat_19: 3,
    char_20: 'trailblazer-imaginary',
    app_rate_20: 0.68,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Welt',
    app_rate_21: 0.53,
    avg_round_21: 9,
    app_flat_21: 3,
    char_22: 'Yunli',
    app_rate_22: 0.45,
    avg_round_22: 9,
    app_flat_22: 1,
    char_23: 'Clara',
    app_rate_23: 0.38,
    avg_round_23: 8.33,
    app_flat_23: 3,
    char_24: 'black-swan',
    app_rate_24: 0.3,
    avg_round_24: 7,
    app_flat_24: 1,
    char_25: 'dr-ratio',
    app_rate_25: 0.3,
    avg_round_25: 7,
    app_flat_25: 1,
    char_26: 'Argenti',
    app_rate_26: 0.08,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Gepard',
    app_rate_27: 0.08,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Luocha',
    round: 8.42,
    char_1: 'ruan-mei',
    app_rate_1: 36.51,
    avg_round_1: 8.29,
    app_flat_1: 271,
    char_2: 'Sparkle',
    app_rate_2: 26.08,
    avg_round_2: 8.37,
    app_flat_2: 177,
    char_3: 'Bronya',
    app_rate_3: 22.2,
    avg_round_3: 8.11,
    app_flat_3: 178,
    char_4: 'imbibitor-lunae',
    app_rate_4: 18.76,
    avg_round_4: 8.5,
    app_flat_4: 96,
    char_5: 'black-swan',
    app_rate_5: 17.24,
    avg_round_5: 8.42,
    app_flat_5: 111,
    char_6: 'Robin',
    app_rate_6: 16.9,
    avg_round_6: 7.89,
    app_flat_6: 165,
    char_7: 'Acheron',
    app_rate_7: 15.94,
    avg_round_7: 8.27,
    app_flat_7: 112,
    char_8: 'Tingyun',
    app_rate_8: 14.99,
    avg_round_8: 7.74,
    app_flat_8: 120,
    char_9: 'Kafka',
    app_rate_9: 14.31,
    avg_round_9: 8.91,
    app_flat_9: 88,
    char_10: 'Blade',
    app_rate_10: 12.73,
    avg_round_10: 8.43,
    app_flat_10: 107,
    char_11: 'trailblazer-imaginary',
    app_rate_11: 12.17,
    avg_round_11: 8.95,
    app_flat_11: 107,
    char_12: 'Jingliu',
    app_rate_12: 10.99,
    avg_round_12: 9.18,
    app_flat_12: 63,
    char_13: 'Pela',
    app_rate_13: 10.87,
    avg_round_13: 8.36,
    app_flat_13: 95,
    char_14: 'Jiaoqiu',
    app_rate_14: 10.48,
    avg_round_14: 7.95,
    app_flat_14: 76,
    char_15: 'Yunli',
    app_rate_15: 8.51,
    avg_round_15: 7.43,
    app_flat_15: 103,
    char_16: 'Firefly',
    app_rate_16: 8.06,
    avg_round_16: 8.74,
    app_flat_16: 66,
    char_17: 'Boothill',
    app_rate_17: 6.7,
    avg_round_17: 7.78,
    app_flat_17: 81,
    char_18: 'Clara',
    app_rate_18: 6.37,
    avg_round_18: 8.9,
    app_flat_18: 75,
    char_19: 'march-7th-swordmaster',
    app_rate_19: 6.14,
    avg_round_19: 8.51,
    app_flat_19: 71,
    char_20: 'dr-ratio',
    app_rate_20: 5.92,
    avg_round_20: 8.48,
    app_flat_20: 63,
    char_21: 'silver-wolf',
    app_rate_21: 4.9,
    avg_round_21: 8.2,
    app_flat_21: 41,
    char_22: 'topaz',
    app_rate_22: 3.77,
    avg_round_22: 8.37,
    app_flat_22: 37,
    char_23: 'Jade',
    app_rate_23: 2.99,
    avg_round_23: 8.18,
    app_flat_23: 17,
    char_24: 'Hanya',
    app_rate_24: 1.24,
    avg_round_24: 8.17,
    app_flat_24: 6,
    char_25: 'Sampo',
    app_rate_25: 0.9,
    avg_round_25: 8.67,
    app_flat_25: 5,
    char_26: 'Argenti',
    app_rate_26: 0.73,
    avg_round_26: 9,
    app_flat_26: 3,
    char_27: 'Yukong',
    app_rate_27: 0.51,
    avg_round_27: 5,
    app_flat_27: 1,
    char_28: 'Welt',
    app_rate_28: 0.51,
    avg_round_28: 8.8,
    app_flat_28: 5,
    char_29: 'Luka',
    app_rate_29: 0.45,
    avg_round_29: 9.0,
    app_flat_29: 4,
    char_30: 'Aventurine',
    app_rate_30: 0.45,
    avg_round_30: 9,
    app_flat_30: 2
  },
  {
    char: 'Hanya',
    round: 8.43,
    char_1: 'imbibitor-lunae',
    app_rate_1: 57.3,
    avg_round_1: 8.53,
    app_flat_1: 19,
    char_2: 'Tingyun',
    app_rate_2: 30.34,
    avg_round_2: 9.33,
    app_flat_2: 15,
    char_3: 'Huohuo',
    app_rate_3: 30.34,
    avg_round_3: 8.2,
    app_flat_3: 15,
    char_4: 'Luocha',
    app_rate_4: 24.72,
    avg_round_4: 8.17,
    app_flat_4: 6,
    char_5: 'Aventurine',
    app_rate_5: 19.1,
    avg_round_5: 8.86,
    app_flat_5: 7,
    char_6: 'Sparkle',
    app_rate_6: 19.1,
    avg_round_6: 8.4,
    app_flat_6: 7,
    char_7: 'fu-xuan',
    app_rate_7: 14.61,
    avg_round_7: 8.5,
    app_flat_7: 6,
    char_8: 'ruan-mei',
    app_rate_8: 13.48,
    avg_round_8: 8,
    app_flat_8: 4,
    char_9: 'Yunli',
    app_rate_9: 8.99,
    avg_round_9: 7.8,
    app_flat_9: 5,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 7.87,
    avg_round_10: 7.5,
    app_flat_10: 2,
    char_11: 'Robin',
    app_rate_11: 6.74,
    avg_round_11: 7.25,
    app_flat_11: 6,
    char_12: 'Clara',
    app_rate_12: 6.74,
    avg_round_12: 7.25,
    app_flat_12: 4,
    char_13: 'Pela',
    app_rate_13: 4.49,
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: 'dr-ratio',
    app_rate_14: 4.49,
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'Boothill',
    app_rate_15: 4.49,
    avg_round_15: 7.5,
    app_flat_15: 2,
    char_16: 'Bronya',
    app_rate_16: 4.49,
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'black-swan',
    app_rate_17: 3.37,
    avg_round_17: 9,
    app_flat_17: 2,
    char_18: 'Argenti',
    app_rate_18: 3.37,
    avg_round_18: 9.5,
    app_flat_18: 2,
    char_19: 'Misha',
    app_rate_19: 3.37,
    avg_round_19: 9,
    app_flat_19: 3,
    char_20: 'Yukong',
    app_rate_20: 3.37,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Kafka',
    app_rate_21: 2.25,
    avg_round_21: 8,
    app_flat_21: 1,
    char_22: 'Natasha',
    app_rate_22: 2.25,
    avg_round_22: 8,
    app_flat_22: 2,
    char_23: 'Firefly',
    app_rate_23: 2.25,
    avg_round_23: 8,
    app_flat_23: 1,
    char_24: 'Asta',
    app_rate_24: 2.25,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'march-7th-swordmaster',
    app_rate_25: 2.25,
    avg_round_25: 9,
    app_flat_25: 1,
    char_26: 'Yanqing',
    app_rate_26: 2.25,
    avg_round_26: 8,
    app_flat_26: 2,
    char_27: 'Gallagher',
    app_rate_27: 2.25,
    avg_round_27: 9,
    app_flat_27: 2,
    char_28: 'topaz',
    app_rate_28: 2.25,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Lynx',
    app_rate_29: 2.25,
    avg_round_29: 9.5,
    app_flat_29: 2,
    char_30: 'Jingliu',
    app_rate_30: 2.25,
    avg_round_30: 10,
    app_flat_30: 1
  },
  {
    char: 'march-7th-swordmaster',
    round: 8.52,
    char_1: 'Robin',
    app_rate_1: 47.27,
    avg_round_1: 8.31,
    app_flat_1: 359,
    char_2: 'Aventurine',
    app_rate_2: 28.13,
    avg_round_2: 8.49,
    app_flat_2: 210,
    char_3: 'dr-ratio',
    app_rate_3: 26.22,
    avg_round_3: 8.66,
    app_flat_3: 208,
    char_4: 'Huohuo',
    app_rate_4: 24.69,
    avg_round_4: 8.01,
    app_flat_4: 182,
    char_5: 'Yunli',
    app_rate_5: 21.34,
    avg_round_5: 7.85,
    app_flat_5: 161,
    char_6: 'fu-xuan',
    app_rate_6: 17.13,
    avg_round_6: 8.66,
    app_flat_6: 105,
    char_7: 'ruan-mei',
    app_rate_7: 14.55,
    avg_round_7: 9.14,
    app_flat_7: 70,
    char_8: 'Clara',
    app_rate_8: 13.78,
    avg_round_8: 8.67,
    app_flat_8: 104,
    char_9: 'trailblazer-imaginary',
    app_rate_9: 12.82,
    avg_round_9: 9.28,
    app_flat_9: 75,
    char_10: 'Gallagher',
    app_rate_10: 11.0,
    avg_round_10: 9.1,
    app_flat_10: 84,
    char_11: 'Luocha',
    app_rate_11: 10.43,
    avg_round_11: 8.51,
    app_flat_11: 71,
    char_12: 'Sparkle',
    app_rate_12: 10.14,
    avg_round_12: 8.89,
    app_flat_12: 56,
    char_13: 'topaz',
    app_rate_13: 8.04,
    avg_round_13: 7.79,
    app_flat_13: 48,
    char_14: 'Blade',
    app_rate_14: 7.56,
    avg_round_14: 8.46,
    app_flat_14: 46,
    char_15: 'Tingyun',
    app_rate_15: 7.08,
    avg_round_15: 8.36,
    app_flat_15: 56,
    char_16: 'Bronya',
    app_rate_16: 5.93,
    avg_round_16: 8.71,
    app_flat_16: 38,
    char_17: 'Firefly',
    app_rate_17: 4.69,
    avg_round_17: 8.71,
    app_flat_17: 17,
    char_18: 'imbibitor-lunae',
    app_rate_18: 4.4,
    avg_round_18: 9.18,
    app_flat_18: 21,
    char_19: 'silver-wolf',
    app_rate_19: 4.21,
    avg_round_19: 8.78,
    app_flat_19: 34,
    char_20: 'Lynx',
    app_rate_20: 3.64,
    avg_round_20: 8.93,
    app_flat_20: 27,
    char_21: 'Pela',
    app_rate_21: 2.68,
    avg_round_21: 7.85,
    app_flat_21: 20,
    char_22: 'Boothill',
    app_rate_22: 2.3,
    avg_round_22: 8.07,
    app_flat_22: 14,
    char_23: 'Jingliu',
    app_rate_23: 1.82,
    avg_round_23: 9.3,
    app_flat_23: 10,
    char_24: 'black-swan',
    app_rate_24: 1.34,
    avg_round_24: 8.57,
    app_flat_24: 7,
    char_25: 'Bailu',
    app_rate_25: 1.05,
    avg_round_25: 8.62,
    app_flat_25: 8,
    char_26: 'Welt',
    app_rate_26: 0.96,
    avg_round_26: 10.0,
    app_flat_26: 4,
    char_27: 'Jiaoqiu',
    app_rate_27: 0.96,
    avg_round_27: 8.14,
    app_flat_27: 7,
    char_28: 'Sushang',
    app_rate_28: 0.86,
    avg_round_28: 8.5,
    app_flat_28: 4,
    char_29: 'Yanqing',
    app_rate_29: 0.77,
    avg_round_29: 8.75,
    app_flat_29: 6,
    char_30: 'dan-heng',
    app_rate_30: 0.57,
    avg_round_30: 8.33,
    app_flat_30: 3
  },
  {
    char: 'Sampo',
    round: 8.52,
    char_1: 'black-swan',
    app_rate_1: 75.44,
    avg_round_1: 8.81,
    app_flat_1: 41,
    char_2: 'Kafka',
    app_rate_2: 47.37,
    avg_round_2: 8.78,
    app_flat_2: 18,
    char_3: 'Huohuo',
    app_rate_3: 44.74,
    avg_round_3: 8.59,
    app_flat_3: 27,
    char_4: 'ruan-mei',
    app_rate_4: 42.11,
    avg_round_4: 8.4,
    app_flat_4: 20,
    char_5: 'Aventurine',
    app_rate_5: 15.79,
    avg_round_5: 8.71,
    app_flat_5: 7,
    char_6: 'Luocha',
    app_rate_6: 14.04,
    avg_round_6: 8.67,
    app_flat_6: 5,
    char_7: 'Acheron',
    app_rate_7: 12.28,
    avg_round_7: 8.86,
    app_flat_7: 7,
    char_8: 'Jiaoqiu',
    app_rate_8: 10.53,
    avg_round_8: 7.25,
    app_flat_8: 4,
    char_9: 'fu-xuan',
    app_rate_9: 9.65,
    avg_round_9: 7.75,
    app_flat_9: 4,
    char_10: 'Gallagher',
    app_rate_10: 9.65,
    avg_round_10: 9.0,
    app_flat_10: 4,
    char_11: 'Robin',
    app_rate_11: 5.26,
    avg_round_11: 9.0,
    app_flat_11: 4,
    char_12: 'Bailu',
    app_rate_12: 3.51,
    avg_round_12: 9.5,
    app_flat_12: 2,
    char_13: 'Pela',
    app_rate_13: 1.75,
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'Gepard',
    app_rate_14: 1.75,
    avg_round_14: 9,
    app_flat_14: 1,
    char_15: 'march-7th-swordmaster',
    app_rate_15: 0.88,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'trailblazer-imaginary',
    app_rate_16: 0.88,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Sparkle',
    app_rate_17: 0.88,
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: 'dr-ratio',
    app_rate_18: 0.88,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'Guinaifen',
    app_rate_19: 0.88,
    avg_round_19: 8,
    app_flat_19: 1,
    char_20: 'Asta',
    app_rate_20: 0.88,
    avg_round_20: 9,
    app_flat_20: 1,
    char_21: 'Tingyun',
    app_rate_21: 0.88,
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Guinaifen',
    round: 8.61,
    char_1: 'Acheron',
    app_rate_1: 64.37,
    avg_round_1: 8.88,
    app_flat_1: 32,
    char_2: 'Pela',
    app_rate_2: 41.38,
    avg_round_2: 8.52,
    app_flat_2: 25,
    char_3: 'black-swan',
    app_rate_3: 33.33,
    avg_round_3: 8.2,
    app_flat_3: 10,
    char_4: 'fu-xuan',
    app_rate_4: 33.33,
    avg_round_4: 8.89,
    app_flat_4: 15,
    char_5: 'Kafka',
    app_rate_5: 32.18,
    avg_round_5: 8.4,
    app_flat_5: 10,
    char_6: 'Aventurine',
    app_rate_6: 31.03,
    avg_round_6: 8.25,
    app_flat_6: 16,
    char_7: 'Huohuo',
    app_rate_7: 24.14,
    avg_round_7: 8.62,
    app_flat_7: 8,
    char_8: 'Gallagher',
    app_rate_8: 6.9,
    avg_round_8: 10.0,
    app_flat_8: 4,
    char_9: 'Jiaoqiu',
    app_rate_9: 6.9,
    avg_round_9: 9,
    app_flat_9: 1,
    char_10: 'dr-ratio',
    app_rate_10: 4.6,
    avg_round_10: 8.5,
    app_flat_10: 4,
    char_11: 'Sparkle',
    app_rate_11: 3.45,
    avg_round_11: 9,
    app_flat_11: 1,
    char_12: 'ruan-mei',
    app_rate_12: 2.3,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Luocha',
    app_rate_13: 2.3,
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'Tingyun',
    app_rate_14: 2.3,
    avg_round_14: 7.5,
    app_flat_14: 2,
    char_15: 'Robin',
    app_rate_15: 2.3,
    avg_round_15: 8,
    app_flat_15: 1,
    char_16: 'Gepard',
    app_rate_16: 1.15,
    avg_round_16: 6,
    app_flat_16: 1,
    char_17: 'Bronya',
    app_rate_17: 1.15,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Firefly',
    app_rate_18: 1.15,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'trailblazer-imaginary',
    app_rate_19: 1.15,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Sampo',
    app_rate_20: 1.15,
    avg_round_20: 8,
    app_flat_20: 1,
    char_21: 'Jingliu',
    app_rate_21: 1.15,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'silver-wolf',
    app_rate_22: 1.15,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Lynx',
    app_rate_23: 1.15,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Kafka',
    round: 8.65,
    char_1: 'black-swan',
    app_rate_1: 88.44,
    avg_round_1: 8.33,
    app_flat_1: 606,
    char_2: 'Acheron',
    app_rate_2: 46.3,
    avg_round_2: 8.5,
    app_flat_2: 364,
    char_3: 'Huohuo',
    app_rate_3: 35.89,
    avg_round_3: 8.56,
    app_flat_3: 219,
    char_4: 'ruan-mei',
    app_rate_4: 31.79,
    avg_round_4: 8.5,
    app_flat_4: 194,
    char_5: 'fu-xuan',
    app_rate_5: 24.01,
    avg_round_5: 8.65,
    app_flat_5: 181,
    char_6: 'Aventurine',
    app_rate_6: 16.08,
    avg_round_6: 8.31,
    app_flat_6: 151,
    char_7: 'Luocha',
    app_rate_7: 13.35,
    avg_round_7: 8.91,
    app_flat_7: 88,
    char_8: 'Robin',
    app_rate_8: 10.19,
    avg_round_8: 8.14,
    app_flat_8: 73,
    char_9: 'Jiaoqiu',
    app_rate_9: 6.78,
    avg_round_9: 8.35,
    app_flat_9: 43,
    char_10: 'Gallagher',
    app_rate_10: 4.68,
    avg_round_10: 8.42,
    app_flat_10: 31,
    char_11: 'Pela',
    app_rate_11: 4.41,
    avg_round_11: 8.94,
    app_flat_11: 35,
    char_12: 'Sampo',
    app_rate_12: 2.84,
    avg_round_12: 8.78,
    app_flat_12: 18,
    char_13: 'Gepard',
    app_rate_13: 2.15,
    avg_round_13: 8.29,
    app_flat_13: 14,
    char_14: 'Bailu',
    app_rate_14: 1.79,
    avg_round_14: 9,
    app_flat_14: 9,
    char_15: 'Tingyun',
    app_rate_15: 1.63,
    avg_round_15: 8.67,
    app_flat_15: 6,
    char_16: 'Guinaifen',
    app_rate_16: 1.47,
    avg_round_16: 8.4,
    app_flat_16: 10,
    char_17: 'Asta',
    app_rate_17: 1.26,
    avg_round_17: 9.0,
    app_flat_17: 11,
    char_18: 'Sparkle',
    app_rate_18: 1.21,
    avg_round_18: 9,
    app_flat_18: 5,
    char_19: 'Luka',
    app_rate_19: 1.16,
    avg_round_19: 8,
    app_flat_19: 11,
    char_20: 'Lynx',
    app_rate_20: 1.05,
    avg_round_20: 9.0,
    app_flat_20: 6,
    char_21: 'silver-wolf',
    app_rate_21: 1.0,
    avg_round_21: 9.17,
    app_flat_21: 6,
    char_22: 'Yunli',
    app_rate_22: 0.42,
    avg_round_22: 8.5,
    app_flat_22: 2,
    char_23: 'march-7th-swordmaster',
    app_rate_23: 0.32,
    avg_round_23: 8,
    app_flat_23: 1,
    char_24: 'Boothill',
    app_rate_24: 0.21,
    avg_round_24: 8.5,
    app_flat_24: 2,
    char_25: 'Bronya',
    app_rate_25: 0.21,
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'dr-ratio',
    app_rate_26: 0.16,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Jingliu',
    app_rate_27: 0.16,
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'Clara',
    app_rate_28: 0.16,
    avg_round_28: 9.33,
    app_flat_28: 3,
    char_29: 'Blade',
    app_rate_29: 0.16,
    avg_round_29: 9.33,
    app_flat_29: 3,
    char_30: 'Hanya',
    app_rate_30: 0.11,
    avg_round_30: 8,
    app_flat_30: 1
  },
  {
    char: 'Luka',
    round: 8.67,
    char_1: 'ruan-mei',
    app_rate_1: 48.0,
    avg_round_1: 8.27,
    app_flat_1: 11,
    char_2: 'Kafka',
    app_rate_2: 44.0,
    avg_round_2: 8,
    app_flat_2: 11,
    char_3: 'Acheron',
    app_rate_3: 26.0,
    avg_round_3: 8.86,
    app_flat_3: 13,
    char_4: 'Pela',
    app_rate_4: 22.0,
    avg_round_4: 9,
    app_flat_4: 9,
    char_5: 'fu-xuan',
    app_rate_5: 20.0,
    avg_round_5: 8.56,
    app_flat_5: 9,
    char_6: 'Huohuo',
    app_rate_6: 18.0,
    avg_round_6: 7.67,
    app_flat_6: 6,
    char_7: 'Luocha',
    app_rate_7: 16.0,
    avg_round_7: 9.0,
    app_flat_7: 4,
    char_8: 'Aventurine',
    app_rate_8: 14.0,
    avg_round_8: 8.25,
    app_flat_8: 4,
    char_9: 'trailblazer-imaginary',
    app_rate_9: 14.0,
    avg_round_9: 10.0,
    app_flat_9: 4,
    char_10: 'black-swan',
    app_rate_10: 12.0,
    avg_round_10: 7,
    app_flat_10: 2,
    char_11: 'Gallagher',
    app_rate_11: 12.0,
    avg_round_11: 10,
    app_flat_11: 2,
    char_12: 'Gepard',
    app_rate_12: 10.0,
    avg_round_12: 8.5,
    app_flat_12: 4,
    char_13: 'Boothill',
    app_rate_13: 8.0,
    avg_round_13: 7,
    app_flat_13: 3,
    char_14: 'Jiaoqiu',
    app_rate_14: 6.0,
    avg_round_14: 7.67,
    app_flat_14: 3,
    char_15: 'Misha',
    app_rate_15: 6.0,
    avg_round_15: 10,
    app_flat_15: 1,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 4.0,
    avg_round_16: 10,
    app_flat_16: 2,
    char_17: 'Bailu',
    app_rate_17: 4.0,
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'Clara',
    app_rate_18: 4.0,
    avg_round_18: 9,
    app_flat_18: 1,
    char_19: 'march-7th',
    app_rate_19: 4.0,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'silver-wolf',
    app_rate_20: 2.0,
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'Tingyun',
    app_rate_21: 2.0,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Sparkle',
    app_rate_22: 2.0,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Bronya',
    app_rate_23: 2.0,
    avg_round_23: 8,
    app_flat_23: 1,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Bailu',
    round: 8.92,
    char_1: 'ruan-mei',
    app_rate_1: 37.27,
    avg_round_1: 9.46,
    app_flat_1: 13,
    char_2: 'Acheron',
    app_rate_2: 27.95,
    avg_round_2: 8.91,
    app_flat_2: 19,
    char_3: 'Kafka',
    app_rate_3: 21.12,
    avg_round_3: 9,
    app_flat_3: 9,
    char_4: 'black-swan',
    app_rate_4: 19.88,
    avg_round_4: 9,
    app_flat_4: 10,
    char_5: 'Pela',
    app_rate_5: 17.39,
    avg_round_5: 9.0,
    app_flat_5: 13,
    char_6: 'trailblazer-imaginary',
    app_rate_6: 16.77,
    avg_round_6: 8.86,
    app_flat_6: 7,
    char_7: 'Bronya',
    app_rate_7: 16.15,
    avg_round_7: 8.67,
    app_flat_7: 12,
    char_8: 'Sparkle',
    app_rate_8: 16.15,
    avg_round_8: 9.2,
    app_flat_8: 9,
    char_9: 'Firefly',
    app_rate_9: 16.15,
    avg_round_9: 9.2,
    app_flat_9: 5,
    char_10: 'Jingliu',
    app_rate_10: 15.53,
    avg_round_10: 9.6,
    app_flat_10: 9,
    char_11: 'Robin',
    app_rate_11: 14.29,
    avg_round_11: 8.55,
    app_flat_11: 11,
    char_12: 'Yunli',
    app_rate_12: 11.18,
    avg_round_12: 8.54,
    app_flat_12: 13,
    char_13: 'Jiaoqiu',
    app_rate_13: 9.94,
    avg_round_13: 8.8,
    app_flat_13: 9,
    char_14: 'Tingyun',
    app_rate_14: 9.32,
    avg_round_14: 9,
    app_flat_14: 8,
    char_15: 'silver-wolf',
    app_rate_15: 8.07,
    avg_round_15: 7.67,
    app_flat_15: 6,
    char_16: 'Clara',
    app_rate_16: 8.07,
    avg_round_16: 8.78,
    app_flat_16: 9,
    char_17: 'imbibitor-lunae',
    app_rate_17: 6.83,
    avg_round_17: 9.33,
    app_flat_17: 3,
    char_18: 'march-7th-swordmaster',
    app_rate_18: 6.83,
    avg_round_18: 8.62,
    app_flat_18: 8,
    char_19: 'Blade',
    app_rate_19: 4.35,
    avg_round_19: 7.0,
    app_flat_19: 4,
    char_20: 'Jade',
    app_rate_20: 3.11,
    avg_round_20: 8.5,
    app_flat_20: 2,
    char_21: 'Sampo',
    app_rate_21: 2.48,
    avg_round_21: 9.5,
    app_flat_21: 2,
    char_22: 'dr-ratio',
    app_rate_22: 1.86,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Boothill',
    app_rate_23: 1.24,
    avg_round_23: 8,
    app_flat_23: 2,
    char_24: 'Luka',
    app_rate_24: 1.24,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Hanya',
    app_rate_25: 0.62,
    avg_round_25: 7,
    app_flat_25: 1,
    char_26: 'Hook',
    app_rate_26: 0.62,
    avg_round_26: 9,
    app_flat_26: 1,
    char_27: 'Asta',
    app_rate_27: 0.62,
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: 'Welt',
    app_rate_28: 0.62,
    avg_round_28: 99.99,
    app_flat_28: 0,
    char_29: 'Xueyi',
    app_rate_29: 0.62,
    avg_round_29: 9,
    app_flat_29: 1,
    char_30: 'jing-yuan',
    app_rate_30: 0.62,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Argenti',
    round: 9.14,
    char_1: 'Tingyun',
    app_rate_1: 51.76,
    avg_round_1: 8.82,
    app_flat_1: 21,
    char_2: 'Aventurine',
    app_rate_2: 40.0,
    avg_round_2: 9.44,
    app_flat_2: 15,
    char_3: 'ruan-mei',
    app_rate_3: 30.59,
    avg_round_3: 9.22,
    app_flat_3: 9,
    char_4: 'Huohuo',
    app_rate_4: 28.24,
    avg_round_4: 8.86,
    app_flat_4: 13,
    char_5: 'Robin',
    app_rate_5: 27.06,
    avg_round_5: 10.0,
    app_flat_5: 5,
    char_6: 'Sparkle',
    app_rate_6: 23.53,
    avg_round_6: 8.18,
    app_flat_6: 11,
    char_7: 'Luocha',
    app_rate_7: 15.29,
    avg_round_7: 9,
    app_flat_7: 3,
    char_8: 'fu-xuan',
    app_rate_8: 14.12,
    avg_round_8: 10,
    app_flat_8: 1,
    char_9: 'Clara',
    app_rate_9: 10.59,
    avg_round_9: 10.0,
    app_flat_9: 4,
    char_10: 'Boothill',
    app_rate_10: 9.41,
    avg_round_10: 9,
    app_flat_10: 4,
    char_11: 'Jiaoqiu',
    app_rate_11: 9.41,
    avg_round_11: 8,
    app_flat_11: 2,
    char_12: 'Bronya',
    app_rate_12: 7.06,
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'Yunli',
    app_rate_13: 7.06,
    avg_round_13: 9.33,
    app_flat_13: 3,
    char_14: 'Jingliu',
    app_rate_14: 5.88,
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'Hanya',
    app_rate_15: 3.53,
    avg_round_15: 9.5,
    app_flat_15: 2,
    char_16: 'Acheron',
    app_rate_16: 2.35,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Jade',
    app_rate_17: 2.35,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Gallagher',
    app_rate_18: 2.35,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Herta',
    app_rate_19: 1.18,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'imbibitor-lunae',
    app_rate_20: 1.18,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Blade',
    app_rate_21: 1.18,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'march-7th-swordmaster',
    app_rate_22: 1.18,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Welt',
    app_rate_23: 1.18,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'silver-wolf',
    app_rate_24: 1.18,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'jing-yuan',
    app_rate_25: 1.18,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Pela',
    app_rate_26: 1.18,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Welt',
    round: 9.14,
    char_1: 'Acheron',
    app_rate_1: 51.76,
    avg_round_1: 8.94,
    app_flat_1: 18,
    char_2: 'Aventurine',
    app_rate_2: 29.41,
    avg_round_2: 9,
    app_flat_2: 12,
    char_3: 'Sparkle',
    app_rate_3: 27.06,
    avg_round_3: 8.25,
    app_flat_3: 4,
    char_4: 'Pela',
    app_rate_4: 17.65,
    avg_round_4: 8.88,
    app_flat_4: 8,
    char_5: 'fu-xuan',
    app_rate_5: 16.47,
    avg_round_5: 8.33,
    app_flat_5: 3,
    char_6: 'Jiaoqiu',
    app_rate_6: 16.47,
    avg_round_6: 9,
    app_flat_6: 8,
    char_7: 'ruan-mei',
    app_rate_7: 15.29,
    avg_round_7: 9.33,
    app_flat_7: 3,
    char_8: 'Huohuo',
    app_rate_8: 14.12,
    avg_round_8: 9.57,
    app_flat_8: 7,
    char_9: 'trailblazer-imaginary',
    app_rate_9: 11.76,
    avg_round_9: 10,
    app_flat_9: 2,
    char_10: 'march-7th-swordmaster',
    app_rate_10: 11.76,
    avg_round_10: 10.0,
    app_flat_10: 4,
    char_11: 'silver-wolf',
    app_rate_11: 10.59,
    avg_round_11: 9.67,
    app_flat_11: 3,
    char_12: 'Luocha',
    app_rate_12: 10.59,
    avg_round_12: 8.8,
    app_flat_12: 5,
    char_13: 'Gallagher',
    app_rate_13: 10.59,
    avg_round_13: 8.14,
    app_flat_13: 7,
    char_14: 'dr-ratio',
    app_rate_14: 10.59,
    avg_round_14: 7.83,
    app_flat_14: 6,
    char_15: 'imbibitor-lunae',
    app_rate_15: 8.24,
    avg_round_15: 9,
    app_flat_15: 3,
    char_16: 'black-swan',
    app_rate_16: 7.06,
    avg_round_16: 9.33,
    app_flat_16: 3,
    char_17: 'Bronya',
    app_rate_17: 7.06,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Firefly',
    app_rate_18: 4.71,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Blade',
    app_rate_19: 3.53,
    avg_round_19: 7,
    app_flat_19: 1,
    char_20: 'Tingyun',
    app_rate_20: 3.53,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Kafka',
    app_rate_21: 2.35,
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: 'Robin',
    app_rate_22: 2.35,
    avg_round_22: 5.5,
    app_flat_22: 2,
    char_23: 'Bailu',
    app_rate_23: 1.18,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Lynx',
    app_rate_24: 1.18,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Gepard',
    app_rate_25: 1.18,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'Argenti',
    app_rate_26: 1.18,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Yunli',
    app_rate_27: 1.18,
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: 'Clara',
    app_rate_28: 1.18,
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Jingliu',
    round: 9.21,
    char_1: 'Bronya',
    app_rate_1: 55.81,
    avg_round_1: 9.08,
    app_flat_1: 122,
    char_2: 'ruan-mei',
    app_rate_2: 48.2,
    avg_round_2: 9.25,
    app_flat_2: 96,
    char_3: 'fu-xuan',
    app_rate_3: 30.04,
    avg_round_3: 8.91,
    app_flat_3: 45,
    char_4: 'Huohuo',
    app_rate_4: 27.1,
    avg_round_4: 9.21,
    app_flat_4: 68,
    char_5: 'Luocha',
    app_rate_5: 26.03,
    avg_round_5: 9.18,
    app_flat_5: 63,
    char_6: 'Pela',
    app_rate_6: 25.1,
    avg_round_6: 9.47,
    app_flat_6: 58,
    char_7: 'Sparkle',
    app_rate_7: 19.09,
    avg_round_7: 8.61,
    app_flat_7: 38,
    char_8: 'Robin',
    app_rate_8: 18.42,
    avg_round_8: 9.0,
    app_flat_8: 36,
    char_9: 'Blade',
    app_rate_9: 7.21,
    avg_round_9: 8.42,
    app_flat_9: 12,
    char_10: 'Yunli',
    app_rate_10: 6.54,
    avg_round_10: 9.33,
    app_flat_10: 17,
    char_11: 'Tingyun',
    app_rate_11: 6.41,
    avg_round_11: 9.4,
    app_flat_11: 9,
    char_12: 'Aventurine',
    app_rate_12: 6.01,
    avg_round_12: 9.44,
    app_flat_12: 15,
    char_13: 'Clara',
    app_rate_13: 5.21,
    avg_round_13: 9.2,
    app_flat_13: 18,
    char_14: 'Bailu',
    app_rate_14: 3.34,
    avg_round_14: 9.6,
    app_flat_14: 9,
    char_15: 'march-7th-swordmaster',
    app_rate_15: 2.54,
    avg_round_15: 9.3,
    app_flat_15: 10,
    char_16: 'Gallagher',
    app_rate_16: 2.54,
    avg_round_16: 9.33,
    app_flat_16: 3,
    char_17: 'silver-wolf',
    app_rate_17: 2.0,
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: 'Lynx',
    app_rate_18: 1.74,
    avg_round_18: 8.6,
    app_flat_18: 5,
    char_19: 'Jade',
    app_rate_19: 0.93,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Acheron',
    app_rate_20: 0.8,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Argenti',
    app_rate_21: 0.67,
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'Jiaoqiu',
    app_rate_22: 0.67,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'march-7th',
    app_rate_23: 0.67,
    avg_round_23: 9.5,
    app_flat_23: 2,
    char_24: 'Kafka',
    app_rate_24: 0.4,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Gepard',
    app_rate_25: 0.4,
    avg_round_25: 10,
    app_flat_25: 1,
    char_26: 'jing-yuan',
    app_rate_26: 0.27,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Firefly',
    app_rate_27: 0.27,
    avg_round_27: 99.99,
    app_flat_27: 0,
    char_28: 'Hanya',
    app_rate_28: 0.27,
    avg_round_28: 10,
    app_flat_28: 1,
    char_29: 'Boothill',
    app_rate_29: 0.27,
    avg_round_29: 8,
    app_flat_29: 1,
    char_30: 'topaz',
    app_rate_30: 0.13,
    avg_round_30: 99.99,
    app_flat_30: 0
  },
  {
    char: 'Qingque',
    round: 9.3,
    char_1: 'Sparkle',
    app_rate_1: 100.0,
    avg_round_1: 9.36,
    app_flat_1: 19,
    char_2: 'fu-xuan',
    app_rate_2: 92.19,
    avg_round_2: 9.22,
    app_flat_2: 17,
    char_3: 'silver-wolf',
    app_rate_3: 90.62,
    avg_round_3: 9.25,
    app_flat_3: 16,
    char_4: 'Pela',
    app_rate_4: 4.69,
    avg_round_4: 10,
    app_flat_4: 1,
    char_5: 'Lynx',
    app_rate_5: 3.12,
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: 'Misha',
    app_rate_6: 1.56,
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'Gepard',
    app_rate_7: 1.56,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Luocha',
    app_rate_8: 1.56,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Tingyun',
    app_rate_9: 1.56,
    avg_round_9: 4,
    app_flat_9: 1,
    char_10: 'Huohuo',
    app_rate_10: 1.56,
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'ruan-mei',
    app_rate_11: 1.56,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'march-7th',
    round: 9.44,
    char_1: 'ruan-mei',
    app_rate_1: 53.33,
    avg_round_1: 9.67,
    app_flat_1: 10,
    char_2: 'Pela',
    app_rate_2: 30.0,
    avg_round_2: 8.33,
    app_flat_2: 6,
    char_3: 'Acheron',
    app_rate_3: 23.33,
    avg_round_3: 8,
    app_flat_3: 3,
    char_4: 'silver-wolf',
    app_rate_4: 20.0,
    avg_round_4: 8,
    app_flat_4: 3,
    char_5: 'Robin',
    app_rate_5: 20.0,
    avg_round_5: 10,
    app_flat_5: 3,
    char_6: 'trailblazer-imaginary',
    app_rate_6: 20.0,
    avg_round_6: 10.0,
    app_flat_6: 5,
    char_7: 'Jingliu',
    app_rate_7: 16.67,
    avg_round_7: 9.5,
    app_flat_7: 2,
    char_8: 'Boothill',
    app_rate_8: 13.33,
    avg_round_8: 6,
    app_flat_8: 2,
    char_9: 'Sparkle',
    app_rate_9: 10.0,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Yunli',
    app_rate_10: 10.0,
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'Clara',
    app_rate_11: 10.0,
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'Firefly',
    app_rate_12: 10.0,
    avg_round_12: 10,
    app_flat_12: 2,
    char_13: 'Misha',
    app_rate_13: 10.0,
    avg_round_13: 9.67,
    app_flat_13: 3,
    char_14: 'Sushang',
    app_rate_14: 6.67,
    avg_round_14: 8.5,
    app_flat_14: 2,
    char_15: 'Kafka',
    app_rate_15: 6.67,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Tingyun',
    app_rate_16: 6.67,
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'topaz',
    app_rate_17: 6.67,
    avg_round_17: 10,
    app_flat_17: 1,
    char_18: 'Luka',
    app_rate_18: 6.67,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'fu-xuan',
    app_rate_19: 3.33,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Aventurine',
    app_rate_20: 3.33,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Jiaoqiu',
    app_rate_21: 3.33,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: 'Yanqing',
    app_rate_22: 3.33,
    avg_round_22: 10,
    app_flat_22: 1,
    char_23: 'Bronya',
    app_rate_23: 3.33,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'dr-ratio',
    app_rate_24: 3.33,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Asta',
    round: 9.5,
    char_1: 'Firefly',
    app_rate_1: 50.75,
    avg_round_1: 9.57,
    app_flat_1: 7,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 50.75,
    avg_round_2: 9.57,
    app_flat_2: 7,
    char_3: 'Kafka',
    app_rate_3: 35.82,
    avg_round_3: 9.0,
    app_flat_3: 11,
    char_4: 'black-swan',
    app_rate_4: 34.33,
    avg_round_4: 9.0,
    app_flat_4: 10,
    char_5: 'Gallagher',
    app_rate_5: 34.33,
    avg_round_5: 9.6,
    app_flat_5: 9,
    char_6: 'Huohuo',
    app_rate_6: 22.39,
    avg_round_6: 8.67,
    app_flat_6: 5,
    char_7: 'ruan-mei',
    app_rate_7: 16.42,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'Aventurine',
    app_rate_8: 7.46,
    avg_round_8: 9.33,
    app_flat_8: 3,
    char_9: 'Luocha',
    app_rate_9: 5.97,
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'fu-xuan',
    app_rate_10: 4.48,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Robin',
    app_rate_11: 4.48,
    avg_round_11: 6,
    app_flat_11: 1,
    char_12: 'Gepard',
    app_rate_12: 4.48,
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'Hook',
    app_rate_13: 2.99,
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'Hanya',
    app_rate_14: 2.99,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Lynx',
    app_rate_15: 2.99,
    avg_round_15: 9,
    app_flat_15: 2,
    char_16: 'Pela',
    app_rate_16: 2.99,
    avg_round_16: 10,
    app_flat_16: 1,
    char_17: 'Bronya',
    app_rate_17: 1.49,
    avg_round_17: 9,
    app_flat_17: 1,
    char_18: 'Bailu',
    app_rate_18: 1.49,
    avg_round_18: 9,
    app_flat_18: 1,
    char_19: 'topaz',
    app_rate_19: 1.49,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Clara',
    app_rate_20: 1.49,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Himeko',
    app_rate_21: 1.49,
    avg_round_21: 6,
    app_flat_21: 1,
    char_22: 'Sampo',
    app_rate_22: 1.49,
    avg_round_22: 9,
    app_flat_22: 1,
    char_23: 'Boothill',
    app_rate_23: 1.49,
    avg_round_23: 99.99,
    app_flat_23: 0,
    char_24: 'Jingliu',
    app_rate_24: 1.49,
    avg_round_24: 10,
    app_flat_24: 1,
    char_25: 'Acheron',
    app_rate_25: 1.49,
    avg_round_25: 99.99,
    app_flat_25: 0,
    char_26: 'jing-yuan',
    app_rate_26: 1.49,
    avg_round_26: 10,
    app_flat_26: 1,
    char_27: 'Tingyun',
    app_rate_27: 1.49,
    avg_round_27: 10,
    app_flat_27: 1,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yukong',
    round: 99.99,
    char_1: 'imbibitor-lunae',
    app_rate_1: 77.78,
    avg_round_1: 8.2,
    app_flat_1: 5,
    char_2: 'Sparkle',
    app_rate_2: 36.11,
    avg_round_2: 7.5,
    app_flat_2: 2,
    char_3: 'Luocha',
    app_rate_3: 25.0,
    avg_round_3: 5,
    app_flat_3: 1,
    char_4: 'Tingyun',
    app_rate_4: 25.0,
    avg_round_4: 7.2,
    app_flat_4: 5,
    char_5: 'Huohuo',
    app_rate_5: 25.0,
    avg_round_5: 6.33,
    app_flat_5: 3,
    char_6: 'Aventurine',
    app_rate_6: 25.0,
    avg_round_6: 8.67,
    app_flat_6: 3,
    char_7: 'Robin',
    app_rate_7: 19.44,
    avg_round_7: 9,
    app_flat_7: 1,
    char_8: 'Yunli',
    app_rate_8: 11.11,
    avg_round_8: 6.33,
    app_flat_8: 3,
    char_9: 'fu-xuan',
    app_rate_9: 8.33,
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'ruan-mei',
    app_rate_10: 8.33,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Hanya',
    app_rate_11: 8.33,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 5.56,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Firefly',
    app_rate_13: 2.78,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'trailblazer-imaginary',
    app_rate_14: 2.78,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Lynx',
    app_rate_15: 2.78,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Clara',
    app_rate_16: 2.78,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Bailu',
    app_rate_17: 2.78,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Seele',
    app_rate_18: 2.78,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'dr-ratio',
    app_rate_19: 2.78,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'Pela',
    app_rate_20: 2.78,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: 'Bronya',
    app_rate_21: 2.78,
    avg_round_21: 99.99,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Himeko',
    round: 99.99,
    char_1: 'Firefly',
    app_rate_1: 71.88,
    avg_round_1: 10,
    app_flat_1: 3,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 71.88,
    avg_round_2: 10,
    app_flat_2: 3,
    char_3: 'ruan-mei',
    app_rate_3: 59.38,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Gallagher',
    app_rate_4: 21.88,
    avg_round_4: 10.0,
    app_flat_4: 4,
    char_5: 'Aventurine',
    app_rate_5: 18.75,
    avg_round_5: 7.5,
    app_flat_5: 2,
    char_6: 'Robin',
    app_rate_6: 12.5,
    avg_round_6: 7,
    app_flat_6: 3,
    char_7: 'dr-ratio',
    app_rate_7: 6.25,
    avg_round_7: 8,
    app_flat_7: 1,
    char_8: 'Jade',
    app_rate_8: 3.12,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Herta',
    app_rate_9: 3.12,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Acheron',
    app_rate_10: 3.12,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Jiaoqiu',
    app_rate_11: 3.12,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'silver-wolf',
    app_rate_12: 3.12,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Yunli',
    app_rate_13: 3.12,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Gepard',
    app_rate_14: 3.12,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Blade',
    app_rate_15: 3.12,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'march-7th-swordmaster',
    app_rate_16: 3.12,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Luocha',
    app_rate_17: 3.12,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Asta',
    app_rate_18: 3.12,
    avg_round_18: 6,
    app_flat_18: 1,
    char_19: 'topaz',
    app_rate_19: 3.12,
    avg_round_19: 7,
    app_flat_19: 1,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Herta',
    round: 99.99,
    char_1: 'Robin',
    app_rate_1: 64.52,
    avg_round_1: 8.6,
    app_flat_1: 7,
    char_2: 'Aventurine',
    app_rate_2: 41.94,
    avg_round_2: 8.67,
    app_flat_2: 5,
    char_3: 'Yunli',
    app_rate_3: 32.26,
    avg_round_3: 8.4,
    app_flat_3: 5,
    char_4: 'Huohuo',
    app_rate_4: 25.81,
    avg_round_4: 8,
    app_flat_4: 3,
    char_5: 'Jade',
    app_rate_5: 22.58,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'fu-xuan',
    app_rate_6: 19.35,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Sparkle',
    app_rate_7: 12.9,
    avg_round_7: 9,
    app_flat_7: 1,
    char_8: 'topaz',
    app_rate_8: 12.9,
    avg_round_8: 4,
    app_flat_8: 1,
    char_9: 'Clara',
    app_rate_9: 9.68,
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'ruan-mei',
    app_rate_10: 9.68,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Gallagher',
    app_rate_11: 9.68,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Boothill',
    app_rate_12: 6.45,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Acheron',
    app_rate_13: 6.45,
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'Argenti',
    app_rate_14: 3.23,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Himeko',
    app_rate_15: 3.23,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'jing-yuan',
    app_rate_16: 3.23,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'march-7th-swordmaster',
    app_rate_17: 3.23,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Jiaoqiu',
    app_rate_18: 3.23,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'Tingyun',
    app_rate_19: 3.23,
    avg_round_19: 8,
    app_flat_19: 1,
    char_20: 'Luocha',
    app_rate_20: 3.23,
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'Pela',
    app_rate_21: 3.23,
    avg_round_21: 9,
    app_flat_21: 1,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Misha',
    round: 99.99,
    char_1: 'ruan-mei',
    app_rate_1: 48.28,
    avg_round_1: 9.67,
    app_flat_1: 3,
    char_2: 'trailblazer-imaginary',
    app_rate_2: 27.59,
    avg_round_2: 10.0,
    app_flat_2: 4,
    char_3: 'fu-xuan',
    app_rate_3: 17.24,
    avg_round_3: 10,
    app_flat_3: 1,
    char_4: 'Tingyun',
    app_rate_4: 17.24,
    avg_round_4: 10,
    app_flat_4: 2,
    char_5: 'Pela',
    app_rate_5: 17.24,
    avg_round_5: 8.33,
    app_flat_5: 3,
    char_6: 'Robin',
    app_rate_6: 13.79,
    avg_round_6: 10,
    app_flat_6: 1,
    char_7: 'Aventurine',
    app_rate_7: 13.79,
    avg_round_7: 10,
    app_flat_7: 1,
    char_8: 'Gallagher',
    app_rate_8: 13.79,
    avg_round_8: 8.33,
    app_flat_8: 3,
    char_9: 'Boothill',
    app_rate_9: 13.79,
    avg_round_9: 8,
    app_flat_9: 3,
    char_10: 'Sparkle',
    app_rate_10: 10.34,
    avg_round_10: 10,
    app_flat_10: 1,
    char_11: 'Luka',
    app_rate_11: 10.34,
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'Hanya',
    app_rate_12: 10.34,
    avg_round_12: 9,
    app_flat_12: 3,
    char_13: 'march-7th',
    app_rate_13: 10.34,
    avg_round_13: 9.67,
    app_flat_13: 3,
    char_14: 'Huohuo',
    app_rate_14: 10.34,
    avg_round_14: 9,
    app_flat_14: 1,
    char_15: 'Luocha',
    app_rate_15: 10.34,
    avg_round_15: 8.5,
    app_flat_15: 2,
    char_16: 'Jiaoqiu',
    app_rate_16: 10.34,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'march-7th-swordmaster',
    app_rate_17: 6.9,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Lynx',
    app_rate_18: 6.9,
    avg_round_18: 10,
    app_flat_18: 1,
    char_19: 'Yunli',
    app_rate_19: 3.45,
    avg_round_19: 10,
    app_flat_19: 1,
    char_20: 'Qingque',
    app_rate_20: 3.45,
    avg_round_20: 10,
    app_flat_20: 1,
    char_21: 'Natasha',
    app_rate_21: 3.45,
    avg_round_21: 10,
    app_flat_21: 1,
    char_22: 'Clara',
    app_rate_22: 3.45,
    avg_round_22: 99.99,
    app_flat_22: 0,
    char_23: 'Bronya',
    app_rate_23: 3.45,
    avg_round_23: 10,
    app_flat_23: 1,
    char_24: 'Firefly',
    app_rate_24: 3.45,
    avg_round_24: 99.99,
    app_flat_24: 0,
    char_25: 'Sushang',
    app_rate_25: 3.45,
    avg_round_25: 7,
    app_flat_25: 1,
    char_26: 'Gepard',
    app_rate_26: 3.45,
    avg_round_26: 99.99,
    app_flat_26: 0,
    char_27: 'Jingliu',
    app_rate_27: 3.45,
    avg_round_27: 9,
    app_flat_27: 1,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Xueyi',
    round: 99.99,
    char_1: 'fu-xuan',
    app_rate_1: 52.94,
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'Sparkle',
    app_rate_2: 47.06,
    avg_round_2: 9,
    app_flat_2: 1,
    char_3: 'silver-wolf',
    app_rate_3: 47.06,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'ruan-mei',
    app_rate_4: 23.53,
    avg_round_4: 9,
    app_flat_4: 1,
    char_5: 'Robin',
    app_rate_5: 17.65,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Boothill',
    app_rate_6: 17.65,
    avg_round_6: 9,
    app_flat_6: 1,
    char_7: 'trailblazer-imaginary',
    app_rate_7: 17.65,
    avg_round_7: 9.33,
    app_flat_7: 3,
    char_8: 'Gallagher',
    app_rate_8: 17.65,
    avg_round_8: 9.5,
    app_flat_8: 2,
    char_9: 'Aventurine',
    app_rate_9: 11.76,
    avg_round_9: 99.99,
    app_flat_9: 0,
    char_10: 'Yunli',
    app_rate_10: 11.76,
    avg_round_10: 9,
    app_flat_10: 1,
    char_11: 'march-7th-swordmaster',
    app_rate_11: 11.76,
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'Lynx',
    app_rate_12: 5.88,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Bailu',
    app_rate_13: 5.88,
    avg_round_13: 9,
    app_flat_13: 1,
    char_14: 'Huohuo',
    app_rate_14: 5.88,
    avg_round_14: 9,
    app_flat_14: 1,
    char_15: 'topaz',
    app_rate_15: 5.88,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Sushang',
    round: 99.99,
    char_1: 'march-7th-swordmaster',
    app_rate_1: 50.0,
    avg_round_1: 8.5,
    app_flat_1: 4,
    char_2: 'Robin',
    app_rate_2: 38.89,
    avg_round_2: 8,
    app_flat_2: 3,
    char_3: 'ruan-mei',
    app_rate_3: 38.89,
    avg_round_3: 7.67,
    app_flat_3: 3,
    char_4: 'trailblazer-imaginary',
    app_rate_4: 27.78,
    avg_round_4: 8.67,
    app_flat_4: 3,
    char_5: 'Huohuo',
    app_rate_5: 27.78,
    avg_round_5: 7,
    app_flat_5: 2,
    char_6: 'Luocha',
    app_rate_6: 22.22,
    avg_round_6: 6.5,
    app_flat_6: 2,
    char_7: 'Aventurine',
    app_rate_7: 11.11,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: 'march-7th',
    app_rate_8: 11.11,
    avg_round_8: 8.5,
    app_flat_8: 2,
    char_9: 'Gallagher',
    app_rate_9: 11.11,
    avg_round_9: 10,
    app_flat_9: 2,
    char_10: 'Bronya',
    app_rate_10: 11.11,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Tingyun',
    app_rate_11: 5.56,
    avg_round_11: 99.99,
    app_flat_11: 0,
    char_12: 'Pela',
    app_rate_12: 5.56,
    avg_round_12: 7,
    app_flat_12: 1,
    char_13: 'Sparkle',
    app_rate_13: 5.56,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Boothill',
    app_rate_14: 5.56,
    avg_round_14: 99.99,
    app_flat_14: 0,
    char_15: 'Kafka',
    app_rate_15: 5.56,
    avg_round_15: 99.99,
    app_flat_15: 0,
    char_16: 'Firefly',
    app_rate_16: 5.56,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Misha',
    app_rate_17: 5.56,
    avg_round_17: 7,
    app_flat_17: 1,
    char_18: 'Hanya',
    app_rate_18: 5.56,
    avg_round_18: 7,
    app_flat_18: 1,
    char_19: 'fu-xuan',
    app_rate_19: 5.56,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'dan-heng',
    round: 99.99,
    char_1: 'march-7th-swordmaster',
    app_rate_1: 50.0,
    avg_round_1: 8.33,
    app_flat_1: 3,
    char_2: 'Huohuo',
    app_rate_2: 50.0,
    avg_round_2: 10.0,
    app_flat_2: 4,
    char_3: 'Tingyun',
    app_rate_3: 50.0,
    avg_round_3: 10.0,
    app_flat_3: 4,
    char_4: 'ruan-mei',
    app_rate_4: 25.0,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: 'Sparkle',
    app_rate_5: 25.0,
    avg_round_5: 10,
    app_flat_5: 3,
    char_6: 'Robin',
    app_rate_6: 25.0,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Gallagher',
    app_rate_7: 16.67,
    avg_round_7: 10,
    app_flat_7: 1,
    char_8: 'fu-xuan',
    app_rate_8: 16.67,
    avg_round_8: 99.99,
    app_flat_8: 0,
    char_9: 'Bronya',
    app_rate_9: 8.33,
    avg_round_9: 10,
    app_flat_9: 1,
    char_10: 'trailblazer-imaginary',
    app_rate_10: 8.33,
    avg_round_10: 99.99,
    app_flat_10: 0,
    char_11: 'Aventurine',
    app_rate_11: 8.33,
    avg_round_11: 10,
    app_flat_11: 1,
    char_12: 'Jiaoqiu',
    app_rate_12: 8.33,
    avg_round_12: 99.99,
    app_flat_12: 0,
    char_13: 'Hanya',
    app_rate_13: 8.33,
    avg_round_13: 10,
    app_flat_13: 1,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Natasha',
    round: 99.99,
    char_1: 'ruan-mei',
    app_rate_1: 35.71,
    avg_round_1: 8,
    app_flat_1: 2,
    char_2: 'Yunli',
    app_rate_2: 28.57,
    avg_round_2: 9,
    app_flat_2: 1,
    char_3: 'Tingyun',
    app_rate_3: 28.57,
    avg_round_3: 8,
    app_flat_3: 2,
    char_4: 'Firefly',
    app_rate_4: 21.43,
    avg_round_4: 8,
    app_flat_4: 1,
    char_5: 'trailblazer-imaginary',
    app_rate_5: 21.43,
    avg_round_5: 8,
    app_flat_5: 1,
    char_6: 'Bronya',
    app_rate_6: 21.43,
    avg_round_6: 8,
    app_flat_6: 1,
    char_7: 'Clara',
    app_rate_7: 21.43,
    avg_round_7: 8.33,
    app_flat_7: 3,
    char_8: 'Robin',
    app_rate_8: 21.43,
    avg_round_8: 9.33,
    app_flat_8: 3,
    char_9: 'Hanya',
    app_rate_9: 14.29,
    avg_round_9: 8,
    app_flat_9: 2,
    char_10: 'dr-ratio',
    app_rate_10: 14.29,
    avg_round_10: 9,
    app_flat_10: 1,
    char_11: 'Jingliu',
    app_rate_11: 7.14,
    avg_round_11: 8,
    app_flat_11: 1,
    char_12: 'march-7th-swordmaster',
    app_rate_12: 7.14,
    avg_round_12: 10,
    app_flat_12: 1,
    char_13: 'Pela',
    app_rate_13: 7.14,
    avg_round_13: 99.99,
    app_flat_13: 0,
    char_14: 'Misha',
    app_rate_14: 7.14,
    avg_round_14: 10,
    app_flat_14: 1,
    char_15: 'silver-wolf',
    app_rate_15: 7.14,
    avg_round_15: 9,
    app_flat_15: 1,
    char_16: 'Jade',
    app_rate_16: 7.14,
    avg_round_16: 99.99,
    app_flat_16: 0,
    char_17: 'Sparkle',
    app_rate_17: 7.14,
    avg_round_17: 99.99,
    app_flat_17: 0,
    char_18: 'Kafka',
    app_rate_18: 7.14,
    avg_round_18: 99.99,
    app_flat_18: 0,
    char_19: 'black-swan',
    app_rate_19: 7.14,
    avg_round_19: 99.99,
    app_flat_19: 0,
    char_20: 'topaz',
    app_rate_20: 7.14,
    avg_round_20: 99.99,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Hook',
    round: 99.99,
    char_1: 'Asta',
    app_rate_1: 66.67,
    avg_round_1: 9,
    app_flat_1: 1,
    char_2: 'fu-xuan',
    app_rate_2: 66.67,
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Bronya',
    app_rate_3: 33.33,
    avg_round_3: 9,
    app_flat_3: 1,
    char_4: 'Bailu',
    app_rate_4: 33.33,
    avg_round_4: 9,
    app_flat_4: 1,
    char_5: 'dr-ratio',
    app_rate_5: 33.33,
    avg_round_5: 99.99,
    app_flat_5: 0,
    char_6: 'Robin',
    app_rate_6: 33.33,
    avg_round_6: 99.99,
    app_flat_6: 0,
    char_7: 'Hanya',
    app_rate_7: 33.33,
    avg_round_7: 99.99,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'trailblazer-fire',
    round: 99.99,
    char_1: 'Acheron',
    app_rate_1: 100.0,
    avg_round_1: 8,
    app_flat_1: 1,
    char_2: 'silver-wolf',
    app_rate_2: 100.0,
    avg_round_2: 8,
    app_flat_2: 1,
    char_3: 'Lynx',
    app_rate_3: 50.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Sparkle',
    app_rate_4: 50.0,
    avg_round_4: 8,
    app_flat_4: 1,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Serval',
    round: 99.99,
    char_1: 'Clara',
    app_rate_1: 100.0,
    avg_round_1: 10,
    app_flat_1: 1,
    char_2: 'Pela',
    app_rate_2: 50.0,
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Aventurine',
    app_rate_3: 50.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Jingliu',
    app_rate_4: 50.0,
    avg_round_4: 10,
    app_flat_4: 1,
    char_5: 'Bailu',
    app_rate_5: 50.0,
    avg_round_5: 10,
    app_flat_5: 1,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Arlan',
    round: 99.99,
    char_1: 'Bronya',
    app_rate_1: 100.0,
    avg_round_1: 99.99,
    app_flat_1: 0,
    char_2: 'Aventurine',
    app_rate_2: 100.0,
    avg_round_2: 99.99,
    app_flat_2: 0,
    char_3: 'Robin',
    app_rate_3: 50.0,
    avg_round_3: 99.99,
    app_flat_3: 0,
    char_4: 'Tingyun',
    app_rate_4: 50.0,
    avg_round_4: 99.99,
    app_flat_4: 0,
    char_5: '-',
    app_rate_5: 0.0,
    avg_round_5: 0.0,
    app_flat_5: 0,
    char_6: '-',
    app_rate_6: 0.0,
    avg_round_6: 0.0,
    app_flat_6: 0,
    char_7: '-',
    app_rate_7: 0.0,
    avg_round_7: 0.0,
    app_flat_7: 0,
    char_8: '-',
    app_rate_8: 0.0,
    avg_round_8: 0.0,
    app_flat_8: 0,
    char_9: '-',
    app_rate_9: 0.0,
    avg_round_9: 0.0,
    app_flat_9: 0,
    char_10: '-',
    app_rate_10: 0.0,
    avg_round_10: 0.0,
    app_flat_10: 0,
    char_11: '-',
    app_rate_11: 0.0,
    avg_round_11: 0.0,
    app_flat_11: 0,
    char_12: '-',
    app_rate_12: 0.0,
    avg_round_12: 0.0,
    app_flat_12: 0,
    char_13: '-',
    app_rate_13: 0.0,
    avg_round_13: 0.0,
    app_flat_13: 0,
    char_14: '-',
    app_rate_14: 0.0,
    avg_round_14: 0.0,
    app_flat_14: 0,
    char_15: '-',
    app_rate_15: 0.0,
    avg_round_15: 0.0,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
