import React from 'react';

export const ProfileSynergyBoothill: React.FC = () => {
  return (
    <>
      <ul>
        <li>
          <strong>Normal Basic Attack</strong>
        </li>
        <ul>
          <li>
            Boothill's normal Basic Attack is almost never used or accessible
            throughout his rotation as it is disabled when using his Skill and
            replaced with his Enhanced Basic Attack instead.
          </li>
        </ul>
        <li>
          <strong>Enhanced Basic Attack</strong>
        </li>
        <ul>
          <li>
            Boothill's Enhanced Basic Attacks are divided into two parts - the
            first portion consists of the skill damage multiplier itself (220%
            at max rank), this takes the form of a quick hip shot animation done
            by Boothill. After the damage from this initial hit lands, the game
            performs a check on the enemy to see if they were just broken or
            were already broken and if that is true he can perform the second
            portion of his Enhanced Basic Attack thanks to his talent.
          </li>
          <li>
            The second portion of Boothill's Enhanced Basic Attack that triggers
            when the first portion Breaks or hits an already broken enemy is the
            primary source of his sustained damage throughout a fight and scales
            directly off of Break Effect (the entire second portion of the
            attack is pure Physical Break Damage). To make it clear the second
            shot consists of what, his Talent states as "If the target is
            Weakness Broken while the Enhanced Basic ATK is being used, based on
            the number of Pocket Trickshot stacks, deals Break DMG to this
            target equal to 70%/120%/170% of Boothill's Physical Break DMG." The
            animation associated with this is a close up of Boothill's face
            followed by a mega shot from his mechanical pistol.
          </li>
          <li>
            For Boothill to perform well he must be able to trigger the second
            portion of the Enhanced Basic as almost all of his damage stems from
            Physical Break Damage and the scaling around it.
          </li>
          <li>
            Despite being a Basic Attack, Boothill's Enhanced Basic Attack
            generates 30 energy instead of the usual 20 from standard basic
            attacks.
          </li>
        </ul>
        <li>
          <strong>Skill</strong>
        </li>
        <ul>
          <li>
            Boothill's Skill allows him to lock onto an enemy entering the
            "Standoff State". During the state, Boothill loses access to his
            standard Basic Attack and instead has it replaced by an enhanced
            version.
          </li>
          <li>
            During Boothill's Standoff he cannot attack or even target any other
            enemy on the field other than the one he has locked onto.
          </li>
          <li>
            After locking onto a target with the standoff state the enemy marked
            is Taunted by Boothill and will always attack him. In return
            Boothill applies a 30% damage taken multiplier to the chosen enemy
            (same multiplier group as Aventurine and Silver Wolf Cones) which is
            exceptionally powerful. But there's a catch - the enemy gains a 15%
            damage taken multiplier against Boothill himself.
          </li>
          <li>Boothill's Standoff IS considered a debuff on the enemy.</li>
          <li>
            The Skill lasts for 2 turns total and only loses a charge of
            duration during the start of Boothills own turns.
          </li>
          <li>
            While in a Standoff, Boothill cannot switch his Standoff target or
            use the skill again until the enemy dies or the duration times out.
            Upon killing an enemy you can use the skill as normal though.
          </li>
          <li>
            In the event that you use your skill on the target and kill it in
            the same action for example by using Boothill's Ultimate, you can
            immediately use his skill again albeit at a hefty skill point cost.
          </li>
          <li>
            Despite costing a Skill Point Boothill's skill does not generate any
            energy.
          </li>
        </ul>
        <li>
          <strong>Talent</strong>
        </li>
        <ul>
          <li>
            When acquiring Pocket Trickshot stacks, you can gain 2 at the same
            time if you both Break and kill an enemy in the same action. At the
            start of battle it's recommended you take advantage of this
            interaction to kill a weaker enemy you're confident you can one shot
            to immediately kick start Boothill's rotation.
          </li>
          <li>
            After gaining 3 Pocket Trickshot's Boothill will keep them
            permanently for the fight.
          </li>
          <li>
            The 50% Toughness multiplier per Trickshot on Boothill's Enhanced BA
            is a more multiplier to Toughness damage that is separate from
            "Break Efficiency", meaning that they multiply together. For example
            Boothill's Enhanced Basic deals 60 Toughness but is enhanced by 3
            Pocket Trickshots to become 150, that is then empowered again by
            Ruan Mei's efficiency yielding 225 toughness damage in 1 Basic
            Attack.
          </li>
          <li>
            The additional damaging affect associated with Boothill's Pocket
            Trickshot's are treated as true Break Damage and function
            identically as if you broke the enemies break bar for Physical
            damage (with the added bonus that unlike normal break this damage
            doesn't suffer from the 10% toughness damage reduction).
          </li>
        </ul>
        <li>
          <strong>Ultimate</strong>
        </li>
        <ul>
          <li>
            Boothill's Ultimate implants the Physical Weakness before doing it's
            damage.
          </li>
          <li>
            By combining Boothill's Ultimate with a full stacked
            Trickshot-enhanced Basic on top of Ruan Mei, Boothill can
            realistically take most elite and even some bosses all the way from
            100 Toughness to 0.
          </li>
          <li>
            The delay on Boothill's Ultimate is great defensively but also has
            offensive merit potentially netting Boothill additional actions
            against the enemy while they're already broken.
          </li>
          <li>
            When playing Boothill off-element and attempting to brute force
            content, strategic use of his ultimate is absolutely essential to
            ensure he can deal effective damage.
          </li>
        </ul>
        <li>
          <strong>BA: Point Blank</strong>
        </li>
        <ul>
          <li>
            Essential bonus ability in order to remedy Boothill's awkward energy
            costs, allowing him to fairly reliably maintain a 3-action rotation,
            granting good access to his Ultimate. Without this bonus ability, in
            the 3 action rotation he is at 95 / 115 energy gaining an extra 10
            for breaking an enemy alongside some extra energy from kills or
            combat energy will get you there most of the time in the end.
          </li>
        </ul>
        <li>
          <strong>BA: Ghost Load</strong>
        </li>
        <ul>
          <li>
            A massive value bonus ability granting a tonne of CRIT RATE and CRIT
            DMG for free up to a cap of 30% CRIT RATE and 150% CRIT DMG based on
            your break effect. You need 300% Break Effect to get maximum bonuses
            from this passive but it's worth noting Boothill doesn't gain a
            particularly large benefit from CRIT RATE or CRIT DMG as the lions
            share of his damage is pure physical break which cannot CRIT.
          </li>
        </ul>
      </ul>
    </>
  );
};
