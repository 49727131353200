export const HSRDPSFireflyDPS = {
  V1: {
    e0: 694579,
    e1: 734739,
    e2: 956579,
    e3: 967238,
    e4: 967238,
    e5: 1003105,
    e6: 1429377,
    sig_e0: 2157282,
    sig_e1: 2340314,
    sig_e2: 3333489,
    sig_e3: 3344148,
    sig_e4: 3344148,
    sig_e5: 3467437,
    sig_e6: 5346432
  }
};
