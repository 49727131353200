import React from 'react';

export const ProfileSynergyGalla: React.FC = () => {
  return (
    <>
      <ul>
        <li>
          Even though Gallagher's ultimate Advances him by 100% he wont get a
          chance to use an enhanced basic attack until after the current
          characters action has concluded. Planning your healing around this to
          ensure you don't die mid-way through an enemy attack is critical.
        </li>
        <li>
          Using Gallagher's ultimate after your primary target moves is advised
          as it will give you the largest possible uptime on the Besotted State
          debuff.
        </li>
        <li>
          All of Gallagher's healing and supportive capabilities are flat
          numerical values and do not have base multipliers like other
          characters. This means he cannot stack HP, DEF or ATK in order to
          boost his output and must rely on other stats instead.
        </li>
        <li>
          Gallagher gains outgoing healing% based on his break effect% thanks to
          his bonus ability Novel Concoction allowing him to gain up to 75%
          outgoing healing bonus if he has 150% break effect. This bonus
          somewhat makes up for his lack of base multiplier scaling.
        </li>
        <li>
          The faster the enemy is the less opportunity for healing Gallagher
          will have from his ultimate. Bringing ways to slow enemies down and
          prevent them from moving will improve Gallagher's healing uptime.
        </li>
        <li>
          After acquiring Gallagher's E1 he is one of the easiest characters in
          the game to achieve 100% Effect RES on making him immune to almost all
          crowd controls and debuffs. If you have E1 Gallagher, Effect Res
          should be a priority as he does not require many other sub stats on
          his gear.
        </li>
        <li>
          Combining an Energy Recharge Light Cone with Gallagher's E4 allows him
          to get substantially higher uptime on his Besottled state drastically
          improving his ultimates uptime and as a result his healing
          consistency.
        </li>
      </ul>
    </>
  );
};
