import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

export const AveProfileStats: React.FC = () => {
  return (
    <div className="profile fu-ehp">
      <div className={`content-header Imaginary`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Main Stats Comparison
      </div>
      <p>
        The table below shows the importance of stacking DEF on Aventurine. You
        won't benefit from the additional DMG% or CRIT DMG if you neglect DEF,
        so only switch away from DEF main stats pieces once you secure enough
        DEF from other sources (for example Light Cone or sub-stats).
      </p>
      <p>
        In all setups, Aventurine is using 4-PC Knight of Purity Palace / Inert
        Salsatto and S5 Destiny's Threads Forewoven Light Cone.
      </p>
      <Table bordered responsive className="colored-table">
        <thead>
          <tr>
            <th>Body / Boots / Orb / Robe</th>
            <th>Follow-up</th>
            <th>Ultimate</th>
            <th>Basic</th>
            <th>Skill Shield</th>
            <th>Follow-up Shield</th>
            <th>DEF</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>DEF/SPD/DEF/DEF</td>
            <td>10,544</td>
            <td>17,707</td>
            <td>5,515</td>
            <td>1,529</td>
            <td>449</td>
            <td>3977</td>
          </tr>
          <tr>
            <td>CRIT DMG/SPD/DEF/DEF</td>
            <td>10,202</td>
            <td>17,308</td>
            <td>5,321</td>
            <td>1,402</td>
            <td>412</td>
            <td>3536</td>
          </tr>
          <tr>
            <td>DEF/SPD/IMG%/DEF</td>
            <td>9,960</td>
            <td>16,897</td>
            <td>5,286</td>
            <td>1,402</td>
            <td>412</td>
            <td>3536</td>
          </tr>
          <tr>
            <td>CRIT DMG/SPD/IMG%/DEF</td>
            <td>9,195</td>
            <td>15,889</td>
            <td>4,867</td>
            <td>1,229</td>
            <td>362</td>
            <td>2933</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
