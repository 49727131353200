import React from 'react';
import { ProfileRobinEnergy } from './robin-energy';

export const ProfileRobinTricks: React.FC = () => {
  return (
    <>
      <h6>Kit interactions</h6>
      <ul>
        <li>
          Robin's Signature Light Cone, Flowing Nightglow, despite removing 5
          stacks of its Energy Recharge buff upon using her ultimate can be
          immediately re-stacked{' '}
          <strong>
            allowing for close to 80-90% uptime on the 15% energy regeneration
            buff
          </strong>
          ,
        </li>
        <li>
          Every separate action performed by an ally that strikes an enemy
          including Ultimates, standard abilities and follow-up attacks{' '}
          <strong>all trigger Robin's talent to generate energy</strong>,
        </li>
        <li>
          Hits from entry Techniques count as hits for Robin's energy
          regeneration granting her an additional 2 energy at the start of
          combat,
        </li>
        <li>
          <strong>Always place Robin in the centre of your team</strong>{' '}
          composition and if possible next or in between whichever characters
          have the highest chance to be hit by enemies (Preservation or tanks).
          This will grant her a tonne of free energy from being hit,
        </li>
        <li>
          Robin's Skill generates substantially more energy than her Basic
          Attack. If you have Skill Points to spare and Robin is close to
          gaining her ultimate consider using her Skill instead of the basic
          even if it's not time to refresh it's duration,
        </li>
        <li>
          Robin's{' '}
          <strong>
            Ultimate does not advance summons such as Jing Yuan's Lightning Lord
            or Topaz's Trotter Numby
          </strong>
          .
        </li>
      </ul>
      <h6>How many buffs does Robin provide?</h6>
      <ul>
        <li>
          <strong>PASSIVE:</strong>
        </li>
        <ul>
          <li>
            <strong>50%</strong> DMG%,
          </li>
          <li>
            <strong>20%</strong> CRIT DMG,
          </li>
        </ul>
        <li>
          <strong>DURING ULT:</strong>
        </li>
        <ul>
          <li>
            <strong>1000-1400</strong> Flat ATK (equivalent to a 80-100% ATK%
            increase),
          </li>
          <li>
            <strong>25%</strong> FUA CRIT DMG.
          </li>
          <li>
            <strong>100%</strong> full team Action Advance,
          </li>
          <li>
            <strong>24%</strong> All-Type RES PEN (E1),
          </li>
          <li>
            <strong>16%</strong> Speed (E2).
          </li>
        </ul>
      </ul>
      <h6>When should I use Robin's Ultimate?</h6>
      <p>
        There are multiple factors to consider when choosing the best time to
        use Robin's Ultimate.{' '}
        <strong>
          At the most basic level you should aim to use it after all of your
          team has just moved allowing the entire team to be fully boosted,
          granting each of them a full action advance
        </strong>
        . On a more advanced level in endgame there are a few more things to
        consider.
      </p>
      <ul>
        <li>
          In <strong>Pure Fiction</strong> you have a set amount of cycles in
          which to slay as many enemies as possible. Your goal with Robin should
          be to maximize the uptime of her Ultimate but also to gain as many
          activations of it as possible for as many free advances as you can. To
          achieve this, it can sometimes be correct to use Robin's Ultimate
          earlier than described above so she doesn't end up overcapping her
          energy and losing out on a potential Ultimates later on in combat.
          Balancing early Ultimates with full team advances is something to be
          on the look out for in Pure Fiction,
        </li>
        <li>
          In <strong>Memory of Chaos</strong> getting clears in as few cycles as
          possible means everything and Robin's Ultimate, when used correctly,
          can guarantee you a better performance. After you clear a wave in
          Memory of Chaos all characters and enemies Speeds are reset, which
          introduces a new consideration on when you might want to use Robin's
          Ultimate to ensure you gain from this change and don't lose character
          actions:
        </li>
        <ul>
          <li>
            The first new timing for using Robin's Ultimate is at the first
            start of a new wave right as every characters actions are reset - so
            you can immediately perfectly advance all your team mates right as
            the new wave starts gaining full value from Robin's ultimate.
          </li>
          <li>
            The second new timing is to use Robin's Ultimate right before a
            cycle ends and all enemies and allies have moved to grant your team
            1 full additional round of actions, potentially saving you a cycle
            and earning you a 3 star rating. Another advantage of using Robin's
            Ultimate to finish off an existing wave is that she'll start the
            next wave with a fully refreshed Ultimate duration as the duration
            of her Ultimate is based on the Speed mechanic and that speed is
            reset each new wave.
          </li>
        </ul>
      </ul>
      <h6>How is Robin on Auto?</h6>
      <p>
        Robin isn't bad on auto but she will most of the time immediately use
        her Ultimate as soon as it's available regardless of your team's current
        points in the action order, potentially completely wasting the action
        advance portion of her Kit. The rest of her AI gameplay is intelligent
        and smooth.
      </p>
      <h6>
        Do I need to use ERR Rope and Sprightly Vonwacq / Penacony, Land of the
        Dreams on Robin?
      </h6>
      <p>
        <strong>While an ERR Rope is a must have on Robin</strong>, Sprightly
        Vonwacq / Penacony, Land of the Dreams are not. Though the 5% Energy
        Regeneration can situationally be incredibly powerful.
      </p>
      <h6>
        How many attacks do allies need to deal to secure permanent uptime on
        Robin's Ultimate?
      </h6>
      <p>
        Worst case, you want your Robin to achieve a 3-action rotation of 1
        Skill and 2 Basics. You can refer to the table below to see the impact
        of different Light Cones and energy generation on how many allied
        attacks are required to make that 3-action rotation work without taking
        hits in combat.
      </p>
      <p>
        Total ERR refers to the total energy regeneration that Robin has with a
        specific setup. Rotation Energy refers to how much energy Robin will
        generate from 2 basic attacks and 1 skill without taking combat damage.
        Required ally attacks refers to how many attacks and as a result
        triggers of her 2 energy talent you need in order to make the rotation
        work flawlessly.
      </p>
      <p>
        Remember that taking combat damage especially with Robin placed in the
        middle of the team is very real and will happen, lowering the amount of
        required ally attacks needed and potentially allowing for 2 or even
        1-action rotations.
      </p>
      <ProfileRobinEnergy />
    </>
  );
};
