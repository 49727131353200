import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

export const ProfileHuoER: React.FC = () => {
  return (
    <div className="profile fu-ehp">
      <div className={`content-header Wind`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Energy Breakpoints
      </div>
      <p>
        With Huohuo Ultimate being super expensive (140) by default you will be
        able to use her Ultimate every 4 actions but only if you will play her
        in SP-neutral way (so 2 Skill + 2 Basic rotation). If you want to make
        her SP-positive (1S + 3B rotation), reaching the 140 Energy breakpoint
        won't be always possible. The table below shows how various Light Cones
        will affect the Energy gained in both rotations (Huohuo is already
        equipped with ER% Rope).
      </p>
      <p>
        <strong className="green">Green color</strong> means you hit the
        required Energy in the rotation and <strong className="red">Red</strong>{' '}
        you didn't.
      </p>
      <Table bordered responsive className="colored-table">
        <thead>
          <tr>
            <th>Light Cone</th>
            <th>2S + 2B Rotation</th>
            <th>1S + 3B Rotation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>S1 Night Of Fright</strong>
            </td>
            <td className="green">165.6</td>
            <td className="green">152.5</td>
          </tr>
          <tr>
            <td>
              <strong>S1 Time Waits For No One</strong>
            </td>
            <td className="green">150.5</td>
            <td className="red">138.6</td>
          </tr>
          <tr>
            <td>
              <strong>S5 Hey Over Here</strong>
            </td>
            <td className="green">150.5</td>
            <td className="red">138.6</td>
          </tr>
          <tr>
            <td>
              <strong>S5 Perfect Timing</strong>
            </td>
            <td className="green">150.5</td>
            <td className="red">138.6</td>
          </tr>
          <tr>
            <td>
              <strong>S5 Multiplication</strong>
            </td>
            <td className="green">150.5</td>
            <td className="red">138.6</td>
          </tr>
          <tr>
            <td>
              <strong>S5 Shared Feeling</strong>
            </td>
            <td className="green">160.0</td>
            <td className="green">143.3</td>
          </tr>
          <tr>
            <td>
              <strong>S5 Post-Op Conversation</strong>
            </td>
            <td className="green">170.7</td>
            <td className="green">157.1</td>
          </tr>
          <tr>
            <td>
              <strong>S5 Quid Pro Quo</strong>
            </td>
            <td className="green">150.5</td>
            <td className="red">138.6</td>
          </tr>
        </tbody>
      </Table>
      <p>
        The numbers already include the energy gained from allies turn starting
        and them using the Ultimate - so for the benefit of Huohuo Talent.
      </p>
    </div>
  );
};
