export const HSRDPSJiaoqiuDPS = {
  V1: {
    e0: 208868,
    e1: 252709,
    e2: 497138,
    e3: 505188,
    e4: 505188,
    e5: 535357,
    e6: 787626,
    alt_e0: 530320,
    alt_e1: 638531,
    alt_e2: 1371819,
    alt_e3: 1384008,
    alt_e4: 1384008,
    alt_e5: 1469013,
    alt_e6: 2161186,
    sig_e0: 275376,
    sig_e1: 342053,
    sig_e2: 649104,
    sig_e3: 661127,
    sig_e4: 661127,
    sig_e5: 697505,
    sig_e6: 1005513,
    alt_sig_e0: 682320,
    alt_sig_e1: 847531,
    alt_sig_e2: 1768684,
    alt_sig_e3: 1786890,
    alt_sig_e4: 1786890,
    alt_sig_e5: 1888632,
    alt_sig_e6: 2722570
  }
};
