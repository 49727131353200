import React from 'react';
import Chart from 'react-google-charts';

export const dataBoot = [
  ['Skill', 'Dmg'],
  ['Break', 329675],
  ['Normal', 56102]
];

export const optionsBoot = {
  is3D: true,
  pieSliceText: 'percentage',
  backgroundColor: '#2C2D33',
  legend: {
    position: 'bottom',
    alignment: 'center',
    textStyle: { color: '#ffffff' },
    scrollArrows: { inactiveColor: '#ffffff', activeColor: '#ffffff' },
    pagingTextStyle: { color: '#ffffff' }
  },
  chartArea: { top: 20 },
  slices: {
    0: { color: '#009EEC' },
    1: { color: '#C267EC' }
  }
};

export const ProfileBootSplit: React.FC = () => {
  return (
    <>
      <div className="damage-distribution">
        <Chart
          chartType="PieChart"
          data={dataBoot}
          options={optionsBoot}
          width={'100%'}
          height={'400px'}
        />
      </div>
    </>
  );
};
