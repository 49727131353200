export const HSRDPSBoothillDPS = {
  V1: {
    e0: 1643923,
    e1: 1778732,
    e2: 1900252,
    e3: 1924253,
    e4: 2101877,
    e5: 2274131,
    e6: 2836664,
    sig_e0: 2136891,
    sig_e1: 2330285,
    sig_e2: 2476130,
    sig_e3: 2496011,
    sig_e4: 2726412,
    sig_e5: 2961515,
    sig_e6: 3742525
  }
};
