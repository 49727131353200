import React from 'react';

export const ProfileFireflyInvest: React.FC = () => {
  return (
    <>
      <p>
        Are you wondering whether you should pull for Firefly Eidolons or her
        Light Cone? Or maybe there's another option? Then this section is for
        you!
      </p>
      <blockquote className="firefly-order">
        <p>
          <strong className={`Ice`}>Ruan Mei E0</strong> &gt;{' '}
          <strong className={`Fire`}>Firefly E1</strong> &gt;{' '}
          <strong className={`Fire`}>Firefly E2</strong> &gt;
          <strong className={`Fire`}>Firefly S1</strong> ={' '}
          <strong className={`Ice`}>Ruan Mei E1</strong>
        </p>
      </blockquote>
      <ol>
        <li>
          <strong>Ruan Mei E0</strong> - yes. That's right. If you're planning
          to get Firefly and you don't have Ruan Mei, she has priority over both
          Firefly's Eidolons and Light Cone. Why? Because you simply get way
          more damage compared to either - Ruan Mei is roughly 70% dmg increase
          over Pela/SW who are the second best option if you don't have her.
          While Eidolon 1 translates to roughly 8% dmg increase (but with huge
          QOL) and the signature Cone to 17%.
        </li>
        <li>
          <strong>Firefly E1</strong> - That's the next priority. While the
          damage increase is pretty decent (~8%), the big thing about the
          Eidolon is the reduced Skill Point cost in Firefly's rotation. You go
          from ~20 to ~7 Skill Points used which means Harmony MC will be able
          to spam their skill and you won't have to think about using Ruan Mei's
          skill as you will always have the SP to do it.
        </li>
        <li>
          <strong>Firefly E2</strong> - The new strongest E2 in the game that
          honestly powercreeps both Acheron E2 and DHIL E2 with how much power
          it brings. What does it do? Whenever an enemy is defeated or Broken
          while under Complete Combustion, Firefly gets an Extra Turn, with a 1
          turn cooldown between triggering this effect. Basically you get
          Seele's Resurgence but without the need to worry about SP (since
          you're already past E1 at this stage).
        </li>
        <li>
          <strong>Ruan Mei E1</strong> or <strong>Firefly S1</strong> - both are
          roughly equal, but I would say Ruan Mei's E1 will help you more in the
          long run as you can also use her in other teams.
        </li>
      </ol>
    </>
  );
};
