import React from 'react';

export const ProfileSynergySparkle: React.FC = () => {
  return (
    <>
      <ul>
        <li>
          Sparkle's skill buff by default only lasts until the end of the
          characters boosted turn (functionally the same as Bronya), but after
          unlocking the bonus ability "Artificial Flower", the buff{' '}
          <strong>lasts until the start of the characters next turn</strong>. So
          now the buff will also apply to any attacks that occur before that
          turn arrives allowing Sparkle to effectively buff characters with
          follow-up attacks like{' '}
          <strong>Clara, Topaz, Jing Yuan and Dr. Ratio</strong>.
        </li>
        <ul>
          <li>
            It is important to note outside of the benefit of extending the buff
            to follow-ups and Ultimates activated before the following turn -
            this does not extend the buffs duration to INCLUDE the next turn.
          </li>
        </ul>
        <li>
          Activating Sparkle's Ultimate{' '}
          <strong>
            during the turn of your main damage dealer will apply the Cipher
            buff to them with 2-turn duration
          </strong>{' '}
          and as it was cast during their turn (thanks to how Cipher is coded in
          the game) it will remain at 2 turns even after their turn ends. When
          using Sparkles Ultimate this way, you can extend the duration of it
          from 2 turns to 3 turns, substantially boosting its up time and in
          some cases granting your main damage dealer permanant uptime (the Max
          Speed Sparkle build can achieve this).
        </li>
        <li>
          Making Sparkle 1 Speed slower than your dps and using her skill every
          action allows for a clean 50% more actions. By syncing up Sparkle with
          the dps she grants half a turn to your damage dealer every time she
          uses her skill and after using the 2nd skill she would have granted
          them a full turn.{' '}
          <strong>
            In Memory of Chaos, if your damage dealer would have gotten 2
            actions on the first cycle with Sparkle using her skill 1 Speed
            slower than them they will instead get 3
          </strong>
          . The best example of this is making your main damage dealer 135 Speed
          and Sparkle 134 Speed allowing you to get 3 actions on cycle 0 of
          Memory of Chaos instead of the usual 2.
        </li>
        <li>
          Raising Sparkle's Speed to 160 (with incredibly good gear), while
          leaving your main damage dealer with 0 Speed investment, allows
          Sparkle to pull your carry along with her thanks to her 50%
          advancement. With this setup, you will use your Sparkle's skill every
          action on your main damage carry effectively transferring Sparkle's
          Speed to your carry along with all her other buffs.{' '}
          <strong>
            With this setup, your carry, despite having 0 Speed investment will
            behave as a 160 Speed character
          </strong>{' '}
          gaining 2 attacks on the first and second cycle of Memory of Chaos,
          dramatically increasing their damage output while investing their
          relic stats elsewhere.
        </li>
        <ul>
          <li>
            The effective Speed granted by playing Sparkle this way is 26.5
            sub-stats of Speed. Which is more sub-stats than most characters
            have dedicated to their entire build. As a rule of thumb when
            playing Sparkle in this way as long as the character you're aiming
            to drag along with Sparkle is half her Speed (or higher) you'll
            always be able to instantly pull them to her. For exampe, if your
            dps is 102 Speed and Sparkle could but as fast as 204 Speed, she
            would still pull the dps to her with 0 gaps or wasted action value.
          </li>
        </ul>
        <li>
          Both Speed builds are viable and each have their own pros and cons.
          Speed syncing by 1 speed offers higher Break, faster rotations and
          less Speed investment needed for Sparkle but at the cost of lower
          Sparkle ult and skill uptime, higher skill point consumption and less
          Cone flexibility.
        </li>
        <li>
          Sparkles skill buff that grants the target a portion of her CRIT DMG
          only grants bonuses off of Sparkles base CRIT DMG stat.{' '}
          <strong>
            Buffing her in combat with other CRIT DMG boosts like Bronya's
            ultimate will not boost the efficacy of Sparkle skill.
          </strong>
        </li>
        <li>
          Sparkle's bonus ability that grants Quantum allies ATK% based on how
          many <strong>Quantum units are in your team includes herself</strong>{' '}
          so just having Sparkle and a Quantum damage dealer is already 2/3
          required, meaning the full bonus only requires 3 Quantum units leaving
          the last slot open for any elemental type.
        </li>
        <li>
          Thanks to Sparkles bonus ability Almanac you can use her basic attack
          at any time without worrying about gaining less energy as with it
          active her basic attack regenerates the same amount as her skill,
          ensuring you'll always gain access to her Ultimate within 3 actions
          minimum (assuming you have an energy rope equipped).
        </li>
        <li>
          Sparkles Ultimate immediately boosts her talents effect and doesn't
          require you to re-apply it before getting full benefit. Assuming you
          have 3 stacks already you can treat the ultimate as simply a 30%
          damage increase.
        </li>
        <li>
          <strong>
            Assuming you're using the correct setup, Sparkle is slightly skill
            point positive
          </strong>
          . After competing her full rotation she ends with skill point
          generation of 0.33 skill points per action which is on par with
          characters like Fu Xuan, Ruan Mei and E0 Huohuo. But thanks to her
          technique granting 3 more skill points you'll have a tonne to work
          with at the start, meaning you team will have more than enough fuel to
          tackle all but the longest fights without running out.
        </li>
      </ul>
    </>
  );
};
