import React from 'react';

export const ProfileSynergyAve: React.FC = () => {
  return (
    <>
      <ul>
        <li>
          Aventurine's Talent that grants him stacks of Blind Bet when allies
          are hit can activate multiple times per single enemy action with the
          maximum amount of stacks being possible being 5 - 1 from each party
          member being hit and 1 extra from aventurine being hit,
        </li>
        <li>
          When Aventurine possesses Fortified Wager and is hit by an enemy
          ability he gains 2 stacks of Blind Bet instead of one thanks to his
          Talent granting one as he possesses the Shield and 1 as he himself was
          hit,
        </li>
        <li>
          Aventurine's own follow-up attack does not count as a follow-up to
          gain Blind Bet,
        </li>
        <li>
          When adding additional shielding to Fortified Wager after triggering
          his Follow-Up Attack Aventurine refreshes the duration of the Shield
          back to 3 turns,
        </li>
        <li>
          Aventurine can accumulate up to twice the value of his fortified wager
          shield by re-applying it using his skill or follow-up attack,
        </li>
        <li>
          Aventurine becomes a more potent sustain character the more Follow-Up
          attackers he is teamed with or the more AoE attacks the enemy
          possesses,
        </li>
        <li>
          When paired with Follow-Up attackers ensuring Aventurine is fast
          enough to reset his bonus ability granting him Blind Bets from
          follow-up attacks is essential. The goal is for him to be fast enough
          he doesn't surpass the cap of 3,
        </li>
        <li>
          Aventurine's best Defensive and Offensive stat by far is Defence until
          he reaches 4000. After 4000 DEF he has ample shielding capabilties and
          no longer gains free CRIT RATE both of which increase the value of
          other options.
        </li>
      </ul>
    </>
  );
};
