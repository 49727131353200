import React from 'react';
import { HSRCharacter } from '../common/components/hsr-character';
import { Row, Col } from 'react-bootstrap';

export const ProfileSparkleDPS: React.FC = () => {
  return (
    <>
      <Row xs={1} xl={1} className="dps-comparison">
        <Col>
          <div className="box">
            <h5>Sparkle's buff impact on a damage dealer</h5>
            <div className="simulation-container">
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="sparkle" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>IL E0S1 + Hyperspeed Sparkle E2 + Earthly Escapade S1</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '100%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">2,013,365 dmg</span>
                      <span className="percent">383%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="sparkle" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>IL E0S1 + Hyperspeed Sparkle E2 + Past and Future S5</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '98.15%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">1,976,160 dmg</span>
                      <span className="percent">376%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="sparkle" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>IL E0S1 + Hyperspeed Sparkle E1 + Earthly Escapade S1</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '87.16%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">1,754,905 dmg</span>
                      <span className="percent">334%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="sparkle" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>IL E0S1 + Hyperspeed Sparkle E1 + Past and Future S5</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '85.55%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">1,722,477 dmg</span>
                      <span className="percent">327%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="sparkle" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>IL E0S1 + Hyperspeed Sparkle E0 + Earthly Escapade S1</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '76.39%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">1,537,957 dmg</span>
                      <span className="percent">292%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="sparkle" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>IL E0S1 + Hyperspeed Sparkle E0 + Past and Future S5</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '74.72%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">1,504,294 dmg</span>
                      <span className="percent">286%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter slug="sparkle" mode="icon" enablePopover />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>
                      IL E0S1 + Hyperspeed Sparkle E0 + But the Battle Isn't
                      Over S1
                    </p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar quantum`}
                      style={{ width: '74.22%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">1,494,335 dmg</span>
                      <span className="percent">284%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simulation-row">
                <div className="character">
                  <HSRCharacter
                    slug="imbibitor-lunae"
                    mode="icon"
                    enablePopover
                  />
                </div>
                <div className="chart">
                  <div className="info-2">
                    <p>Baseline (IL solo dps)</p>
                  </div>
                  <div className="dps-bar-outside">
                    <div
                      className={`dps-bar imaginary`}
                      style={{ width: '26.1%' }}
                    ></div>
                    <div className="data">
                      <span className="dps">525,416 dmg</span>
                      <span className="percent">100%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
